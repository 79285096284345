import React from "react";
import { Container } from "../../components/Container";
import { Sidebar } from "../../components/Sidebar";
import { Content } from "../../components/Content";
import { Navbar } from "../../components/Navbar";

import AppClass from "../../AppClass";
import Applications from "./Applications";
import LoanDetailsPayments from "../LoanDetailsPayments";
import LoanApplicationDetails from "./LoanApplicationDetails";
import AddPayment from "./AddPayment";
import Payments from "../Payments";
import UpdateApplication from "./UpdateApplication";
import Reports from "./Reports";
import LoanList from "./LoanList";
import Settings from "./Settings";
import ApplyLoan from "../member/ApplyLoan";
import Clients from "./Clients";
import TrustScreen from "./Trusts";
import SharesScreen from "./SharesScreen";
import TransactionsScreen from "./TransactionsScreen";
import PenaltyScreen from "./Penalty";
import AnalyticsScreen from "./AnalyticsScreen";

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      onTaggle: true,
      page: "",
      showDropDown: false,
      user_id: "",
      username: "",
      ztoken: "",
      admin: false,
      route: "",
      epesi_app: "Epesi Loan",
      apps: [],
    };
  }

  componentDidMount() {
    let data = AppClass.get_user_sessions();
    if (data?.ztoken) {
      this.setState({
        page: AppClass.getParams(this, "page"),
        user_id: data._id,
        username: data.firstName + " " + data.lastName,
        ztoken: data.ztoken,
        admin: true,
        phone_number: data.phone,
        apps: data.apps,
        diladmin: data.diladmin,
      });
    } else {
      window.location.href = "/adminAuth";
    }

    //setup page header from params
    let page = AppClass.getParams(this, "page");

    this.setState({
      route:
        page === "analytics"
          ? "Analytics"
          : page === "loans"
          ? "Loans"
          : page === "settings"
          ? "Loan Settings"
          : page === "applications"
          ? "Applications"
          : page === "view_loan_application"
          ? "Loan Application"
          : page === "view_loan_payments"
          ? "Loan Payments"
          : page === "add_payment"
          ? "New Payment"
          : page === "apply_loan"
          ? "Apply Loan"
          : page === "payments"
          ? "Payments"
          : page === "clients"
          ? "Clients"
          : page === "transactions"
          ? "Epesi Transactions"
          : page === "trusts"
          ? "Trusts"
          : page === "shares"
          ? "Shares"
          : page === "reports" && "Reports",
    });

    //monitoring system
    page === "analytics"
      ? this.save_monitoring("Analytics", data.firstName + " " + data.lastName)
      : page === "loans"
      ? this.save_monitoring("Loans", data.firstName + " " + data.lastName)
      : page === "settings"
      ? this.save_monitoring(
          "Loan Settings",
          data.firstName + " " + data.lastName
        )
      : page === "applications"
      ? this.save_monitoring(
          "Applications",
          data.firstName + " " + data.lastName
        )
      : page === "view_loan_application"
      ? this.save_monitoring(
          "Loan Application",
          data.firstName + " " + data.lastName
        )
      : page === "view_loan_payments"
      ? this.save_monitoring(
          "Loan Payments",
          data.firstName + " " + data.lastName
        )
      : page === "payments"
      ? this.save_monitoring("Payments", data.firstName + " " + data.lastName)
      : page === "clients"
      ? this.save_monitoring("Clients", data.firstName + " " + data.lastName)
      : page === "reports" &&
        this.save_monitoring("Reports", data.firstName + " " + data.lastName);
  }

  onTaggleSidebar() {
    this.setState({ onTaggle: this.state.onTaggle ? false : true });
  }

  signMeOut() {
    AppClass.signMeOut();

    window.location.href = "/adminAuth";
  }

  openMenu() {
    this.setState({ showDropDown: this.state.showDropDown ? false : true });
  }

  onChangeEpesiApp(e) {
    this.setState({ epesi_app: e.target.value });
    this.get_home_page(e.target.value);
  }

  apply_for_loan() {
    window.location.href = "/home/admin/apply_loan";
  }

  get_home_page(target_value) {
    if (target_value === "Epesi Loan") {
      window.location.href = "/home/admin/loans";
    }

    if (target_value === "Epesi Saving") {
      window.location.href = "/home/admin_savings/list";
    }
  }

  save_monitoring(page, username) {
    let body = {
      app_name: "Loan app",
      username,
      section: page,
      category: "Out-House",
      date: new Date().getTime(),
    };

    AppClass.monitoring(body)
      .then((data) => {
        //saved
      })
      .catch((err) => {
        //no error
      });
  }

  render() {
    const { admin, username, page, ztoken, route, epesi_app, phone_number } =
      this.state;
    console.log(this.state.user_id);
    return (
      <Container>
        <Sidebar
          role={admin && "admin"}
          page={this.state.page}
          onTaggle={this.state.onTaggle}
          onTaggleSidebar={this.onTaggleSidebar.bind(this)}
          epesi_app={epesi_app}
          onChangeEpesiApp={this.onChangeEpesiApp.bind(this)}
        />

        <Content>
          <Navbar
            role={admin && "admin"}
            signMeOut={this.signMeOut.bind(this)}
            openMenu={this.openMenu.bind(this)}
            showDropDown={this.state.showDropDown}
            username={username}
            page={page}
            route={route}
            onTaggle={this.state.onTaggle}
            onTaggleSidebar={this.onTaggleSidebar.bind(this)}
            current_app_type="loan"
            onSwitch={this.get_home_page.bind(this, "Epesi Saving")}
            show_btn={true}
            apply_for_loan={this.apply_for_loan.bind(this)}
            admin={admin}
          />
          <div className=" main-content">
            {this.state.page === "analytics" ? (
              <AnalyticsScreen
                ztoken={ztoken}
                role={admin && "admin"}
                apps={this.state.apps}
                admin={admin}
                user_id={this.state.user_id}
              />
            ) : this.state.page === "applications" ? (
              <Applications
                ztoken={ztoken}
                role={admin && "admin"}
                apps={this.state.apps}
                admin={admin}
                user_id={this.state.user_id}
              />
            ) : this.state.page === "payments" ? (
              <Payments
                ztoken={ztoken}
                role={admin && "admin"}
                admin={admin}
                apps={this.state.apps}
              />
            ) : this.state.page === "view_loan_payments" ? (
              <LoanDetailsPayments
                ztoken={ztoken}
                somethingID={AppClass.getParams(this, "somethingID")}
                role={admin && "admin"}
                apps={this.state.apps}
                admin={admin}
              />
            ) : this.state.page === "apply_loan" ? (
              <ApplyLoan
                ztoken={ztoken}
                role={admin && "admin"}
                user_id={this.state.user_id}
                apps={this.state.apps}
                admin={admin}
                somethingID={AppClass.getParams(this, "somethingID")}
              />
            ) : this.state.page === "view_loan_application" ? (
              <LoanApplicationDetails
                ztoken={ztoken}
                role={admin && "admin"}
                somethingID={AppClass.getParams(this, "somethingID")}
                apps={this.state.apps}
                user_id={this.state.user_id}
                admin={admin}
              />
            ) : this.state.page === "add_payment" ? (
              <AddPayment
                ztoken={ztoken}
                somethingID={AppClass.getParams(this, "somethingID")}
                phone_number={phone_number}
                role={admin && "admin"}
                apps={this.state.apps}
                admin={admin}
              />
            ) : this.state.page === "update_loan_application" ? (
              <UpdateApplication
                ztoken={ztoken}
                apps={this.state.apps}
                role={admin && "admin"}
              />
            ) : this.state.page === "reports" ? (
              <Reports
                ztoken={ztoken}
                apps={this.state.apps}
                role={admin && "admin"}
                admin={admin}
              />
            ) : this.state.page === "loans" ? (
              <LoanList
                ztoken={ztoken}
                apps={this.state.apps}
                role={admin && "admin"}
                admin={admin}
              />
            ) : this.state.page === "trusts" ? (
              <TrustScreen
                ztoken={ztoken}
                apps={this.state.apps}
                role={admin && "admin"}
                admin={admin}
              />
            ) : this.state.page === "transactions" ? (
              <TransactionsScreen
                ztoken={ztoken}
                apps={this.state.apps}
                role={admin && "admin"}
                admin={admin}
              />
            ) : this.state.page === "shares" ? (
              <SharesScreen
                ztoken={ztoken}
                apps={this.state.apps}
                role={admin && "admin"}
                admin={admin}
              />
            ) : this.state.page === "penalties" ? (
              <PenaltyScreen
                ztoken={ztoken}
                apps={this.state.apps}
                role={admin && "admin"}
                admin={admin}
              />
            ) : this.state.page === "clients" ? (
              <Clients ztoken={ztoken} apps={this.state.apps} />
            ) : (
              this.state.page === "settings" && (
                <Settings
                  ztoken={ztoken}
                  apps={this.state.apps}
                  role={admin && "admin"}
                  admin={admin}
                />
              )
            )}
          </div>
        </Content>
      </Container>
    );
  }
}

export default Home;
