import React from "react";
import { Container } from "../../../components/Container";
import { Sidebar } from "../../../components/Sidebar";
import { Content } from "../../../components/Content";
import { Navbar } from "../../../components/Navbar";

import AppClass from "../../../AppClass";
import Savings from "../Savings";

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      onTaggle: true,
      page: "",
      showDropDown: false,
      user_id: "",
      username: "",
      ztoken: "",
      admin: false,
      route: "",
      epesi_app: "Epesi Saving",
      saving_id: "",
      apps: [],
    };
  }

  componentDidMount() {
    let data = AppClass.get_user_sessions();
    if (data !== null) {
      this.setState({
        page: AppClass.getParams(this, "page"),
        user_id: data._id,
        username: data.firstName + " " + data.lastName,
        ztoken: data.ztoken,
        admin: false,
        apps: data.apps,
      });
    } else {
      window.location.href = "/adminAuth";
    }

    //setup page header from params
    let page = AppClass.getParams(this, "page");

    this.setState({
      route: page === "list" ? "Savings" : "",
    });

    page === "list" &&
      this.save_monitoring("Savings", data.firstName + " " + data.lastName);
  }

  onTaggleSidebar() {
    this.setState({ onTaggle: this.state.onTaggle ? false : true });
  }

  signMeOut() {
    AppClass.signMeOut();

    window.location.href = "/";
  }

  openMenu() {
    this.setState({ showDropDown: this.state.showDropDown ? false : true });
  }

  onChangeEpesiApp(e) {
    this.setState({ epesi_app: e.target.value });
    this.get_home_page(e.target.value);
  }

  get_home_page(target_value) {
    if (target_value === "Epesi Loan") {
      window.location.href = "/home/member/overview";
    }

    if (target_value === "Epesi Saving") {
      window.location.href = "/home/member_savings/list";
    }
  }

  save_monitoring(page, username) {
    let body = {
      app_name: "Loan app / Savings",
      username,
      section: page,
      category: "Out-House",
      date: new Date().getTime(),
    };

    AppClass.monitoring(body)
      .then((data) => {
        //saved
      })
      .catch((err) => {
        //no error
      });
  }

  render() {
    const { username, page, ztoken, route, epesi_app, user_id } = this.state;
    console.log("page here******", this.state.page);
    return (
      <Container>
        <Sidebar
          role={"member"}
          page={this.state.page}
          onTaggle={this.state.onTaggle}
          onTaggleSidebar={this.onTaggleSidebar.bind(this)}
          epesi_app={epesi_app}
          onChangeEpesiApp={this.onChangeEpesiApp.bind(this)}
        />

        <Content>
          <Navbar
            role={"member"}
            signMeOut={this.signMeOut.bind(this)}
            openMenu={this.openMenu.bind(this)}
            showDropDown={this.state.showDropDown}
            username={username}
            page={page}
            route={route}
            onTaggle={this.state.onTaggle}
            onTaggleSidebar={this.onTaggleSidebar.bind(this)}
            show_btn={epesi_app === "Epesi Saving" ? false : true}
            current_app_type="savings"
            onSwitch={this.get_home_page.bind(this, "Epesi Loan")}
          />
          {this.state.page === "list" && (
            <Savings
              ztoken={ztoken}
              role={"member"}
              user_id={user_id}
              saving_permission={"member"}
              apps={this.state.apps}
            />
          )}
        </Content>
      </Container>
    );
  }
}

export default Home;
