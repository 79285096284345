/* eslint-disable no-unused-vars */
import React from "react";
import { Container } from "../../components/Container";
import AppClass from "../../AppClass";
import axios from "axios";
import HOST_URL from "../../configs/api";
import { Redirect } from "react-router-dom";
import { Loading } from "../../components/Loader";
import toastMessage from "../../components/toastMessage";
import { Select } from "../../components/Select";

class Admin extends React.Component {
  constructor() {
    super();

    this.state = {
      isSubmitting: false,
      response: {},
      user_id: "",
      password: "",
      redirect: false,
      isAutoLogin: false,
      user: {},
      apps: [],
      isAppActive: false,
    };
  }

  componentDidMount = async () => {
    const { search } = this.props.location;

    if (search) {
      var str_f = search.substring(1);
      const decData = decodeURI(str_f);
      const finalData = JSON.parse(decData || {});
      let { userProfile, token, apps } = finalData;

      const user = {
        ...userProfile,
        ztoken: token,
        apps,
      };

      this.setState({ isAutoLogin: true });

      const app_id = await AppClass.get_app_ids(apps || []);

      this.register_member(user, app_id);
    }
  };

  onChangeApps(e) {
    let { response } = this.state;
    delete response["app"];
    this.setState({
      app: e,
      response,
    });
  }

  handleLogin = async (e) => {
    e && e.preventDefault();

    this.setState({ isSubmitting: true });

    const { user_id, password } = this.state;

    const data = {
      user: user_id,
      password,
    };

    const errors = AppClass.validate_form(data);

    if (!Object.keys(errors).length === 0) {
    }

    const newLoginStepOne = await this.handleNewLoginStepOne();

    if (newLoginStepOne?.apps?.length > 0) {
      this.setState({
        isAppActive: true,
      });

      return;
    }

    //handle oldLogin
    this.onOldLogin();
  };

  handleNewLoginStepOne = async () => {
    let { user_id, apps } = this.state;

    try {
      const stepOneOptions = {
        method: "POST",
        url: `${HOST_URL.new_api}/login-send-user`,
        data: {
          data: {
            user: user_id,
          },
        },
        timeout: 5000, // Timeout set to 5 seconds
      };

      const userOrg = await axios(stepOneOptions);

      const { data: dataStepOne } = userOrg;

      if (!dataStepOne.success) {
        return;
      }

      if (dataStepOne?.data?.length > 0) {
        apps = dataStepOne.data[0].map((el) => {
          return {
            label: el.name,
            value: el._id,
            ...el,
          };
        });

        this.setState({ apps, isSubmitting: false });

        return { apps };
      }
    } catch (error) {
      return error;
    }
  };

  validateStepTwoLogin() {
    let { response, app } = this.state;

    if (!app) {
      response.app = "Please select sacco";
    }

    this.setState({ response });
  }

  handleNewLoginStepTwo = async (e) => {
    e.preventDefault();
    const { user_id, password, app, response } = this.state;

    await this.validateStepTwoLogin();

    if (Object.keys(response).length === 0) {
      this.setState({ isSubmitting: true });

      const options = {
        method: "POST",
        url: `${HOST_URL.new_api}/login`,
        data: {
          data: {
            user: user_id,
            password: password,
            orgid: app.value,
          },
        },
      };

      axios(options)
        .then(async (res) => {
          let { data, token, modules } = res.data;
          data.ztoken = token;

          const apps = await this.getModulesOnlyHasLoanAndSavingsAccess(
            modules,
            token
          );
          data.apps = apps;

          const app_ids = await AppClass.get_app_ids(apps);
          this.register_member(data, app_ids);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", "Wrong credentials");
        });
    }
  };

  getModulesOnlyHasLoanAndSavingsAccess = async (modules = {}, token) => {
    let apps = [];
    for (let md of Object.keys(modules)) {
      if (modules[md].loansAndSaving) {
        const appInfo = await AppClass.getNewAppById(token, md);

        apps.push({
          label: appInfo.name,
          value: appInfo._id,
          ...appInfo,
        });
      }
    }

    return apps;
  };

  onOldLogin() {
    this.setState({ isSubmitting: true });

    const { user_id, password } = this.state;

    const data = {
      user: user_id,
      password,
    };

    const errors = AppClass.validate_form(data);

    if (!Object.keys(errors).length) {
      let response = {};

      axios({
        method: "POST",
        url: HOST_URL.mobitill_url + "/login",
        data: { data: data },
        timeout: 5000, // Timeout set to 5 seconds
      })
        .then((data) => {
          let user = data.data.data;
          user.ztoken = data.data.token;

          if (!user.name && !user.firstName) {
            user.firstName = user.phone;
            user.lastName = "-";
          }

          this.onSuccess(user);
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          if (err.response && err.response.data) {
            response.error = err.response.data.errors;
          } else {
            response.error = err.error;
          }
          this.setState({
            response,
            isSubmitting: false,
          });
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  onSuccess = async (user) => {
    try {
      let apps = [],
        app_ids = [];

      apps =
        user && user.role && user.role.diladmin
          ? await AppClass.get_apps(user.ztoken)
          : await AppClass.get_user_apps(user.ztoken, {
              user: {
                _id: user._id,
              },
            });

      let target =
        user && user.role && user.role.diladmin ? "diladmin" : "subAccess";

      apps = await AppClass.accessedApps(apps.data.data, target); //get apps  have access to

      user.apps = apps;
      app_ids = await AppClass.get_app_ids(apps);

      this.register_member(user, app_ids);
    } catch (error) {
      let response = {};
      response.error = "Error, " + error;
      this.setState({
        response,
        isSubmitting: false,
      });
    }
  };

  register_member(user, app_id) {
    let response = {},
      clientid = undefined;

    if (user.name) {
      let split = user.name.split(" ");

      user.firstName = split[0];
      user.lastName = split.length > 1 ? split[1] : "-";
    }

    if (user.clientid && user.clientid.length > 0) {
      clientid = user.clientid[0].clientid;
      user._id = user.clientid[0].clientid;
      user.client_id = user.clientid[0].clientid;
    }

    const body = {
      model: "register",
      app_id,
      user_name: user.firstName + " " + user.lastName,
      user_id: clientid || user._id,
      phone_number: user.phone,
      clientid,
    };

    AppClass.register(body)
      .then((data) => {
        this.setState({
          apps: data.data.data,
          isSubmitting: false,
          isAutoLogin: false,
        });

        if (this.state.autoUsed) {
          delete user.user_id;
        }

        if (data.data.data.length > 0) {
          user.epesi_loan_saving_account_id = data.data.data[0]._id;
        }

        sessionStorage.setItem("saccoloan", JSON.stringify(user));
        this.setState({
          isSubmitting: false,
          redirect: true,
          user,
        });

        if (user.role && user.role.diladmin && this.state.autoUsed) {
          return (window.location.href = "/home/admin/loans");
        }

        if (
          this.state.autoUsed &&
          user.role &&
          user.role.sales &&
          this.state.autoUsed
        ) {
          return (window.location.href = "/home/admin/loans");
        }

        if (user.role && user.role.tadmin && this.state.autoUsed) {
          return (window.location.href = "/home/admin/loans");
        }

        return (window.location.href = "/home/member/overview");
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
          isAutoLogin: false,
        });
      });
  }

  render() {
    const { response, isSubmitting, user_id, password, user, autoUsed } =
      this.state;

    return (
      <Container>
        {this.state.isAutoLogin ? (
          <Loading icon="fa fa-spinner fa-spin" message="Logging you in..." />
        ) : (
          <div className="col-xs-10 col-sm-7 col-md-3 login">
            <div className="panel auth">
              <div className="panel-heading">
                <div className="header-logo">
                  {/* <div className="logo">
                    <img src={mobitilllogo} className="dil_logo" />
                  </div> */}
                  <span>Sign in as a member</span>
                </div>
              </div>
              <div className="panel-body">
                {response.error ? (
                  <div className="alert alert-danger">
                    <span>{response.error}</span>
                  </div>
                ) : (
                  response.success && (
                    <div className="alert alert-success">
                      <span>{response.success}</span>
                    </div>
                  )
                )}
                <form method="post">
                  <div className="form-group">
                    <label>
                      Email Address <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control input-lg"
                      name="user_id"
                      placeholder="User ID"
                      value={user_id}
                      onChange={(e) => AppClass.handleformonchange(this, e)}
                    />
                    {/* <i className="fa fa-user" /> */}
                    <span className="text-danger">{response.user_id}</span>
                  </div>

                  <div className="form-group">
                    <label>
                      Password <span className="required">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control input-lg"
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => AppClass.handleformonchange(this, e)}
                    />
                    {/* <i className="fa fa-lock" /> */}
                    <span className="text-danger">{response.password}</span>
                  </div>
                  {this.state.isAppActive && (
                    <div className="form-group">
                      <Select
                        options={this.state.apps}
                        value={this.state.app}
                        label="Organization"
                        onChange={this.onChangeApps.bind(this)}
                      />
                      <span className="text-danger">{response.app}</span>
                    </div>
                  )}
                  <button
                    disabled={isSubmitting}
                    onClick={
                      this.state.isAppActive
                        ? this.handleNewLoginStepTwo.bind(this)
                        : this.handleLogin.bind(this)
                    }
                    className="btn btn-primary btn-lg btn-block"
                  >
                    {isSubmitting
                      ? " Please wait..."
                      : this.state.isAppActive
                      ? "Continue"
                      : "Login"}
                  </button>

                  {/* <center className="otherlogin">
                  <a href="/register">Don't have an account? <b>Register</b></a>
                </center> */}
                  <center className="otherlogin">
                    <a href="/adminAuth">Admin Login</a>
                  </center>
                </form>
              </div>
            </div>
            {/* <center className="otherlogin">
            <a href="/adminAuth">Admin Login</a>
          </center> */}
          </div>
        )}
      </Container>
    );
  }
}

export default Admin;
