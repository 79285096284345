// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr.loan-paid {
  opacity: 0.6;
}

tr.loan-paid:hover {
  cursor: no-drop;
}

div.panel-body.pending {
  padding: 0;
}
div.loan-pending {
  background: #f2f2f2;
  position: relative;
  padding: 20px;
  border-bottom: 1px solid #e7e7e7;
}

div.loan-pending::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  margin-left: 50%;
  margin-bottom: -10px;
  transform: rotate(45deg);
  border-top: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
}

div.loan-explanation {
  padding: 20px;
  width: 400px;
}

div.loan-explanation h2 {
  font-size: 20px;
}

div.loan-explanation span {
  /* font-size: 20px; */
  opacity: 0.6;
}

div.loan-explanation a {
  text-decoration: underline;
  font-weight: 600;
  color: rgb(243, 35, 35);
}
`, "",{"version":3,"sources":["webpack://./src/App/components/Loan/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,gBAAgB;EAChB,oBAAoB;EACpB,wBAAwB;EACxB,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":["tr.loan-paid {\n  opacity: 0.6;\n}\n\ntr.loan-paid:hover {\n  cursor: no-drop;\n}\n\ndiv.panel-body.pending {\n  padding: 0;\n}\ndiv.loan-pending {\n  background: #f2f2f2;\n  position: relative;\n  padding: 20px;\n  border-bottom: 1px solid #e7e7e7;\n}\n\ndiv.loan-pending::before {\n  content: \"\";\n  width: 20px;\n  height: 20px;\n  background: #ffffff;\n  position: absolute;\n  bottom: 0;\n  margin-left: 50%;\n  margin-bottom: -10px;\n  transform: rotate(45deg);\n  border-top: 1px solid #e7e7e7;\n  border-left: 1px solid #e7e7e7;\n}\n\ndiv.loan-explanation {\n  padding: 20px;\n  width: 400px;\n}\n\ndiv.loan-explanation h2 {\n  font-size: 20px;\n}\n\ndiv.loan-explanation span {\n  /* font-size: 20px; */\n  opacity: 0.6;\n}\n\ndiv.loan-explanation a {\n  text-decoration: underline;\n  font-weight: 600;\n  color: rgb(243, 35, 35);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
