import React from "react";
import { Card } from "../Card";

const WithdrawForm = ({
  amount,
  isSubmitting,
  handleSaveData,
  balance,
  isLoading,
  error,
  onChangeText,
}) => (
  <div>
    <div>
      {error.errorMessage && (
        <div className="alert alert-danger">
          <span>{error.errorMessage}</span>
        </div>
      )}
    </div>
    <Card>
      <div className="panel-heading card-title">
        <span>Withdraw Form</span>
      </div>
      <div className="panel-body">
        <div className="col-md-4">
          <div className="form-group">
            <label>Balance</label>
            <input
              className="form-control"
              type="text"
              name="amount"
              value={"KES " + balance}
              disabled
            />
            <span className="text-danger">{error.balance}</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Amount</label>
            <input
              className="form-control"
              type="number"
              value={amount}
              onChange={(e) => onChangeText("amount", e)}
              max={balance}
              min={0}
            />
            <span className="text-danger">{error.amount}</span>
          </div>
        </div>
        <div className="col-md-2" style={{ marginTop: 25 }}>
          <button
            className="btn btn-primary  btn-block"
            disabled={isSubmitting || isLoading ? true : false}
            onClick={handleSaveData}
          >
            {isSubmitting ? "Sending..." : "Submit"}
          </button>
        </div>
      </div>
    </Card>
  </div>
);
export default WithdrawForm;
