import moment from "moment";
import { ON_ADD_FILTER, ON_RESET_FILTERS } from "../actions/Filters";
import { getStorage } from "../utils/storage";

const filtersLocal = await getStorage("filters");

console.log({ filtersLocal });

const defaultFilters = {
  start_date: moment().startOf("D").subtract(30, "days").format("YYYY-MM-DD"),
  end_date: moment().endOf("day").format("YYYY-MM-DD"),
};

let filters = filtersLocal || defaultFilters;

const initialState = {
  filters,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_ADD_FILTER:
      Object.keys(action.payload).forEach((key) => {
        state.filters[key] = action.payload[key];
      });

      sessionStorage.setItem("filters", JSON.stringify(state.filters));

      return {
        ...state,
        filters: { ...state.filters },
      };
    case ON_RESET_FILTERS:
      if (action.fieldName) {
        delete state.filters[action.fieldName];
      } else {
        state.filters = {
          ...defaultFilters,
        };
      }

      sessionStorage.setItem("filters", JSON.stringify(state.filters));

      return {
        ...state,
        filters: { ...state.filters },
      };
    default:
      return state;
  }
};
export default reducer;
