import React from "react";
import { Analytics } from "../../components/Analytics";

class AnalyticsScreen extends React.Component {
  render() {
    return <Analytics {...this.props} />;
  }
}

export default AnalyticsScreen;
