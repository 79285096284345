import React from "react";
import { Card } from "../../Card";
import moment from "moment";

const UpdateLoanApplication = ({
  handleformonchange,
  response,
  approved_amount,
  status,
  date_approved,
  update_loan,
  isSubmitting,
  handleFile,
  logged_user_id,
  user_id,
  decline,
  createdBy,
  loan,
}) => (
  <Card className="col-md-12">
    <div className="panel-heading card-title">
      <span>Update Application </span>
    </div>
    <div className="panel-body">
      <div className="col-md-4">
        <div className="form-group">
          <label>Status</label>
          <select
            className="form-control"
            name="status"
            onChange={handleformonchange}
            data-live-search="true"
          >
            <option selected={status === "Pending" && true}>
              Under Evaluation
            </option>
            {status === "Processing" && (
              <option selected={status === "Processing" && true}>
                Accepted
              </option>
            )}
          </select>
          <span className="text-danger">{response.status}</span>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label>Approved Amount</label>
          <input
            className="form-control"
            type="number"
            value={approved_amount}
            name="approved_amount"
            step=".01"
            onChange={handleformonchange}
          />
          <span className="text-danger">{response.approved_amount}</span>
        </div>
      </div>
      <div className="col-md-4">
        {console.log({ date_approved })}
        <div className="form-group">
          <label>Date Approved</label>
          <input
            className="form-control"
            type="date"
            name="date_approved"
            value={moment(date_approved).format("YYYY-MM-DD")}
            onChange={handleformonchange}
          />
          <span className="text-danger">{response.date_approved}</span>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label>File</label>
          <input
            className="form-control"
            type="file"
            name="file"
            onChange={handleFile}
          />
          <span className="text-danger">{response.file}</span>
        </div>
      </div>
      <div className="col-md-12">
        {createdBy !== logged_user_id && (
          <div className="row" style={{ marginTop: 25 }}>
            <div className="col-md-3">
              <button className="btn btn-danger btn-block " onClick={decline}>
                Decline
              </button>
            </div>
            {((loan && loan.length > 0) ||
              (loan.length > 0 &&
                loan[0].guaranted_amount &&
                Object.keys(loan[0].guaranted_amount).length > 0)) && (
              <div className="col-md-3">
                {isSubmitting ? (
                  <button
                    className="btn btn-primary btn-block "
                    disabled={true}
                  >
                    Updating...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-block "
                    onClick={(approved_amount, interestrate, files) =>
                      update_loan(approved_amount, interestrate, files)
                    }
                  >
                    Update
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  </Card>
);

export default UpdateLoanApplication;
