import React from "react";
import { connect } from "react-redux";
import { Loading } from "../Loader";
import "./styles.css";
import { Empty } from "../Empty";
import moment from "moment";
import { Line } from "react-chartjs-2";
import AppClass from "../../AppClass";
import toastMessage from "../toastMessage";
import HOST_URL from "../../configs/api";
import axios from "axios";

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
};

class Daily extends React.Component {
  state = {
    isLoading: false,
    data: {},
  };

  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.getData(true);
    }
  }

  // getData = async (isLoading) => {
  //   const { defaultLanguage, filters } = this.props;
  //   const user = await getStorage();
  //   try {
  //     this.setState({ isLoading });

  //     // await reportFilters(filters);

  //     const responseData = await

  //     // fetchTransactionsSummary(
  //     //   defaultLanguage,
  //     //   await reportFilters(filters)
  //     // );
  //     const chartData = await this.returnChartData(responseData.content);

  //     this.setState({
  //       data: chartData,
  //       isLoading: false,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     this.setState({
  //       isLoading: false,
  //     });
  //   }
  // };

  getData(isLoading) {
    this.setState({ isLoading });

    let { token, start_date, end_date, apps, appid } = this.props;

    let params = {},
      app_id = [];

    if (!appid?.value) {
      app_id = AppClass.get_app_ids(apps);
    } else {
      app_id = [appid.value];
    }

    params.app_id = JSON.stringify(app_id);

    if (start_date !== undefined || end_date !== undefined) {
      params["fromDate"] = start_date;
      params["toDate"] = end_date;
    }

    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/general`,
      params,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    axios(options)
      .then((data) => {
        const { loans, savings, payments } = data?.data?.data || {};
        console.log(data.data);
        // this.setState({
        //   isLoading: false,
        //   loans,
        //   savings,
        //   payments,
        // });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  returnChartData(data) {
    const chartData = [];
    data.forEach((item) => {
      const date = moment(item.date).format("YYYY-MM-DD");

      if (!chartData[date]) {
        chartData[date] = 0;
      }

      chartData[date] += Math.round(item.amount);
    });

    return {
      labels: Object.keys(chartData),
      datasets: [
        {
          label: "Total Daily Transactions",
          data: Object.values(chartData),
          backgroundColor: "#ffeded",
          borderColor: "#c21510",
          radius: 5,
        },
      ],
    };
  }

  render() {
    console.log(this.props);
    return (
      <div className="chart-container mb-3 mt-3">
        <div className="card">
          <div className="card-header">
            <div style={{ flex: 1 }}>Daily Transactions</div>
          </div>
          <div className="card-body" style={{ height: 380 }}>
            {this.state.isLoading ? (
              <Loading />
            ) : this.state.data?.datasets?.length > 0 ? (
              <Line data={this.state.data} options={options} />
            ) : (
              <Empty
                title="No data available"
                // icon={icons.chart}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(Daily);
