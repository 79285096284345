import React from "react";
import { numberWithCommas } from "../../../configs/formatCurrency";
import HOST_URL from "../../../configs/api";
import Axios from "axios";
import toastMessage from "../../../components/toastMessage";
import { saveAs } from "file-saver";
import { Body, Footer, Header, Modal } from "../../../components/Modal";
class Loans extends React.Component {
  state = {
    selectedData: {},
  };
  handleShowTransaction(i) {
    const key = "showTrans_" + i;

    this.setState({
      [key]: !this.state[key],
    });
  }

  handleGenerateReport(type='pdf') {
    this.setState({ isSubmitting: true });
    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/aggregate/loans/${type}`,
      responseType: "blob",
      params: {
        fromDate: this.props.fromDate,
        toDate: this.props.toDate,
        app_id: this.props.appid,
      },
      headers: {
        authorization: `Bearer ${this.props.ztoken}`,
        "Content-Type": `application/${type}`,
      },
    };

    Axios(options)
      .then((data) => {
        this.downloadFile(data.data, type === 'excel' ? 'xlsx' : type);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("error", "Unable to generate loans aggregate statement report");
      });
  }

  downloadFile(file, type) {
    try {
      toastMessage("success", "Download successful");
      this.setState({ isSubmitting: false });
      const pdfBlob = new Blob([file], { type: `application/${type}` });
      saveAs(
        pdfBlob,
        "AggregateReport" +
          this.props.fromDate +
          "" +
          this.props.toDate +
          `.${type}`
      );
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("Error downloading File:", JSON.stringify(error));
    }
  }

  handleOpenModal(modal, modalTitle, selectedData) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  render() {
    return (
      <>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => this.handleGenerateReport('pdf')}
            disabled={this.state.isSubmitting}
          >
            {this.state.isSubmitting
              ? "Downloading, Please wait..."
              : "Download PDF Report"}
          </button>
          <button
            className="btn csv-btn"
            onClick={() => this.handleGenerateReport('excel')}
            disabled={this.state.isSubmitting}
          >
            {this.state.isSubmitting
              ? "Downloading, Please wait..."
              : "Download Excel Report"}
          </button>
        </div>
        <br />
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Member Name</th>
                <th style={{ textAlign: "right" }}>Total Amount</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.map((el, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {i === this.props.data.length - 1 ? (
                        <strong>{el.name}</strong>
                      ) : (
                        el.name
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {i === this.props.data.length - 1 ? (
                        <strong>KES {numberWithCommas(el.total)}</strong>
                      ) : (
                        "KES " + numberWithCommas(el.total)
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <button
                        className="btn"
                        onClick={() =>
                          this.handleOpenModal("showLoansModal", el.name, el)
                        }
                      >
                        <i className="fa fa-eye" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Modal show={this.state.showLoansModal}>
            <Header>
              <h5 className="modal-title">{this.state.modalTitle}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.handleCloseModal("showLoansModal")}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Header>
            <Body>
              <div className="panel">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Loan Type</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state?.selectedData?.loans?.map((el, i) => {
                          return (
                            <tr key={i}>
                              <td>{el.purpose}</td>
                              <td>KES {numberWithCommas(el.total)}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td>
                            <strong>TOTAL</strong>
                          </td>
                          <td>
                            <strong>
                              KES{" "}
                              {numberWithCommas(
                                this.state?.selectedData?.total
                              )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Body>
            <Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => this.handleCloseModal("showLoansModal")}
                style={{ marginRight: 25 }}
              >
                Close
              </button>
            </Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default Loans;
