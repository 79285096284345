import React from "react";
import PropTypes from "prop-types";

const Content = ({ children, clickOutSide }) => (
  <div className="content" onClick={clickOutSide}>
    {children}
  </div>
);
Content.propTypes = {
  children: PropTypes.any,
};
export default Content;
