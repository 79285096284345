import React from "react";
import { Card } from "../Card";
import Sacco from "../../data/sacco.json";

const ViewApp = ({
  onChangeLoanPeriod,
  handleformonchange,
  rate,
  response,
  loan_period,
  caluculateLoanAmortization,
  total_interest,
  monthly_payment,
  total_payments,
  disabled,
  onChangeSacco,
  sacco,
  application_id,
  handleApplyData,
  isSubmitting,
  editForm,
}) => (
  <div>
    <div className="col-md-12">
      {response.error ? (
        <div className="alert alert-danger">
          <span>{response.error}</span>
        </div>
      ) : (
        response.success && (
          <div className="alert alert-success">
            <span>
              {response.success}{" "}
              <a href="/home/member/loandetails/pending">View Application</a>
            </span>
          </div>
        )
      )}
    </div>
    <Card className="col-md-6 filter">
      <div className="panel-heading card-title">
        <span>Application Form</span>
      </div>
      <div className="panel-body">
        <div className="col-md-7">
          <div className="form-group">
            <label>Sacco</label>
            <select
              className="form-control"
              name="sacco"
              onChange={onChangeSacco}
              disabled={disabled}
              data-live-search="true"
            >
              <option>Choose Sacco</option>
              {Sacco.map((item, i) => {
                return (
                  <option
                    key={i}
                    selected={item.app_id === sacco}
                    value={item.app_id}
                  >
                    {item.name}
                  </option>
                );
              })}
            </select>
            <span className="text-danger">{response.sacco}</span>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label>Loan Application ID</label>
            <input
              className="form-control"
              value={application_id}
              disabled
              onChange={handleformonchange}
            />
            <span className="text-danger">{response.application_id}</span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Loan Amount</label>
            <input
              className="form-control"
              type="number"
              name="loan_amount"
              onChange={handleformonchange}
              disabled={disabled}
            />
            <span className="text-danger">{response.loan_amount}</span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Interest Rate</label>
            <input
              className="form-control"
              disabled
              value={rate + "%"}
              name="rate"
              min={0}
              max={100}
              step={0.1}
              onChange={handleformonchange}
            />
            <span className="text-danger">{response.rate}</span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>
              Loan Period{" "}
              <span className="text-danger">{response.loan_period}</span>
            </label>
            <input
              className="form-control"
              value={loan_period}
              name="loan_period"
              onChange={onChangeLoanPeriod}
              disabled={disabled}
            />
            <span className="text-disabled">Format: 12m, 26m, 3y, 4y, 4y</span>
          </div>
        </div>
        <div className="col-md-4">
          <button className="btn btn-info  btn-block" onClick={editForm}>
            Edit
          </button>
        </div>
        <div className="col-md-4">
          <button
            className="btn btn-info  btn-block"
            onClick={caluculateLoanAmortization}
            disabled
          >
            Calculate
          </button>
        </div>
      </div>
    </Card>
    <Card className="col-md-6">
      <div className="panel-heading card-title">
        <span>Result</span>
      </div>
      <div className="panel-body">
        <p>
          <b>Monthly Payment</b>
        </p>
        <span>KES {monthly_payment}</span>
        <hr />
        <p>
          <b>Number of Payments</b>
        </p>
        <span>{loan_period} Months</span>
        <hr />
        <p>
          <b>Total Payments</b>
        </p>
        <span>KES {total_payments}</span>
        <hr />
        <p>
          <b>Total Interest</b>
        </p>
        <span>KES {total_interest}</span>
      </div>
      <button
        className="btn btn-primary  btn-block"
        style={{ borderRadius: 0 }}
        disabled
        onClick={handleApplyData}
      >
        {isSubmitting ? "Sending..." : "Save Changes"}
      </button>
    </Card>
  </div>
);

export default ViewApp;
