import { jwtDecode } from "jwt-decode";
import AppClass from "../AppClass";

export default () => {
  let { ztoken } = AppClass.get_user_sessions() || {};

  let now = Math.round(new Date().getTime() / 1000);

  if (!ztoken) return;

  let { exp } = jwtDecode(ztoken);
  let isExpired = false;

  if (now > exp) {
    isExpired = true;
  }

  return isExpired;
};
