// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.loading {
  height: 400px;
  opacity: .4;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.loading i{
  font-size: 50px;
  margin-bottom: 20px
}

div.loading p {
  font-size: 20px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/App/components/Loader/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf;AACF;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["div.loading {\n  height: 400px;\n  opacity: .4;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\ndiv.loading i{\n  font-size: 50px;\n  margin-bottom: 20px\n}\n\ndiv.loading p {\n  font-size: 20px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
