import React from "react";
import { Container } from "../../components/Container";
import AppClass from "../../AppClass";
import { Redirect } from "react-router-dom";

class Register extends React.Component {
  constructor() {
    super();

    this.state = {
      isSubmitting: false,
      response: {},
      name: "",
      fleet: "",
      redirect: false,
    };
  }

  onRegister(e) {
    e.preventDefault();

    this.setState({ isSubmitting: true });

    const { name, fleet, password } = this.state;

    const input_data = {
      name,
      // fleet,
      password,
      model: "Register",
    };

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors).length) {
      let response = {};

      AppClass.add_data(input_data)
        .then((data) => {
          sessionStorage.setItem("saccoloan", JSON.stringify(data.data.data));
          this.setState({
            isSubmitting: false,
            redirect: true,
          });
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          response.error = "Error, " + err;
          this.setState({
            response,
          });
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  render() {
    const { response, isSubmitting, name, fleet, password, email } = this.state;

    if (this.state.redirect) {
      const sess = [];
      sess.push(JSON.parse(sessionStorage.getItem("saccoloan")));
      if (sessionStorage.getItem("saccoloan") !== null) {
        return <Redirect to={`/home/member/overview`} />;
      }
    }

    return (
      <Container>
        <div className="col-xs-10 col-sm-7 col-md-3 login">
          {response.error ? (
            <div className="alert alert-danger">
              <span>{response.error}</span>
            </div>
          ) : (
            response.success && (
              <div className="alert alert-success">
                <span>{response.success}</span>
              </div>
            )
          )}
          <div className="panel auth">
            <div className="panel-heading">
              <div className="header-logo">
                <div className="logo">E</div>
                <span>You're welcome to Epesi Loan</span>
              </div>
            </div>
            <div className="panel-body" style={{ marginTop: 100 }}>
              <form method="post">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control input-lg"
                    name="name"
                    placeholder="Your Names *"
                    value={name}
                    onChange={(e) => AppClass.handleformonchange(this, e)}
                  />
                  <i className="fa fa-user" />
                  <span className="text-danger">{response.name}</span>
                </div>
                <div className="form-group" style={{ display: "none" }}>
                  <input
                    type="text"
                    className="form-control input-lg"
                    name="fleet"
                    placeholder="Fleet Number"
                    value={fleet}
                    onChange={(e) => AppClass.handleformonchange(this, e)}
                  />
                  <i className="fa fa-car" />
                  <span className="text-danger">{response.fleet}</span>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control input-lg"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => AppClass.handleformonchange(this, e)}
                  />
                  <i className="fa fa-lock" />
                  <span className="text-danger">{response.password}</span>
                </div>
                {isSubmitting ? (
                  <button disabled className="btn btn-primary btn-lg btn-block">
                    Submitting...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    onClick={this.onRegister.bind(this)}
                  >
                    Submit
                  </button>
                )}

                <center className="otherlogin">
                  <a href="/">
                    Already have an account? <b>Login</b>
                  </a>
                </center>
              </form>
            </div>
          </div>
          <center className="otherlogin">
            <a href="/adminAuth">Admin Login</a>
          </center>
        </div>
      </Container>
    );
  }
}

export default Register;
