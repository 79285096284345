/* eslint-disable no-unused-vars */
import Axios from "axios";
import { data } from "jquery";
import React from "react";
import AppClass from "../../../AppClass";
import { Body, Footer, Header, Modal } from "../../../components/Modal";
import { AddNew } from "../../../components/Saving";
import toastMessage from "../../../components/toastMessage";
import HOST_URL from "../../../configs/api";

class NewSaving extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rate: 3,
      response: {},
      saving_duration: "",
      disabled: true,
      total_saving: 0,
      user_id: "",
      isLoading: false,
      apps: [],
      term: "Monthly",
      available: true,
      organization_id: "",
      organization_name: "",
      interestrate: [],
      savings: {},
      saving_type: "",
      isSubmitting: false,
      members: [],
      phone_number: "",
      member_name: "",
      last_deposit: 0,
      last_withdraw: 0,
      last_total_deposit: 0,
      last_total_withdraw: 0,
      fleet: [],
      operation: "",
      operations: ["Deposit", "Withdraw"],
      total_interest: 0,
      capital_share: 0,
      // eslint-disable-next-line no-dupe-keys
      saving_duration: 0,
      fleets: [],
      registered_members: [],
      isFleet: true,
      isInterestRate: false,
      isCapitalShare: false,
      payment_methods: [],
      payment_method_id: "",
      error_saving_type: {},
      new_saving_type: "",
      saving_types: [],
      sacco_settings_id: "",
      products: [],
      client_record_date: new Date().getTime(),
    };
  }

  componentDidMount() {
    // this.get_apps(this.props.ztoken, { params: {} });
    console.log("fleets present...", this.state.fleet);
    // console.log({ ztoken: this.props.ztoken });

    const { apps } = this.props;

    let app_options = [];

    for (let el of apps) {
      app_options.push({
        label: el.name,
        value: el._id,
        ...el,
      });
    }

    this.setState({
      apps: app_options,
    });
  }

  getFleets(app_id) {
    const config = {
      method: "GET",
      url: HOST_URL.mobitill_url + "/fleets?0[]=appid&1[]=" + app_id,
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };
    Axios(config).then((data) => {
      const fleets = data.data.data;
      const fleets_array = [];

      for (let i = 0; i < fleets.length; i++) {
        fleets_array.push({
          value: fleets[i].vehicleno,
          label: fleets[i].vehicleno,
        });
      }

      this.setState({
        fleets: fleets_array,
      });
    });
  }

  onChangeClientRecordDate(e) {
    let { response } = this.state;

    delete response["client_record_date"];

    this.setState({
      client_record_date: new Date(e.target.value).getTime(),
      response,
    });
  }

  onChangeonChangeSavingDuration(e) {
    let value = e.target.value;

    this.setState({ saving_duration: value });
  }

  onChangeOperation(e) {
    this.setState({
      operation: e.target.value,
      disabled: e.target.value !== "Withdraw",
    });
  }

  onChangeSacco(e) {
    let appid = e;

    let org_id = e.orgid;
    let app_id = e.value;

    let { response } = this.state;
    delete response["app_id"];

    this.setState(
      {
        isLoading: true,
        organization_id: org_id,
        isGettingSaccoSettings: true,
        appid,
        response,
      },
      async () => {
        // await this.get_organization(value);
        await this.get_interest_rate(app_id);
        await this.get_members(app_id);
        await this.getFleets(app_id);
        await this.getProducts(app_id);
      }
    );
  }

  // get_organization(sacco_id) {
  //   let apps = this.state.apps;
  //   for (var i = 0; i < apps.length; i++) {
  //     if (apps[i]._id === sacco_id) {
  //       this.setState({ organization_name: apps[i].name, isLoading: false });
  //     }
  //   }
  // }

  getProducts(app_id) {
    this.setState({
      isLoading: true,
    });

    const options = {
      method: "GET",
      url: `${HOST_URL.mobitill_url}/products?0[]=appid&1[]=${app_id}`,
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };

    Axios(options)
      .then((data) => {
        let products = [];

        for (let el of data.data.data) {
          if (el.loaning_savings) {
            products.push({
              label: el.name,
              value: el._id,
            });
          }
        }
        this.setState({ isLoading: false, products });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  }

  get_members = async (app_id) => {
    this.setState({
      isLoading: true,
    });

    try {
      const members = await AppClass.getRegisteredMembers(
        app_id,
        !this.props.admin ? this.props.user_id : undefined
      );

      this.setState({ isLoading: false, members });
    } catch (error) {
      console.log(error);
      toastMessage("error", error);
    }
  };

  onChangeMember = async (e) => {
    // var index = e.target.selectedIndex;
    // var optionElement = e.target.childNodes[index];
    // let member_phone = optionElement.getAttribute("data-phone_number");
    // let member_name = optionElement.getAttribute("data-username");

    let member = e;

    let { response } = this.state;
    delete response["user_id"];

    this.setState(
      {
        // user: user_id,
        // phone_number: member_phone,
        // member_name,
        member,
        last_total_deposit: 0,
        response,
      },
      () => {
        this.get_last_deposit(this.state.member.value, this.state.appid.value);
      }
    );
  };

  get_last_deposit(user_id, app_id, fleet) {
    this.setState({ isLoading: true, savings: [] });
    let response = {};
    let app_ids = [];
    app_ids.push(app_id);

    const body = {
      app_ids,
      model: "save",
      tag: "by_last",
      status: "Active",
      user_id,
      fleet,
      available: true,
      product_id: this.state.product.value,
    };

    AppClass.get_savedata(body)
      .then((data) => {
        let arrayData = data.data;

        if (arrayData.length > 0) {
          this.setState({
            last_total_deposit: arrayData[0].last.total_saving,
            last_total_withdraw: arrayData[0].last.total_withdrawn,
            last_deposit: arrayData[0].last.last_deposit,
            last_withdraw: arrayData[0].last.last_withdraw,
            last_saving_duration: arrayData[0].last.saving_duration,
            last_total_interest: arrayData[0].last.total_interest,
            last_capital_share: arrayData[0].last.capital_share,
          });
        }
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  get_interest_rate(app_id) {
    let response = {};
    const body = {
      app_id: [app_id],
      model: "interestrate",
    };
    AppClass.get_savedata(body)
      .then((data) => {
        if (data.data.length > 0) {
          const extension = data.data[0].extension;
          let { isFleet, isInterestRate, isCapitalShare, saving_types } =
            this.state;

          if (extension) {
            const ext = JSON.parse(extension);

            isFleet = ext.fleet;
            isCapitalShare = ext.capitalShare;
            isInterestRate = ext.interestRate;
          }

          if (data.data[0].saving_type && data.data[0].saving_type.length > 0) {
            saving_types = data.data[0].saving_type.split(",");
          }

          this.setState({
            interestrate: data.data,
            available: true,
            isLoading: false,
            rate: data.data[0].rate,
            isFleet,
            isCapitalShare,
            isInterestRate,
            saving_types,
            sacco_settings_id: data.data[0]._id,
          });
        }
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  handleChangeFleet(newValue, actionMeta) {
    if (newValue !== null) {
      this.setState({ fleet: [newValue], count_fleet: [newValue].length });
    } else {
      this.setState({ fleet: [], count_fleet: 1 });
    }

    this.get_last_deposit(
      this.state.member.value,
      this.state.appid.value,
      newValue.value
    );
  }

  handleSaveData() {
    let fleet = [];

    for (let i = 0; i < this.state.fleet.length; i++) {
      fleet.push(this.state.fleet[i].value);
    }

    let amount = 0;

    if (this.state.fleet.length > 0) {
      for (let i = 0; i < this.state.fleet.length; i++) {
        amount += parseFloat(this.state["amount_" + i]);
      }
    } else {
      amount = this.state.amount;
    }

    // const { operation } = this.state;

    let last_withdraw = this.state.last_withdraw,
      last_deposit = amount,
      total_withdrawn = this.state.last_total_withdraw,
      total_interest = this.state.total_interest,
      capital_share = this.state.capital_share,
      saving_duration = this.state.saving_duration;

    // if (operation === "Deposit") {
    //   last_deposit = amount;
    //   last_withdraw = this.state.last_withdraw;
    //   total_saving =
    //     Number(this.state.total_saving) + this.state.last_total_deposit;
    // } else {
    //   last_withdraw = amount;
    //   last_deposit = this.state.last_deposit;
    //   total_withdrawn += amount;
    //   total_saving = this.state.last_total_deposit;
    //   capital_share = this.state.last_capital_share;
    //   saving_duration = this.state.last_saving_duration;
    //   total_interest = this.state.last_total_interest;
    // }

    last_deposit = amount;
    last_withdraw = this.state.last_withdraw;
    let balance = Number(amount) + this.state.last_total_deposit;

    const body = {
      app_id: this.state?.appid?.value,
      organization: this.state?.appid?.label,
      model: "save",
      amount,
      last_withdraw,
      interestrate:
        this.state.interestrate.length > 0 ? this.state.interestrate[0]._id : 0,
      saving_duration,
      total_interest,
      total_saving: balance,
      last_deposit,
      total_withdrawn,
      user_id: this.state.member ? this.state.member.value : undefined,
      user_name: this.state.member ? this.state.member.label : undefined,
      status: "Active",
      available: true,
      client_record_date: this.state.client_record_date,
      capital_share,
      fleet: fleet.length > 0 ? fleet[0] : "",
      // loan_debit_id: this.state.payment_method_id,
      product_id: this.state?.product?.value,
    };

    if (this.state.fleet.length === 0) {
      delete body.fleet;
    }

    const errors = AppClass.validate_form(body);
    console.log("errors encoutered........", errors);
    // if (this.state.product.label) {
    //   body.product_name = this.state.product.label;
    // }

    this.setState({ isSubmitting: true });

    if (Object.keys(errors).length === 0) {
      const options = {
        method: "post",
        url: HOST_URL.url + "/savings",
        data: body,
        headers: {
          Authorization: `Bearer ${this.props.ztoken}`,
        },
      };

      Axios(options)
        .then((data) => {
          let message = "New saving has been added successfully";

          toastMessage("success", message);

          let sms_body = {
            phone_number: this.state.member.phone_number,
            member_name: this.state.member.label,
            amount,
            product: undefined,
            member: undefined,
          };

          sms_body.total = data.data.total_saving;
          // if (operation === "Deposit") {
          sms_body.action = "added";
          // }
          // else {
          //   sms_body.action = "withdrawn";
          // }
          // this.notifyMember(sms_body);

          this.resetForm();

          window.location.reload();
        })
        .catch((err) => {
          console.log(err, err.response);

          if (
            err &&
            err.response &&
            err.response.data === "save ref not found"
          ) {
            this.handleNoExistedMember(body);

            return;
          }
          toastMessage("error", err);
          this.setState({
            isSubmitting: false,
          });
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });

      toastMessage("error", { error: errors });
    }
  }

  handleNoExistedMember = async (body) => {
    await this.registerMember(body);
    this.handleSaveData();
  };

  registerMember(params) {
    const { member, appid } = this.state;
    const body = {
      model: "register",
      app_id: appid.value,
      user_name: member.label,
      user_id: member.value,
      phone_number: member.phone_number,
    };

    this.setState({ isLoading: true });

    AppClass.register(body)
      .then((data) => {
        //user saved
        console.log("Member has saved", data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  resetForm() {
    this.setState({
      amount: "",
      total_saving: 0,
      disabled: true,
      savings: {},
      isSubmitting: false,
      fleet: [],
      capital_share: "",
      saving_duration: "",
      response: {},
      payment_method_id: "",
      member: {},
      appid: {},
    });
  }

  notifyMember(body) {
    let option = {
      phonenumber: body.phone_number,
      text: `Dear ${body.member_name}, you have ${body.action} KHS ${body.amount} in your saving account, your new balance is KSH ${body.total}. Thank you for saving with us.`,
      appid: this.state.appid.value,
    };

    let response = {};
    response.sms_sending_message = "Wait sending sms...";
    this.setState({ sending_sms: true, response });

    AppClass.sendSMS(option)
      .then((data) => {
        response.success =
          "SMS has been sent successfully to " + body.phone_number;
        this.setState({
          response,
          sending_sms: false,
        });
      })
      .catch((err) => {
        this.setState({
          response,
          sending_sms: false,
          isSubmitting: false,
        });
      });
  }

  openSavingTypeModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onChangeSavingType(e) {
    this.setState({
      new_saving_type: e.target.value,
    });
  }

  onChangeProduct(product) {
    let { response } = this.state;
    delete response["product_id"];
    this.setState({ product, last_total_deposit: 0, response }, () => {
      if (this.state?.member?.value && this.state?.appid?.value) {
        this.get_last_deposit(this.state.member.value, this.state.appid.value);
      }
    });
  }

  render() {
    const {
      response,
      organization_name,
      organization_id,
      apps,
      available,
      interestrate,
      savings,
      saving_duration,
      rate,
      total_interest,
      total_saving,
      isSubmitting,
      disabled,
      members,
      sending_sms,
      fleet,
      fleets,
      isFleet,
      isCapitalShare,
      isInterestRate,
      payment_methods,
      payment_method_id,
      saving_types,
      isGettingSaccoSettings,
    } = this.state;

    return (
      <div className="content-cont">
        <AddNew
          saving_duration={saving_duration}
          response={response}
          rate={rate}
          handleformonchange={(e) => AppClass.handleformonchange(this, e)}
          total_interest={total_interest}
          onChangeSacco={this.onChangeSacco.bind(this)}
          organization_name={organization_name}
          handleSaveData={this.handleSaveData.bind(this)}
          isSubmitting={isSubmitting}
          apps={apps}
          interestrate={interestrate}
          available={available}
          organization_id={organization_id}
          savings={savings}
          total_saving={total_saving}
          disabled={disabled}
          members={members}
          member={this.state.member}
          onChangeMember={this.onChangeMember.bind(this)}
          sending_sms={sending_sms}
          fleet={fleet}
          fleets={fleets}
          handleChangeFleet={this.handleChangeFleet.bind(this)}
          operations={this.state.operations}
          operation={this.state.operation}
          onChangeOperation={this.onChangeOperation.bind(this)}
          isFleet={isFleet}
          isInterestRate={isInterestRate}
          isCapitalShare={isCapitalShare}
          payment_methods={payment_methods}
          payment_method_id={payment_method_id}
          openSavingTypeModal={this.openSavingTypeModal.bind(this)}
          saving_types={saving_types}
          isGettingSaccoSettings={isGettingSaccoSettings}
          appid={this.state.appid}
          amount={this.state.amount}
          products={this.state.products}
          product={this.state.product}
          onChangeProduct={this.onChangeProduct.bind(this)}
          client_record_date={this.state.client_record_date}
          onChangeClientRecordDate={this.onChangeClientRecordDate.bind(this)}
        />
      </div>
    );
  }
}

export default NewSaving;
