import React from "react";
import Loans from "./Loan";
import Savings from "./Savings";
import { Filter } from "../../../components/Filter";
import { Loading } from "../../../components/Loader";
import toastMessage from "../../../components/toastMessage";
import axios from "axios";
import HOST_URL from "../../../configs/api";
import moment from "moment";
import AppClass from "../../../AppClass";
import Dividends from "./Dividends";

class MemberStatement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: [],
      loans: [],
      isLoading: false,
      start_date: moment().subtract("30", "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      members: [],
      savings: [],
      loanSummary: {},
      savingsSummary: {},
      dividendsReport: [],
    };
  }

  componentDidMount = async () => {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    await this.setState({ apps: f_apps });

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    }
  };

  onChangeSacco = async (e) => {
    await this.setState({ appid: e, members: [] }, () => {
      this.get_members(true);
    });
  };

  get_members(isLoadingMembers) {
    this.setState({ isLoadingMembers });

    const { appid } = this.state;

    let response = {};
    let body = {
      app_id: [appid.value],
      from_dil: false,
    };
    AppClass.get_member(body)
      .then((data) => {
        let members = [];

        for (let el of data.data.data) {
          members.push({
            label: el.user_name,
            value: el.user_id,
          });
        }
        this.setState({
          members,
          isLoadingMembers: false,
        });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoadingMembers: false,
        });
      });
  }

  onChangeMember(e) {
    this.setState({ member: e }, () => {
      this.get_member_loans(true);
      this.get_member_savings(true);
      this.get_member_dividends(true);
    });
  }

  onChangeStartingDate(e) {
    e.preventDefault();
    this.setState({ start_date: e.target.value }, () => {
      if (this.state?.member?.value) {
        this.get_member_loans(true);
        this.get_member_savings(true);
        this.get_member_dividends(true);
      }
    });
  }

  onChangeEndDate(e) {
    e.preventDefault();
    this.setState({ end_date: e.target.value }, () => {
      if (this.state?.member?.value) {
        this.get_member_loans(true);
        this.get_member_savings(true);
        this.get_member_dividends(true);
      }
    });
  }

  get_member_loans(isLoading) {
    this.setState({ isLoading, loans: [] });

    const { start_date, end_date, member, appid } = this.state;
    let { ztoken } = this.props;
    let params = {};

    if (start_date !== undefined || end_date !== undefined) {
      params["fromDate"] = start_date;
      params["toDate"] = end_date;
    }

    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/${member.value}/loans`,
      params,
      headers: {
        authorization: `Bearer ${ztoken}`,
      },
    };

    axios(options)
      .then((data) => {
        const { summary, loans } = data?.data?.data || {};
        this.setState({
          isLoading: false,
          loanSummary: summary,
          loans,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error.message);
      });
  }

  get_member_savings(isLoading) {
    this.setState({ isLoading, loans: [] });

    const { start_date, end_date, member, appid } = this.state;
    let { ztoken } = this.props;
    let params = {};

    if (start_date !== undefined || end_date !== undefined) {
      params["fromDate"] = start_date;
      params["toDate"] = end_date;
    }

    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/${member.value}/savings`,
      params,
      headers: {
        authorization: `Bearer ${ztoken}`,
      },
    };

    axios(options)
      .then((data) => {
        const { summary, transactions } = data?.data?.data || {};
        this.setState({
          isLoading: false,
          savingsSummary: summary,
          savingsTransactions: transactions,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error.message);
      });
  }

  get_member_dividends(isLoading) {
    this.setState({ isLoading, dividendsReport: {} });

    const { start_date, end_date, member, appid } = this.state;
    let { ztoken } = this.props;
    let params = {};

    if (start_date !== undefined || end_date !== undefined) {
      params["fromDate"] = start_date;
      params["toDate"] = end_date;
    }

    if (appid.value) {
      params["app_id"] = appid.value;
    }

    const options = {
      method: "GET",
      url: `${HOST_URL.url}/dividends/${member.value}`,
      params,
      headers: {
        authorization: `Bearer ${ztoken}`,
      },
    };

    axios(options)
      .then((res) => {
        const data = res?.data || {};
        this.setState({
          isLoading: false,
          dividendsReport: data,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error.message);
      });
  }

  render() {
    return (
      <div className="content-cont">
        <div className="panel-body" style={{ paddingLeft: 0 }}>
          <Filter
            appid={this.state.appid}
            apps={this.state.apps}
            start_date={this.state.start_date}
            end_date={this.state.end_date}
            response={this.state.response}
            onChangeStartingDate={this.onChangeStartingDate.bind(this)}
            onChangeEndDate={this.onChangeEndDate.bind(this)}
            onChangeSacco={this.onChangeSacco.bind(this)}
            onChangeMember={this.onChangeMember.bind(this)}
            members={this.state.members}
            member={this.state.member}
            isLoadingMembers={this.state.isLoadingMembers}
          />
          <div className="col-md-12">
            {this.state.isLoading ? (
              <Loading message="Please wait..." icon="fa fa-spinner fa-spin" />
            ) : Object.keys(this.state.loanSummary).length > 0 ||
              Object.keys(this.state.savingsSummary).length > 0 ? (
              <div className="panel">
                <ul className="nav nav-tabs">
                  <li className="active">
                    <a href="#loan" data-toggle="tab">
                      Loans Statement
                    </a>
                  </li>
                  <li>
                    <a href="#saving" data-toggle="tab">
                      Savings Statement
                    </a>
                  </li>
                  <li>
                    <a href="#dividends" data-toggle="tab">
                      Dividends
                    </a>
                  </li>
                </ul>
                <div className="panel-heading">
                  <div>
                    <h4>{this.state.loanSummary.period}</h4>
                    <p>Member Name: {this.state.loanSummary.memberName}</p>
                    <p>Member No: {this.state.loanSummary?.memberNo || ''}</p>
                    <p>Phone Number: {this.state.loanSummary.phoneNumber}</p>
                  </div>
                </div>
                <div className="panel-body ">
                  <div className="tab-content">
                    <div className="tab-pane active" id="loan">
                      <Loans
                        {...this.props}
                        loans={this.state.loans}
                        summary={this.state.loanSummary}
                        fromDate={this.state.start_date}
                        toDate={this.state.end_date}
                        memberId={this.state.member?.value}
                        memberName={this.state.member?.label}
                      />
                    </div>
                    <div className="tab-pane" id="saving">
                      <Savings
                        {...this.props}
                        summary={this.state.savingsSummary}
                        savings={this.state.savingsTransactions}
                        fromDate={this.state.start_date}
                        toDate={this.state.end_date}
                        memberId={this.state.member?.value}
                        memberName={this.state.member?.label}
                      />
                    </div>
                    <div className="tab-pane" id="dividends">
                      <Dividends
                        {...this.props}
                        appid={this.state.appid}
                        data={this.state.dividendsReport}
                        fromDate={this.state.start_date}
                        toDate={this.state.end_date}
                        memberId={this.state.member?.value}
                        memberName={this.state.member?.label}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <center>
                <span style={{ opacity: 0.5 }}>
                  Member statement will appear here
                </span>
              </center>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MemberStatement;
