import React from "react";
import { Card } from "../../Card";
import moment from "moment";
import { Payment } from "../../Payments";
import { CSVLink } from "react-csv";
import { numberWithCommas } from "../../../configs/formatCurrency";
import { Loading } from "../../Loader";
import getTotalFees from "../../../utils/getTotalFees";

const ViewLoan = ({
  data = [],
  sacco,
  payments,
  role,
  response,
  id,
  csvData,
  csvPayment,
  delete_payment,
  view_files,
  loan_id,
  handleOpenModal,
  isLoadingPayment,
  total_payment,
}) => (
  <div>
    <Card className="col-md-12">
      <div className="panel-heading card-title">
        <span>
          Loan ID: <b>{data.length > 0 && data[0].loan_id}</b>
        </span>
      </div>
      <div className="panel-body table-responsive">
        <div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Requested By</th>
                <th>Sacco</th>
                <th>Principal</th>
                <th>Interest</th>
                <th>Fees</th>
                <th>Amount Payable</th>
                <th>Date Issued</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.map((item, i) => {
                  if (item.last_payment === 0) {
                    return (
                      <tr key={i}>
                        <td>
                          <b>{item.user_name}</b>
                        </td>
                        <td>
                          <b>{sacco}</b>
                        </td>
                        <td>
                          <b>KES {numberWithCommas(item.req_amount)}</b>
                        </td>
                        <td>
                          <b>KES {numberWithCommas(item.expected_interest)}</b>
                        </td>
                        <td>
                          <b>KES {getTotalFees(item.fees)}</b>
                        </td>
                        <td>
                          <b>KES {numberWithCommas(item.disbursed_amount)}</b>
                        </td>
                        <td>{moment(item.date).format("ll")}</td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Card>

    {data.length > 0 &&
      Object.keys(data[0]?.guaranted_amount || {}).length > 0 && (
        <div className="col-md-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">Guarantor</h4>
            </div>
            <div class="panel-body">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Guaranted Amount</th>
                  </tr>
                </tbody>
                <tbody>
                  {Object.keys(data[0]?.guaranted_amount || {}).map((g, i) => {
                    return (
                      <tr key={i}>
                        <td>{data[0].guaranted_amount[g].name}</td>
                        <td>{"KES " + data[0].guaranted_amount[g].amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

    {isLoadingPayment ? (
      <Loading icon="fa fa-spinner fa-spin" />
    ) : (
      <Payment
        className="col-md-12"
        payments={payments}
        role={role}
        response={response}
        id={id}
        loan_id={loan_id}
        hide_add={false}
        csvData={csvPayment}
        view_files={view_files}
        delete_payment={(e) =>
          delete_payment(e, data.length > 0 && data[0]._id)
        }
      />
    )}
  </div>
);

export default ViewLoan;
