import React from "react";
import { UpdateLoanApplication } from "../../components/Loan";

class UpdateApplication extends React.Component {
  constructor() {
    super();

    this.state = {
      response: {}
    };
  }
  render() {
    return (
      <div className="content-cont">
        <UpdateLoanApplication
          response={this.state.response}
        />
      </div>
    );
  }
}

export default UpdateApplication;
