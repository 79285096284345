import React from "react";

const LoanMenus = ({ role, page }) => (
  <ul>
    <li className="nav-item pcoded-menu-caption"></li>
    {role === "member" && (
      <div>
        <li className={page === "overview" ? "active" : ""}>
          <a href={`/home/${role}/overview`}>
            <i className="fas fa-home" />
            <span>Overview</span>
          </a>
        </li>
        <li className={page === "loanhistory" ? "active" : ""}>
          <a href={`/home/${role}/loanhistory`}>
            <i className="fas fa-history" /> <span>Loan History</span>
          </a>
        </li>
        <li
          className={
            page === "myapplication" || page === "apply_loan" ? "active" : ""
          }
        >
          <a href={`/home/${role}/myapplication`}>
            <i className="fas fa-book" /> <span>My Application</span>
          </a>
        </li>
        <li className={page === "payments" ? "active" : ""}>
          <a href={`/home/${role}/payments`}>
            <i className="fas fa-money-bill-wave-alt" /> <span>Payments</span>
          </a>
        </li>
        <li className={page === "shares" ? "active" : ""}>
          <a href={`/home/${role}/shares`}>
            <i className="fa fa-credit-card" /> <span>Shares</span>
          </a>
        </li>
        <li className={page === "trusts" ? "active" : ""}>
          <a href={`/home/${role}/trusts`}>
            <i className="fa fa-university" /> <span>Trusts</span>
          </a>
        </li>
      </div>
    )}

    {/* admin menus */}
    {role === "admin" && (
      <div>
        {/* <li className={page === "analytics" ? "active" : ""}>
          <a href={`/home/${role}/analytics`}>
            <i className="fa fa-bar-chart" /> <span>Analytics</span>
          </a>
        </li> */}
        <li className={page === "loans" ? "active" : ""}>
          <a href={`/home/${role}/loans`}>
            <i className="fas fa-book-open" /> <span>Loans</span>
          </a>
        </li>
        <li className={page === "applications" ? "active" : ""}>
          <a href={`/home/${role}/applications`}>
            <i className="fas fa-book" /> <span>Loan Applications</span>
          </a>
        </li>
        <li className={page === "payments" ? "active" : ""}>
          <a href={`/home/${role}/payments`}>
            <i className="fas fa-money-bill-wave-alt" /> <span>Payments</span>
          </a>
        </li>
        <li className={page === "transactions" ? "active" : ""}>
          <a href={`/home/${role}/transactions`}>
            <i className="fas fa-money-bill" /> <span>Transactions</span>
          </a>
        </li>
        <li className={page === "shares" ? "active" : ""}>
          <a href={`/home/${role}/shares`}>
            <i className="fa fa-credit-card" /> <span>Shares</span>
          </a>
        </li>
        <li className={page === "trusts" ? "active" : ""}>
          <a href={`/home/${role}/trusts`}>
            <i className="fa fa-university" /> <span>Trusts</span>
          </a>
        </li>
        <li className={page === "penalties" ? "active" : ""}>
          <a href={`/home/${role}/penalties`}>
            <i className="fa fa-window-close" /> <span>Penalties</span>
          </a>
        </li>
        <li className={page === "clients" ? "active" : ""}>
          <a href={`/home/${role}/clients`}>
            <i className="fa fa-address-card" /> <span>Members</span>
          </a>
        </li>
        {/* <li className={page === "trusts" ? "active" : ""}>
          <a href={`/home/${role}/trusts`}>
            <i className="fa fa-university" /> <span>Dividends</span>
          </a>
        </li> */}
        <li className={page === "reports" ? "active" : ""}>
          <a href={`/home/${role}/reports`}>
            <i className="fas fa-file-signature" /> <span>Reports</span>
          </a>
        </li>
        <li className={page === "settings" ? "active" : ""}>
          <a href={`/home/${role}/settings`}>
            <i className="fas fa-cog" /> <span>Settings</span>
          </a>
        </li>
      </div>
    )}
  </ul>
);

export default LoanMenus;
