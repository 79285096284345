import React from "react";
import { LoanType } from "../../components/Loan";
import AppClass from "../../AppClass";
import toastMessage from "../../components/toastMessage";
import ReminderSettings from "../../components/Loan/admin/LoanReminders/ReminderSettings";
import SavingSettings from "../../components/Loan/admin/SavingSettings/SavingSettings";
import LoanIDSettings from "../../components/Loan/admin/LoanIdSettings/LoanIDSettings";

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: [],
      exist: false,
      _id: "",
      short_term_purpose: [],
      long_term_purpose: [],
      loan_type: [],
      default_limit_period: "",
      isSubmitting: false,
      setting_id: "",
      fees: {
        insurance: false,
        loanProcessingFee: false,
        loanFormFee: false,
        Others: false,
      },
      errorFee: {},
      customFee: "",
      other: {
        pos: true,
        dashboardPaymentsEnabled: false,
      },
    };
  }

  componentDidMount() {
    const { apps } = this.props;

    let app_options = [];

    for (let el of apps) {
      app_options.push({
        label: el.name,
        value: el._id,
      });
    }

    if (apps.length === 1) {
      this.setState({ appid: apps[0] });
      this.onChangeSacco(apps[0]);
    }

    this.setState({
      apps: app_options,
    });
  }

  addShortTermPurpose() {
    const { loan_type, short_term_p, short_term_purpose } = this.state;

    if (loan_type.includes("Short-term Loans") && short_term_p !== "") {
      let { short_term_purpose } = this.state;
      let body = {
        type: short_term_p,
      };

      if (!Array.isArray(short_term_purpose)) {
        short_term_purpose = [];
      }

      if (!short_term_purpose?.includes(body)) {
        // body["interest_rate_" + Number(short_term_purpose.length)] = 0;
        short_term_purpose.push(body);
      }
    }

    this.setState({
      short_term_purpose,
      short_term_p: "",
    });
  }

  addLongTermPurpose() {
    const { loan_type, long_term_p, long_term_purpose } = this.state;

    if (loan_type.includes("Long-term Loans") && long_term_p !== "") {
      let body = {
        type: long_term_p,
      };

      if (!Array.isArray(long_term_purpose)) {
        long_term_purpose = [];
      }

      if (!long_term_purpose?.includes(body)) {
        long_term_purpose.push(body);
      }
    }

    this.setState({
      long_term_purpose,
      long_term_p: "",
    });
  }

  onChangeGracePeriod(e, loan_type, i) {
    const type = this.state[loan_type];
    type[i]["grace_period_" + i] = e.target.value;
    this.setState({
      [type]: this.state[loan_type],
    });
  }

  onChangeTerm(e, loan_type, i) {
    const type = this.state[loan_type];
    type[i]["term_" + i] = e.target.value;
    this.setState({
      [type]: this.state[type],
    });
  }

  onChangeContribution(e, loan_type, i) {
    const type = this.state[loan_type];
    type[i]["contribution_rate_" + i] = e.target.value;
    this.setState({
      [type]: this.state[type],
    });
  }

  onChangeLoanPeriod(e, loan_type, i) {
    const type = this.state[loan_type];
    type[i]["loan_period_" + i] = e.target.value;
    this.setState({
      [type]: this.state[type],
    });
  }

  onChangeMethod(e, loan_type, i) {
    const type = this.state[loan_type];
    type[i]["method_" + i] = e.target.value;
    this.setState({
      [type]: this.state[type],
    });
  }

  onChangeAmountRule(e, loan_type, i) {
    const type = this.state[loan_type];
    type[i]["ruleamount_" + i] = e.target.value;
    this.setState({
      [type]: this.state[type],
    });
  }

  onChangeInterestRate(e, loan_type, i) {
    const type = this.state[loan_type];
    type[i]["interest_rate_" + i] = e.target.value;
    this.setState({
      [type]: this.state[type],
    });
  }

  onChangePaymentDuration(e, loan_type, i) {
    const type = this.state[loan_type];
    type[i]["payment_duration_" + i] = e.target.value;
    this.setState({
      [type]: this.state[type],
    });
  }

  onChangeTerm(e, loan_type, i) {
    const type = this.state[loan_type];
    type[i]["term_" + i] = e.target.value;
    this.setState({
      [type]: this.state[type],
    });
  }

  onChangeLongTermGracePeriod(e, i) {
    const { long_term_purpose } = this.state;
    long_term_purpose[i]["long_term_grace_period_" + i] = e.target.value;
    this.setState({
      long_term_p: "",
    });
  }

  onChangeChargedAmount(e, i) {
    const { short_term_purpose } = this.state;

    short_term_purpose[i]["amount_" + i] = e.target.value;

    this.setState({
      short_term_p: "",
    });
  }

  onChangeLoanType(e) {
    const {
      loan_type,
      short_term_purpose,
      long_term_purpose,
      contribution_rate,
      interest_rate,
    } = this.state;
    if (!loan_type.includes(e.target.value)) {
      loan_type.push(e.target.value);
    } else {
      loan_type.splice(loan_type.indexOf(e.target.value), 1);
    }
    this.setState({
      short_term_purpose: !loan_type.includes("Short-term Loans")
        ? []
        : short_term_purpose,
      long_term_purpose: !loan_type.includes("Long-term Loans")
        ? []
        : long_term_purpose,
      contribution_rate: !loan_type.includes("Long-term Loans")
        ? "0"
        : contribution_rate,
      interest_rate: !loan_type.includes("Long-term Loans")
        ? "0"
        : interest_rate,
      loan_type,
    });
  }

  removePurpose(i, loan_type) {
    console.log({ i, loan_type });

    console.log(this.state[loan_type]);
    // const { short_term_purpose, loan_type, long_term_purpose } = this.state;

    if (this.state[loan_type]) {
      this.state[loan_type].splice(i, 1);

      this.setState({
        // short_term_purpose,
        // long_term_purpose,
        // ["grace_period_" + i]: undefined,
        // ["charged_amount_" + i]: undefined,
        [loan_type]: this.state[loan_type],
      });
    }
  }

  get_setting_by_appid(app_id) {
    let response = {};
    const body = {
      app_ids: [app_id],
      model: "setting",
      tag: "by_appid",
    };
    this.setState({ isSubmitting: true });
    AppClass.get_setting_data(body)
      .then((_data) => {
        if (_data.data.length > 0) {
          let data = _data.data[0];
          let loan_type = [],
            keys = Object.keys(data);

          if (keys.includes("short_term")) {
            loan_type.push("Short-term Loans");
          }

          if (keys.includes("long_term")) {
            loan_type.push("Long-term Loans");
          }

          this.setState({
            short_term_purpose: this.formatSettings(true, data.short_term),
            long_term_purpose: this.formatSettings(true, data.long_term),
            loan_type,
            isSubmitting: false,
            setting_id: data._id,
            fees: data.fees || {},
            other: data.other || {},
          });
        } else {
          this.setState({
            isSubmitting: false,
            exist: false,
          });
        }
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isSubmitting: false,
          exist: false,
        });
      });
  }

  onChangeSacco(e) {
    let appid = e;
    this.setState(
      {
        appid,
        short_term_purpose: [],
        long_term_purpose: [],
        default_limit_period: "",
        exist: true,
      },
      () => {
        this.get_setting_by_appid(appid.value);
      }
    );
  }

  formatSettings(firstLoad, settings) {
    return settings.map((el, i) => {
      let finalObject = {};
      Object.keys(el).map((key) => {
        let split_key = key.split("_" + i);

        if (firstLoad) {
          return (finalObject[split_key[0] + "_" + i] = el[key]);
        }

        // if (split_key.length > 1) {
        //   return (finalObject[split_key[0] + "_" + i] = el[key]);
        // }
        return (finalObject[split_key[0]] = el[key]);
      });
      console.log("====================================");
      console.log(finalObject);
      console.log("====================================");
      return finalObject;
    });
  }

  onSubmit() {
    this.setState({ isSubmitting: true });

    const { short_term_purpose, long_term_purpose, appid, setting_id, fees } =
      this.state;
    let input_data = {};

    this.setState({ isSubmitting: true });

    input_data = {
      model: "setting",
      available: true,
      app_id: appid.value,
      date: new Date().getTime(),
      short_term: this.formatSettings(false, short_term_purpose),
      long_term: this.formatSettings(false, long_term_purpose),
      fees,
      other: this.state.other || {},
    };

    console.log("payload saving...", input_data);

    const errors = AppClass.validate_form(input_data);

    let func_route = "add_setting_data";

    if (setting_id && setting_id !== "") {
      input_data.id = setting_id;
      func_route = "update_setting_data";
    }

    if (Object.keys(errors).length === 0) {
      AppClass[func_route](input_data)
        .then(() => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("success", "Setting saved successful");
        })
        .catch(() => {
          this.setState({ isSubmitting: false });

          toastMessage("error", "Setting saved has failed");
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  onChangeFees(field) {
    let { fees } = this.state;
    let fieldV = fees[field];

    if (fieldV) {
      fees[field] = false;
    } else {
      fees[field] = true;
    }

    this.setState({
      fees,
    });
  }

  onChangeCustomFee(e) {
    let { errorFee } = this.state;

    delete errorFee["customFee"];
    this.setState({
      customFee: e.target.value,
    });
  }

  validateCustomFee() {
    let { errorFee, customFee } = this.state;

    if (customFee === "") {
      errorFee["customFee"] = "This field is mandatory";
    }

    this.setState({ errorFee });
  }

  handleCustomizeFee = async () => {
    await this.validateCustomFee;
    let { customFee, fees, errorFee } = this.state;

    if (Object.keys(errorFee).length === 0) {
      let key = customFee.replaceAll(" ", "_");

      fees[key] = true;

      this.setState({ fees, customFee: "" });
    }
  };

  handleRemoveFee(field) {
    let { fees } = this.state;

    delete fees[field];

    this.setState({
      fees,
    });
  }

  onChangePaymentOption(paymentOption) {
    let { other } = this.state;

    other[paymentOption] = !other[paymentOption];
    this.setState({
      other,
    });
  }

  onChangeMaximumAmount(e, loan_type, i) {
    const type = this.state[loan_type];
    type[i]["maximumamount_" + i] = e.target.value;
    this.setState({
      [type]: this.state[type],
    });
  }

  render() {
    const {
      response,
      exist,
      interest_rate,
      loan_type,
      payment_period,
      apps,
      appid,
      isSubmitting,
      contribution_rate,
      method,
      long_term_purpose,
      short_term_purpose,
      long_term_p,
      short_term_p,
      default_limit_period,
    } = this.state;

    return (
      <div className="content-cont">
        <div
          className="col-md-8 col-md-offset-2"
          style={{ marginBottom: "2rem" }}
        >
          <ul className="nav nav-tabs">
            <li className="active">
              <a href="#a" data-toggle="tab">
                Loan Setup
              </a>
            </li>
            <li>
              <a href="#b" data-toggle="tab">
                Reminders
              </a>
            </li>
            <li>
              <a href="#c" data-toggle="tab">
                Savings
              </a>
            </li>
            <li>
              <a href="#d" data-toggle="tab">
                Loan ID Settings
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="a">
            <LoanType
              handleformonchange={(e) => AppClass.handleformonchange(this, e)}
              response={response}
              interest_rate={interest_rate}
              loan_type={loan_type}
              payment_period={payment_period}
              onSubmit={this.onSubmit.bind(this)}
              apps={apps}
              appid={appid}
              isSubmitting={isSubmitting}
              onChangeSacco={this.onChangeSacco.bind(this)}
              exist={exist}
              contribution_rate={contribution_rate}
              method={method}
              addLongTermPurpose={this.addLongTermPurpose.bind(this)}
              addShortTermPurpose={this.addShortTermPurpose.bind(this)}
              short_term_purpose={short_term_purpose}
              long_term_purpose={long_term_purpose}
              short_term_p={short_term_p}
              long_term_p={long_term_p}
              removePurpose={this.removePurpose.bind(this)}
              onChangeGracePeriod={this.onChangeGracePeriod.bind(this)}
              // onChangeChargedAmount={this.onChangeChargedAmount.bind(this)}
              onChangeLoanType={this.onChangeLoanType.bind(this)}
              // onChangeLongTermGracePeriod={this.onChangeLongTermGracePeriod.bind(
              //   this
              // )}
              default_limit_period={default_limit_period}
              onChangeTerm={this.onChangeTerm.bind(this)}
              onChangeContribution={this.onChangeContribution.bind(this)}
              onChangeLoanPeriod={this.onChangeLoanPeriod.bind(this)}
              onChangeInterestRate={this.onChangeInterestRate.bind(this)}
              onChangeMethod={this.onChangeMethod.bind(this)}
              onChangePaymentDuration={this.onChangePaymentDuration.bind(this)}
              onChangeAmountRule={this.onChangeAmountRule.bind(this)}
              fees={this.state.fees}
              onChangeFees={this.onChangeFees.bind(this)}
              handleCustomizeFee={this.handleCustomizeFee.bind(this)}
              onChangeCustomFee={this.onChangeCustomFee.bind(this)}
              customFee={this.state.customFee}
              errorFee={this.state.errorFee}
              handleRemoveFee={this.handleRemoveFee.bind(this)}
              other={this.state.other}
              onChangePaymentOption={this.onChangePaymentOption.bind(this)}
              onChangeMaximumAmount={this.onChangeMaximumAmount.bind(this)}
            />
          </div>
          <div className="tab-pane" id="b">
            <ReminderSettings apps={this.state.apps} />
          </div>
          <div className="tab-pane" id="c">
            <SavingSettings apps={this.state.apps} />
          </div>
          <div className="tab-pane" id="d">
            <LoanIDSettings apps={this.state.apps} />
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
