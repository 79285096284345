import React from "react";
import { Container } from "../../components/Container";
import { Sidebar } from "../../components/Sidebar";
import { Content } from "../../components/Content";
import { Navbar } from "../../components/Navbar";
import Overview from "./Overview";
import AppClass from "../../AppClass";
import LoanHistory from "./LoanHistory";
import MyApplication from "./MyApplication";
import LoanDetails from "./LoanDetails";
import ApplyLoan from "./ApplyLoan";
import ViewApplication from "./ViewApplication";
import Payments from "../Payments";
import Profile from "../member/Profile";
import LoanDetailsPayments from "../LoanDetailsPayments";
import SharesScreen from "../admin/SharesScreen";
import TrustScreen from "../admin/Trusts";

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      onTaggle: true,
      page: "",
      showDropDown: false,
      user_id: "",
      username: "",
      ztoken: "",
      admin: false,
      route: "",
      epesi_app: "Epesi Loan",
      apps: [],
    };
  }

  componentDidMount() {
    //ge sessions data
    let data = AppClass.get_user_sessions();
    if (data?.ztoken) {
      this.setState({
        page: AppClass.getParams(this, "page"),
        user_id: data._id,
        username: data.firstName + " " + data.lastName,
        ztoken: data.ztoken,
        phone_number: data.phone,
        apps: data.apps,
      });
    } else {
      this.signMeOut();
    }

    //setup page header from params
    let page = AppClass.getParams(this, "page");

    this.setState({
      route:
        page === "overview"
          ? "Overview"
          : page === "loanhistory"
          ? "Loan History"
          : page === "myapplication"
          ? "My Application"
          : page === "payments"
          ? "Payments"
          : page === "apply_loan"
          ? "Apply Loan"
          : page === "profile"
          ? "Profile"
          : page === "loandetails" && "Loan Details",
    });

    //monitoring system

    page === "overview"
      ? this.save_monitoring("Overview", data.firstName + " " + data.lastName)
      : page === "loanhistory"
      ? this.save_monitoring(
          "Loan History",
          data.firstName + " " + data.lastName
        )
      : page === "myapplication"
      ? this.save_monitoring(
          "My Application",
          data.firstName + " " + data.lastName
        )
      : page === "apply_loan"
      ? this.save_monitoring("Apply Loan", data.firstName + " " + data.lastName)
      : page === "loandetails"
      ? this.save_monitoring(
          "Loan Details",
          data.firstName + " " + data.lastName
        )
      : page === "payments" &&
        this.save_monitoring("Payments", data.firstName + " " + data.lastName);
  }

  //calapse sidebar
  onTaggleSidebar() {
    this.setState({ onTaggle: this.state.onTaggle ? false : true });
  }

  //sign out
  signMeOut() {
    AppClass.signMeOut();

    window.location.href = "/";
  }

  openMenu() {
    this.setState({ showDropDown: this.state.showDropDown ? false : true });
  }

  apply_for_loan() {
    window.location.href = "/home/member/apply_loan";
  }

  onChangeEpesiApp(e) {
    this.setState({ epesi_app: e.target.value });
    this.get_home_page(e.target.value);
  }

  get_home_page(target_value) {
    if (target_value === "Epesi Loan") {
      window.location.href = "/home/member/overview";
    }

    if (target_value === "Epesi Saving") {
      window.location.href = "/home/member_savings/list";
    }
  }

  save_monitoring(page, username) {
    let body = {
      app_name: "Loan app",
      username,
      section: page,
      category: "Out-House",
      date: new Date().getTime(),
    };

    AppClass.monitoring(body)
      .then((data) => {
        //saved
      })
      .catch((err) => {
        //no error
      });
  }

  render() {
    const { username, page, ztoken, user_id, route, epesi_app, phone_number } =
      this.state;
    return (
      <Container>
        <Sidebar
          role={"member"}
          page={this.state.page}
          onTaggle={this.state.onTaggle}
          onTaggleSidebar={this.onTaggleSidebar.bind(this)}
          epesi_app={epesi_app}
          onChangeEpesiApp={this.onChangeEpesiApp.bind(this)}
        />

        <Content>
          <Navbar
            apply_for_loan={this.apply_for_loan.bind(this)}
            role={"member"}
            signMeOut={this.signMeOut.bind(this)}
            openMenu={this.openMenu.bind(this)}
            showDropDown={this.state.showDropDown}
            username={username}
            page={page}
            route={route}
            onTaggle={this.state.onTaggle}
            onTaggleSidebar={this.onTaggleSidebar.bind(this)}
            show_btn={true}
            current_app_type="loan"
            onSwitch={this.get_home_page.bind(this, "Epesi Saving")}
          />
          {this.state.page === "overview" ? (
            <Overview
              ztoken={ztoken}
              user_id={user_id}
              username={username}
              apps={this.state.apps}
            />
          ) : this.state.page === "loanhistory" ? (
            <LoanHistory
              ztoken={ztoken}
              user_id={user_id}
              username={username}
              apps={this.state.apps}
            />
          ) : this.state.page === "myapplication" ? (
            <MyApplication
              ztoken={ztoken}
              user_id={user_id}
              apps={this.state.apps}
            />
          ) : this.state.page === "payments" ? (
            <Payments
              ztoken={ztoken}
              user_id={user_id}
              username={username}
              role={"member"}
              apps={this.state.apps}
            />
          ) : this.state.page === "loandetails" ? (
            <LoanDetails
              somethingID={AppClass.getParams(this, "somethingID")}
              ztoken={ztoken}
              apps={this.state.apps}
            />
          ) : this.state.page === "view_loan_payments" ? (
            <LoanDetailsPayments
              ztoken={ztoken}
              somethingID={AppClass.getParams(this, "somethingID")}
              role={"member"}
              apps={this.state.apps}
            />
          ) : this.state.page === "apply_loan" ? (
            <ApplyLoan
              ztoken={ztoken}
              user_id={user_id}
              username={username}
              phone_number={phone_number}
              apps={this.state.apps}
            />
          ) : this.state.page === "view_application" ? (
            <ViewApplication
              somethingID={AppClass.getParams(this, "somethingID")}
              apps={this.state.apps}
            />
          ) : this.state.page === "trusts" ? (
            <TrustScreen
              ztoken={ztoken}
              apps={this.state.apps}
              user_id={user_id}
            />
          ) : this.state.page === "shares" ? (
            <SharesScreen
              ztoken={ztoken}
              apps={this.state.apps}
              user_id={user_id}
            />
          ) : (
            this.state.page === "profile" && (
              <Profile user_id={user_id} apps={this.state.apps} />
            )
          )}
        </Content>
      </Container>
    );
  }
}

export default Home;
