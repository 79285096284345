import React from "react";
import PropTypes from "prop-types";

const Container = ({ children }) => (
  <div className="container-fluid app_container">{children}</div>
);

Container.propTypes = {
  children: PropTypes.any,
};

export default Container;
