import React from "react";
import "./styles.css";

class Pagination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: props.limit,
      page: props.page,
    };
  }

  onNext() {
    let { page } = this.state;

    let c_page = page + 1;

    this.setState({
      page: c_page,
    });

    this.props.handlePagination(c_page);
  }

  onPrevious() {
    let { page } = this.state;

    let c_page = page > 0 ? page - 1 : 1;

    this.setState({
      page: c_page,
    });

    this.props.handlePagination(c_page);
  }

  render() {
    let activePages = (this.state.page - 1) * this.props.limit;

    if (this.props.totalItemsCount === 0) return <></>;
    return (
      <>
        <div className="container pagination-container">
          <div style={{ flex: 1 }} />
          <div className="pagination-page-controller">
            <div className="form-group">
              <select
                className="form-control"
                onChange={(e) => this.props.handleChangeLimit(e)}
              >
                {[10, 50, 100, 500].map((el, i) => {
                  return (
                    <option selected={el === this.state.limit}>{el}</option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="pagination-btn-content">
            <div
              className="pagination-item"
              style={activePages <= 0 ? { opacity: 0.3 } : { opacity: 1 }}
              onClick={() => (activePages > 0 ? this.onPrevious() : null)}
            >
              <i className="fa fa-chevron-left" />
            </div>
            <div className="pagination-counts">
              <span>{`${activePages === 0 ? 1 : activePages}-${
                this.props.limit
              } of ${this.props.totalItemsCount}`}</span>
            </div>
            <div
              className="pagination-item"
              style={
                this.state.page === this.props.totalItemsCount
                  ? { opacity: 0.3 }
                  : { opacity: 1 }
              }
              onClick={() =>
                this.state.page < this.props.totalItemsCount
                  ? this.onNext()
                  : null
              }
            >
              <i className="fa fa-chevron-right" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Pagination;
