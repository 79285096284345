import React from "react";
import { Card } from "../Card";
import moment from "moment";
import Select from "react-select";

const Filter = ({
  apps,
  response,
  onChangeStartingDate,
  onChangeEndDate,
  app_id,
  onChangeMember,
  onChangeSacco,
  members,
  onChangeProduct,
  start_date,
  end_date,
  member,
  products,
  product,
  showReset,
  handleResetFilters,
}) => {
  return (
    <Card className="col-md-12 filter">
      <div className="panel-body">
        <div className="col-md-3">
          <div className="form-group">
            <label>Saccos</label>
            <Select
              value={app_id}
              options={apps}
              name="fleet"
              onChange={onChangeSacco}
            />
            <span className="text-danger">{response.app_id}</span>
          </div>
        </div>
        {products && products?.length > 0 && (
          <div className="col-md-3">
            <div className="form-group">
              <label>Product</label>
              <Select
                value={product}
                options={products}
                name="product"
                onChange={onChangeProduct}
              />
              <span className="text-danger">{response.product}</span>
            </div>
          </div>
        )}
        <div className="col-md-3">
          <div className="form-group">
            <label>Member</label>
            <Select
              value={member}
              options={members}
              name="member"
              onChange={onChangeMember}
            />
            {/* <select
              className="form-control"
              name="user"
              onChange={onChangeMember}
            >
              <option>All</option>
              {members.map((item, i) => {
                return (
                  <option
                    key={i}
                    selected={item._id === selected_user_id}
                    value={item._id}
                  >
                    {item.name}
                  </option>
                );
              })}
            </select> */}
            <span className="text-danger">{response.loan_id}</span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Start Date</label>
            <input
              className="form-control"
              type="date"
              value={moment(start_date).format("YYYY-MM-DD")}
              name="start_date"
              onChange={onChangeStartingDate}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>End Date</label>
            <input
              className="form-control"
              value={moment(end_date).format("YYYY-MM-DD")}
              type="date"
              name="end_date"
              onChange={onChangeEndDate}
            />
          </div>
        </div>
        {showReset && (
          <div className="col-md-12">
            <a href="#" onClick={handleResetFilters}>
              Reset Filters
            </a>
          </div>
        )}
      </div>
    </Card>
  );
};

export default Filter;
