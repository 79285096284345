import React from "react";
import { Card } from "../Card";
import moment from "moment";
import Select from "react-select";
import { onAddFilter } from "../../actions/Filters";
import { connect } from "react-redux";
class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.setState({
      appid: props.appid,
      loan_id: props.loan_id,
      start_date: props.start_date,
      end_date: props.end_date,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appid !== this.props.appid) {
      this.setState({
        appid: this.props.appid,
      });

      this.props.dispatch(
        onAddFilter({
          appid: this.props.appid,
        })
      );
    }

    if (prevProps.loan_id !== this.props.loan_id) {
      this.setState({
        loan_id: this.props.loan_id,
      });

      this.props.dispatch(
        onAddFilter({
          loan_id: this.props.loan_id,
        })
      );
    }

    if (prevProps.start_date !== this.props.start_date) {
      this.setState({
        start_date: this.props.start_date,
      });

      this.props.dispatch(
        onAddFilter({
          start_date: this.props.start_date,
        })
      );
    }

    if (prevProps.end_date !== this.props.end_date) {
      this.setState({
        start_date: this.props.start_date,
      });

      this.props.dispatch(
        onAddFilter({
          end_date: this.props.end_date,
        })
      );
    }

    if (prevProps.member !== this.props.member) {
      this.setState({
        member: this.props.member,
      });

      this.props.dispatch(
        onAddFilter({
          member: this.props.member,
        })
      );
    }

    if (prevProps.end_date !== this.props.end_date) {
      this.setState({
        end_date: this.props.end_date,
      });
    }
  }
  render() {
    const {
      apps,
      response,
      purpose_value,
      onChangeStartingDate,
      onChangeEndDate,
      appid,
      loans,
      onChangeSacco,
      onChangeLoanID,
      loan_id,
      start_date,
      end_date,
      onChangePurpose,
      page,
      purposes,
      loan_ids,
      members,
      member,
      onChangeMember,
      showReset,
      handleResetFilters,
      isLoadingMembers,
    } = this.props;

    let dp_loan_id = [],
      c_loan_ids = [];
    if (loan_ids) {
      for (let el of loan_ids) {
        if (!dp_loan_id.includes(el.value)) {
          c_loan_ids.push(el);
          dp_loan_id.push(el.value);
        }
      }
    }
    return (
      <Card className="col-md-12 filter">
        <div className="panel-body">
          {apps && apps.length > 0 && (
            <div className="col-md-3">
              <div className="form-group">
                <label>Saccos</label>
                <Select
                  value={appid}
                  options={apps}
                  name="fleet"
                  onChange={onChangeSacco}
                />
                {response && response.app_id && (
                  <span className="text-danger">{response?.app_id}</span>
                )}
              </div>
            </div>
          )}
          {page === "report" && (
            <div className="col-md-3">
              <div className="form-group">
                <label>Purpose</label>
                <select
                  className="form-control"
                  name="purpose"
                  onChange={onChangePurpose}
                  data-live-search="true"
                >
                  <option>All</option>
                  {purposes.map((item, i) => {
                    return (
                      <option key={i} selected={purpose_value === item.type}>
                        {item.type}
                      </option>
                    );
                  })}
                </select>
                {response && response.loan_id && (
                  <span className="text-danger">{response.loan_id}</span>
                )}
              </div>
            </div>
          )}
          {members && (
            <div className="col-md-3">
              <div className="form-group">
                <label>Member</label>
                <Select
                  value={member}
                  options={members}
                  name="member"
                  onChange={onChangeMember}
                  isLoading={isLoadingMembers}
                />
                {response && response.member && (
                  <span className="text-danger">{response.member}</span>
                )}
              </div>
            </div>
          )}
          {c_loan_ids?.length > 0 && page !== "report" && (
            <div className="col-md-3">
              <div className="form-group">
                <label>Loan App. ID</label>
                <Select
                  value={loan_id}
                  options={c_loan_ids}
                  name="loan_id"
                  onChange={onChangeLoanID}
                />
                <span className="text-danger">{response.loan_id}</span>
              </div>
            </div>
          )}
          <div className="col-md-3">
            <div className="form-group">
              <label>Start Date</label>
              <input
                className="form-control"
                type="date"
                value={moment(start_date).format("YYYY-MM-DD")}
                name="start_date"
                onChange={onChangeStartingDate}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>End Date</label>
              <input
                className="form-control"
                value={moment(end_date).format("YYYY-MM-DD")}
                type="date"
                name="end_date"
                onChange={onChangeEndDate}
              />
            </div>
          </div>
          {showReset && (
            <div className="col-md-12">
              <a href="#" onClick={handleResetFilters}>
                Reset Filters
              </a>
            </div>
          )}
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(Filter);
