import React from "react";
import { Card } from "../../Card";
import moment from 'moment'

const LoanDetail = ({ sacco, data }) => (
  <Card className="col-md-12">
    <div className="panel-heading card-title">
      <span>
        Loan ID: <b style={{ opacity: 0.8 }}>{data.length > 0 && data[0].loan_id}</b>
      </span>
    </div>
    <div className="panel-body">
      <div className="col-md-3 card-home">
        <h3 className="title"> Loan Principle amount</h3>
        <p>
          <span>KES</span> {data.length > 0 && data[0].amount}
        </p>
      </div>
      <div className="col-md-3 card-home">
        <h3 className="title"> Next Payment date On </h3>
        <p>{data.length > 0 ? moment(data[0].date).add(30, "days").format("ll") : "-"}</p>
      </div>
      <div className="col-md-3 card-home">
        <h3 className="title pending">Outstanding Amount </h3>
        <p className="pending">
          <span>KES</span> {data.length > 0 && (data[0].amount - data[0].last_payment) > 0 ? (data[0].amount - data[0].last_payment) : 0}
        </p>
      </div>
      <div className="col-md-3 card-home">
        <h3 className="title pending">End date of Loan Repayment </h3>
        <p className="pending">{data.length > 0 ? moment(data[0].date).add(data[0].payment_duration, "months").format("ll") : "-"}</p>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Issued by</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>{sacco}</b>
            </td>
            <td>{data.length > 0 && moment(data[0].date).format("ll")}</td>
            <td className="text-primary">
              <b>Current</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Card>
);

export default LoanDetail;
