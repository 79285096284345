import React from "react";
import { Clients } from "../../components/Clients";

class ClientScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apps: [],
    };
  }

  componentDidMount = async () => {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    await this.setState({ apps: f_apps });
  };

  render() {
    return (
      <div>
        <Clients apps={this.state.apps} token={this.props.ztoken} />
      </div>
    );
  }
}

export default ClientScreen;
