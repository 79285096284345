import React from 'react';

const File = ({ files }) => (
    <div className="fileContainer">
        <div className="row">
            {
                files.map((item, i) => {
                    return <div className="col-md-4" key={i}>
                        <div className="thumbnail">
                            <img src={`data:image/png;base64,${item}`} />
                            <div className="overlay">
                                <a href={item}
                                    download="file.png"><i className="fas fa-cloud-download-alt"></i></a>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
)

export default File;