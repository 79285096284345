/* eslint-disable no-unused-vars */
import React from "react";
import { Card } from "../Card";
import moment from "moment";
import { CSVLink } from "react-csv";
import toastMessage from "../toastMessage";
import AppClass from "../../AppClass";
import Select from "react-select";
import Axios from "axios";
import HOST_URL from "../../configs/api";
import { numberWithCommas } from "../../configs/formatCurrency";
import { Pagination } from "../Pagination";
import CardCount from "../CardCount/CardCount";
class MemberSavings extends React.Component {
  state = {
    savings: [],
    isLoading: true,
    products: [],
    page: 1,
    limit: 10,
    summary: {},
    start_date: moment().subtract("30", "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };

  componentDidMount = async () => {
    await this.getProducts();

    this.getMemberSavings();
  };

  componentDidUpdate = async (prevProps) => {
    if (
      (prevProps.show !== this.props.show && this.props.show) ||
      prevProps.needRefresh !== this.props.needRefresh
    ) {
      await this.getProducts();
      this.getMemberSavings();
    }
  };

  onChangeText(field, e) {
    this.setState({
      [field]: e.target ? e.target.value : e,
    });

    if (field === "product") {
      this.getMemberSavings(e);
    }
  }

  getMemberSavings(product) {
    const { user_id, app_id, fleet, product_id } = this.props;

    if (!app_id) return;

    this.setState({ isLoading: true });

    const body = {
      app_id: [app_id],
      status: "active",
      type: "Saving",
      user_id: user_id,
    };

    if ((product && product.value) || product_id) {
      body.product_id = product?.value || product_id;
    }

    if (fleet && fleet.length > 0) {
      body.fleet = fleet;
    }

    AppClass.get_save(body)
      .then((res) => {
        const { data, summary, pagination } = res.data;
        const token = JSON.parse(sessionStorage.getItem("saccoloan")).ztoken;
        // for (const [i, saving] of data.entries()) {
        //   AppClass.getProductById(token, saving.product_id)
        //     .then((data) => data.data.data)
        //     .then((product) => {
        //       data[i]["product_name"] =
        //         product && product.name ? product.name : "SAVINGS";
        //     });
        // }
        this.setState({
          isLoading: false,
          savings: data,
          summary,
        });
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isLoading: false,
        });
      });
  }

  getProducts = async () => {
    this.setState({
      isLoading: true,
    });

    const { app_id } = this.props;

    const options = {
      method: "GET",
      url: `${HOST_URL.mobitill_url}/products?0[]=appid&1[]=${app_id}`,
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };

    return await Axios(options)
      .then((data) => {
        let products = [],
          { product_id } = this.props,
          product = undefined;

        for (let el of data.data.data) {
          if (el.loaning_loans) {
            products.push({
              label: el.name,
              value: el._id,
            });
          }

          if (product_id === el._id) {
            product = {
              label: el.name,
              value: el._id,
            };
          }
        }

        this.setState({ isLoading: false, products, product });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  };

  resetFilters() {
    this.setState(
      {
        product: {},
      },
      () => {
        this.getMemberSavings();
      }
    );
  }

  handleChangeLimit(e) {
    this.setState({
      limit: e.target.value,
      page: 1,
    });
  }

  handlePagination(page) {
    this.setState({
      page,
    });
  }

  render() {
    let total = 0;

    let { savings } = this.state;

    let { isFleet } = this.props;

    return (
      <>
        <div style={{ marginBottom: 12 }}>
          <div className="row">
            <div className="col-md-4">
              <Select
                options={this.state.products}
                value={this.state.product}
                onChange={(e) => this.onChangeText("product", e)}
              />
              {/* {this.state.product && this.state.product.value && (
                <div>
                  <a onClick={this.resetFilters.bind(this)} href="#">
                    Reset Filters
                  </a>
                </div>
              )} */}
            </div>

            <div className="col-md-4">
              <input
                className="form-control"
                value={this.state.start_date}
                onChange={(e) => this.onChangeText("start_date", e)}
                type="date"
              />
            </div>
            <div className="col-md-4">
              <input
                className="form-control"
                value={this.state.end_date}
                onChange={(e) => this.onChangeText("end_date", e)}
                type="date"
              />
            </div>
          </div>
        </div>
        {/* {this.props?.terminalId?.toLowerCase() === "dashboard" && (
          <button
            className="btn btn-default"
            style={{ marginRight: "1rem" }}
            onClick={() =>
              (window.location.href =
                "/home/admin_savings/withdraw-reversal/" + this.props.id)
            }
          >
            Reversal
          </button>
        )} */}
        {this.props?.savingInfo &&
          this.props?.savingInfo?.type !== "Withdrawal" && (
            <button className="btn btn-primary" onClick={this.props.onWithdraw}>
              Withdraw
            </button>
          )}
        <hr />
        {savings.length > 0 && (
          <>
            <div className="row">
              <CardCount
                count={this.state.summary.noOfSavings}
                title="Total Number of Savings"
                className="col-md-4"
              />
              <CardCount
                count={
                  "KES " + numberWithCommas(this.state.summary.totalSavings)
                }
                title="Total Savings"
                className="col-md-4"
              />
              <CardCount
                count={
                  "KES " +
                  numberWithCommas(this.state.summary.totalCumulativeSavings)
                }
                title="Total Cumulative Savings"
                className="col-md-4"
              />
            </div>
            <hr />
          </>
        )}
        <Card>
          <div
            className="panel-body table-responsive"
            style={{ maxHeight: 500, overflowY: "auto" }}
          >
            <table className="table table-bordered">
              <thead>
                <tr>
                  {isFleet && <th>Fleet </th>}

                  {/* <th>Saved Amount</th> */}
                  <th>Last Transaction</th>
                  <th>Balance </th>
                  <th>Product</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {this.state.isLoading ? (
                  <tr>
                    <td align="center" colSpan={5}>
                      Please wait...
                    </td>
                  </tr>
                ) : savings.length > 0 ? (
                  savings.map((item, i) => {
                    total += item.last_deposit;
                    return (
                      <tr key={i}>
                        {isFleet && (
                          <td>
                            {item.fleet && item.fleet !== "" ? item.fleet : "-"}
                          </td>
                        )}
                        <td
                          id="tooltip"
                          data-toggle={
                            item.last_deposit < 0 ? "Withdraw" : "Deposit"
                          }
                          title={
                            item.last_deposit < 0
                              ? "Withdraw of KES " + Math.abs(item.last_deposit)
                              : "Deposit of KES " + item.last_deposit
                          }
                        >
                          <i
                            className={
                              item.last_deposit < 0 ? "fa fa-arrow-down" : ""
                            }
                            style={{ color: "red", marginRight: 5 }}
                          />
                          {item.balBroughtForward ? "(Bal C/F) " : ""}
                          KES {numberWithCommas(item.last_deposit)}
                        </td>
                        <td>KES {numberWithCommas(item.total_saving)}</td>
                        <td>{item.product_name || "-"}</td>
                        <td>{moment(item.client_record_date).format("ll")}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} align="center">
                      No data here
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* <div className="panel-footer">
            <tr>
              <td>
                Current Balance: <b>KES {numberWithCommas(total)}</b>
              </td>
            </tr>
          </div> */}
        </Card>
        <Pagination
          totalItemsCount={this.state.savings.length}
          page={this.state.page}
          limit={this.state.limit}
          handleChangeLimit={this.handleChangeLimit.bind(this)}
          handlePagination={this.handlePagination.bind(this)}
        />
      </>
    );
  }
}

export default MemberSavings;
