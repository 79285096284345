import React from "react";
import AppClass from "../../../AppClass";
import { WithdrawForm } from "../../../components/Saving";
import { Loading } from "../../../components/Loader";
import toastMessage from "../../../components/toastMessage";
import axios from "axios";

class Withdraw extends React.Component {
  constructor() {
    super();

    this.state = {
      error: {},
      data: [],
      isSubmitting: false,
      balance: 0,
      balance: "",
    };
  }

  componentDidMount() {
    this.getBalance();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.savingInfo !== this.props.savingInfo) {
      this.getBalance();
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];
    this.setState({ error, [field]: e.target ? e.target.value : e });
  }

  getBalance() {
    // console.log("getBalance****");
    this.setState({ isLoading: true });

    let { ztoken, savingInfo } = this.props;

    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_URL}/savings/balance/${savingInfo.user_id}`,
      headers: {
        Authorization: `Bearer ${ztoken}`,
      },
    };

    axios(options)
      .then((data) => {
        console.log("*** balance", data.data);
        this.setState({
          balance: data?.data?.data?.balance || 0,
          isLoading: false,
        });
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isLoading: false,
        });
      });
  }

  validateForm() {
    let { error } = this.state;

    if (!this.state.amount || this.state.amount === 0) {
      error.amount = "Amount is required";
      this.setState({ error, isSubmitting: false });
      return;
    }

    this.setState({ error });
  }

  handleSaveData = async () => {
    await this.validateForm();
    let { error } = this.state;

    if (Object.keys(error).length > 0) return;

    this.setState({ isSubmitting: true });

    let { amount } = this.state,
      { savingInfo } = this.props;

    const input_data = {
      user_id: savingInfo.user_id,
      amount,
    };

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_URL}/savings/withdraw`,
      data: input_data,
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };

    axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
          amount: "",
        });
        toastMessage("success", "Withdraw is success");

        this.getBalance();
        this.props.getSavings(true);
        this.props.handleSavingUpdatingAndNeedAutoRefresh &&
          this.props.handleSavingUpdatingAndNeedAutoRefresh();
      })
      .catch((err) => {
        this.setState({ isSubmitting: false });
        toastMessage("error", err);
        console.log("error after withdraw>>>>>>", err);
      });
  };

  render() {
    const { error, amount, isSubmitting, isLoading, balance } = this.state;
    return (
      <div className="content-cont">
        {isLoading ? (
          <Loading icon="fa fa-spinner fa-spin" />
        ) : (
          <WithdrawForm
            error={error}
            balance={balance}
            amount={amount}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            handleSaveData={this.handleSaveData.bind(this)}
            onChangeText={this.onChangeText.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default Withdraw;
