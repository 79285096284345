import Axios from "axios";
import React from "react";
import HOST_URL from "../../configs/api";
import { Card } from "../Card";
import { Loading } from "../Loader";
import toastMessage from "../toastMessage";
import AppClass from "../../AppClass";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import CardCount from "../CardCount/CardCount";
import { numberWithCommas } from "../../configs/formatCurrency";
import { Filter } from "../Filter";

class Penalty extends React.Component {
  state = {
    transactions: [],
    data: [],
    error: {},
    limit: 10,
    page: 1,
    totalItemsCount: 0,
    fromDate: moment().subtract("30", "days").format("YYYY-MM-DD"),
    toDate: moment().add(1, "d").format("YYYY-MM-DD"),
    summary: {},
  };

  componentDidMount = async () => {
    const { apps } = this.props;

    console.log({ apps });

    await this.setState({ apps: apps });

    if (apps.length === 1) {
      let appid = { label: apps[0].label, value: apps[0].value };
      await this.setState({ appid }, () => {
        this.getData(true, [appid]);
      });
    } else {
      this.getData(true, apps);
    }
  };

  // componentDidUpdate = async (prevProps) => {
  //   if (prevProps.apps !== this.props.apps) {
  //     this.getData(true);
  //   }
  // };

  getData = async (isLoading, apps) => {
    this.setState({ isLoading });

    const { fromDate, toDate } = this.state;
    const { user_id, role, type = "loans" } = this.props;

    const app_ids = AppClass.get_app_ids(apps);

    let params = {
      app_id: JSON.stringify(app_ids),
      fromDate,
      toDate,
    };

    if (role !== "admin") {
      params.user_id = user_id;
    }

    const options = {
      method: "GET",
      url: `${HOST_URL.url}/penalties/${type}`,
      params,
      headers: {
        authorization: `Bearer ${this.props.token}`,
      },
    };

    Axios(options)
      .then((response) => {
        const { data, summary } = response.data;
        this.setState({ isLoading: false, data, summary });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  };

  getAppIds = async (apps) => {
    const app_ids = await AppClass.get_app_ids(apps);
    return app_ids;
  };

  onChangeText(field, e) {
    this.setState({
      [field]: e.target ? e?.target?.value : e,
    });

    if (field === "appid") {
      this.getData(true, [e]);
    }
  }
  handleGeneratePDFReport = (penalty) => {
    const { fromDate, toDate } = this.state;
    const { token } = this.props;

    console.log(this.props)

    const filters = JSON.parse(sessionStorage.getItem("filters"));

    const options = {
      method: "GET",
      url: `${HOST_URL.url}/dividends/${penalty.user_id}/pdf`,
      responseType: "blob",
      params: {
        fromDate,
        toDate,
        app_id: penalty.app_id,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    Axios(options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "penalty_report.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        toastMessage("error", "Unable to generate PDF report");
      });
  };

  handleChangeLimit(e) {
    this.setState({
      limit: e.target.value,
      page: 1,
    });
  }

  handlePagination(page) {
    this.setState({
      page,
    });
  }

  onChangeStartingDate(e) {
    this.setState({ fromDate: e.target.value, page: 1 }, () => {
      this.getData(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ endDate: e.target.value, page: 1 }, () => {
      this.getData(true);
    });
  }

  handleResetFilters() {
    this.setState(
      {
        sacco: undefined,
        fromDate: moment().subtract("1", "month").format("yyyy-MM-dd"),
        toDate: moment().add("1", "d").format("yyyy-MM-dd"),
      },
      () => {
        window.location.reload();
      }
    );
  }

  render() {
    return (
      <div>
        <Filter
          appid={this.state.appid}
          apps={this.props.apps}
          start_date={this.state.fromDate}
          end_date={this.state.toDate}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
          onChangeSacco={(e) => this.onChangeText("appid", e)}
          showReset={this.state?.sacco?.value}
          handleResetFilters={this.handleResetFilters.bind(this)}
        />

        <div>
          {this.state.isLoading ? (
            <Loading icon="fa fa-spinner fa-spin" />
          ) : (
            <>
              <div className="col-md-12 ">
                <div className="row">
                  <CardCount count={this.state.summary.count} title="Count" />
                  <CardCount
                    count={"KES " + numberWithCommas(this.state.summary.total)}
                    title="Total"
                  />
                </div>
              </div>
              <Card className="col-md-12">
                <button
                  onClick={this.handleGeneratePDFReport}
                  className="btn btn-primary"
                >
                  Generate CSV Report
                </button>
                <div className="panel-body ">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Member Name</th>
                        {this.props.type !== "savings" && <th>Loan ID</th>}
                        <th style={{ textAlign: "right" }}>Late Payment Of</th>
                        <th style={{ textAlign: "right" }}>Penalty Amount</th>
                        <th style={{ textAlign: "center" }}>Status</th>
                        <th style={{ textAlign: "center" }}>Date</th>
                        <th style={{ textAlign: "center" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.length > 0 ? (
                        this.state.data
                          .slice(
                            (this.state.page - 1) * this.state.limit,
                            this.state.page * this.state.limit
                          )
                          .map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.user_name || ""}</td>
                                {this.props.type !== "savings" && (
                                  <td>{item.loan_id}</td>
                                )}
                                <td style={{ textAlign: "right" }}>
                                  KES {numberWithCommas(item.charged_on_amount)}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  KES {numberWithCommas(item.amount)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div className={`badge badge-${item.status}`}>
                                    <span>{item.status}</span>
                                  </div>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {moment(item.created_at).format("lll")}
                                </td>
                                <td style={{ textAlign: "center" }} onClick={() => this.handleGeneratePDFReport(item)}>
                                  Download
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={10} align="center">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalItemsCount={this.state.data.length}
                  page={this.state.page}
                  limit={this.state.limit}
                  handleChangeLimit={this.handleChangeLimit.bind(this)}
                  handlePagination={this.handlePagination.bind(this)}
                />
              </Card>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Penalty;
