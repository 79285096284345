import React from "react";
import { Card } from "../Card";
import { Loading } from "../Loader";

const AddNew = ({
  handleformonchange,
  response,
  handleSaveData,
  amount_paid,
  isSubmitting,
  loan_id,
  handleFile,
  sending_sms,
  purpose,
  loan_amortization_results,
  unpaid_schedules,
  handleAddModal,
  next_schedules,
  onAddMoreSchedules,
  onChangePaidAmount,
  isLoading,
  onChangeBeginningAmount,
}) => (
  <div>
    {unpaid_schedules.length > 0 && (
      <Card className="col-md-12">
        <div className="panel-heading card-title">
          <div style={{ flex: 1 }}>
            <span>Loan Amortization Schedule</span>
          </div>
          {next_schedules.length > 0 && (
            <div className="card-menus">
              <div className="icon-danger" onClick={handleAddModal}>
                <a href="#">
                  <i className="fa fa-plus" /> Add More Schedules
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="table-responsive">
          {isLoading && <Loading />}
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Unpaid</th>
                <th>Payment Date</th>
                <th>Beginning Balance</th>
                <th>Payment</th>
                {/* <th>Paid Amount</th> */}
                <th>Principal</th>
                <th>Interest</th>
                <th>Ending Balance</th>
              </tr>
            </thead>
            <tbody>
              {unpaid_schedules.length > 0 &&
                unpaid_schedules.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <input
                          type="checkbox"
                          checked
                          onChange={() => onAddMoreSchedules(item, i)}
                        />
                      </td>
                      <td>{item.payment_date}</td>
                      <td>
                        <input
                          value={item.beginning_balance}
                          type="number"
                          onChange={(e) => onChangeBeginningAmount(e, item, i)}
                        />
                      </td>
                      {/* <td>{item.beginning_balance}</td> */}
                      <td>
                        <input
                          value={item.term_payment}
                          type="number"
                          onChange={(e) => onChangePaidAmount(e, item, i)}
                        />
                      </td>
                      {/* <td>

                    </td> */}
                      <td>{item.principal}</td>
                      <td>{item.term_interest}</td>
                      <td>{item.ending_balance}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Card>
    )}
    <div className="col-md-12">
      {response.error ? (
        <div className="alert alert-danger">
          <span>{response.error}</span>
        </div>
      ) : response.success ? (
        <div className="alert alert-success">
          <span>{response.success}</span>
        </div>
      ) : (
        sending_sms && (
          <div className="alert alert-info">
            <span>
              <i className="fa fa-spin fa-spinner"></i>{" "}
              {response.sms_sending_message}
            </span>
          </div>
        )
      )}
    </div>
    <Card className="col-md-12">
      <div className="panel-heading card-title">
        <span>Payment Form</span>
      </div>
      <div className="panel-body">
        <div className="col-md-4">
          <div className="form-group">
            <label>Loan ID(*)</label>
            <select
              className="form-control"
              name="loan_id"
              onChange={handleformonchange}
              data-live-search="true"
            >
              <option>{loan_id}</option>
            </select>
            <span className="text-danger">{response.loan_id}</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Amount(*)</label>
            <input
              className="form-control"
              type="number"
              name="amount_paid"
              value={amount_paid}
              onChange={handleformonchange}
              disabled
            />
            <span className="text-danger">{response.last_payment}</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Upload File</label>
            <input
              className="form-control"
              type="file"
              name="files"
              onChange={handleFile}
              multiple
            />
            <span className="text-danger">{response.cheque}</span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Purpose</label>
            <input
              className="form-control"
              type="text"
              name="purpose"
              value={purpose}
              onChange={handleformonchange}
            />
            <span className="text-danger">{response.purpose}</span>
          </div>
        </div>
        <div className="col-md-4">
          {isSubmitting ? (
            <button className="btn btn-info  btn-block" disabled={true}>
              Saving...
            </button>
          ) : (
            <button
              className="btn btn-info  btn-block"
              onClick={handleSaveData}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </Card>
  </div>
);

export default AddNew;
