import React from "react";
import { Card } from "../../../Card";
import { Select } from "../../../Select";
import AppClass from "../../../../AppClass";
import toastMessage from "../../../toastMessage";
import { Loading } from "../../../Loader";
import axios from "axios";
import HOST_URL from "../../../../configs/api";

class SavingSettings extends React.Component {
  state = {
    isLoading: false,
    data: [],
    error: {},
    settingId: "",
    amountWithLoan: "",
    amountWithoutLoan: "",
    penaltyPercentage: "",
  };

  componentDidMount() {
    const { apps } = this.props;
    if (apps.length === 1) {
      this.setState({ appid: apps[0] });
      this.onChangeInput("sacco", apps[0]);
    }
  }

  onChangeInput(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target ? e.target.value : e }, () => {
      if (field === "sacco") {
        this.get_setting_by_appid(e.value);
      }
    });
  }

  get_setting_by_appid(app_id) {
    const body = {
      app_ids: [app_id],
      model: "setting",
      tag: "by_appid",
    };
    this.setState({ isLoading: true });
    AppClass.get_setting_data(body)
      .then((_data) => {
        if (_data.data.length > 0) {
          if (!_data.data[0]?.savings_setting) _data.data[0].savings_setting = {};
          const {
            amount_with_loan,
            amount_without_loan,
            penalty_percentage,
            frequency,
            penalize_on,
          } = _data.data[0]?.savings_setting;
          this.setState({
            settingId: _data.data[0]._id,
            amountWithLoan: amount_with_loan || 0,
            amountWithoutLoan: amount_without_loan || 0,
            penaltyPercentage: penalty_percentage || 0,
            frequency: frequency
              ? {
                  label: frequency,
                  value: frequency,
                }
              : null,
            penalizeOn: penalize_on
              ? {
                  label: penalize_on,
                  value: penalize_on,
                }
              : null,
          });
        } else {
          toastMessage("error", "Loan settings not found");
        }

        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        toastMessage("error", "Unable to find loan settings");
        this.setState({
          isLoading: false,
        });
      });
  }

  handleOpenModal(modal) {
    this.setState({ [modal]: true });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  validateForm() {
    const {
      amountWithLoan,
      amountWithoutLoan,
      penaltyPercentage,
      penalizeOn,
      error,
      frequency,
    } = this.state;

    if (amountWithLoan === "") {
      error.amountWithLoan = "amount with loan is required";
    }

    if (amountWithoutLoan === "") {
      error.amountWithoutLoan = "amount without loan is required";
    }

    if (penaltyPercentage === "") {
      error.penaltyPercentage = "Penalty percentage is required";
    }

    if (!penalizeOn) {
      error.penalizeOn = "Please select";
    }

    if (!frequency) {
      error.frequency = "Please select";
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      amountWithLoan,
      amountWithoutLoan,
      penaltyPercentage,
      penalizeOn,
      error,
      settingId,
      frequency,
      sacco,
    } = this.state;

    const user = await AppClass.get_user_sessions();
    if (Object.keys(error).length === 0) {
      this.setState({ isSubmitting: true });

      const options = {
        method: "POST",
        url: HOST_URL.url + "/update_settingdata",
        data: {
          id: settingId,
          model: "setting",
          savings_setting: {
            frequency: frequency.value,
            amount_with_loan: amountWithLoan,
            amount_without_loan: amountWithoutLoan,
            penalty_percentage: penaltyPercentage,
            penalize_on: penalizeOn.value,
          },
        },
        headers: {
          Authorization: "Bearer " + user.ztoken,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("success", "Settings updated successfully");

          this.get_setting_by_appid(sacco.value);
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });

          toastMessage("error", "error updating settings, please try again");
        });
    }
  };

  render() {
    return (
      <Card className="col-md-8 col-md-offset-2">
        <div className="panel-heading  card-title">
          <span>Savings Settings</span>
        </div>
        <div className="panel-body">
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="col-md-12">
                <div className="form-group">
                  <Select
                    label="Sacco"
                    value={this.state.sacco}
                    options={this.props.apps}
                    name="fleet"
                    onChange={(e) => this.onChangeInput("sacco", e)}
                  />
                  {this.state.error.sacco && (
                    <span className="text-danger">
                      {this.state.error.sacco}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <Select
                    label="Frequency"
                    value={this.state.frequency}
                    options={[
                      {
                        label: "Daily",
                        value: "daily",
                      },
                      {
                        label: "Weekly",
                        value: "weekly",
                      },
                      {
                        label: "Monthly",
                        value: "monthly",
                      },
                    ]}
                    onChange={(e) => this.onChangeInput("frequency", e)}
                  />
                  {this.state.error.frequency && (
                    <span className="text-danger">
                      {this.state.error.frequency}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Amount with Loan</label>
                  <input
                    type="number"
                    label="Amount with Loan"
                    value={this.state.amountWithLoan}
                    onChange={(e) => this.onChangeInput("amountWithLoan", e)}
                    className="form-control"
                  />
                  {this.state.error.amountWithLoan && (
                    <span className="text-danger">
                      {this.state.error.amountWithLoan}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Amount Without Loan</label>
                  <input
                    type="number"
                    label="Amount without Loan"
                    value={this.state.amountWithoutLoan}
                    onChange={(e) => this.onChangeInput("amountWithoutLoan", e)}
                    className="form-control"
                  />
                  {this.state.error.amountWithoutLoan && (
                    <span className="text-danger">
                      {this.state.error.amountWithoutLoan}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Penalty Percentage</label>
                  <input
                    type="number"
                    label="Penalty Percentage"
                    value={this.state.penaltyPercentage}
                    onChange={(e) => this.onChangeInput("penaltyPercentage", e)}
                    className="form-control"
                  />
                  {this.state.error.penaltyPercentage && (
                    <span className="text-danger">
                      {this.state.error.penaltyPercentage}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <Select
                    label="Penalize On"
                    value={this.state.penalizeOn}
                    options={[
                      {
                        label: "Difference",
                        value: "difference",
                      },
                      {
                        label: "Principal",
                        value: "principal",
                      },
                    ]}
                    onChange={(e) => this.onChangeInput("penalizeOn", e)}
                  />
                  {this.state.error.penalizeOn && (
                    <span className="text-danger">
                      {this.state.error.penalizeOn}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <button
                  className="btn btn-primary"
                  disabled={this.state.isSubmitting}
                  onClick={this.onSubmit.bind(this)}
                >
                  {this.state.isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </>
          )}
        </div>
      </Card>
    );
  }
}

export default SavingSettings;
