import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ children }) => (
    <div class="modal-header">
        {children}
    </div>
);

Header.propTypes = {
    children: PropTypes.any
}

export default Header;