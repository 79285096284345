import React from "react";
import { Card } from "../Card";
import Select from "react-select";

const Setting = ({
  saving_type,
  handleformonchange,
  exist,
  onUpdate,
  response,
  interest_rate,
  isSubmitting,
  onSubmit,
  onChangeSacco,
  apps,
  appid,
  isFleet,
  handleCheckboxChange,
  isInterestRate,
  isCapitalShare,
  openSavingTypeModal,
  saving_types,
  onDeleteProduct,
  onEditSavingType,
  isGettingSettings,
  products,
}) => (
  <div>
    <Card className="col-md-12">
      {response.error ? (
        <div className="alert alert-danger">
          <span>{response.error}</span>
        </div>
      ) : (
        response.success && (
          <div className="alert alert-success">
            <span>{response.success}</span>
          </div>
        )
      )}
      <div className="panel-body">
        <div className="col-md-12">
          <div className="form-group">
            <label>Sacco</label>
            <Select
              name="appid"
              onChange={onChangeSacco}
              options={apps}
              value={appid}
            />
            <span className="text-danger">{response.app_id}</span>
          </div>
        </div>

        <div className="col-md-4" style={{ marginBottom: 15 }}>
          {appid && appid !== "" && (
            <div style={{ marginBottom: 15 }}>
              <a
                href="#"
                onClick={() => openSavingTypeModal("show_saving_type")}
              >
                <u>Add New Product</u>
              </a>
            </div>
          )}
          {products?.length > 0 && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Identifier</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {products &&
                  products.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.name}</td>
                        <td>{item.identifier}</td>
                        <td align="right">
                          <div>
                            <button
                              onClick={() => onDeleteProduct(i)}
                              className="btn btn-default"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
        {/* <div className="col-md-12">
          <div className="form-group">
            <label>Saving Type</label>
            <select
              className="form-control"
              name="saving_type"
              onChange={handleformonchange}
            >
              <option selected disabled>
                Choose Saving type
              </option>
              {savingTypes.map((item, i) => {
                return (
                  <option selected={item === saving_type} key={i}>
                    {item}
                  </option>
                );
              })}
            </select>
            <span className="text-danger">{response.saving_type}</span>
          </div>
        </div> */}

        <div className="col-md-12">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="isFleet"
                onChange={(e) => handleCheckboxChange("isFleet", e)}
                checked={isFleet}
              />{" "}
              Fleet field as mandatory
            </label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="isInterestRate"
                onChange={(e) => handleCheckboxChange("isInterestRate", e)}
                checked={isInterestRate}
              />{" "}
              Interest Rate field as mandatory
            </label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="isCapitalShare"
                onChange={(e) => handleCheckboxChange("isCapitalShare", e)}
                checked={isCapitalShare}
              />{" "}
              Capital Share field as mandatory
            </label>
          </div>
        </div>
        {isInterestRate && (
          <div className="col-md-12">
            <div className="form-group">
              <label>Interest Rate (%)</label>
              <input
                className="form-control"
                name="interest_rate"
                type="number"
                onChange={handleformonchange}
                value={interest_rate}
              />
              <span className="text-danger">{response.rate}</span>
            </div>
          </div>
        )}
        {isGettingSettings ? (
          <center className="text-disabled">
            <i className="fa fa-spinner fa-spin" /> Please wait..., getting
            sacco settings
          </center>
        ) : (
          <div className="col-md-4">
            {isSubmitting ? (
              <button className="btn btn-info  btn-block" disabled>
                SAVING...
              </button>
            ) : exist ? (
              <button className="btn btn-info  btn-block" onClick={onUpdate}>
                UPDATE
              </button>
            ) : (
              <button className="btn btn-info  btn-block" onClick={onSubmit}>
                SAVE
              </button>
            )}
          </div>
        )}
      </div>
    </Card>
  </div>
);

export default Setting;
