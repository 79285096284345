import React from "react";
import { Filter } from "../components/Filter";
import { Payment } from "../components/Payments";
import { Loading } from "../components/Loader";
import AppClass from "../AppClass";
import moment from "moment";
import { Modal, Header, Body, Footer } from "../components/Modal";
import { File } from "../components/File";
import toastMessage from "../components/toastMessage";
import Pagination from "../components/Pagination/Pagination";
import CardCount from "../components/CardCount/CardCount";
import { numberWithCommas } from "../configs/formatCurrency";

class Payments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: [],
      app_ids: [],
      loan_ids: [],
      loans: [],
      isLoading: false,
      message: "Please wait...",
      payments: [],
      start_date: moment().subtract("30", "days").format("YYYY-MM-DD"),
      end_date: moment().add("1", "d").format("YYYY-MM-DD"),
      all_payments: [],
      files: [],
      show: false,
      page: 1,
      limit: 10,
      totalItemsCount: 0,
      summary: {},
      members: [],
      saccoSettings: {},
    };
  }

  componentDidMount() {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    this.setState({ apps: f_apps }, () => {
      this.get_payments_by_apps(true, f_apps);
    });

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    }
  }

  get_payments_by_apps(isLoading) {
    this.setState({ isLoading });

    let { user_id } = this.props,
      { start_date, end_date, appid, loan_id, member, page, limit, apps } =
        this.state;

    let _appid = "";

    if (!appid || !appid?.value) {
      _appid = AppClass.get_app_ids(apps);
    } else {
      _appid = [appid.value];
    }

    const body = {
      app_id: _appid,
      pageNumber: page,
      pageLimit: limit,
    };

    if (this.props.role == "member") {
      body["user_id"] = user_id;
    }

    if (start_date !== undefined || end_date !== undefined) {
      body["fromDate"] = start_date;
      body["toDate"] = end_date;
    }

    if (member && member.value) {
      body["user_id"] = member.value;
    }

    if (loan_id && loan_id.value) {
      body["loan_id"] = loan_id.value;
    }

    AppClass.get_repaymentdata(body)
      .then((res) => {
        const { data, summary, pagination } = res.data;

        this.setState({
          isLoading: false,
          payments: data,
          summary,
          totalItemsCount: pagination.totalRecords,
        });

        let loan_ids = [];

        for (let el of data) {
          if (!JSON.stringify(loan_ids).includes(JSON.stringify(el))) {
            loan_ids.push({
              label: el.loan_id,
              value: el.loan_id,
            });
          }
        }

        this.setState({ loan_ids });
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isLoading: false,
        });
      });
  }

  onChangeSacco(e) {
    this.setState({ appid: e, loan_id: undefined }, () => {
      this.get_payments_by_apps(true);
      this.get_members(e.value);
    });
  }

  onChangeLoanID(e) {
    this.setState({ loan_id: e }, () => {
      this.get_payments_by_apps(true);
    });
  }

  onChangeMember(e) {
    this.setState({ member: e }, () => {
      this.get_payments_by_apps(true);
    });
  }

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value, page: 1 }, () => {
      this.get_payments_by_apps(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value, page: 1 }, () => {
      this.get_payments_by_apps(true);
    });
  }

  onDelete(e, id) {
    e.preventDefault();
    if (window.confirm("Are you sure to delete this payment?")) {
      this.delete_payment(id);
    }
  }

  delete_payment(id) {
    let response = {};

    const input_data = {
      id,
      model: "loan",
    };

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors).length) {
      let response = {};

      AppClass.delete_data(input_data)
        .then((data) => {
          this.setState({
            isLoading: false,
          });
          response.success = "Payment deleted, success";
          this.setState({
            response,
            // payments: [],
          });
          // this.get_payments_by_apps(this.state.apps);

          toastMessage("success", response.success);

          window.location.reload();
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          response.error = "Error, " + err;
          this.setState({
            response,
            isLoading: false,
          });
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  printcsv() {
    this.setState({ csv: true });
  }

  view_files(files) {
    this.setState({ show: true });
    this.setState({ files });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleChangeLimit(e) {
    this.setState(
      {
        limit: e.target.value,
        page: 1,
      },
      () => {
        this.get_payments_by_apps(true);
      }
    );
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.get_payments_by_apps(true);
      }
    );
  }

  get_members(app_id) {
    const { diladmin } = this.props;
    let body = {
      app_id,
      from_dil: diladmin + "",
    };
    AppClass.get_member(body)
      .then((data) => {
        let members = [];

        for (let el of data.data.data) {
          members.push({
            label: el.user_name,
            value: el.user_id,
          });
        }
        this.setState({
          members,
          isLoading: false,
        });
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const {
      apps,
      response,
      appid,
      loan_id,
      payments,
      isLoading,
      message,
      loans,
      start_date,
      end_date,
      show,
      files,
    } = this.state;

    const csvData = [
      [
        "Loan ID",
        "Payer",
        "Amount Paid",
        "Current Balance",
        "Total Payment",
        "Transaction Date",
        "Cashier",
        "Ticket Number",
        "Device ID",
      ],
    ];

    for (var i = 0; i < payments.length; i++) {
      csvData.push([
        payments[i].loan_id,
        payments[i].user_name,
        "KES " + payments[i].last_payment,
        "KES " + payments[i].amount,
        "KES " + payments[i].total_payment,
        moment(payments[i].date).format("ll"),
        payments[i].cashier,
        payments[i].tickno,
        payments[i].deviceId,
      ]);
    }

    return (
      <div>
        <Filter
          appid={appid}
          loan_id={loan_id}
          onChangeLoanID={this.onChangeLoanID.bind(this)}
          apps={apps}
          loans={payments}
          response={response}
          handleformonchange={(e) => AppClass.handleformonchange(this, e)}
          onChangeSacco={this.onChangeSacco.bind(this)}
          start_date={start_date}
          end_date={end_date}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
          loan_ids={this.state.loan_ids}
          members={this.state.members}
          member={this.state.member}
          onChangeMember={this.onChangeMember.bind(this)}
        />
        <div>
          {isLoading ? (
            <Loading message={message} icon="fa fa-spinner fa-spin" />
          ) : (
            <div>
              <div className="col-md-12 ">
                <div className="row">
                  <CardCount
                    count={this.state.summary.noOfrepayments}
                    title="Number of Repayments"
                    className="col-md-4"
                  />
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(this.state.summary.totalrepayments)
                    }
                    title="Total Repayments"
                    className="col-md-4"
                  />
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(
                        this.state.summary.totalCumulativerepayments
                      )
                    }
                    title="Total Cumulative Repayments"
                    className="col-md-4"
                  />
                </div>
              </div>
              <Payment
                className="col-md-12"
                role={this.props.role}
                payments={payments}
                showAddPayment={
                  this.state?.saccoSettings?.other?.dashboardPaymentsEnabled
                }
                response={response}
                delete_payment={this.onDelete.bind(this)}
                csvData={csvData}
                printcsv={this.printcsv.bind(this)}
                view_files={this.view_files.bind(this)}
                fromDate={start_date}
                toDate={end_date}
                app_id={appid?.value}
                ztoken={this.props.ztoken}
                user_id={this.state.member?.value}
              />
              <Pagination
                totalItemsCount={this.state.totalItemsCount}
                page={this.state.page}
                limit={this.state.limit}
                handleChangeLimit={this.handleChangeLimit.bind(this)}
                handlePagination={this.handlePagination.bind(this)}
              />
            </div>
          )}
        </div>
        <Modal show={show}>
          <Header>
            <h5 class="modal-title">Files</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleClose.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <File files={files} />
          </Body>
          <Footer>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleClose.bind(this)}
            >
              Close
            </button>
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default Payments;
