import React from "react";
import { Card } from "../Card";

const ProfileDetail = ({ name, fleet_number, response, handleformonchange, handleUpdateData, isSubmitting }) => (
    <Card className="col-md-4 col-md-offset-4">
        <div className="panel-body">
            {/* <div className="col-md-12">
                <div className="form-group">
                    <label>Change Profile</label>
                    <input className="form-control" name="profile_pic" type="file" />
                </div>
            </div> */}
            <div className="col-md-12">
                <div className="form-group">
                    <label>Name</label>
                    <input className="form-control" name="name" type="text" onChange={handleformonchange} />
                </div>
            </div>

            <div className="col-md-12">
                <div className="form-group">
                    <label>Fleet Number:</label>
                    <input className="form-control" name="fleet_number" type="text" onChange={handleformonchange} />
                </div>
            </div>
            <div className="col-md-12">
                {
                    isSubmitting ? <button className="btn btn-primary btn-block" disabled>Updating...</button>
                        :
                        <button className="btn btn-primary btn-block" onClick={handleUpdateData}>Save Changes</button>
                }
            </div>
        </div>
    </Card>
);

export default ProfileDetail