// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* content */
div.content {
  /* position: relative; */
  background: #f0f0f0;
  overflow: hidden;
  height: 100vh;
  padding-bottom: 100px;
}

div.main-content {
  overflow-y: auto;
  height: 100%;
}

.table-responsive {
  width: 100%;
  height: 100%;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/App/components/Content/styles.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,wBAAwB;EACxB,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB","sourcesContent":["/* content */\ndiv.content {\n  /* position: relative; */\n  background: #f0f0f0;\n  overflow: hidden;\n  height: 100vh;\n  padding-bottom: 100px;\n}\n\ndiv.main-content {\n  overflow-y: auto;\n  height: 100%;\n}\n\n.table-responsive {\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
