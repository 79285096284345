import React from "react";
import "./styles.css";

const CardCount = ({ className = "col-md-3", title, count, number }) => {
  return (
    <div className={`${className} card-count `}>
      <div className="panel">
        <div className="card-count-item panel-body">
          <h1>{title}</h1>
          <span>{count || 0}</span>
          {number > 0 && <span className="number">/ {number}</span>}
        </div>
      </div>
    </div>
  );
};
export default CardCount;
