import React from 'react'
import { ProfileDetail } from '../../components/Profile';
import AppClass from '../../AppClass'

class Profile extends React.Component {
    constructor() {
        super();

        this.state = {
            isSubmitting: false,
            response: {}
        }
    }

    handleUpdateData() {
        this.setState({ isSubmitting: true });

        const input_data = {
            id: this.props.user_id,
            name: this.state.name,
            fleet_number: this.state.fleet_number,
            model: "member",
            available: true,
            date: new Date().getTime()
        };

        const errors = AppClass.validate_form(input_data);

        if (!Object.keys(errors).length) {
            let response = {};

            AppClass.update_data(input_data)
                .then((data) => {
                    this.setState({
                        isSubmitting: false
                    });
                    response.success = "Profile has been updated, success";
                    this.setState({
                        response,
                    });
                })
                .catch(err => {
                    this.setState({ isSubmitting: false });
                    response.error = "Error, " + err;
                    this.setState({
                        response
                    });
                });
        } else {
            this.setState({ response: errors, isSubmitting: false });
        }
    }

    render() {
        const { response, name, fleet_number, isSubmitting } = this.state
        return (
            <div className="content-cont">
                <ProfileDetail
                    handleformonchange={(e) => AppClass.handleformonchange(this, e)}
                    name={name}
                    fleet_number={fleet_number}
                    response={response}
                    handleUpdateData={this.handleUpdateData.bind(this)}
                    isSubmitting={isSubmitting} />
            </div>
        )
    }
}

export default Profile;