import React from "react";
import { LoanOverview } from "../../components/Loan";
import { Filter } from "../../components/Filter";
import { Payment } from "../../components/Payments";
import AppClass from "../../AppClass";
import { Loading } from "../../components/Loader";
import moment from "moment";
import { Modal, Header, Body, Footer } from "../../components/Modal";
import { File } from "../../components/File";
import { connect } from "react-redux";

let loan_ids = [];
class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      app_ids: [],
      loans: [],
      isLoading: false,
      payments: [],
      start_date: moment().subtract("30", "days"),
      end_date: moment(),
      message: "Please wait...",
      csvData: [],
      show: false,
      files: [],
      apps: [],
      loanCopyData: [],
    };
  }

  componentDidMount = async () => {
    this.handleInitial();
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.handleInitial();
    }
  }

  handleInitial = async () => {
    let f_apps = [],
      { apps } = this.props;

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    await this.setState({ apps: f_apps });

    this.get_loan_by_apps(true, this.state.apps);

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    }

    // const { apps } = this.props;

    // let f_apps = [];

    // for (let el of apps) {
    //   f_apps.push({
    //     label: el.name,
    //     value: el._id,
    //   });
    // }

    // await this.setState({ apps: f_apps });

    // await this.get_loan_by_apps(true, f_apps);

    // if (apps.length === 1) {
    //   this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    // }

    this.getFiltersFromLCStorage();
  };

  getFiltersFromLCStorage = () => {
    const { filters } = this.props;

    this.setState({ ...filters });

    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== "") {
        this.setState({
          [key]: filters[key],
        });
      }
    });
  };

  get_loan_by_apps = async (isLoading, apps) => {
    let app_ids = await AppClass.get_app_ids(apps);

    console.log("================app_ids====================");
    console.log(app_ids);
    console.log("====================================");

    return;

    this.get_loans(isLoading, app_ids);

    this.setState({ app_ids });
  };

  onChangeSacco(e) {
    this.setState({ appid: e }, () => {
      this.get_loans(true);
    });
  }

  onChangeLoanID(e) {
    this.setState({ loan_id: e }, () => {
      this.get_loans(true);
    });
  }

  get_loans(isLoading, app_ids) {
    this.setState({ isLoading, message: "Please wait..." });

    const { user_id } = this.props;
    const { start_date, end_date, loan_id, appid } = this.state;

    let response = {},
      status = ["Accepted", "Disbursed", "Cleared"];

    const _start_date = new Date(start_date).getTime();
    const _end_date = new Date(end_date).getTime();

    let _appid = "";

    if (!appid || !appid?.value) {
      _appid = AppClass.get_app_ids(app_ids);
    } else {
      _appid = [appid.value];
    }

    const body = {
      user_id,
      model: "loan",
      tag: "by_last",
      app_id: _appid,
      status,
    };

    if (loan_id !== undefined) {
      body["loan_id"] = loan_id.value;
    }

    if (start_date !== undefined || end_date !== undefined) {
      body["start_date"] = _start_date;
      body["end_date"] = _end_date;
    }

    if (loan_id && loan_id.value) {
      body["loan_id"] = loan_id.value;
    }

    AppClass.get_data(body)
      .then((data) => {
        //get accepted loans
        let arrayData = [];

        for (var i = 0; i < data.data.length; i++) {
          if (data.data[i].last && status.includes(data.data[i].last.status)) {
            arrayData.push(data.data[i].last);
            loan_ids.push({
              label: data.data[i].last.loan_id,
              value: data.data[i].last.loan_id,
            });
          } else if (status.includes(data.data[i].status)) {
            arrayData.push(data.data[i]);
            loan_ids.push({
              label: data.data[i].loan_id,
              value: data.data[i].loan_id,
            });
          }
        }

        //selected app will be the first which is not fully paid
        for (var i = 0; i < arrayData.length; i++) {
          if (arrayData[i].amount > arrayData[i].total_payment) {
            // this.setState({
            //   appid: arrayData[i].app_id,
            // });

            this.get_payments(arrayData[i].app_id, arrayData[i].loan_id);

            break;
          }
        }

        if (arrayData.length > 0) {
          this.setState({
            loan_id: {
              label: arrayData[0].loan_id,
              value: arrayData[0].loan_id,
            },
            loans: arrayData,
            isLoading: false,
            loanCopyData: arrayData.slice(0),
            loan_ids,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  get_payments(appid, loan_id) {
    this.setState({
      message: "Get payments, just a moment...",
    });
    let response = {};
    const body = {
      tag: "by_loan_id",
      loan_id,
      model: "loan",
    };
    if (appid !== undefined) {
      body["app_id"] = appid;
    }
    if (loan_id !== undefined) {
      body["loan_id"] = loan_id;
    }
    AppClass.get_data(body)
      .then((data) => {
        let loans = data.data,
          payment_array = [];
        for (let i = 0; i < loans.length; i++) {
          if (loans[i].last_payment !== 0 && loans[i].amount !== 0) {
            payment_array.push(loans[i]);
          }
        }

        this.setState({ isLoading: false, payments: payment_array });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value }, () => {
      this.get_loans(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value }, () => {
      this.get_loans(true);
    });
  }

  printcsv() {
    this.setState({ csv: true });
  }

  view_files(files) {
    this.setState({ show: true });
    this.setState({ files });
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    const {
      apps,
      response,
      payments,
      loans,
      isLoading,
      message,
      start_date,
      end_date,
      appid,
      loan_id,
      show,
      files,
      loanCopyData,
      loan_ids,
    } = this.state;

    const csvData = [
      [
        "Borrower",
        "Loan Principle amount",
        "Next Payment date On",
        "Outstanding Amount",
        "End date of Loan Repayment",
      ],
    ];

    for (var i = 0; i < loans.length; i++) {
      csvData.push([
        loans[0].user_id,
        loans[0].req_amount,
        moment(loans[0].date).add(30, "days").format("ll"),
        "KES " + (loans[0].amount - loans[0].last_payment),
        moment(loans[0].date)
          .add(loans[0].payment_duration, "months")
          .format("ll"),
      ]);
    }

    let csvPayment = [["Loan ID", "Date", "Paid Amount", "Balance"]];

    for (var i = 0; i < payments.length; i++) {
      csvPayment.push([
        payments[i].loan_id,
        moment(payments[i].date).format("ll"),
        "KES " + payments[i].last_payment,
        "KES " + (payments[i].amount - payments[i].last_payment),
      ]);
    }

    let current_loan = [];
    for (var i = 0; i < loans.length; i++) {
      if (loans[i].amount >= loans[i].total_payment) {
        current_loan.push(loans[i]);
      }
    }

    return (
      <div
        className="content-cont"
        style={{ height: "100vh", overflowY: "auto", paddingBottom: 150 }}
      >
        <Filter
          apps={apps}
          loans={loanCopyData}
          response={response}
          handleformonchange={(e) => AppClass.handleformonchange(this, e)}
          onChangeSacco={this.onChangeSacco.bind(this)}
          onChangeLoanID={this.onChangeLoanID.bind(this)}
          start_date={start_date}
          end_date={end_date}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
          appid={appid}
          loan_id={loan_id}
          loan_ids={loan_ids}
        />
        {isLoading ? (
          <Loading message={message} icon="fa fa-spinner fa-spin" />
        ) : (
          <div>
            <LoanOverview
              loans={current_loan}
              csvData={csvData}
              printcsv={this.printcsv.bind(this)}
            />
            <Payment
              className="col-md-12"
              role={this.props.role}
              payments={payments}
              response={response}
              csvData={csvPayment}
              view_files={this.view_files.bind(this)}
            />
          </div>
        )}
        <Modal show={show}>
          <Header>
            <h5 class="modal-title">Files</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleClose.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <File files={files} />
          </Body>
          <Footer>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleClose.bind(this)}
            >
              Close
            </button>
          </Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Overview);
