import Axios from "axios";
import React from "react";
import HOST_URL from "../../configs/api";
import { Card } from "../Card";
import { Loading } from "../Loader";
import toastMessage from "../toastMessage";
import AppClass from "../../AppClass";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import CardCount from "../CardCount/CardCount";
import { numberWithCommas } from "../../configs/formatCurrency";
import { Filter } from "../Filter";
import NewShare from "./NewShare";
import { Body, Footer, Header, Modal } from "../Modal";

class Shares extends React.Component {
  state = {
    data: [],
    error: {},
    limit: 10,
    page: 1,
    totalItemsCount: 0,
    fromDate: moment().subtract("30", "days").format("YYYY-MM-DD"),
    toDate: moment().add(1, "d").format("YYYY-MM-DD"),
    summary: {},
    transactions: [],
    members: [],
  };

  // componentDidMount = async () => {
  //   const { apps } = this.props;

  //   await this.setState({ apps: apps });

  //   if (apps.length === 1) {
  //     let appid = { label: apps[0].label, value: apps[0].value };
  //     await this.setState({ appid }, () => {
  //       this.getData(true, [appid]);
  //     });
  //   } else {
  //     this.getData(true, apps);
  //   }
  // };

  // componentDidUpdate = async (prevProps) => {
  //   if (prevProps.apps !== this.props.apps) {
  //     this.getData(true);
  //   }
  // };

  componentDidMount = async () => {
    await this.getSaccoApps();
  };

  getData = async (isLoading, app_ids, memberId) => {
    this.setState({ isLoading });

    const { fromDate, toDate } = this.state;
    const { user_id, role } = this.props;

    let params = {
      app_id: JSON.stringify(app_ids),
      fromDate,
      toDate,
    };

    if (memberId) {
      params.userId = memberId;
    }

    if (role !== "admin") {
      params.user_id = user_id;
    }

    const options = {
      method: "GET",
      url: HOST_URL.url + "/shares",
      params,
      headers: {
        authorization: `Bearer ${this.props.token}`,
      },
    };

    Axios(options)
      .then((response) => {
        const { data, summary } = response.data;
        this.setState({ isLoading: false, data, summary });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  };

  getAppIds = async (apps) => {
    const app_ids = await AppClass.get_app_ids(apps);
    return app_ids;
  };

  onChangeText(field, e) {
    this.setState({
      [field]: e.target ? e?.target?.value : e,
    });

    if (field === "appid") {
      this.getData(true, [e.value], this.state?.member?.value);
      this.get_members(e.value);
    }

    if (field === "member") {
      this.getData(true, [this.state.appid], e.value);
    }
  }

  handleChangeLimit(e) {
    this.setState({
      limit: e.target.value,
      page: 1,
    });
  }

  handlePagination(page) {
    this.setState({
      page,
    });
  }

  onChangeStartingDate(e) {
    const app_id = this.state?.appid?.value
      ? [this.state?.appid?.value]
      : undefined;

    this.setState({ fromDate: e.target.value, page: 1 }, () => {
      this.getData(true, app_id, this.state?.member?.value);
    });
  }

  onChangeEndDate(e) {
    const app_id = this.state?.appid?.value
      ? [this.state?.appid?.value]
      : undefined;
    this.setState({ toDate: e.target.value, page: 1 }, () => {
      this.getData(true, app_id, this.state?.member?.value);
    });
  }

  handleResetFilters() {
    this.setState(
      {
        sacco: undefined,
        fromDate: moment().subtract("30", "days").format("YYYY-MM-DD"),
        toDate: moment().add(1, "d").format("YYYY-MM-DD"),
      },
      () => {
        window.location.reload();
      }
    );
  }

  handleOpenModal(modal, modalTitle, selectedData) {
    this.setState({ [modal]: true, modalTitle, selectedData }, () => {
      if (modal === "showTransactionsModal") {
        this.getSharesById(true);
      }
    });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  getSharesById = async (isLoadingById) => {
    this.setState({ isLoadingById });

    const { selectedData } = this.state;

    const options = {
      method: "GET",
      url: HOST_URL.url + "/shares/" + selectedData._id,
      headers: {
        authorization: `Bearer ${this.props.token}`,
      },
    };

    Axios(options)
      .then((response) => {
        const { data } = response.data;
        this.setState({
          transactions: data?.transactions || [],
          isLoadingById: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoadingById: false });

        toastMessage("error", error);
      });
  };

  get_members(app_id) {
    if (!app_id) return;
    const { diladmin } = this.props;
    let body = {
      app_id,
      from_dil: diladmin + "",
    };

    this.setState({ isLoadingMembers: true, member: undefined });

    AppClass.get_member(body)
      .then((data) => {
        let members = [];

        for (let el of data.data.data) {
          members.push({
            label: el.user_name,
            value: el.user_id,
          });
        }

        this.setState({
          members,
          isLoadingMembers: false,
        });
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isLoadingMembers: false,
        });
      });
  }

  getSaccoApps() {
    let f_apps = [];

    for (let el of this.props.apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    this.setState({ apps: f_apps });

    if (f_apps.length > 0) {
      this.setState({ appid: f_apps[0] }, () => {
        this.getData(true, [f_apps[0]?.value]);
        this.get_members(f_apps[0]?.value);
      });
    }
  }

  render() {
    return (
      <div>
        <Filter
          appid={this.state.appid}
          apps={this.state.apps}
          start_date={this.state.fromDate}
          end_date={this.state.toDate}
          members={this.state.members}
          member={this.state.member}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
          onChangeSacco={(e) => this.onChangeText("appid", e)}
          showReset={this.state?.sacco?.value}
          handleResetFilters={this.handleResetFilters.bind(this)}
          onChangeMember={(e) => this.onChangeText("member", e)}
          isLoadingMembers={this.state.isLoadingMembers}
        />

        <div>
          <div className="col-md-12" style={{ marginBottom: "1.5rem" }}>
            <button
              className="btn btn-primary"
              onClick={() => this.handleOpenModal("shareModal")}
            >
              New Share
            </button>
          </div>
          {this.state.isLoading ? (
            <Loading icon="fa fa-spinner fa-spin" />
          ) : (
            <>
              <div className="col-md-12 ">
                <div className="row">
                  <CardCount count={this.state.summary.count} title="Count" />
                  <CardCount
                    count={"KES " + numberWithCommas(this.state.summary.total)}
                    title="Total"
                  />
                </div>
              </div>
              <Card className="col-md-12">
                <div className="panel-body table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Member Name</th>
                        <th style={{ textAlign: "right" }}>Amount</th>
                        <th style={{ textAlign: "center" }}>Date</th>
                        <th style={{ textAlign: "center" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.length > 0 ? (
                        this.state.data
                          .slice(
                            (this.state.page - 1) * this.state.limit,
                            this.state.page * this.state.limit
                          )
                          .map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.user_name}</td>
                                <td style={{ textAlign: "right" }}>
                                  KES {numberWithCommas(item.amount)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {moment(item.created_at).format("lll")}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <button
                                    className="btn btn-default"
                                    onClick={() =>
                                      this.handleOpenModal(
                                        "showTransactionsModal",
                                        item.user_name + " shares",
                                        item
                                      )
                                    }
                                  >
                                    <i className="fa fa-eye"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={10} align="center">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    totalItemsCount={this.state.data.length}
                    page={this.state.page}
                    limit={this.state.limit}
                    handleChangeLimit={this.handleChangeLimit.bind(this)}
                    handlePagination={this.handlePagination.bind(this)}
                  />
                </div>
              </Card>
            </>
          )}
        </div>
        <Modal show={this.state.shareModal}>
          <Header>
            <h5 class="modal-title">Share</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCloseModal("shareModal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <NewShare
              handleCloseModal={() => this.handleCloseModal("shareModal")}
              apps={this.props.apps}
              getData={this.getData}
              token={this.props.token}
            />
          </Body>
        </Modal>

        <Modal show={this.state.showTransactionsModal}>
          <Header>
            <h5 class="modal-title">{this.state.modalTitle}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCloseModal("showTransactionsModal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <div className="panel">
              {this.state.isLoadingById ? (
                <Loading />
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.transactions.length === 0 ? (
                      <tr>
                        <td colSpan={4} align="center">
                          No Data Available
                        </td>
                      </tr>
                    ) : (
                      this.state.transactions.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{moment(item.created_at).format("lll")}</td>
                            <td>KES {numberWithCommas(item.amount)}</td>
                            <td>KES {numberWithCommas(item.balance)}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </Body>
          <Footer>
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => this.handleCloseModal("showTransactionsModal")}
              style={{ marginRight: 25 }}
            >
              Close
            </button>
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default Shares;
