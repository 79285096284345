import Axios from "axios";
import React from "react";
import HOST_URL from "../../configs/api";
import { Card } from "../Card";
import { Loading } from "../Loader";
import toastMessage from "../toastMessage";
import AppClass from "../../AppClass";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import CardCount from "../CardCount/CardCount";
import { numberWithCommas } from "../../configs/formatCurrency";
import { TransactionsFilter } from "../Filter";
import { Body, Footer, Header, Modal } from "../Modal";

class Transactions extends React.Component {
  state = {
    apps: [],
    missingTransactions: [],
    modalItem: null,
    data: [],
    filteredData: [],
    error: {},
    limit: 50,
    page: 1,
    totalItemsCount: 0,
    appid: {},
    product_name: "LOANS",
    fromDate: moment().subtract('days', 1).format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    summary: {},
    members: [],
    products: [],
    showExistingTransactions: true,
  };

  componentDidMount = async () => {
    await this.getSaccoApps();
  };

  getData = async (isLoading) => {
    this.setState({ isLoading, missingTransactions: [] });
    const {
      fromDate,
      toDate,
      appid,
      product_name,
      limit,
      member = {},
      product = {},
      showExistingTransactions,
    } = this.state;

    let params = {
      app_id: appid.value,
      fromDate,
      toDate,
      product_name: product.label ? product.label : product_name,
      pageLimit: limit,
      user_id: member.value,
      showExistingTransactions,
    };
    const options = {
      method: "GET",
      url: HOST_URL.url + "/epesi-transactions",
      params,
      headers: {
        authorization: `Bearer ${this.props.token}`,
      },
    };

    Axios(options)
      .then((response) => {
        const { data, summary } = response.data;
        const filteredData = data.filter((item) => {
          if (showExistingTransactions) {
            return true;
          } else {
            return !item.exists;
          }
        });
        this.setState({ isLoading: false, data, summary, filteredData });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  };

  getProducts(app_id) {
    this.setState({
      isLoadingProduct: true,
    });

    const options = {
      method: "GET",
      url: `${HOST_URL.mobitill_url}/products?0[]=appid&1[]=${app_id}`,
      headers: {
        Authorization: "Bearer " + this.props.token,
      },
    };

    Axios(options)
      .then((data) => {
        let products = [];

        for (let el of data.data.data) {
          if (el.loaning) {
            products.push({
              label: el.name,
              value: el._id,
            });
          }
        }
        this.setState({ isLoadingProduct: false, products });
      })
      .catch((error) => {
        this.setState({ isLoadingProduct: false });

        toastMessage("error", error);
      });
  }

  getAppIds = async (apps) => {
    const app_ids = await AppClass.get_app_ids(apps);

    return app_ids;
  };

  onChangeText(field, e) {
    this.setState(
      {
        [field]: e.target ? e?.target?.value : e,
      },
      () => {
        if (field === "appid") {
          this.getData(true);
          this.get_members(e.value);
          this.getProducts(e.value);
        }

        if (field === "member" || field === "product") {
          this.getData(true);
        }
      }
    );
  }

  handleChangeLimit(e) {
    this.setState({
      limit: e.target.value,
      page: 1,
    });
  }

  handlePagination(page) {
    this.setState({
      page,
    });
  }

  onChangeStartingDate(e) {
    this.setState({ fromDate: e.target.value, page: 1 }, () => {
      this.getData(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ toDate: e.target.value, page: 1 }, () => {
      this.getData(true);
    });
  }

  onChangeShowExistingTransactions(e) {
    this.setState({ showExistingTransactions: !this.state.showExistingTransactions, page: 1 }, () => {
      // this.getData(true);
      const filteredData = this.state.data.filter((item) => {
        if (this.state.showExistingTransactions) {
          return true;
        } else {
          return !item.exists;
        }
      });
      this.setState({ filteredData });
    });
  }

  handleOpenModal(modal, item) {
    this.setState({ [modal]: true, modalItem: { ...item } });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
    this.setState({ modalItem: null });
  }

  handleResetFilters() {
    this.setState(
      {
        sacco: undefined,
        fromDate: moment().subtract("1", "month").format("yyyy-MM-dd"),
        toDate: moment().add("1", "d").format("yyyy-MM-dd"),
      },
      () => {
        window.location.reload();
      }
    );
  }

  handleCheckboxChange(e, transactionId) {
    // add or remove transactionId from missingTransactions
    const { missingTransactions } = this.state;
    // if action is select then add otherwise remove id
    if (e.target.checked) {
      if (!missingTransactions.includes(transactionId))
        missingTransactions.push(transactionId);
    } else {
      const index = missingTransactions.indexOf(transactionId);
      if (index > -1) {
        missingTransactions.splice(index, 1);
      }
    }

    const updatedData = this.state.filteredData.map((item) => {
      if (item._id === transactionId) {
        return {
          ...item,
          checked: !item.checked, // Toggle the checked state
        };
      }
      return item;
    });

    this.setState({ filteredData: updatedData });
  }

  addMissingTransactions(modal) {
    const { missingTransactions } = this.state;
    
    this.setState({ isLoading: true, missingTransactions: [] });
    const options = {
      method: "POST",
      url: HOST_URL.url + "/epesi-transactions",
      data: { 
        app_id: this.state.appid.value,
        missing_transactions: missingTransactions,
      },
      headers: {
        authorization: `Bearer ${this.props.token}`,
      },
    };
    console.log(options)

    Axios(options)
      .then((response) => {
        const { message } = response.data;
        toastMessage("success", message);
        this.getData(true);
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toastMessage("error", error);
      });
    // close modal
    this.handleCloseModal(modal);
    // pull records
    this.getData(true);
  }

  get_members(app_id) {
    if (!app_id) return;
    // const { diladmin } = this.props;
    let body = {
      app_id,
    };

    this.setState({ isLoadingMembers: true, member: undefined });

    AppClass.get_member(body)
      .then((data) => {
        let members = [];

        for (let el of data.data.data) {
          members.push({
            label: el.user_name,
            value: el.user_id,
          });
        }

        this.setState({
          members,
          isLoadingMembers: false,
        });
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isLoadingMembers: false,
        });
      });
  }

  getSaccoApps() {
    let f_apps = [];

    for (let el of this.props.apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    this.setState({ apps: f_apps });

    if (f_apps.length > 0) {
      this.setState({ appid: f_apps[0] }, () => {
        this.getData(true);
        this.get_members(f_apps[0]?.value);
        this.getProducts(f_apps[0]?.value);
      });
    }
  }

  render() {
    return (
      <div>
        <TransactionsFilter
          appid={this.state.appid}
          apps={this.state.apps}
          start_date={this.state.fromDate}
          end_date={this.state.toDate}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
          onChangeSacco={(e) => this.onChangeText("appid", e)}
          showReset={this.state?.appid?.value}
          handleResetFilters={this.handleResetFilters.bind(this)}
          members={this.state.members}
          member={this.state.member}
          onChangeMember={(e) => this.onChangeText("member", e)}
          isLoadingMembers={this.state.isLoadingMembers}
          isLoadingProduct={this.state.isLoadingProduct}
          products={this.state.products}
          product={this.state.product}
          onChangeProduct={(e) => this.onChangeText("product", e)}
          show_existing_tansactions={this.state.showExistingTransactions}
          onChangeShowExistingTransactions={this.onChangeShowExistingTransactions.bind(
            this
          )}
        />

        <div>
          {this.state.isLoading ? (
            <Loading icon="fa fa-spinner fa-spin" />
          ) : (
            <>
              <div className="col-md-12 ">
                <div className="row">
                  <CardCount count={this.state.summary.count} title="Count" />
                  <CardCount
                    count={"KES " + numberWithCommas(this.state.summary.total)}
                    title="Total"
                  />
                </div>
              </div>
              <Card className="col-md-12">
                <div className="panel-heading card-title">
                  <span>Epesi Transactions</span>
                  <div className="card-menus">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() =>
                        this.handleOpenModal("showEpesiTransactionModal")
                      }
                      disabled={this.state.missingTransactions.length === 0}
                    >
                      <i className="fa fa-plus" /> Post Missing Transaction(s)
                    </button>
                  </div>
                </div>
                <div className="panel-body table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>Exists</th>
                        <th>Date</th>
                        <th>Tick No.</th>
                        <th>Vehicle No.</th>
                        <th>Product</th>
                        <th>Member</th>
                        <th style={{ textAlign: "right" }}>Amount</th>
                        <th style={{ textAlign: "center" }}>More</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.length > 0 ? (
                        this.state.filteredData
                          .slice(
                            (this.state.page - 1) * this.state.limit,
                            this.state.page * this.state.limit
                          )
                          .map((item, i) => {
                            return (
                              <tr key={i}>
                                <td
                                  style={{ textAlign: "center" }}
                                  title="Select this checkbox to add a missing transaction"
                                >
                                  <input
                                    type="checkbox"
                                    name="exists"
                                    onChange={(e) =>
                                      this.handleCheckboxChange(e, item._id)
                                    }
                                    checked={item.checked || false}
                                    disabled={item.exists}
                                  />
                                </td>
                                <td>{moment(item.timestamp).format("lll")}</td>
                                <td>{(item.tickno || "").toUpperCase()}</td>
                                <td>{(item.vehicleno || "").toUpperCase()}</td>
                                <td>
                                  {(item.productname || "").toUpperCase()}
                                </td>
                                <td>{(item.membername || "").toUpperCase()}</td>
                                <td style={{ textAlign: "right" }}>
                                  {numberWithCommas(item.total)}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-default"
                                    onClick={() =>
                                      this.handleOpenModal(
                                        "showTransactionDetailsModal",
                                        item
                                      )
                                    }
                                  >
                                    <i className="fa fa-eye"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={10} align="center">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Card>
              <Pagination
                totalItemsCount={this.state.data.length}
                page={this.state.page}
                limit={this.state.limit}
                handleChangeLimit={this.handleChangeLimit.bind(this)}
                handlePagination={this.handlePagination.bind(this)}
              />
            </>
          )}
        </div>

        <Modal show={this.state.showEpesiTransactionModal}>
          <Header>
            <h5 class="modal-title">Transactions</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCloseModal("showEpesiTransactionModal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            Are you sure you want to add{" "}
            <b>({this.state.missingTransactions.length})</b> missing
            transaction(s)?
          </Body>
          <Footer>
            <button
              type="button"
              class="btn btn-danger"
              onClick={() => this.handleCloseModal("showEpesiTransactionModal")}
              style={{ marginRight: 25 }}
            >
              CANCEL
            </button>
            <button
              type="button"
              class="btn btn-success"
              onClick={() =>
                this.addMissingTransactions("showEpesiTransactionModal")
              }
              style={{ marginRight: 25 }}
            >
              SUBMIT
            </button>
          </Footer>
        </Modal>

        <Modal show={this.state.showTransactionDetailsModal}>
          <Header>
            <h5 class="modal-title">Transaction Details</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() =>
                this.handleCloseModal("showTransactionDetailsModal")
              }
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <div className="panel">
              <table className="table table-bordered">
                <tbody>
                  {!this.state.modalItem ? (
                    <tr>
                      <td colSpan={4} align="center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    <>
                      <tr>
                        <td>_id</td>
                        <td style={{ textAlign: "right" }}>
                          {this.state.modalItem?._id}
                        </td>
                      </tr>
                      <tr>
                        <td>Tick No</td>
                        <td style={{ textAlign: "right" }}>
                          {this.state.modalItem?.tickno}
                        </td>
                      </tr>
                      <tr>
                        <td>Mode</td>
                        <td style={{ textAlign: "right" }}>
                          {this.state.modalItem?.mode}
                        </td>
                      </tr>
                      <tr>
                        <td>TimeStamp</td>
                        <td style={{ textAlign: "right" }}>
                          {this.state.modalItem?.timestamp}
                        </td>
                      </tr>
                      <tr>
                        <td>Quantity</td>
                        <td style={{ textAlign: "right" }}>
                          {this.state.modalItem?.quantity}
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td style={{ textAlign: "right" }}>
                          {this.state.modalItem?.total}
                        </td>
                      </tr>
                      <tr>
                        <td>Product Name</td>
                        <td style={{ textAlign: "right" }}>
                          {this.state.modalItem?.productname}
                        </td>
                      </tr>
                      <tr>
                        <td>Collector</td>
                        <td style={{ textAlign: "right" }}>
                          {this.state.modalItem?.collector}
                        </td>
                      </tr>
                      <tr>
                        <td>Device Name</td>
                        <td style={{ textAlign: "right" }}>
                          {this.state.modalItem?.devicename}
                        </td>
                      </tr>
                      <tr>
                        <td>Vehicle No</td>
                        <td style={{ textAlign: "right" }}>
                          {this.state.modalItem?.vehicleno}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Body>
        </Modal>
      </div>
    );
  }
}

export default Transactions;
