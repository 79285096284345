// require("dotenv").config();

const {
  REACT_APP_MOBITILL_URL,
  REACT_APP_URL,
  REACT_APP_SMS_API,
  REACT_APP_NEW_API,
} = process.env;

const HOST_URL = {
  mobitill_url: REACT_APP_MOBITILL_URL,
  url: REACT_APP_URL,
  sms_api: REACT_APP_SMS_API,
  new_api: REACT_APP_NEW_API,
};

export default HOST_URL;
