import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AdminAuth from "./pages/Auth/Admin";
import Member from "./pages/Auth/Member";
import HomeMember from "./pages/member/Home";
import HomeAdmin from "./pages/admin/Home";
import Register from "./pages/Auth/Register";
import HomeAdminSaving from "./pages/savings/admin/Home";
import HomeMemberSaving from "./pages/savings/member/Home";
import isExpired from "./utils/isExpired";

const createBrowserHistory = require("history").createBrowserHistory;

class App extends React.Component {
  componentDidMount = async () => {
    if (isExpired()) {
      window.location.href = "/";
    }
  };
  render() {
    return (
      <>
        <Router history={createBrowserHistory({ queryKey: false })}>
          <Switch>
            <Route exact path="/" component={Member} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/adminAuth" component={AdminAuth} />
            <Route exact path="/home/member/:page" component={HomeMember} />
            <Route exact path="/home/admin/:page" component={HomeAdmin} />
            <Route
              exact
              path="/home/member/:page/:somethingID"
              component={HomeMember}
            />
            <Route
              exact
              path="/home/admin/:page/:somethingID"
              component={HomeAdmin}
            />
            <Route
              exact
              path="/home/admin_savings/:page"
              component={HomeAdminSaving}
            />
            <Route
              exact
              path="/home/member_savings/:page"
              component={HomeMemberSaving}
            />
            <Route
              exact
              path="/home/admin_savings/:page/:somethingID"
              component={HomeAdminSaving}
            />
            <Route
              exact
              path="/home/member_savings/:page/:somethingID"
              component={HomeMemberSaving}
            />
          </Switch>
        </Router>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
        />
      </>
    );
  }
}

export default App;
