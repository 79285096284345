// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.fileContainer{
    
}

div.thumbnail{
    height: 100px;
    position: relative;
    position: relative;
}

div.thumbnail img{
    height: 100%;
}

div.overlay{
    background: rgba(0, 0, 0, .3);
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
}

div.overlay i{
    color: white;
    font-size: 20px
}`, "",{"version":3,"sources":["webpack://./src/App/components/File/styles.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,KAAK;IACL,OAAO;IACP,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,OAAO;AACX;;AAEA;IACI,YAAY;IACZ;AACJ","sourcesContent":["div.fileContainer{\n    \n}\n\ndiv.thumbnail{\n    height: 100px;\n    position: relative;\n    position: relative;\n}\n\ndiv.thumbnail img{\n    height: 100%;\n}\n\ndiv.overlay{\n    background: rgba(0, 0, 0, .3);\n    position: absolute;\n    top:0;\n    right:0;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    left: 0;\n}\n\ndiv.overlay i{\n    color: white;\n    font-size: 20px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
