// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Mada:400,500,600);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Mada", sans-serif;
  font-size: 14px;
  font-weight: 400;
  background: #eff3f6;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Mada:400,500,600\");\nbody {\n  font-family: \"Mada\", sans-serif;\n  font-size: 14px;\n  font-weight: 400;\n  background: #eff3f6;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
