import React from "react";
import { Card } from "../../Card";
import moment from "moment";
import UpdateLoanApplication from "./UpdateLoanApplication";
import { MemberSavings } from "../../Saving";
import { PendingLoanDetail } from "../../../components/Loan";
import { numberWithCommas } from "../../../configs/formatCurrency";
import getFileTypeFromBase64 from "../../../utils/isPDF";
import getTotalFees from "../../../utils/getTotalFees";

const ViewLoan = ({
  sacco,
  data,
  response,
  status,
  approved_amount,
  handleformonchange,
  update_loan,
  view_purpose,
  isSubmitting,
  decline,
  files,
  handleFile,
  logged_user_id,
  user_id,
  isFleet,
  show,
  app_id,
  fleet,
  file,
  onClientAdminConfirm,
  role,
  free_savings,
  isLoadingGuarantorData,
  guarantor_data,
  onEditGuarantor,
  onDeleteGuarantor,
  onAddNewGuarantor,
  ztoken,
  date_approved,
  fees,
}) => (
  <div>
    {status?.toLowerCase() === "processing" ? (
      <PendingLoanDetail
        data={data}
        isSubmitting={isSubmitting}
        onConfirm={onClientAdminConfirm}
        response={response}
        role={role}
      />
    ) : (
      <>
        <Card className="col-md-12">
          {response.error ? (
            <div className="alert alert-danger">
              <span>{response.error}</span>
            </div>
          ) : (
            response.success && (
              <div className="alert alert-success">
                <span>{response.success}</span>
              </div>
            )
          )}
          <div className="panel-heading card-title">
            <span>
              Loans ID: <b>{data.length > 0 && data[0].loan_id}</b>
            </span>
            {data.length > 0 &&
              (data[0].status === "Pending" ||
                data[0].status === "Processing") && (
                <div className="card-menus">
                  <div className="icon-danger" onClick={decline}>
                    <a href="#">
                      <i className="" />
                      <i className="fa fa-times" /> Decline
                    </a>
                  </div>
                </div>
              )}
          </div>
          <div className="panel-body table-responsive">
            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Sacco</th>
                    <th>Requested By</th>
                    <th>Payment Duration</th>
                    <th>Principal</th>
                    <th>Expected Interest</th>
                    <th>Fees</th>
                    <th>Total Amount Payable</th>
                    <th>Date Requested</th>
                    <th>Purpose</th>
                    <th>Date Approved</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>{sacco}</b>
                    </td>
                    <td>
                      <b> {data.length > 0 && data[0].user_name}</b>
                    </td>
                    <td>
                      <b>
                        {" "}
                        {data.length > 0 &&
                          data[0].payment_duration +
                            " " +
                            (data[0].term || "Month(s)")}
                      </b>
                    </td>
                    <td>
                      <b>
                        KES{" "}
                        {data.length > 0 &&
                          numberWithCommas(data[0].req_amount)}
                      </b>
                    </td>
                    <td>
                      <b>
                        KES{" "}
                        {data.length > 0 &&
                          numberWithCommas(data[0].expected_interest)}
                      </b>
                    </td>

                    <td>
                      <b>
                        {data.length > 0 && "KES " + getTotalFees(data[0].fees)}
                      </b>
                    </td>
                    <td>
                      <b>
                        KES{" "}
                        {data.length > 0 &&
                          numberWithCommas(data[0].disbursed_amount)}
                      </b>
                    </td>
                    <td>{moment(data[0].date).format("ll")}</td>
                    <td>
                      {data[0].purpose !== undefined ? data[0].purpose : "-"}
                    </td>
                    <td>-</td>
                    <td className="text-info">
                      <b>{data[0].status}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>
        {/* <div className="col-md-12">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4
            class="panel-title"
            data-toggle="collapse"
            data-target="#repayment"
          >
            Loan Repayment
          </h4>
        </div>
        <div id="repayment" class="panel-collapse collapse">
          <div class="panel-body">Loan Amortization</div>
        </div>
      </div>
    </div> */}
        <div className="col-md-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4
                class="panel-title"
                data-toggle="collapse"
                data-target="#savings"
              >
                Available Savings
              </h4>
            </div>
            <div id="savings" class="panel-collapse collapse">
              <div class="panel-body">
                <MemberSavings
                  isFleet={isFleet}
                  {...{
                    app_id,
                    user_id,
                    fleet,
                  }}
                  show={true}
                  ztoken={ztoken}
                />
              </div>
            </div>
          </div>
        </div>
        {data.length > 0 && data[0].guarantor_save_id && (
          <div className="col-md-12">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4 class="panel-title">Guarantor</h4>
              </div>
              <div class="panel-body">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Total Deposit</th>
                      <th>Total Guaranted</th>
                      <th>Requested Amount</th>
                      <th>Free Deposit</th>
                      <th>Action</th>
                    </tr>
                  </tbody>
                  <tbody>
                    {isLoadingGuarantorData ? (
                      <tr>
                        <td colSpan={5} align="center">
                          Fetching guarantor's data...
                        </td>
                      </tr>
                    ) : (
                      guarantor_data.map((item, i) => {
                        return (
                          <tr
                            style={
                              item.free_deposit <= 0
                                ? {
                                    backgroundColor: "#facaca",
                                  }
                                : {}
                            }
                          >
                            <td>{item.user_name}</td>
                            <td>{"KES " + item.total_saving}</td>
                            <td>{"KES " + item.guaranted_amount}</td>
                            <td>{"KES " + item.requested_amount}</td>
                            <td>{"KES " + item.free_deposit}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: 15 }}
                                onClick={() => onEditGuarantor(item, i)}
                              >
                                Edit
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => onDeleteGuarantor(item, i)}
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <div className="row" style={{ marginTop: 15, paddingLeft: 15 }}>
                  <button
                    onClick={onAddNewGuarantor}
                    className="btn btn-primary"
                  >
                    Add New Guarantor
                  </button>
                </div>
              </div>
            </div>
            {/* {data[0].guaranted_amount > free_savings && (
                <div className="alert alert-danger">
                  <span>
                    Due to Insuficcient free deposit balance of guarantor, this
                    loan application could be declined
                  </span>
                </div>
              )} */}
          </div>
        )}
        {fees && (
          <div className="col-md-12">
            <div className="panel panel-default" style={{ marginTop: "1rem" }}>
              <div className="panel-heading">
                <h4
                  class="panel-title"
                  data-toggle="collapse"
                  data-target="#attachment"
                >
                  Additional Fees
                </h4>
              </div>
              <div className="panel-body">
                <div className="row">
                  {Object.keys(fees).map((el) => {
                    return (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label style={{ textTransform: "capitalize" }}>
                            {el}
                          </label>
                          <input
                            className="form-control"
                            value={fees[el]}
                            disabled
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {data?.length > 0 && data[0]?.files?.length > 0 && (
          <div className="col-md-12">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4
                  class="panel-title"
                  data-toggle="collapse"
                  data-target="#attachment"
                >
                  Attachment
                </h4>
              </div>
              <div id="attachment" class="panel-collapse collapse">
                <div class="panel-body">
                  {data.length > 0 && data[0]?.files?.length > 0 && (
                    <table className="table table-bordered">
                      <tbody>
                        {data[0]?.files?.map((f) => {
                          if (getFileTypeFromBase64(f) === "application/pdf") {
                            return (
                              <tr>
                                <td align="center">
                                  <embed
                                    src={`data:application/pdf;base64,${f}`}
                                    width="500"
                                    height="600"
                                    type="application/pdf"
                                  />
                                </td>
                              </tr>
                            );
                          }
                          return (
                            <tr>
                              <td align="center">
                                <img
                                  style={{ width: 512 }}
                                  src={`data:image/png;base64, ${f}`}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <UpdateLoanApplication
          response={response}
          status={
            status === undefined ? data.length > 0 && data[0].status : status
          }
          approved_amount={
            approved_amount === undefined
              ? data.length > 0 && data[0].req_amount
              : approved_amount
          }
          handleformonchange={handleformonchange}
          update_loan={() =>
            update_loan(
              data.length > 0 && data[0].req_amount,
              data.length > 0 && data[0].interestrate,
              files,
              data[0].total_interest
            )
          }
          isSubmitting={isSubmitting}
          handleFile={handleFile}
          logged_user_id={logged_user_id}
          user_id={user_id}
          createdBy={data.length > 0 && data[0].createdBy}
          decline={decline}
          free_savings={free_savings}
          guaranted_amount={data.length > 0 ? data[0].guaranted_amount : 0}
          loan={data}
          date_approved={date_approved}
        />
      </>
    )}
  </div>
);

export default ViewLoan;
