import React, { Component } from "react";
import Select from "react-select";
import "./styles.css";

const SelectComponent = ({
  options,
  value,
  onChange,
  label,
  isLoading,
  isMulti,
}) => (
  <div className="form-group">
    <label>{label}</label>
    <Select
      // className="basic-single"
      classNamePrefix="select"
      options={options}
      value={value}
      onChange={onChange}
      isLoading={isLoading}
      isMulti={isMulti}
    />
  </div>
);

export default SelectComponent;
