import React from "react";
import { Card } from "../../Card";
import Select from "react-select";
import {
  data_loan_type,
  loan_methods,
  terms,
  rules,
} from "../../../data/settings";

const LoanType = ({
  loan_type,
  handleformonchange,
  exist,
  onUpdate,
  response,
  interest_rate,
  contribution_rate,
  isSubmitting,
  onSubmit,
  onChangeSacco,
  apps,
  appid,
  method,
  addLongTermPurpose,
  addShortTermPurpose,
  long_term_purpose,
  short_term_purpose,
  long_term_p,
  short_term_p,
  removePurpose,
  onChangeLoanType,
  onChangeGracePeriod,
  default_limit_period,
  onChangeTerm,
  onChangeContribution,
  onChangeInterestRate,
  onChangeMethod,
  onChangeAmountRule,
  onChangeLoanPeriod,
  fees,
  onChangeFees,
  handleCustomizeFee,
  onChangeCustomFee,
  errorFee,
  customFee,
  handleRemoveFee,
  onChangePaymentOption,
  other,
  onChangeMaximumAmount,
}) => (
  <div>
    <Card className="col-md-8 col-md-offset-2">
      {response.error ? (
        <div className="alert alert-danger">
          <span>{response.error}</span>
        </div>
      ) : (
        response.success && (
          <div className="alert alert-success">
            <span>{response.success}</span>
          </div>
        )
      )}
      <div className="panel-heading  card-title">
        <span>Loan Setup</span>
      </div>
      <div className="panel-body">
        <div className="col-md-12">
          <div className="form-group">
            <label>Saccos</label>
            <Select
              value={appid}
              options={apps}
              name="fleet"
              onChange={onChangeSacco}
            />
            <span className="text-danger">{response.app_id}</span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Choose Loan Type</label>
            <br></br>
            <div className="form-check">
              {data_loan_type.map((item, i) => {
                return (
                  <div key={i}>
                    <label>
                      {" "}
                      <input
                        type="checkbox"
                        checked={loan_type.includes(item)}
                        style={{ marginRight: 10 }}
                        className="form-check-input"
                        name="loan_type"
                        value={item}
                        onChange={onChangeLoanType}
                      />
                      {item}{" "}
                    </label>{" "}
                    <br></br>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {loan_type.includes("Short-term Loans") && (
          <div className="col-md-12">
            <div className="form-group">
              <label>Short-term Loan Purposes</label>
              <div style={{ position: "relative" }}>
                <input
                  className="form-control"
                  name="short_term_p"
                  value={short_term_p}
                  onChange={handleformonchange}
                  placeholder="Add loan purposes"
                  style={{ textTransform: "capitalize" }}
                />
                <button
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  className="btn btn-primary"
                  onClick={addShortTermPurpose}
                >
                  Add
                </button>
              </div>
              <span className="text-danger">{response.short_term_p}</span>
            </div>
          </div>
        )}
        {short_term_purpose.length > 0 && (
          <div className="col-md-12">
            <div className="form-group well">
              <label>Selected Short-term Loan Products</label>
              <table className="table table-bordered">
                <tbody>
                  {short_term_purpose.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td
                          width="200"
                          style={{
                            paddingTop: 40,
                            textTransform: "capitalize",
                            fontWeight: 600,
                          }}
                        >
                          {item["type_" + i]}
                        </td>
                        <td>
                          <div className="form-group">
                            <label>Calculation Method</label>
                            <select
                              style={{ height: 40 }}
                              className="form-control"
                              name={`method_${i}`}
                              value={item["method_" + i]}
                              data-live-search="true"
                              onChange={(e) =>
                                onChangeMethod(e, "short_term_purpose", i)
                              }
                            >
                              <option selected disabled>
                                Choose Method
                              </option>
                              {loan_methods.map((method) => {
                                return <option>{method}</option>;
                              })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label>
                              Annual Interest Rate (%){" "}
                              <span className="required">(*)</span>
                            </label>
                            <input
                              type="number"
                              style={{ height: 40 }}
                              className="form-control"
                              name={`interest_rate_${i}`}
                              value={item["interest_rate_" + i]}
                              onChange={(e) =>
                                onChangeInterestRate(e, "short_term_purpose", i)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label>Minimum Contribution</label>
                            <input
                              type="number"
                              style={{ height: 40 }}
                              className="form-control"
                              name={`contribution_rate_${i}`}
                              value={item["contribution_rate_" + i]}
                              onChange={(e) =>
                                onChangeContribution(e, "short_term_purpose", i)
                              }
                            />
                          </div>

                          <div className="form-group">
                            <div className="form-group">
                              <label>
                                Amount calculation Rule{" "}
                                <span className="required">(*)</span>
                              </label>
                              <select
                                type="number"
                                style={{ height: 40 }}
                                className="form-control"
                                name={`ruleamount_${i}`}
                                value={item["ruleamount_" + i]}
                                onChange={(e) =>
                                  onChangeAmountRule(e, "short_term_purpose", i)
                                }
                              >
                                <option selected disabled>
                                  Choose Rule
                                </option>
                                {rules.map((r) => {
                                  return <option>{r}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Loan Period (Months)</label>
                            <input
                              type="number"
                              style={{ height: 40 }}
                              className="form-control"
                              name={`loan_period_${i}`}
                              value={item["loan_period_" + i]}
                              onChange={(e) =>
                                onChangeLoanPeriod(e, "short_term_purpose", i)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label>Maximum Amount (optional)</label>
                            <input
                              type="number"
                              placeholder="0"
                              style={{ height: 40 }}
                              className="form-control"
                              name={`maximum_amount_${i}`}
                              value={item["maximum_amount_" + i]}
                              onChange={(e) =>
                                onChangeMaximumAmount(
                                  e,
                                  "short_term_purpose",
                                  i
                                )
                              }
                            />
                          </div>
                        </td>
                        <td align="right" style={{ paddingTop: 40 }}>
                          <a
                            href="#"
                            style={{ color: "red" }}
                            onClick={() =>
                              removePurpose(i, "short_term_purpose")
                            }
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {loan_type.includes("Long-term Loans") && (
          <div className="col-md-12">
            <div className="form-group">
              <label>Long-term Loan Loan Products</label>
              <div style={{ position: "relative" }}>
                <input
                  className="form-control"
                  name="long_term_p"
                  value={long_term_p}
                  onChange={handleformonchange}
                  placeholder="Add long-term purpose"
                  style={{ textTransform: "capitalize" }}
                />
                <button
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  className="btn btn-primary "
                  onClick={addLongTermPurpose}
                >
                  Add
                </button>
              </div>
              <span className="text-danger">{response.long_term_p}</span>
            </div>
          </div>
        )}
        {long_term_purpose.length > 0 && (
          <div className="col-md-12">
            <div className="form-group well">
              <label>Selected Long-term Purposes</label>
              <table className="table table-bordered">
                <tbody>
                  {long_term_purpose.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td
                          width="200"
                          style={{
                            paddingTop: 40,
                            textTransform: "capitalize",
                            fontWeight: 600,
                          }}
                        >
                          {item["type_" + i]}
                        </td>
                        <td>
                          <div className="form-group">
                            <label>Calculation Method</label>
                            <select
                              style={{ height: 40 }}
                              className="form-control"
                              name={`method_${i}`}
                              value={item["method_" + i]}
                              data-live-search="true"
                              onChange={(e) =>
                                onChangeMethod(e, "long_term_purpose", i)
                              }
                            >
                              <option selected disabled>
                                Choose Method
                              </option>
                              {loan_methods.map((method) => {
                                return <option>{method}</option>;
                              })}
                            </select>
                          </div>

                          <div className="form-group">
                            <label>
                              Annual Interest Rate (%){" "}
                              <span className="required">(*)</span>
                            </label>
                            <input
                              type="number"
                              style={{ height: 40 }}
                              className="form-control"
                              name={`interest_rate_${i}`}
                              value={item["interest_rate_" + i]}
                              onChange={(e) =>
                                onChangeInterestRate(e, "long_term_purpose", i)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label>Minimum Contribution</label>
                            <input
                              type="number"
                              style={{ height: 40 }}
                              className="form-control"
                              name={`contribution_rate_${i}`}
                              value={item["contribution_rate_" + i]}
                              onChange={(e) =>
                                onChangeContribution(e, "long_term_purpose", i)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <div className="form-group">
                              <label>
                                Amount calculation Rule{" "}
                                <span className="required">(*)</span>
                              </label>
                              <select
                                type="number"
                                style={{ height: 40 }}
                                className="form-control"
                                name={`ruleamount_${i}`}
                                value={item["ruleamount_" + i]}
                                onChange={(e) =>
                                  onChangeAmountRule(e, "long_term_purpose", i)
                                }
                              >
                                <option selected disabled>
                                  Choose Rule
                                </option>
                                {rules.map((r) => {
                                  return <option>{r}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Loan Period (Months)</label>
                            <input
                              type="number"
                              style={{ height: 40 }}
                              className="form-control"
                              name={`loan_period_${i}`}
                              value={item["loan_period_" + i]}
                              onChange={(e) =>
                                onChangeLoanPeriod(e, "long_term_purpose", i)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label>Maximum Amount (optional)</label>
                            <input
                              type="number"
                              placeholder="0"
                              style={{ height: 40 }}
                              className="form-control"
                              name={`maximum_amount_${i}`}
                              value={item["maximum_amount_" + i]}
                              onChange={(e) =>
                                onChangeMaximumAmount(e, "long_term_purpose", i)
                              }
                            />
                          </div>
                        </td>
                        <td align="right" style={{ paddingTop: 40 }}>
                          <a
                            href="#"
                            style={{ color: "red" }}
                            onClick={() =>
                              removePurpose(i, "long_term_purpose")
                            }
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {/* fees */}
      </div>
      <div style={{ padding: "0 2rem" }}>
        <div className="panel">
          <div className="panel-heading card-title">
            <span>Loan application select loan fees applied</span>
          </div>
          <div className="panel-body">
            {appid?.value &&
              Object.keys(fees).map((el, i) => {
                return (
                  <div
                    className="form-group"
                    key={i}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div style={{ marginRight: "1rem" }}>
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => onChangeFees(el)}
                          checked={fees[el]}
                        />{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {el}
                        </span>
                      </label>
                    </div>
                    <div
                      onClick={() => handleRemoveFee(el)}
                      style={{ cursor: "pointer" }}
                    >
                      <a href="#" className="text-danger">
                        (Delete)
                      </a>
                    </div>
                  </div>
                );
              })}
            <hr />
            <div>
              <span>Customize your loan fee</span>
              <br />
              <br />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Loan Fee Name</label>
                    <input
                      className="form-control"
                      onChange={onChangeCustomFee}
                      value={customFee}
                    />
                    {errorFee.customFee && (
                      <span className="text-danger">{errorFee.customFee}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6" style={{ marginTop: "2.8rem" }}>
                  <button
                    className="btn btn-primary"
                    onClick={handleCustomizeFee}
                  >
                    Add Fee
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ padding: "0 2rem" }}>
        <div className="panel">
          <div className="panel-heading card-title">
            <span>Payment Settings</span>
          </div>
          <div className="panel-body">
            <div>
              <span>Source of Payments</span>
              <br />
              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        onChange={(e) => onChangePaymentOption("pos", e)}
                        checked={other["pos"]}
                        type="checkbox"
                      />
                      <span style={{ marginLeft: "1rem" }}>POS Device</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        onChange={(e) =>
                          onChangePaymentOption("dashboardPaymentsEnabled", e)
                        }
                        checked={other["dashboardPaymentsEnabled"]}
                        type="checkbox"
                      />
                      <span style={{ marginLeft: "1rem" }}>
                        Loans & Saving Dashboard
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {appid?.value && (
        <center className="col-md-12 panel-footer">
          {isSubmitting ? (
            <button className="btn btn-info " disabled>
              PLEASE WAIT...
            </button>
          ) : exist ? (
            <button
              className="btn btn-info "
              style={{ width: 300 }}
              onClick={onSubmit}
            >
              UPDATE
            </button>
          ) : (
            <button
              className="btn btn-info "
              style={{ width: 300 }}
              onClick={onSubmit}
            >
              SAVE
            </button>
          )}
        </center>
      )}
    </Card>
  </div>
);

export default LoanType;
