import React from "react";

const Loading = ({ message, icon }) => (
  <div className="loading col-md-12">
    <center>
      <i className={icon || "fa fa-spinner fa-spin"}></i>
      <p>{message}</p>
    </center>
  </div>
);

export default Loading;
