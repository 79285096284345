import React from "react";
import { numberWithCommas } from "../../../configs/formatCurrency";
import CardCount from "../../../components/CardCount/CardCount";
import Axios from "axios";
import toastMessage from "../../../components/toastMessage";
import { saveAs } from "file-saver";
import HOST_URL from "../../../configs/api";
class Savings extends React.Component {
  state = {};
  handleShowTransaction(i) {
    const key = "showTrans_" + i;

    this.setState({
      [key]: !this.state[key],
    });
  }

  handleGenerateReport(type = "pdf") {
    this.setState({ isSubmitting: true });
    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/${this.props.memberId}/savings/${type}`,
      responseType: "blob",
      params: {
        fromDate: this.props.fromDate,
        toDate: this.props.toDate,
      },
      headers: {
        authorization: `Bearer ${this.props.ztoken}`,
        "Content-Type": `application/${type}`,
      },
    };

    Axios(options)
      .then((data) => {
        this.downloadFile(data.data, type);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("error", "Unable to generate saving statement report");
      });
  }

  downloadFile(file, type) {
    try {
      toastMessage("success", "Downloaded successful");
      this.setState({ isSubmitting: false });
      const pdfBlob = new Blob([file], { type: `application/${type}` });
      saveAs(
        pdfBlob,
        this.props.memberName +
          "" +
          this.props.fromDate +
          "" +
          this.props.toDate +
          `.${type}`
      );
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("Error downloading file:", JSON.stringify(error));
    }
  }
  render() {
    return (
      <>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => this.handleGenerateReport('pdf')}
            disabled={this.state.isSubmitting}
          >
            {this.state.isSubmitting
              ? "Downloading, Please wait..."
              : "Download PDF Report"}
          </button>
          <button
            className="btn csv-btn"
            onClick={() => this.handleGenerateReport('csv')}
            disabled={this.state.isSubmitting}
          >
            {this.state.isSubmitting
              ? "Downloading, Please wait..."
              : "Download CSV Report"}
          </button>
        </div>
        <br />
        <div className="row">
          {/* <CardCount
            count={this.props.summary.numberOfLoans}
            title="Number of Loans"
            className="col-md-4"
          /> */}
          <CardCount
            count={"KES " + numberWithCommas(this.props.summary.totalSavings)}
            title="Total Savings"
            className="col-md-4"
          />
        </div>

        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Transaction Id</th>
                <th>Amount</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {this.props.savings &&
                this.props.savings.map((el, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td>{el.date}</td>
                        <td>{el.tranId}</td>
                        <td>KES {numberWithCommas(el.amount)}</td>
                        <td>KES {numberWithCommas(el.balance)}</td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default Savings;
