import React from "react";
import { Transactions } from "../../components/Transactions";

class TransactionsScreen extends React.Component {
  render() {
    return (
      <div>
        <Transactions
          apps={this.props.apps}
          token={this.props.ztoken}
          role={this.props.role}
          user_id={this.props.user_id}
        />
      </div>
    );
  }
}

export default TransactionsScreen;
