import React from "react";
import axios from "axios";
import toastMessage from "../toastMessage";
import HOST_URL from "../../configs/api";
import AppClass from "../../AppClass";
import { Select } from "../Select";

class NewShare extends React.Component {
  state = {
    amount: "",
    isSubmitting: false,
    error: {},
    accounts: [],
  };

  componentDidMount = async () => {
    const { apps } = this.props;

    if (this.props._id) {
      await this.setState({
        amount: this.props.amount,
        sacco: this.props.apps.find((el) => el.value === this.props.app_id),
      });
    } else if (apps.length === 1) {
      await this.setState({
        sacco: apps[0],
      });
    }

    this.getRegisteredMembers(true, this.state?.sacco?.value);
  };

  onChangeInput(field, e) {
    this.setState({ [field]: e.target ? e.target.value : e });

    if (field === "sacco") {
      this.getRegisteredMembers(true, [e.value]);
    }
  }

  getRegisteredMembers = async (isLoadingAccount, app_ids) => {
    if (!app_ids) return;

    this.setState({ isLoadingAccount });

    const options = {
      method: "POST",
      url: HOST_URL.url + "/get_registered_account",
      data: {
        from_dil: false,
        app_id: app_ids,
      },
      headers: {
        authorization: `Bearer ${this.props.token}`,
      },
    };

    axios(options)
      .then((data) => {
        let result = data?.data?.data || [];
        this.setState({
          isLoadingAccount: false,
          accounts: result.map((el) => {
            return {
              label: el.user_name,
              value: el.user_id,
            };
          }),
        });
      })
      .catch((error) => {
        this.setState({ isLoadingAccount: false });

        toastMessage("error", error);
      });
  };

  validateForm() {
    const { amount, error, sacco, account } = this.state;

    if (amount === "") {
      error.amount = "Amount is required";
    }

    if (!sacco) {
      error.sacco = "Sacco is required";
    }

    if (!account) {
      error.account = "Account is required";
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { amount, error, sacco, account } = this.state;
    const { _id } = this.props;

    const user = await AppClass.get_user_sessions();

    if (Object.keys(error).length === 0) {
      this.setState({ isSubmitting: true });

      let requestBody = {
        amount,
        app_id: sacco.value,
        user_id: account.value,
      };

      let method = "POST";

      if (_id) {
        method = "PUT";
      }

      const options = {
        method,
        url: HOST_URL.url + "/shares",
        data: requestBody,
        headers: {
          Authorization: "Bearer " + user.ztoken,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            amount: "",
          });

          let message = "Share added successfully";

          if (_id) {
            message = "Share updated successfully";
          }

          toastMessage("success", message);

          this.props.getData();
          this.props.handleCloseModal();
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });

          let message = "Error adding shares " + error;

          if (_id) {
            message = "Unable to update shares " + error;
          }

          toastMessage("error", message);
        });
    }
  };
  render() {
    return (
      <div>
        <div className="form-group">
          <Select
            label="Sacco"
            value={this.state.sacco}
            options={this.props.apps}
            name="fleet"
            onChange={(e) => this.onChangeInput("sacco", e)}
          />
          {this.state.error.sacco && (
            <span className="text-danger">{this.state.error.sacco}</span>
          )}
        </div>
        <div className="form-group">
          <Select
            label="Account"
            options={this.state.accounts}
            value={this.state.account}
            onChange={(e) => this.onChangeInput("account", e)}
          />
          {this.state.error.account && (
            <span className="text-danger">{this.state.error.account}</span>
          )}
        </div>
        <div className="form-group">
          <label>Amount</label>
          <input
            className="form-control"
            type="number"
            placeholder="0"
            value={this.state.amount}
            onChange={(e) => this.onChangeInput("amount", e)}
            error={this.state.error.amount}
          />
        </div>
        <button
          onClick={this.onSubmit.bind(this)}
          disabled={this.state.isSubmitting}
          className="btn btn-primary"
        >
          {this.state.isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    );
  }
}

export default NewShare;
