import Axios from "axios";
import React from "react";
import HOST_URL from "../../configs/api";
import { Card } from "../Card";
import { Loading } from "../Loader";
import { Select } from "../Select";
import toastMessage from "../toastMessage";
import AppClass from "../../AppClass";
import { Body, Header, Footer, Modal } from "../Modal";
import { data } from "jquery";
import Pagination from "../Pagination/Pagination";

let copyData = [];

class Clients extends React.Component {
  state = {
    data: [],
    error: {},
    epesi_members: [],
    selected_member: {},
    limit: 10,
    page: 1,
    totalItemsCount: 0,
  };

  componentDidMount = async () => {
    if (this.props.apps.length > 0) {
      this.getRegisteredMembers(true, await this.getAppIds(this.props.apps));
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.apps !== this.props.apps) {
      this.getRegisteredMembers(true, await this.getAppIds(this.props.apps));
    }
  };

  getRegisteredMembers = async (isLoading, app_ids) => {
    this.setState({ isLoading });

    const options = {
      method: "POST",
      url: HOST_URL.url + "/get_registered_account",
      data: {
        from_dil: false,
        app_id: app_ids,
      },
      headers: {
        authorization: `Bearer ${this.props.token}`,
      },
    };

    Axios(options)
      .then((data) => {
        let result = data?.data?.data || [];
        this.setState({ isLoading: false, data: result });

        copyData = result;
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  };

  filterBySacco(e) {
    this.getRegisteredMembers(true, [e.value]);
  }

  getAppIds = async (apps) => {
    const app_ids = await AppClass.get_app_ids(apps);
    return app_ids;
  };

  onSearch(e) {
    let search_txt = e.target.value,
      array = [];
    this.setState({ search_txt });

    for (let item of copyData) {
      if (
        JSON.stringify(item).toLowerCase().indexOf(search_txt.toLowerCase()) !==
        -1
      ) {
        array.push(item);
      }
    }

    this.setState({ data: array });
  }

  onChangeText(field, e) {
    this.setState({
      [field]: e.target ? e?.target?.value : e,
    });

    if (field === "form_sacco") {
      this.getSaccoClients(e.value);
    }
  }

  onCheck(e) {
    this.setState({
      isAllMembers: e.target.checked,
      epesi_member: e.target.checked ? this.state.epesi_members : undefined,
    });
  }

  getSaccoClients(app_id) {
    this.setState({ isLoadingClient: true });

    const config = {
      method: "GET",
      url: HOST_URL.mobitill_url + "/clients?0[]=appid&1[]=" + app_id,
      headers: {
        Authorization: "Bearer " + this.props.token,
      },
    };
    Axios(config)
      .then((data) => {
        let epesi_members = [];
        for (let client of data.data.data) {
          if (!this.registeredMembers().includes(client.mobilenumber)) {
            epesi_members.push({
              label: client.name || client.mobilenumber,
              value: client._id,
              phone: client.mobilenumber,
            });
          }
        }
        this.setState({ isLoadingClient: false, epesi_members });
      })
      .catch((error) => {
        this.setState({ isLoadingClient: false });

        toastMessage("error", error);
      });
  }

  registeredMembers() {
    let ids = [];

    for (let member of this.state.data) {
      if (!ids.includes(member.phone_number)) {
        ids.push(member.phone_number);
      }
    }

    return ids;
  }

  handleOpenModal(modal) {
    this.setState({ [modal]: true });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  validateForm() {
    let { error, epesi_member, form_sacco } = this.state;

    if (!epesi_member || epesi_member?.length === 0) {
      error.epesi_member = "Please select sacco member";
    }

    if (!form_sacco) {
      error.form_sacco = "Please select sacco";
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    let { error, epesi_member, form_sacco, selected_member } = this.state;

    if (Object.keys(error).length === 0) {
      for (let [i, member] of epesi_member.entries()) {
        this.setState({ isSubmitting: true });

        let url = HOST_URL.url + "/register";

        let body = {
          model: "register",
          app_id: form_sacco.value,
          user_name: member.label,
          user_id: member.value,
          phone_number: member.phone,
        };

        if (selected_member && selected_member.user_id) {
          body.user_id = selected_member.user_id;

          url = HOST_URL.url + "/update_registered_account";
        }

        const options = {
          method: "POST",
          url: url,
          data: body,
          headers: {
            authorization: "Bearer " + this.props.token,
          },
        };

        Axios(options)
          .then(async (data) => {
            if (epesi_member.length - 1 === i) {
              this.setState({
                form_sacco: undefined,
                epesi_member: undefined,
                isSubmitting: false,
              });

              toastMessage(
                "success",
                this.state.selected_member.user_id
                  ? "Member has been updated successful "
                  : "New member(s) added successfully"
              );

              this.getRegisteredMembers(
                true,
                await this.getAppIds(this.props.apps)
              );

              this.handleCloseModal("form_modal");
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              isSubmitting: false,
            });

            toastMessage("error", "Error on adding new sacco members");
          });
      }
    }
  };

  onDelete = async (user_id) => {
    if (window.confirm("Are you sure want to delete?")) {
      this.setState({ isLoading: false });

      let url = HOST_URL.url + "/delete_registered_account";

      let body = {
        model: "register",
        user_id,
      };

      Axios.post(url, body)
        .then(async (data) => {
          toastMessage("success", " member has been deleted successfully");

          this.getRegisteredMembers(
            true,
            await this.getAppIds(this.props.apps)
          );
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });

          toastMessage("error", "Error on delete member");
        });
    }
  };

  handleChangeLimit(e) {
    this.setState({
      limit: e.target.value,
      page: 1,
    });
  }

  handlePagination(page) {
    this.setState({
      page,
    });
  }

  render() {
    return (
      <div>
        <Card className="col-md-12">
          <div className="panel-body">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, marginRight: 20 }}>
                <Select
                  options={this.props.apps}
                  onChange={this.filterBySacco.bind(this)}
                />
              </div>
              <div style={{ flex: 1, marginRight: 25 }}>
                <div>
                  <input
                    placeholder="Search member name..."
                    className="form-control form-control-lg"
                    value={this.state.search_txt}
                    onChange={this.onSearch.bind(this)}
                  />
                </div>
              </div>
              <div>
                <button
                  className="btn btn-default"
                  onClick={() => this.handleOpenModal("form_modal")}
                >
                  <i className="fa fa-plus" style={{ marginRight: 10 }} />
                  New Member
                </button>
              </div>
            </div>
          </div>
        </Card>

        <Card className="col-md-12">
          {this.state.isLoading ? (
            <Loading icon="fa fa-spinner fa-spin" />
          ) : (
            <div className="panel-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Member No</th>
                    <th>Phone Number</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data
                    .slice(
                      (this.state.page - 1) * this.state.limit,
                      this.state.page * this.state.limit
                    )
                    .map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.user_name}</td>
                          <td>{item.member_no || ''}</td>
                          <td>{item.phone_number}</td>
                          <td width={100} align="right">
                            <button
                              style={{ marginLeft: 15 }}
                              className="btn btn-danger"
                              onClick={() => this.onDelete(item.user_id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <Pagination
                totalItemsCount={this.state.data.length}
                page={this.state.page}
                limit={this.state.limit}
                handleChangeLimit={this.handleChangeLimit.bind(this)}
                handlePagination={this.handlePagination.bind(this)}
              />
            </div>
          )}
        </Card>

        <Modal show={this.state.form_modal}>
          <Header>
            <h5 class="modal-title">Member</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCloseModal("form_modal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <div className="panel">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Sacco <span className="required">(*)</span>
                    </label>
                    <Select
                      options={this.props.apps}
                      value={this.state.form_sacco}
                      onChange={(e) => this.onChangeText("form_sacco", e)}
                    />
                    <span className="text-danger">
                      {this.state.error.form_sacco}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Members <span className="required">(*)</span>
                    </label>
                    <Select
                      options={this.state.epesi_members}
                      value={this.state.epesi_member}
                      onChange={(e) => this.onChangeText("epesi_member", e)}
                      isMulti
                      isLoading={this.state.isLoadingClient}
                    />
                    <span className="text-danger">
                      {this.state.error.epesi_member}
                    </span>

                    {this.state.epesi_members.length > 0 && (
                      <label>
                        <input
                          type="checkbox"
                          value={this.state.isAllMembers}
                          onChange={this.onCheck.bind(this)}
                        />{" "}
                        Import {this.state.epesi_members.length} member(s)
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Body>
          <Footer>
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => this.handleCloseModal("form_modal")}
              style={{ marginRight: 25 }}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => this.onSubmit()}
              disabled={this.state.isSubmitting}
            >
              {this.state.isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default Clients;
