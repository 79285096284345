import React from "react";
import { ViewApp } from "../../components/application";
import AppClass from "../../AppClass";
import Saccos from "../../data/sacco.json";

let error = {};

class ViewApplication extends React.Component {
  constructor() {
    super();

    this.state = {
      rate: 3,
      response: {},
      term: "",
      monthly_payment: 0,
      total_interest: 0,
      term_months: "",
      total_payments: 0,
      monthly_interest: 0,
      disabled: true,
      application_id: "",
      sacco: "",
      user_id: "",
    };
  }

  componentDidMount() {
    let data = AppClass.get_user_sessions();
    // this.setState({user_id: data[0]._id})
  }

  onChangeTerm(e) {
    let value = e.target.value;

    this.setState({ term: value });

    if (
      value.charAt(value.length - 1) !== "y" &&
      value.charAt(value.length - 1) !== "m"
    ) {
      error.term = "Specify term in Month(m) or Year(y) in lowercase";
      this.setState({ response: error, disabled: true });
    } else {
      error.term = "";
      this.setState({ response: error, disabled: true });
    }
  }

  caluculateLoanAmortization() {
    if (this.state.loan_amount !== "" && this.state.term !== "") {
      let loan_amount = parseFloat(this.state.loan_amount),
        rate = parseInt(this.state.rate) / 100,
        term = this.state.term,
        term_measure = term.charAt(term.length - 1),
        term_n = "";

      if (term_measure === "y") {
        term_n = parseInt(this.state.term.replace("y", "")) * 12;
      } else {
        term_n = this.state.term.replace("m", "");
      }

      let monthly_payment = this.calculateMonthlyPayment(
          loan_amount,
          rate,
          parseInt(term_n)
        ),
        monthly_interest = this.calculateInterest(
          loan_amount,
          rate,
          parseInt(term_n)
        ),
        total_interest = AppClass.addCommanForThousands(
          monthly_interest * parseInt(term_n)
        ),
        total_payments = AppClass.addCommanForThousands(
          monthly_payment * parseInt(term_n)
        );

      this.setState({
        monthly_interest: AppClass.addCommanForThousands(monthly_interest),
        monthly_payment: AppClass.addCommanForThousands(monthly_payment),
        term_months: term_n,
        total_interest,
        total_payments,
        disabled: false,
      });
    }
  }

  calculateInterest(loan_amount, rate, term_n) {
    //calcultate interest
    let interest = (loan_amount * rate) / parseInt(term_n);

    return interest;
  }

  calculateMonthlyPayment(loan_amount, rate, term_n) {
    let interest = this.calculateInterest(loan_amount, rate, term_n);
    //calc monthly payment
    let monthly_payment = (loan_amount / parseInt(term_n) + interest).toFixed(
      2
    );

    return monthly_payment;
  }

  onChangeSacco(e) {
    this.setState({
      sacco: e.target.value,
    });
  }

  generateRandomID(sacco_id) {
    for (var i = 0; i < Saccos.length; i++) {
      if (Saccos[i].app_id === sacco_id) {
        let sacco_name = Saccos[i].name.substring(0, 3).toUpperCase();

        return (
          sacco_name +
          "-" +
          Math.random().toString(36).substring(2, 5) +
          Math.random().toString(36).substring(2, 5)
        );
      }
    }
  }

  handleApplyData() {
    this.setState({ isSubmitting: true });

    const input_data = {
      app_id: this.state.sacco,
      model: "loan",
      loan_amount: this.state.loan_amount,
      rate: this.state.rate,
      term: this.state.term_months,
      total_interest: this.state.total_interest,
      total_payments: this.state.total_payments,
      user_id: this.state.user_id,
      available: true,
      date: new Date().getTime(),
    };

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors).length) {
      let response = {};

      AppClass.add_data(input_data)
        .then(() => {
          this.setState({
            isSubmitting: false,
          });
          response.success = "Application has been sent, success";
          this.setState({
            response,
          });
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          response.error = "Error, " + err;
          this.setState({
            response,
          });
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  editForm() {
    this.setState({ disabled: false });
  }

  render() {
    const { response, term } = this.state;
    return (
      <div className="content-cont">
        <ViewApp
          onChangeTerm={this.onChangeTerm.bind(this)}
          term={term}
          response={response}
          rate={this.state.rate}
          handleformonchange={(e) => AppClass.handleformonchange(this, e)}
          caluculateLoanAmortization={this.caluculateLoanAmortization.bind(
            this
          )}
          monthly_interest={this.state.monthly_interest}
          monthly_payment={this.state.monthly_payment}
          term_months={this.state.term_months}
          total_payments={this.state.total_payments}
          total_interest={this.state.total_interest}
          disabled={this.state.disabled}
          onChangeSacco={this.onChangeSacco.bind(this)}
          application_id={this.state.application_id}
          sacco={this.state.sacco}
          handleApplyData={this.handleApplyData.bind(this)}
          isSubmitting={this.state.isSubmitting}
          editForm={this.editForm.bind(this)}
          editable={this.state.editable}
        />
      </div>
    );
  }
}

export default ViewApplication;
