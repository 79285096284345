import React from "react";
import { FilterSaving } from "../../components/Filter";
import { Loading } from "../../components/Loader";
import AppClass from "../../AppClass";
import moment from "moment";
import { List, MemberSavings } from "../../components/Saving";
import * as XLSX from "xlsx";
import toastMessage from "../../components/toastMessage";
import HOST_URL from "../../configs/api";
import Axios from "axios";
import { Modal, Header, Body, Footer } from "../../components/Modal";
import Select from "react-select";
import { Link } from "react-router-dom";
import membersTemplate from "../../assets/MembersTemplate.xlsx";
import { Pagination } from "../../components/Pagination";
import CardCount from "../../components/CardCount/CardCount";
import { numberWithCommas } from "../../configs/formatCurrency";
import TransactionReversal from "../savings/admin/TransactionReversal";
import Withdraw from "./admin/Withdraw";

let copyData = [];

class Savings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: [],
      savings: [],
      isLoading: false,
      message: "Please wait...",
      start_date: moment().subtract("30", "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      members: [],
      admin: props.admin,
      diladmin: props.diladmin,
      interestrate: [],
      selected_saving: [],
      isLoadingMemberSavings: false,
      member_savings: [],
      search_text: "",
      csv_results: [],
      csv_result_modal: false,
      selected_data: [],
      isFleet: true,
      products: [],
      totalItemsCount: 0,
      page: 1,
      limit: 10,
      summary: {},
    };
  }

  componentDidMount = async () => {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    await this.setState({ apps: f_apps });

    await this.get_savings_by_apps(true, f_apps);

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    }
  };

  get_savings_by_apps(isLoading, apps) {
    let app_ids = AppClass.get_app_ids(apps);

    this.get_savings(isLoading, app_ids);

    this.setState({ app_ids });
  }

  get_members(app_id) {
    const { epesidiladmin } = this.props;
    let response = {};
    let body = {
      app_id,
      from_dil: epesidiladmin + "",
    };
    AppClass.get_member(body)
      .then((data) => {
        let members = [];

        for (let el of data.data.data) {
          members.push({
            label: el.user_name,
            value: el.user_id,
          });
        }
        this.setState({
          members,
          isLoading: false,
        });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  onChangeSacco(e) {
    this.setState({ app_id: e, member: undefined }, () => {
      this.get_interest_rate(e.value);
      this.getSaccoClients(e.value);
      this.getProducts(e.value);
      this.get_savings(true);
    });
  }

  getProducts(app_id) {
    this.setState({
      isLoading: true,
    });

    const options = {
      method: "GET",
      url: `${HOST_URL.mobitill_url}/products?0[]=appid&1[]=${app_id}`,
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };

    Axios(options)
      .then((data) => {
        let products = [];

        for (let el of data.data.data) {
          if (el.loaning_savings) {
            products.push({
              label: el.name,
              value: el._id,
            });
          }
        }
        this.setState({ isLoading: false, products });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  }

  getSaccoClients = async (app_id) => {
    this.setState({
      isLoading: true,
    });

    try {
      const members = await AppClass.getRegisteredMembers(
        app_id,
        !this.props.admin ? this.props.user_id : undefined
      );
      this.setState({ isLoading: false, members });
    } catch (error) {
      console.log(error);
      toastMessage("error", error);
    }
  };

  onChangeMember(e) {
    this.setState({ savings: [], member: e }, () => {
      this.get_savings(true);
    });
  }

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value, page: 1 }, () => {
      this.get_savings(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value, page: 1 }, () => {
      this.get_savings(true);
    });
  }

  get_savings(isLoading) {
    this.setState({ isLoading });

    let { app_id, member, start_date, end_date, apps, page, limit } =
      this.state;
    const { user_id } = this.props;

    let _appid = "";

    if (!app_id || !app_id?.value) {
      _appid = AppClass.get_app_ids(apps);
    } else {
      _appid = [app_id.value];
    }

    let response = {};
    const body = {
      app_id: _appid,
      status: "active",
      type: "Saving",
      pageNumber: page,
      pageLimit: limit,
    };
    if (!this.state.admin) {
      body["user_id"] = user_id;
    }

    if (member?.value) {
      body["user_id"] = member.value;
    }

    if (start_date !== undefined || end_date !== undefined) {
      body["fromDate"] = start_date;
      body["toDate"] = end_date;
    }

    if (this.state.product && this.state.product.value) {
      body.product_id = this.state.product.value;
    }

    const headers = {
      Authorization: `Bearer ${this.props.ztoken}`,
    };

    AppClass.get_save(body, headers)
      .then(async (res) => {
        const { summary, pagination, data } = res.data;

        this.setState({
          isLoading: false,
          savings: data,
          summary,
          totalItemsCount: pagination.totalRecords,
          needRefresh: false,
        });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        console.log("error", err);
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  onDelete(id) {
    if (window.confirm("Are you sure you want to delete")) {
      const input_data = {
        id: id,
        model: "save",
        status: "Unactive",
        available: false,
        date: new Date().getTime(),
      };

      const errors = AppClass.validate_form(input_data);

      if (!Object.keys(errors).length) {
        let response = {};

        AppClass.update_savedata(input_data)
          .then((data) => {
            this.setState({
              isSubmitting: false,
            });
            response.success = "Deposit has been deleted, success";
            this.setState({
              response,
            });

            // this.get_savings_by_apps(
            //   this.state.apps,
            //   new Date(this.state.start_date).getTime(),
            //   new Date(this.state.end_date).getTime()
            // );
            window.location.reload();
          })
          .catch((err) => {
            this.setState({ isSubmitting: false });
            response.error = "Error, " + err;
            this.setState({
              response,
            });
          });
      } else {
        this.setState({ response: errors, isSubmitting: false });
      }
    }
  }

  onWithdraw(item) {
    // if (this.props.admin || this.props.role === "admin") {
    //   window.location.href = "/home/admin_savings/withdraw/" + id;
    // } else {
    //   window.location.href = "/home/member_savings/withdraw/" + id;
    // }

    this.handleShowModal("showWithdrawForm", item);
  }

  onPressFileImport(e) {
    this.refs.fileUploader.click();
  }

  onImportSavings(e) {
    const { files } = e.target;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */

      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const jsonData = this.convertToJson(data);

      let keys = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];

      console.log(keys);

      if (keys.length == 0) {
        return toastMessage("error", "Empty file");
      }

      if (
        !keys.includes("Name") &&
        !keys.includes("User ID") &&
        !keys.includes("Sacco") &&
        !keys.includes("Sacco ID") &&
        !keys.includes("Phone Number") &&
        !keys.includes("Setting ID") &&
        !keys.includes("Amount") &&
        !keys.includes("Date")
      ) {
        return toastMessage("error", "Invalid saving file format");
      }

      this.setState({ isLoading: true });

      let length_data = 0;

      for (let i = 0; i < jsonData.length; i++) {
        if (
          jsonData[i]["Sacco"] &&
          jsonData[i]["Sacco ID"] &&
          jsonData[i]["User ID"] &&
          jsonData[i]["Setting ID"] &&
          jsonData[i]["Name"] &&
          jsonData[i]["User ID"] &&
          jsonData[i]["Phone Number"] &&
          jsonData[i]["Amount"] &&
          jsonData[i]["Date"]
        ) {
          length_data += 1;
          this.saveData(jsonData[i], length_data - 1, i);
        }
      }
    };
    reader.readAsBinaryString(files[0]);
  }

  saveData = async (data, length, index) => {
    const user = {
      user_name: data["Name"],
      phone_number: data["Phone Number"],
      user_id: data["User ID"],
    };

    this.saveMemberSavings(user, data, length, index);
  };

  register_member = async (user, app_id) => {
    const body = {
      model: "register",
      app_id,
      user_name: user.user_name,
      user_id: user.user_id,
      phone_number: user.phone_number,
    };

    return await AppClass.register(body);
  };

  get_interest_rate(app_id) {
    const body = {
      app_id,
      model: "interestrate",
    };
    AppClass.get_save_interestrate(body)
      .then((data) => {
        let isFleet = false;

        if (data.data.length > 0 && data.data[0].extension) {
          let ext = JSON.parse(data.data[0].extension);

          console.log({ ext });
          isFleet = ext = ext.fleet;
        }
        this.setState({
          interestrate: data.data,
          rate: data.data.length > 0 ? data.data[0].rate : this.state.rate,
          setting_id: data.data.length > 0 ? data.data[0]._id : "",
          isFleet,
        });

        console.log({ setting_id: data.data.length > 0 && data.data[0]._id });
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isLoading: false,
        });
      });
  }

  saveMemberSavings(user, saving_data, length, index) {
    const date = moment(saving_data["Date"]).format("YYYY-MM-DD");

    const body = {
      app_id: saving_data["Sacco ID"],
      organization: saving_data["Sacco"],
      product_id: saving_data["Sacco"],
      model: "save",
      amount: saving_data["Amount"] || 0,
      last_withdraw: saving_data["Last Withdraw"] || 0,
      interestrate: this.state.setting_id,
      saving_duration: saving_data["Saving Period"] || 0,
      total_interest: saving_data["Total Interest"] || 0,
      total_saving: saving_data["Total Saving"] || 0,
      last_deposit: saving_data["Amount"] || 0,
      total_withdrawn: saving_data["Total Withdrawn"] || 0,
      user_id: user.user_id,
      user_name: user.user_name,
      status: "active",
      available: true,
      client_record_date: new Date(date).getTime(),
      capital_share: saving_data["Capital Share"] || 0,
      fleet: saving_data["Fleet"] || "",
      tag: "by_user",
    };

    console.log({ savingdata: body });

    const options = {
      method: "post",
      url: HOST_URL.url + "/add_saving",
      data: body,
    };

    Axios(options)
      .then((data) => {
        if (index === length) {
          toastMessage("success", `Data has been uploaded successfully`);

          this.get_savings(false, [saving_data["Sacco ID"]]);
          this.setState({
            isSaving: false,
          });
        }
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isSaving: false,
        });
      });
  }

  onViewSaving(selected_saving) {
    this.setState({ selected_saving, showTransactionModal: true });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  handleSearch(e) {
    let array = [],
      search_text = e.target.value;

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({
      savings: array,
      search_text,
    });
  }

  onSelectSavings(e, _id) {
    let { selected_data, savings } = this.state;

    if (_id && !selected_data.includes(_id)) {
      selected_data.push(_id);
    } else if (!_id) {
      for (let item of savings) {
        if (!selected_data.includes(item._id)) {
          selected_data.push(item._id);
        } else {
          selected_data.splice(selected_data.indexOf(item._id), 1);
        }
      }
    } else {
      selected_data.splice(selected_data.indexOf(_id), 1);
    }

    console.log(selected_data);

    this.setState({
      selected_data,
    });
  }

  onPressDeleteAll() {
    const { selected_data } = this.state;

    if (
      window.confirm(
        `Are you sure, want to delete ${selected_data.length} data`
      )
    )
      this.setState({
        isDeleting: true,
      });

    for (let i = 0; i < selected_data.length; i++) {
      const id = selected_data[i];

      const options = {
        method: "POST",
        url: HOST_URL.url + "/delete_savedata",
        data: {
          id,
          model: "save",
          // status: "unactive",
          available: false,
        },
      };

      Axios(options)
        .then((data) => {
          if (i + 1 === selected_data.length) {
            this.setState({
              isDeleting: false,
              selected_data: [],
            });
            toastMessage(
              "success",
              `${selected_data.length} deleted successful`
            );

            this.get_savings_by_apps(true, this.state.apps);
          }
        })
        .catch((error) => {
          this.setState({
            isDeleting: false,
          });
          toastMessage(
            "error",
            `Delete savings (${selected_data.length}) was failed`
          );
        });
    }
  }

  onChangeProduct(product) {
    this.setState({ product }, () => {
      this.get_savings(true);
    });
  }

  convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    this.setState({
      count_new_saving: result.length - 1,
    });

    return result; //JSON
  }

  onPressFileImport() {
    this.refs.fileUploader.click();
  }

  onImportMembers(e) {
    try {
      const { files } = e.target;
      const reader = new FileReader();
      this.setState({ isLoadingMember: true });

      reader.onload = async (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */

        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        const jsonData = this.convertToJson(data);

        let keys = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];

        if (keys.length == 0) {
          return toastMessage("error", "Empty members");
        }

        if (
          !keys.includes("Name") &&
          !keys.includes("Phone Number") &&
          !keys.includes("User ID") &&
          !keys.includes("Sacco") &&
          !keys.includes("Sacco ID")
        ) {
          return toastMessage("error", "Invalid member file format");
        }

        if (this.state.setting_id === "") {
          return toastMessage("error", "Missing setting ID");
        }

        if (!this.state.product || this.state.product === "") {
          return toastMessage("error", "Missing Saving product");
        }

        let results = [];

        for (let i = 0; i < jsonData.length; i++) {
          const el = jsonData[i];

          if (el["Name"] && el["User ID"] && el["Phone Number"]) {
            console.log(this.state.members);
            let member_id = {};

            for (let member of this.state.members) {
              if (
                member.user_id === el["User ID"] ||
                el["User ID"] === member._id
              ) {
                if (!member_id[el["User ID"]]) {
                  member_id[el["User ID"]] = member._id;
                }
              }
            }

            if (!Object.keys(member_id).includes(el["User ID"])) {
              const new_member = await this.register_member({
                app_id: this.state.appid.value,
                user_name: el["Name"],
                user_id: el["User ID"],
                phone_number: el["Phone Number"],
              });

              member_id[el["User ID"]] = new_member._id;
            }

            if (member_id[el["User ID"]]) {
              results.push({
                ["Name"]: el["Name"],
                ["User ID"]: member_id[el["User ID"]],
                ["Phone Number"]: el["Phone Number"],
                ["Sacco"]: this.state.appid.label,
                ["Sacco ID"]: this.state.appid.value,
                ["Setting ID"]: this.state.setting_id,
                ["Product ID"]: this.state.product.value,
              });
            }

            //check if member exist otherwise save new member

            if (i === jsonData.length - 1) {
              this.setState({ isLoadingSetting: false });
            }
          }
        }
        this.generateSavingTemplate(results);
      };
      reader.readAsBinaryString(files[0]);
    } catch (error) {
      console.log(error);
    }
  }

  generateSavingTemplate(data) {
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet([{}], {
      header: [
        "Name",
        "User ID",
        "Phone Number",
        "Sacco",
        "Sacco ID",
        "Setting ID",
        "Product ID",
        "Saving Period",
        "Amount",
        "Interest",
        "Fleet",
        "Capital Share",
        "Total Saving",
        "Total Interest",
        "Last Withdraw",
        "Total Withdrawn",
        "Date",
      ],
    });
    XLSX.utils.sheet_add_json(sheet, data, { origin: "A3" });
    XLSX.utils.book_append_sheet(workbook, sheet);
    XLSX.writeFile(workbook, `epesiSavingTemplate${new Date().getTime()}.xlsx`);

    window.location.reload();
  }

  onDownloadTemplate() {
    this.setState({
      template_modal: true,
    });
  }

  handleChangeLimit(e) {
    this.setState(
      {
        limit: e.target.value,
        page: 1,
      },
      () => {
        this.get_savings(true);
      }
    );
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.get_savings(true);
      }
    );
  }

  handleResetFilters() {
    this.setState(
      {
        app_id: undefined,
        member: undefined,
        product: undefined,
      },
      () => {
        window.location.reload();
      }
    );
  }

  handleShowModal(modal, item) {
    this.setState({
      [modal]: true,
      selected_saving: item,
    });
  }

  handleSavingUpdatingAndNeedAutoRefresh() {
    this.setState({
      needRefresh: true,
    });
  }

  render() {
    const {
      response,
      savings,
      isLoading,
      message,
      start_date,
      end_date,
      apps,
      app_id,
      members,
      selected_user_id,
    } = this.state;
    const csvData = [
      [
        "Owner",
        "Amount (KES)",
        "Saving Period",
        "Interest (KES)",
        "Balance (KES)",
        "Date",
      ],
    ];

    for (var i = 0; i < savings.length; i++) {
      let isInterestActive =
        moment(savings[i].date)
          .add(savings[i].payment_duration, "months")
          .format("ll") === moment().format("ll");

      csvData.push([
        savings[i].user_name,
        savings[i].last_deposit,
        savings[i].saving_duration + " Month(s)",
        savings[i].total_interest ? savings[i].total_interest : 0,
        savings[i].total_saving,
        moment(savings[i].client_record_date).format("ll"),
      ]);
    }

    return (
      <div className="content-cont">
        <FilterSaving
          apps={apps}
          response={response}
          handleformonchange={(e) => AppClass.handleformonchange(this, e)}
          onChangeSacco={this.onChangeSacco.bind(this)}
          onChangeMember={this.onChangeMember.bind(this)}
          start_date={start_date}
          end_date={end_date}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
          app_id={app_id}
          user_id={this.props.user_id}
          members={members}
          selected_user_id={selected_user_id}
          products={this.state.products}
          product={this.state.product}
          onChangeProduct={this.onChangeProduct.bind(this)}
          showReset={this.state.app_id}
          handleResetFilters={this.handleResetFilters.bind(this)}
        />

        {isLoading ? (
          <Loading message={message} icon="fa fa-spinner fa-spin" />
        ) : (
          <div>
            <div className="col-md-12 ">
              <div className="row">
                <CardCount
                  count={this.state.summary.noOfSavings}
                  title="Count"
                  className="col-md-4"
                />
                <CardCount
                  count={
                    "KES " +
                    numberWithCommas(this.state.summary.totalSavings || 0)
                  }
                  title="Total"
                  className="col-md-4"
                />
                <CardCount
                  count={
                    "KES " +
                    numberWithCommas(
                      this.state.summary.totalCumulativeSavings || 0
                    )
                  }
                  title="Total Cumulative Savings"
                  className="col-md-4"
                />
              </div>
            </div>
            <List
              savings={savings.slice(
                (this.state.page - 1) * this.state.limit,
                this.state.page * this.state.limit
              )}
              csvData={csvData}
              onDelete={this.onDelete.bind(this)}
              response={response}
              saving_permission={this.props.saving_permission}
              onPressFileImport={this.onPressFileImport.bind(this)}
              onViewSaving={this.onViewSaving.bind(this)}
              search_text={this.state.search_text}
              handleSearch={this.handleSearch.bind(this)}
              onSelectSavings={this.onSelectSavings.bind(this)}
              selected_data={this.state.selected_data}
              onPressDeleteAll={this.onPressDeleteAll.bind(this)}
              isFleet={this.state.isFleet}
              onDownloadTemplate={this.onDownloadTemplate.bind(this)}
              admin={this.props.admin}
              handleShowReversalModal={(item) =>
                this.handleShowModal("showReversalForm", item)
              }
              fromDate={start_date}
              toDate={end_date}
              app_id={app_id?.value}
              ztoken={this.props.ztoken}
              user_id={this.state.member?.value}
            />
          </div>
        )}
        <Pagination
          totalItemsCount={this.state.totalItemsCount}
          page={this.state.page}
          limit={this.state.limit}
          handleChangeLimit={this.handleChangeLimit.bind(this)}
          handlePagination={this.handlePagination.bind(this)}
        />
        <input
          type="file"
          ref="fileUploader"
          style={{ display: "none" }}
          accept=".xlsx, .xls, .csv"
          onChange={this.onImportSavings.bind(this)}
        />

        <Modal
          show={this.state.showTransactionModal}
          handleCloseModal={this.handleCloseModal.bind(
            this,
            "showTransactionModal"
          )}
          size={"lg"}
          id="transactionModal"
        >
          <Header>
            <h5 class="modal-title">{`${this.state.selected_saving.user_name}'s savings`}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleCloseModal.bind(this, "showTransactionModal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          {this.state.isLoadingMemberSavings ? (
            <Loading icon="fa fa-spinner fa-spin" />
          ) : (
            this.state.showTransactionModal && (
              <Body>
                <MemberSavings
                  isFleet={this.state.isFleet}
                  {...this.state.selected_saving}
                  show={this.state.showTransactionModal}
                  id={this.state.selected_saving._id}
                  ztoken={this.props.ztoken}
                  onWithdraw={() => this.onWithdraw(this.state.selected_saving)}
                  role={this.props.admin && "admin"}
                  terminalId={this.state.selected_saving.terminalId}
                  savingInfo={this.state.selected_saving}
                  needRefresh={this.state.needRefresh}
                />
              </Body>
            )
          )}

          <Footer>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleCloseModal.bind(this, "showTransactionModal")}
            >
              Close
            </button>
          </Footer>
        </Modal>

        <Modal show={this.state.template_modal}>
          <Header>
            <h5 class="modal-title">Saving Template</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCloseModal("template_modal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <div className="panel">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Sacco <span className="required">(*)</span>
                    </label>
                    <Select
                      options={apps}
                      value={this.state.sacco}
                      onChange={this.onChangeSacco.bind(this)}
                    />
                    <span className="text-danger">
                      {this.state.response.sacco}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Product <span className="required">(*)</span>
                    </label>
                    <Select
                      options={this.state.products}
                      value={this.state.product}
                      onChange={this.onChangeProduct.bind(this)}
                    />
                    <span className="text-danger">
                      {this.state.response.loan_product}
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Upload Member(s) <span className="required">(*)</span>
                    </label>
                    <input
                      value={this.state.member_list}
                      className="form-control"
                      type="file"
                      onChange={this.onImportMembers.bind(this)}
                    />
                    <span className="text-danger">
                      {this.state.response.member}
                    </span>
                  </div>
                </div>
              </div>
              <div className="alert alert-info">
                Upload members and saving template will be generate
                automatically. Don't have member template?{" "}
                <Link
                  target={"_blank"}
                  to={membersTemplate}
                  download={`MembersTemplate.xlsx`}
                >
                  Download Member Template
                </Link>
              </div>
              {this.state.isLoadingSetting && (
                <center>
                  <i
                    className="fa fa-spinner fa-spin"
                    style={{ fontSize: 20 }}
                  />
                </center>
              )}
            </div>
          </Body>
        </Modal>

        <Modal show={this.state.showReversalForm} id="reversalModal">
          <Header>
            <h5 className="modal-title">Transaction Reversal</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCloseModal("showReversalForm")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <TransactionReversal
              {...this.props}
              savingInfo={this.state.selected_saving}
              getData={() => this.get_savings(true)}
              handleCloseModal={() => this.handleCloseModal("showReversalForm")}
            />
          </Body>
        </Modal>

        <Modal show={this.state.showWithdrawForm} id="showWithdrawForm">
          <Header>
            <h5 className="modal-title">
              Withdraw #{this.state.selected_saving.tickno}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCloseModal("showWithdrawForm")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <Withdraw
              {...this.props}
              savingInfo={this.state.selected_saving}
              getSavings={() => this.get_savings(true)}
              handleSavingUpdatingAndNeedAutoRefresh={this.handleSavingUpdatingAndNeedAutoRefresh.bind(
                this
              )}
            />
          </Body>
        </Modal>
      </div>
    );
  }
}

export default Savings;
