import React from "react";
import LoanMenus from "./LoanMenus";
import SavingMenus from "./SavingMenus";
import mobitil_logo from "../../assets/mobitill_logo.png";

const Sidebar = ({ onChangeEpesiApp, onTaggle, epesi_app, role, page }) => (
  <div className={onTaggle ? "sidebar active" : "sidebar hide"}>
    <div className="header-logo">
      <div className="logo">
        <img src={mobitil_logo} className="mobitill_logo" />
      </div>
    </div>
    <div className="application-option">
      <select
        className="form-control"
        name="epesi_app"
        onChange={onChangeEpesiApp}
        value={epesi_app}
      >
        <option>Epesi Loan</option>
        <option>Epesi Saving</option>
      </select>
    </div>
    {epesi_app === "Epesi Loan" ? (
      <LoanMenus role={role} page={page} />
    ) : (
      epesi_app === "Epesi Saving" && <SavingMenus role={role} page={page} />
    )}
  </div>
);
export default Sidebar;
