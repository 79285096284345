/* eslint-disable no-unused-vars */
import React from "react";
import { Container } from "../../components/Container";
import AppClass from "../../AppClass";
import axios from "axios";
import HOST_URL from "../../configs/api";
import { Redirect } from "react-router-dom";
import toastMessage from "../../components/toastMessage";
import { Select } from "../../components/Select";
import { Loading } from "../../components/Loader";

class Admin extends React.Component {
  constructor() {
    super();

    this.state = {
      isSubmitting: false,
      response: {},
      user_id: "",
      password: "",
      redirect: false,
      apps: [],
      isAppActive: false,
      isAutoLogin: false,
    };
  }

  componentDidMount = async () => {
    const { search } = this.props.location;

    if (search) {
      var str_f = search.substring(1);
      const decData = decodeURI(str_f);
      const finalData = JSON.parse(decData);
      let { userProfile, token, apps } = finalData;

      const user = {
        ...userProfile,
        ztoken: token,
        apps,
        admin: true,
      };

      this.setState({ isAutoLogin: true });

      const app_id = await AppClass.get_app_ids(apps || []);

      this.register_member(user, app_id);
    }
  };

  onChangeApps(e) {
    let { response } = this.state;
    delete response["app"];
    this.setState({
      app: e,
      response,
    });
  }

  handleLogin = async (e) => {
    e.preventDefault();

    this.setState({ isSubmitting: true, response: {} });

    const { user_id, password } = this.state;

    const data = {
      user: user_id,
      password,
    };

    const errors = AppClass.validate_form(data);

    if (!Object.keys(errors).length === 0) {
    }

    const newLoginStepOne = await this.handleNewLoginStepOne();

    if (newLoginStepOne?.apps?.length > 0) {
      this.setState({
        isAppActive: true,
      });

      return;
    }

    //handle oldLogin
    this.onOldLogin();
  };

  handleNewLoginStepOne = async () => {
    let { user_id, apps } = this.state;

    try {
      const stepOneOptions = {
        method: "POST",
        url: `${HOST_URL.new_api}/login-send-user`,
        data: {
          data: {
            user: user_id,
          },
        },
      };

      const userOrg = await axios(stepOneOptions);

      const { data: dataStepOne } = userOrg;

      if (!dataStepOne.success) {
        return;
      }

      if (dataStepOne?.data?.length > 0) {
        apps = dataStepOne.data[0].map((el) => {
          return {
            label: el.name,
            value: el._id,
            ...el,
          };
        });

        this.setState({ apps, isSubmitting: false });

        return { apps };
      }
    } catch (error) {
      return error;
    }
  };

  validateStepTwoLogin() {
    let { response, app } = this.state;

    if (!app) {
      response.app = "Please select sacco";
    }

    this.setState({ response });
  }

  handleNewLoginStepTwo = async (e) => {
    e.preventDefault();
    const { user_id, password, app, response } = this.state;

    this.setState({ response: {} });

    await this.validateStepTwoLogin();

    if (Object.keys(response).length === 0) {
      this.setState({ isSubmitting: true });

      const options = {
        method: "POST",
        url: `${HOST_URL.new_api}/login`,
        data: {
          data: {
            user: user_id,
            password: password,
            orgid: app.value,
          },
        },
      };

      axios(options)
        .then(async (res) => {
          let { data, token, modules } = res.data;
          data.ztoken = token;

          const apps = await this.getModulesOnlyHasLoanAndSavingsAccess(
            modules,
            token
          );
          data.apps = apps;

          const app_ids = await AppClass.get_app_ids(apps);
          this.register_member(data, app_ids);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", "Wrong credentials");
        });
    }
  };

  getModulesOnlyHasLoanAndSavingsAccess = async (modules = {}, token) => {
    let apps = [];
    for (let md of Object.keys(modules)) {
      if (modules[md].loansAndSaving) {
        const appInfo = await AppClass.getNewAppById(token, md);

        apps.push({
          label: appInfo.name,
          value: appInfo._id,
          ...appInfo,
        });
      }
    }

    return apps;
  };

  onOldLogin(e) {
    e && e.preventDefault();

    this.setState({ isSubmitting: true });

    const { user_id, password } = this.state;

    const input_data = {
      user: user_id,
      password,
    };

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors).length) {
      let response = {};

      axios
        .post(HOST_URL.mobitill_url + "/login", { data: input_data })
        .then(async (data) => {
          let user = data.data.data;

          user.ztoken = data.data.token;

          if (!user.name && !user.firstName) {
            user.firstName = user.phone;
            user.lastName = "-";
          }

          this.get_user_apps(user);
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          response.error = "Wrong email or password";
          this.setState({
            response,
            isSubmitting: false,
          });
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  get_user_apps = async (user) => {
    if (user && user.role && user.role.diladmin)
      return this.query_apps(user, "diladmin");
    // if (user && user.roleName && user.roleName === 'Super User')
    //   return this.query_apps(user, "diladmin");

    if (user && user.role && user.role.diladmin === false) {
      console.log("NotYetImpelemented");
      return;
    }

    this.query_apps(user);
  };

  query_apps = async (user, target) => {
    let apps = [];
    try {
      let params = {};

      if (target === "diladmin") {
        params = { token: user.ztoken };

        apps = await AppClass.get_apps(params.token, params.data);

        user.apps = AppClass.accessedApps(apps.data.data, "diladmin");
        user.diladmin = true;
      } else {
        params.user = {
          _id: user._id,
        };

        apps = await AppClass.get_user_apps(user.ztoken, params);

        user.apps = AppClass.accessedApps(apps.data.data, "admin");

        user.diladmin = false;
      }

      user.admin = true; //this will differentiate if you're a member or admin included diladmins

      this.onSuccess(user);
    } catch (error) {
      this.setState({ isSubmitting: false });
      this.state.response.error = "Failed to fetch apps, please try again";
      this.setState({
        response: this.state.response,
        redirect: false,
        isSubmitting: false,
      });
    }
  };

  onSuccess(user) {
    if (user.apps.length > 0) {
      this.register_member(user);
    } else {
      let response = {};
      response.error = "You need an admin account to login.";
      this.setState({
        isSubmitting: false,
        response,
      });

      console.log({ response });
    }
  }

  checkAdmin(role) {
    let keys = Object.keys(role);

    let admin_strings = keys.filter((e) => e.includes("admin"));

    let is_admin = false;

    for (let i = 0; i < admin_strings.length; i++) {
      let str = admin_strings[i];
      if (role[str]) {
        is_admin = true;
      }
    }
    return is_admin;
  }

  register_member = async (user) => {
    let response = {};

    const app_id = await AppClass.get_app_ids(user.apps);

    const body = {
      model: "register",
      app_id,
      user_name: user.firstName + " " + user.lastName,
      user_id: user._id,
      phone_number: user.phone,
    };

    AppClass.register(body)
      .then((data) => {
        this.setState({
          apps: data.data.data,
          isSubmitting: false,
          isAutoLogin: false,
        });

        if (data.data && data.data.data._id) {
          user.epesi_loan_saving_account_id = data.data.data._id;
        }
        sessionStorage.setItem("saccoloan", JSON.stringify(user));
        this.setState({
          isSubmitting: false,
          user,
        });

        window.location.href = "/home/admin/loans";
      })
      .catch((err) => {
        console.log(err);
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
          isAutoLogin: false,
          isSubmitting: false,
        });
      });
  };

  render() {
    const { response, isSubmitting, user_id, password } = this.state;

    if (this.state.redirect) {
      const sess = [];
      sess.push(JSON.parse(sessionStorage.getItem("saccoloan")));
      if (sessionStorage.getItem("saccoloan") !== null) {
        return <Redirect to={`/home/admin/loans`} />;
      }
    }

    return (
      <Container>
        {this.state.isAutoLogin ? (
          <Loading icon="fa fa-spinner fa-spin" message="Logging you in..." />
        ) : (
          <>
            {/* <center className="mobitil_logo_container">
          <img src={mobitilllogo} className="mobitil_logo" />
        </center> */}
            <div className="col-xs-10 col-sm-7 col-md-3 login">
              <div className="panel auth">
                <div className="panel-heading">
                  <div className="header-logo">
                    {/* <div className="logo">
                  <img src={mobitilllogo} className="dil_logo" />
                </div> */}
                    <span>Sign in as an admin</span>
                  </div>
                </div>
                <div className="panel-body">
                  {response.error ? (
                    <div className="alert alert-danger">
                      <span>{response.error}</span>
                    </div>
                  ) : (
                    response.success && (
                      <div className="alert alert-success">
                        <span>{response.success}</span>
                      </div>
                    )
                  )}
                  <form method="post">
                    <div className="form-group">
                      <label>
                        Email Address <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input-lg"
                        name="user_id"
                        placeholder="User ID"
                        value={user_id}
                        onChange={(e) => AppClass.handleformonchange(this, e)}
                      />
                      {/* <i className="fa fa-user" /> */}
                      <span className="text-danger">{response.user_id}</span>
                    </div>

                    <div className="form-group">
                      <label>
                        Password <span className="required">*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control input-lg"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => AppClass.handleformonchange(this, e)}
                      />
                      {/* <i className="fa fa-lock" /> */}
                      <span className="text-danger">{response.password}</span>
                    </div>
                    {this.state.isAppActive && (
                      <div className="form-group">
                        <Select
                          options={this.state.apps}
                          value={this.state.app}
                          label="Organization"
                          onChange={this.onChangeApps.bind(this)}
                        />
                        <span className="text-danger">{response.app}</span>
                      </div>
                    )}
                    <button
                      disabled={isSubmitting}
                      onClick={
                        this.state.isAppActive
                          ? this.handleNewLoginStepTwo.bind(this)
                          : this.handleLogin.bind(this)
                      }
                      className="btn btn-primary btn-lg btn-block"
                    >
                      {isSubmitting
                        ? " Please wait..."
                        : this.state.isAppActive
                        ? "Continue"
                        : "Log in"}
                    </button>

                    {/* <center className="otherlogin">
                  <a href="/register">Don't have an account? <b>Register</b></a>
                </center> */}
                    <center className="otherlogin">
                      <a href="/">Member Login</a>
                    </center>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    );
  }
}

export default Admin;
