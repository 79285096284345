// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-count {
  min-height: 2.5rem;
}

.card-count h1 {
  font-size: 1.5rem;
  opacity: 0.8;
}

.card-count span {
  font-size: 2rem;
  font-weight: 900;
}

.card-count .panel {
  border: 1px solid #ddd;
}

.card-count .number {
  font-size: 12px;
  margin-left: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/App/components/CardCount/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".card-count {\n  min-height: 2.5rem;\n}\n\n.card-count h1 {\n  font-size: 1.5rem;\n  opacity: 0.8;\n}\n\n.card-count span {\n  font-size: 2rem;\n  font-weight: 900;\n}\n\n.card-count .panel {\n  border: 1px solid #ddd;\n}\n\n.card-count .number {\n  font-size: 12px;\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
