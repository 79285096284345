import React from "react";
import { Card } from "../Card";
import moment from "moment";
import Select from "react-select";

const TransactionsFilter = ({
  apps,
  response,
  onChangeStartingDate,
  onChangeEndDate,
  appid,
  onChangeSacco,
  start_date,
  end_date,
  members,
  member,
  onChangeMember,
  showReset,
  handleResetFilters,
  isLoadingMembers,
  products,
  product,
  onChangeProduct,
  show_existing_tansactions,
  onChangeShowExistingTransactions,
}) => {
  return (
    <Card className="col-md-12 filter">
      <div className="panel-body">
        {apps && apps.length > 0 && (
          <div className="col-md-3">
            <div className="form-group">
              <label>Saccos</label>
              <Select
                value={appid}
                options={apps}
                name="fleet"
                onChange={onChangeSacco}
              />
              <span className="text-danger">{response?.app_id}</span>
            </div>
          </div>
        )}
        {members && (
          <div className="col-md-3">
            <div className="form-group">
              <label>Member</label>
              <Select
                value={member}
                options={members}
                name="member"
                onChange={onChangeMember}
                isLoading={isLoadingMembers}
              />
            </div>
          </div>
        )}
        {products && (
          <div className="col-md-3">
            <div className="form-group">
              <label>Product</label>
              <Select
                value={product}
                options={products}
                name="product"
                onChange={onChangeProduct}
              />
            </div>
          </div>
        )}
        <div className="col-md-3">
          <div className="form-group">
            <label>Start Date</label>
            <input
              className="form-control"
              type="date"
              value={moment(start_date).format("YYYY-MM-DD")}
              name="start_date"
              onChange={onChangeStartingDate}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>End Date</label>
            <input
              className="form-control"
              value={moment(end_date).format("YYYY-MM-DD")}
              type="date"
              name="end_date"
              onChange={onChangeEndDate}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <input
              style={{ marginRight: "5px" }}
              value={show_existing_tansactions}
              checked={show_existing_tansactions}
              type="checkbox"
              name="show_existing_transactions"
              onChange={onChangeShowExistingTransactions}
            />
            <label>Show Existing Transactions</label>
          </div>
        </div>
        {/* {showReset && (
          <div className="col-md-12">
            <a href="#" onClick={handleResetFilters}>
              Reset Filters
            </a>
          </div>
        )} */}
      </div>
    </Card>
  );
};

export default TransactionsFilter;
