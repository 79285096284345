import moment from "moment";

export const getLoanAmortization = (params) => {
  if (params.method === "Straight-line method") {
    return straightLineMethod(params);
  }
  return reducingBalance(params);
};

const reducingBalance = (params) => {
  let {
    loan_amount = 0,
    loan_period = 0,
    start_date_loan,
    sacco_term = "Monthly",
    interest = 0,
    client_loan_period_term = "Monthly",
    contribution = 0,
    paid_dates = [],
  } = params;

  loan_amount = parseFloat(loan_amount - contribution);

  let number_payment = loan_period; // * number_of_term;

  let rate = interest / 100 / number_payment;

  let term_payment =
    loan_amount * (rate / (1 - Math.pow(1 + rate, -number_payment)));

  let total_cost_loan = parseFloat(term_payment * number_payment);

  let results = [];
  let beginning_balance = loan_amount;
  let ending_balance = 0;
  let principal = 0;
  let payment_date = start_date_loan;
  let unpaid_dates = [],
    next_schedules = [],
    now = moment().format("YYYY-MM-DD");

  for (let i = 0; i < number_payment; ++i) {
    payment_date = paymentDate(payment_date, sacco_term);

    if (
      // (now >= payment_date || now <= payment_date) &&
      !paid_dates.includes(payment_date) &&
      unpaid_dates.length === 0
    ) {
      unpaid_dates.push(payment_date);
    }

    if (
      !unpaid_dates.includes(payment_date) &&
      !paid_dates.includes(payment_date)
    ) {
      next_schedules.push(payment_date);
    }

    let term_interest = parseFloat(beginning_balance * rate);

    principal = parseFloat(term_payment - term_interest);

    ending_balance = parseFloat(beginning_balance - principal);

    results.push({
      beginning_balance: Math.round(beginning_balance),
      ending_balance: Math.round(ending_balance),
      principal: Math.round(principal),
      term_interest: Math.round(term_interest),
      term_payment: Math.round(term_payment),
      payment_date,
      payment_period: i + 1,
      payment_duration: loan_period,
    });

    beginning_balance = ending_balance;
  }

  return {
    number_payment,
    term_payment: term_payment,
    total_cost_loan: total_cost_loan,
    results,
    unpaid_dates,
    next_schedules,
    payment_duration: loan_period,
  };
};

const straightLineMethod = (params) => {
  let {
    loan_amount = 0,
    loan_period = 0,
    start_date_loan,
    sacco_term = "Monthly",
    interest = 0,
    contribution = 0,
    paid_dates = [],
  } = params;

  let beginning_balance = Math.ceil(loan_amount - contribution);
  const number_payment = loan_period; //* number_of_term;
  let term_payment = Math.ceil(beginning_balance / loan_period);
  let rate = interest / 100 / loan_period;

  let results = [];
  let ending_balance = 0;
  let principal = 0;
  let payment_date = start_date_loan;
  let unpaid_dates = [];
  let next_schedules = [];

  for (let i = 0; i < number_payment; ++i) {
    payment_date = paymentDate(payment_date, sacco_term);

    if (!paid_dates.includes(payment_date) && unpaid_dates.length === 0) {
      unpaid_dates.push(payment_date);
    }

    if (
      !unpaid_dates.includes(payment_date) &&
      !paid_dates.includes(payment_date)
    ) {
      next_schedules.push(payment_date);
    }

    let term_interest = Math.ceil(term_payment * rate);
    principal = Math.ceil(term_payment - term_interest);
    ending_balance = beginning_balance - term_payment;

    // Check if ending balance is negative
    if (i === (number_payment - 1)) {
      term_payment = beginning_balance;
      term_interest = Math.ceil(term_payment * rate);
      principal = Math.ceil(term_payment - term_interest);
      ending_balance = 0;
    }

    results.push({
      beginning_balance,
      ending_balance,
      principal,
      term_interest,
      term_payment,
      payment_date,
      payment_period: i + 1,
      payment_duration: loan_period,
    });

    beginning_balance = ending_balance;
  }

  return {
    number_payment,
    term_payment: term_payment,
    total_cost_loan: loan_amount,
    results,
    unpaid_dates,
    next_schedules,
  };
};

export const getTotalInterest = ({
  method,
  loan_amount,
  interest,
  loan_period,
}) => {
  if (method === "Straight-line method") {
    return parseFloat(loan_amount * (interest / 100));
  }

  let rate = interest / 100 / loan_period;

  let term_payment =
    loan_amount * (rate / (1 - Math.pow(1 + rate, -loan_period)));

  let total_cost_loan = parseFloat(term_payment * loan_period);

  return parseFloat(total_cost_loan - loan_amount);
};

const paymentDate = (currentDate, term) => {
  let t = "M",
    tm = "month";

  if (term === "Daily") {
    t = "D";
    tm = "day";
  } else if (term === "Yearly") {
    t = "Y";
    tm = "year";
  }

  let futureMonth = moment(currentDate).add(1, t);
  let futureMonthEnd = moment(futureMonth).endOf(tm);

  if (futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))) {
    futureMonth = futureMonth.add(1, "d");
  }

  return moment(futureMonth).format("YYYY-MM-DD");
};

export const getUnpaidSchedule = ({ amortization_result, paid_dates = [] }) => {
  try {
    let unpaid_dates = [],
      next_schedules = [];

    for (let i = 0; i < amortization_result.length; ++i) {
      let payment_date = amortization_result[i].payment_date;

      if (!paid_dates.includes(payment_date) && unpaid_dates.length === 0) {
        unpaid_dates.push(payment_date);
      }

      if (
        !unpaid_dates.includes(payment_date) &&
        !paid_dates.includes(payment_date)
      ) {
        next_schedules.push(payment_date);
      }
    }

    return {
      unpaid_dates,
      next_schedules,
    };
  } catch (error) {
    console.log("====================================");
    console.log(error);
    console.log("====================================");
  }
};

export const sumFees = (fees) => {
  let total = 0,
  _fees = typeof fees === "string" ? JSON.parse(fees) : fees;

  for (let el of Object.keys(_fees)) {
    if (typeof _fees[el] === "string" || typeof _fees[el] === "number") {
      total += Number(_fees[el]);
    }
  }
  return Number(total);
};
