import React from "react";
import { ApplicationList } from "../../components/application";
import { Filter } from "../../components/Filter";
import { Loading } from "../../components/Loader";
import AppClass from "../../AppClass";
import moment from "moment";
import Pagination from "../../components/Pagination/Pagination";

class MyApplication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: [],
      loans: [],
      isLoading: false,
      message: "Please wait...",
      start_date: moment().subtract("30", "days"),
      end_date: moment(),
      app_ids: [],
      other_column: "",
      page: 1,
      limit: 10,
      totalItemsCount: 0,
    };
  }

  componentDidMount = async () => {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    await this.setState({ apps: f_apps });

    await this.get_loan_by_apps(true, f_apps);

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    }
  };

  get_loan_by_apps(isLoading, apps) {
    let app_ids = AppClass.get_app_ids(apps);

    this.get_loans(isLoading, app_ids);

    this.setState({ app_ids });
  }

  onChangeSacco(e) {
    this.setState({ appid: e, loan_id: undefined }, () => {
      this.get_loans(true);
    });
  }

  onChangeLoanID(e) {
    this.setState({ loan_id: e }, () => {
      this.get_loans(true);
    });
  }

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value, page: 1 }, () => {
      this.get_loans(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value, page: 1 }, () => {
      this.get_loans(true);
    });
  }

  get_loans(isLoading) {
    let { app_id, loan_id, start_date, end_date, apps } = this.state;

    this.setState({ isLoading });

    const _start_date = new Date(start_date).getTime();
    const _end_date = new Date(end_date).getTime();
    let _appid = "";

    if (!app_id || !app_id?.value) {
      _appid = AppClass.get_app_ids(apps);
    } else {
      _appid = [app_id.value];
    }

    let response = {};
    const body = {
      app_id: _appid,
      // admin: this.props.user_id,
      status: ["Pending", "Processing"],
    };

    if (start_date !== undefined || end_date !== undefined) {
      body["fromDate"] = _start_date;
      body["toDate"] = _end_date;
    }

    if (loan_id) {
      body["loan_id"] = loan_id;
    }

    AppClass.get_loandata(body)
      .then((res) => {
        const { data, pagination } = res.data;

        let loan_ids = [];

        for (let el of data) {
          loan_ids.push({
            label: el.loan_id,
            value: el.loan_id,
          });
        }
        this.setState({
          loan_ids,
          loans: data,
          isLoading: false,
          totalItemsCount: pagination.totalRecords,
        });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  handleOtherColumn(e) {
    this.setState({
      other_column: e.target.value,
    });
  }

  handleChangeLimit(e) {
    this.setState(
      {
        limit: e.target.value,
        page: 1,
      },
      () => {
        this.get_loans(true);
      }
    );
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.get_loans(true);
      }
    );
  }

  render() {
    const {
      apps,
      response,
      loans,
      isLoading,
      message,
      appid,
      loan_id,
      start_date,
      end_date,
      other_column,
    } = this.state;
    return (
      <div>
        <Filter
          appid={appid}
          loan_id={loan_id}
          onChangeLoanID={this.onChangeLoanID.bind(this)}
          apps={apps}
          loans={loans}
          response={response}
          handleformonchange={(e) => AppClass.handleformonchange(this, e)}
          onChangeSacco={this.onChangeSacco.bind(this)}
          start_date={start_date}
          end_date={end_date}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
          loan_ids={this.state.loan_ids}
        />
        {isLoading ? (
          <Loading message={message} icon="fa fa-spinner fa-spin" />
        ) : (
          <div>
            <ApplicationList
              role={this.props.role}
              loans={loans}
              handleOtherColumn={this.handleOtherColumn.bind(this)}
              other_column={other_column}
              user_id={this.props.user_id}
            />
            <Pagination
              totalItemsCount={this.state.totalItemsCount}
              page={this.state.page}
              limit={this.state.limit}
              handleChangeLimit={this.handleChangeLimit.bind(this)}
              handlePagination={this.handlePagination.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default MyApplication;
