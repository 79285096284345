import React from "react";
import { numberWithCommas } from "../../../configs/formatCurrency";
import CardCount from "../../../components/CardCount/CardCount";
import HOST_URL from "../../../configs/api";
import Axios from "axios";
import toastMessage from "../../../components/toastMessage";
import { saveAs } from "file-saver";
class Dividends extends React.Component {
  state = {};
  handleShowTransaction(i) {
    const key = "showTrans_" + i;

    this.setState({
      [key]: !this.state[key],
    });
  }

  handleGenerateReport(type="pdf") {
    this.setState({ isSubmitting: true });
    const options = {
      method: "GET",
      url: `${HOST_URL.url}/dividends/${this.props.memberId}/${type}`,
      responseType: "blob",
      params: {
        fromDate: this.props.fromDate,
        toDate: this.props.toDate,
        app_id: this.props.appid.value,
      },
      headers: {
        authorization: `Bearer ${this.props.ztoken}`,
        "Content-Type": `application/${type}`,
      },
    };

    Axios(options)
      .then((data) => {
        this.downloadFile(data.data, type);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("error", "Unable to generate dividend statement report");
      });
  }

  downloadFile(file, type) {
    try {
      toastMessage("success", "Downloaded successful");
      this.setState({ isSubmitting: false });
      const fileBlob = new Blob([file], { type: `application/${type}` });
      saveAs(
        fileBlob,
        this.props.memberName +
          "" +
          this.props.fromDate +
          "" +
          this.props.toDate +
          `.${type}`
      );
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("Error downloading file:", JSON.stringify(error));
    }
  }
  render() {
    return (
      <>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => this.handleGenerateReport('pdf')}
            disabled={this.state.isSubmitting}
          >
            {this.state.isSubmitting
              ? "Downloading, Please wait..."
              : "Download PDF Report"}
          </button>
          <button
            className="btn csv-btn"
            onClick={() => this.handleGenerateReport('csv')}
            disabled={this.state.isSubmitting}
          >
            {this.state.isSubmitting
              ? "Downloading, Please wait..."
              : "Download CSV Report"}
          </button>
        </div>
        <br />
        <div className="row">
          <CardCount
            count={"KES " + numberWithCommas(this.props.data?.total || 0)}
            title="Total Dividends"
            className="col-md-4"
          />
          <div className="col-md-12">
            <div className="alert alert-info">
              <p>
                <b>Shares</b>
              </p>
              <span style={{ marginRight: "1rem" }}>
                Total Shares:
                <b>
                  {" "}
                  {"KES " + numberWithCommas(this.props?.data?.shares?.total)}
                </b>
              </span>
              <span>
                Dividends:{" "}
                <b>
                  {" "}
                  {"KES " +
                    numberWithCommas(this.props?.data?.shares?.dividend)}
                </b>
              </span>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Loan Type</th>
                <th style={{ textAlign: "right" }}>Interest Rate</th>
                <th style={{ textAlign: "right" }}>Repaid Amount</th>
                <th style={{ textAlign: "right" }}>Dividends</th>
              </tr>
            </thead>
            <tbody>
              {this.props?.data?.loans?.map((el, i) => {
                return (
                  <tr key={i}>
                    <td>{el.purpose}</td>
                    <td style={{ textAlign: "right" }}>{el.rate}%</td>
                    <td style={{ textAlign: "right" }}>
                      KES {numberWithCommas(el.total)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      KES {numberWithCommas(el.dividend)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default Dividends;
