import Axios from "axios";
import React from "react";
import AppClass from "../../../AppClass";
import { Body, Footer, Header, Modal } from "../../../components/Modal";
import { Setting } from "../../../components/Saving";
import toastMessage from "../../../components/toastMessage";
import HOST_URL from "../../../configs/api";

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: [],
      exist: false,
      _id: "",
      isFleet: false,
      isInterestRate: false,
      isCapitalShare: false,
      saving_types: [],
      new_saving_type: "",
      error_saving_type: {},
      saving_type: "",
      error: {},
      identifier: "",
      products: [],
    };
  }

  componentDidMount() {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    this.setState({ apps: f_apps });
  }

  get_setting_by_appid(app_id) {
    this.setState({
      isGettingSettings: true,
    });
    let response = {};
    const body = {
      app_id,
      model: "interestrate",
    };
    AppClass.get_savedata(body)
      .then((data) => {
        this.setState({
          isGettingSettings: false,
        });

        if (data.data.length > 0) {
          const extension = data.data[0].extension;
          let { isFleet, isInterestRate, isCapitalShare, saving_type } =
            this.state;

          if (extension) {
            const ext = JSON.parse(extension);

            isFleet = ext.fleet;
            isCapitalShare = ext.capitalShare;
            isInterestRate = ext.interestRate;
          }

          if (data.data[0].saving_type && data.data[0].saving_type.length > 0) {
            saving_type = data.data[0].saving_type.split(",");
          } else {
            saving_type = [];
          }
          this.setState({
            exist: true,
            _id: data.data[0]._id,
            interest_rate: data.data[0].rate,
            isInterestRate,
            isCapitalShare,
            isFleet,
            saving_types: saving_type,
            isGettingSettings: false,
            rate: data.data[0].rate,
          });
        } else {
          this.setState({
            exist: false,
            _id: "",
            isGettingSettings: false,
          });
        }
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
        });
      });
  }

  onChangeSacco(e) {
    let appid = e;
    this.setState(
      {
        appid,
        response: {},
        exist: false,
        _id: "",
        isFleet: false,
        isInterestRate: false,
        isCapitalShare: false,
        saving_types: [],
        new_saving_type: "",
        error_saving_type: {},
        saving_type: "",
      },
      () => {
        this.get_setting_by_appid(appid.value);
        this.getProducts(true);
      }
    );
  }

  onSubmit() {
    this.setState({ isSubmitting: true });

    let _app_id = "";

    if (this.state.appid.value) {
      _app_id = this.state.appid.value;
    }

    const input_data = {
      app_id: _app_id,
      model: "interestrate",
      available: true,
      date: new Date().getTime(),
      extension: JSON.stringify({
        fleet: this.state.isFleet,
        capitalShare: this.state.isCapitalShare,
        interestRate: this.state.isInterestRate,
      }),
      saving_type: "-",
    };

    if (this.state.interest_rate && this.state.interest_rate !== "") {
      input_data.rate = this.state.interest_rate;
    }

    const errors = AppClass.validate_form(input_data);

    // input_data.saving_type = this.state.saving_types.length
    //   ? this.state.saving_types.toString()
    //   : "";

    if (!Object.keys(errors).length) {
      let response = {};
      console.log("-----------saving interest data", input_data);
      AppClass.add_savedata(input_data)
        .then(() => {
          response.success = "Setup, success";
          this.setState({
            isSubmitting: false,
            response,
          });

          toastMessage("success", "Settings saved successful");
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          response.error = "Error, " + err.response.data.error;
          this.setState({
            response,
          });
          toastMessage("error", response.error);
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  onUpdate() {
    this.setState({ isSubmitting: true });

    const input_data = {
      id: this.state._id,
      model: "interestrate",
      rate: this.state.interest_rate,
      extension: {
        fleet: this.state.isFleet,
        capitalShare: this.state.isCapitalShare,
        interestRate: this.state.isInterestRate,
      },
    };

    const errors = AppClass.validate_form(input_data);

    // input_data.saving_type = this.state.saving_types.length
    //   ? this.state.saving_types.toString()
    //   : "";

    if (!Object.keys(errors).length) {
      let response = {};

      AppClass.update_savedata(input_data)
        .then(() => {
          response.success = "Setup, updated";
          this.setState({
            isSubmitting: false,
            response,
          });

          toastMessage("success", "Settings updated successful");
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          response.error =
            "Error, " + err.response.data ? err.response.data.error : err;
          this.setState({
            response,
          });
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  handleCheckboxChange(name, e) {
    if (name === "isFleet") {
      this.setState({
        rate: 0,
      });
    }
    this.setState({
      [name]: e.target.checked,
    });
  }

  openSavingTypeModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onChangeSavingType(e) {
    this.setState({
      new_saving_type: e.target.value,
    });
  }

  validateSavingType() {
    let { error, new_saving_type, identifier } = this.state;

    if (identifier === "") {
      error.identifier = "Indentifier is required";
    }

    if (new_saving_type === "") {
      error.new_saving_type = "Saving product name is required";
    }

    this.setState({
      error,
    });
  }

  onAddSavingType = async () => {
    let { new_saving_type, identifier, error } = this.state;

    await this.validateSavingType();

    if (Object.keys(error).length > 0) return;

    this.setState({ isSubmittingType: true });

    const options = {
      method: "POST",
      url: HOST_URL.mobitill_url + "/products",
      data: {
        data: {
          appid: this.state.appid.value,
          identifier,
          name: new_saving_type,
          // module: "saving",
          // price: 0,
        },
      },
      headers: {
        Authorization: `Bearer ${this.props.ztoken}`,
      },
    };

    Axios(options)
      .then((data) => {
        this.setState({
          isSubmittingType: false,
          show_saving_type: false,
          new_saving_type: "",
          identifier: "",
        });

        this.getProducts(true);

        toastMessage("success", `Saving product added successful`);
      })
      .catch((error) => {
        this.setState({ isSubmittingType: false });

        toastMessage("error", error?.response?.data?.errors[0]);
      });

    // let input_data = {
    //   app_id: this.state.appid,
    //   model: "interestrate",
    //   rate: this.state.rate ? this.state.rate : 0,
    //   available: true,
    //   date: new Date().getTime(),
    //   saving_type: this.state.saving_types.toString(),
    //   extension: {
    //     fleet: this.state.isFleet,
    //     capitalShare: this.state.isCapitalShare,
    //     interestRate: this.state.isInterestRate,
    //   },
    // };

    // let route = "/add_savedata";

    // if (this.state._id && this.state._id !== "") {
    //   input_data.id = this.state._id;
    //   route = "/update_savedata";
    // }

    // let formData = new FormData();
    // Object.keys(input_data).map((key) => {
    //   let v = input_data[key];

    //   if (typeof v === "object") {
    //     return formData.append(key, JSON.stringify(input_data[key]));
    //   }

    //   return formData.append(key, input_data[key]);
    // });

    // Axios.post(HOST_URL.url + route, formData)
    //   .then((data) => {
    //     this.setState({
    //       isSubmittingType: false,
    //     });

    //     this.get_setting_by_appid(this.state.appid);

    //     toastMessage(
    //       "success",
    //       `Data ${input_data.id ? "updated" : "saved"} successful`
    //     );
    //   })
    //   .catch((error) => {
    //     this.setState({ isSubmittingType: false });
    //     toastMessage("error", "Error on saving type");
    //   });
    // }
  };

  onChangeIdentifier(e) {
    let { error } = this.state;
    delete error["identifier"];

    this.setState({
      error,
      identifier: e.target.value,
    });
  }

  onDeleteProduct(index) {
    let { products } = this.state;

    const options = {
      method: "DELETE",
      url: `${HOST_URL.mobitill_url}/products/${products[index]._id}`,
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };

    products.splice(index, 1);

    Axios(options)
      .then((data) => {
        toastMessage("success", "Product deleted successfully");

        this.getProducts(true);
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  }

  getProducts(isLoading) {
    this.setState({
      isLoading,
    });

    const options = {
      method: "GET",
      url: `${HOST_URL.mobitill_url}/products?0[]=appid&1[]=${this.state.appid.value}`,
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };

    Axios(options)
      .then((data) => {
        let products = [];
        for (let el of data.data.data) {
          if (el.loaning_loans) {
            products.push(el);
          }
        }
        this.setState({ isLoading: false, products });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  }

  render() {
    const {
      response,
      exist,
      interest_rate,
      saving_type,
      payment_period,
      apps,
      appid,
      isSubmitting,
      isFleet,
      isInterestRate,
      isCapitalShare,
      new_saving_type,
    } = this.state;
    return (
      <div className="content-cont">
        <Setting
          handleformonchange={(e) => AppClass.handleformonchange(this, e)}
          response={response}
          interest_rate={interest_rate}
          saving_type={saving_type}
          payment_period={payment_period}
          onSubmit={this.onSubmit.bind(this)}
          apps={apps}
          appid={appid}
          isSubmitting={isSubmitting}
          onChangeSacco={this.onChangeSacco.bind(this)}
          onUpdate={this.onUpdate.bind(this)}
          exist={exist}
          isFleet={isFleet}
          isCapitalShare={isCapitalShare}
          isInterestRate={isInterestRate}
          products={this.state.products}
          new_saving_type={new_saving_type}
          handleCheckboxChange={this.handleCheckboxChange.bind(this)}
          openSavingTypeModal={this.openSavingTypeModal.bind(this)}
          onDeleteProduct={this.onDeleteProduct.bind(this)}
          isSubmittingType={this.state.isSubmittingType}
          isGettingSettings={this.state.isGettingSettings}
        />
        <Modal
          show={this.state.show_saving_type}
          handleCloseModal={this.handleCloseModal.bind(
            this,
            "show_saving_type"
          )}
        >
          <Header>
            <h5 class="modal-title">Product</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleCloseModal.bind(this, "show_saving_type")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <div className="form-group">
              <label>
                Identifier <span className="required">(*)</span>
              </label>
              <input
                placeholder="001"
                className="form-control"
                value={this.state.identifier}
                onChange={this.onChangeIdentifier.bind(this)}
              />
              {this.state.error.identifier && (
                <span className="text-danger">
                  {this.state.error.identifier}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>
                Product Name <span className="required">(*)</span>
              </label>
              <input
                placeholder="Eg: Share capital"
                className="form-control"
                value={this.state.new_saving_type}
                onChange={this.onChangeSavingType.bind(this)}
              />
              {this.state.error.new_saving_type && (
                <span className="text-danger">
                  {this.state.error.new_saving_type}
                </span>
              )}
            </div>
          </Body>

          <Footer>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleCloseModal.bind(this, "show_saving_type")}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-danger"
              onClick={this.onAddSavingType.bind(this)}
              isSubmitting={this.state.isSubmittingType}
            >
              {this.state.isSubmittingType ? "Saving..." : "Save"}
            </button>
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default Settings;
