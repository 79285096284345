export const ON_ADD_FILTER = "ON_ADD_FILTER";
export const ON_RESET_FILTERS = "ON_RESET_FILTERS";

export const onAddFilter = (payload) => {
  return {
    type: ON_ADD_FILTER,
    payload,
  };
};

export const onResetFilters = (fieldName) => {
  return {
    type: ON_RESET_FILTERS,
    fieldName,
  };
};
