import React from "react";
import { ViewLoanPayments } from "../components/Loan";
import { Loading } from "../components/Loader";
import { Empty } from "../components/Empty";
import AppClass from "../AppClass";
import moment from "moment";
import { Modal, Header, Body, Footer } from "../components/Modal";
import { File } from "../components/File";
import { Penalty } from "../components/Penalty";
import { Filter } from "../components/Filter";
import toastMessage from "../components/toastMessage";
import { Pagination } from "../components/Pagination";
import CardCount from "../components/CardCount/CardCount";
import { numberWithCommas } from "../configs/formatCurrency";

class LoanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      status: "",
      apps: props.apps,
      message: "Please wait...",
      sacco: "",
      response: {},
      isSubmitting: false,
      payments: [],
      id: "",
      show: false,
      files: [],
      loan_id: "",
      start_date: moment().subtract("365", "days").format("YYYY-MM-DD"),
      end_date: moment().add("1", "d").format("YYYY-MM-DD"),
      summary: {},
      page: 1,
      limit: 50,
    };
  }

  componentDidMount() {
    this.setState(
      {
        loan_id: this.props.somethingID,
      },
      () => {
        this.get_loan_by_id(true);
      }
    );
  }

  get_loan_by_id(isLoading) {
    this.setState({ isLoading });

    let response = {},
      { loan_id } = this.state;
    const body = {
      tag: "by_loan_id",
      loan_id,
      model: "loan",
    };
    AppClass.get_data(body)
      .then((res) => {
        const loan = res.data;
        console.log('---got loan data', loan)
        let requested_loan = {};
        for (let i = 0; i < loan.length; i++) {
          if (loan[i].last_payment === 0) {
            console.log('requested loan', loan[i])
            requested_loan = loan[i];
          }
        }

        this.setState({
          isLoading: false,
          status: requested_loan.status,
          data: loan,
          id: requested_loan._id,
          requested_loan,
        });

        this.getPayments();
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  getPayments(isLoadingPayment) {
    const { start_date, end_date, loan_id, page, limit } = this.state;

    this.setState({
      isLoadingPayment,
    });

    const body = {
      loan_id,
      pageNumber: page,
      pageLimit: limit,
    };

    if (start_date && end_date) {
      body["fromDate"] = start_date;
      body["toDate"] = end_date;
    }

    AppClass.get_repaymentdata(body)
      .then((res) => {
        const { data, summary } = res.data;

        console.log({
          data,
        });

        this.setState({
          isLoadingPayment: false,
          payments: data,
          summary,
        });
      })
      .catch((err) => {
        toastMessage("error", err);
        this.setState({
          isLoadingPayment: false,
        });
      });
  }

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value }, () => {
      this.getPayments(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value }, () => {
      this.getPayments(true);
    });
  }

  view_files(files) {
    this.setState({ show: true });
    this.setState({ files });
  }

  onDelete(e, id) {
    e.preventDefault();
    if (window.confirm("Are you sure to delete this payment?")) {
      this.delete_payment(id);
    }
  }

  delete_payment(id) {
    let response = {};

    const input_data = {
      id,
      model: "loan",
    };

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors).length) {
      let response = {};

      AppClass.delete_data(input_data)
        .then((data) => {
          this.setState({
            isLoading: false,
          });
          response.success = "Payment deleted, success";
          this.setState({
            response,
            payments: [],
          });
          this.get_loan_by_id(this.props.somethingID);
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          response.error = "Error, " + err;
          this.setState({
            response,
            isLoading: false,
          });
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  handleOpenModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleClose(modal) {
    this.setState({ [modal]: false });
  }

  handleChangeLimit(e) {
    this.setState(
      {
        limit: e.target.value,
        page: 1,
      },
      () => {
        this.getPayments(true);
      }
    );
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getPayments(true);
      }
    );
  }

  render() {
    const {
      data,
      isLoading,
      message,
      apps,
      payments,
      response,
      id,
      show,
      files,
    } = this.state;
    const csvData = [
      ["Loan ID", "Requested By", "Date Approved", "Approved Amount", "	Sacco"],
    ];

    let sacco =
      this.state.data.length > 0
        ? AppClass.get_app_by_id(apps, data[0].app_id).name
        : "";

    if (data.length > 0) {
      csvData.push([
        data[0].loan_id,
        data[0].user_id,
        moment(data[0].date).format("ll"),
        data[0].amount,
        sacco,
      ]);
    }

    const csvPayment = [["Loan ID", "Date", "Paid Amount", "Balance"]];

    for (var i = 0; i < payments.length; i++) {
      csvPayment.push([
        payments[i].loan_id,
        moment(payments[i].date).format("ll"),
        "KES " + payments[i].last_payment,
        "KES " + (payments[i].amount - payments[i].last_payment),
      ]);
    }

    return (
      <div className="content-cont">
        {isLoading ? (
          <Loading message={message} icon="fa fa-spinner fa-spin" />
        ) : data.length === 0 ? (
          <Empty
            message="Loan ID is missing please go back and try again"
            icon="far fa-sad-tear"
          />
        ) : (
          <>
            <div style={{ paddingTop: 15 }}>
              <Filter
                start_date={this.state.start_date}
                end_date={this.state.end_date}
                onChangeStartingDate={this.onChangeStartingDate.bind(this)}
                onChangeEndDate={this.onChangeEndDate.bind(this)}
              />
              <div className="col-md-12 ">
                <div className="row">
                  <CardCount
                    count={this.state.summary.noOfrepayments}
                    title="Number of Repayments"
                    className="col-md-4"
                  />
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(this.state.summary.totalrepayments)
                    }
                    title="Total Repayments"
                    className="col-md-4"
                  />
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(
                        this.state.summary.totalCumulativerepayments
                      )
                    }
                    title="Total Cumulative Repayments"
                    className="col-md-4"
                  />
                </div>
              </div>
              <ViewLoanPayments
                data={data}
                apps={apps}
                payments={payments}
                role={this.props.role}
                response={response}
                sacco={sacco}
                id={id}
                loan_id={this.props.somethingID}
                csvData={csvData}
                csvPayment={csvPayment}
                delete_payment={this.onDelete.bind(this)}
                view_files={this.view_files.bind(this)}
                handleOpenModal={() => this.handleOpenModal("showPenaltyModal")}
                show={this.state.showPenaltyModal}
                isLoadingPayment={this.state.isLoadingPayment}
              />
              <Pagination
                totalItemsCount={this.state.payments.length}
                page={this.state.page}
                limit={this.state.limit}
                handleChangeLimit={this.handleChangeLimit.bind(this)}
                handlePagination={this.handlePagination.bind(this)}
              />
            </div>
          </>
        )}
        <Modal show={show}>
          <Header>
            <h5 class="modal-title">Files</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleClose("show")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <File files={files} />
          </Body>
          <Footer>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => this.handleClose("show")}
            >
              Close
            </button>
          </Footer>
        </Modal>

        <Modal show={this.state.showPenaltyModal}>
          <Penalty
            handleClose={() => this.handleClose("showPenaltyModal")}
            loan_id={this.props.somethingID}
            getData={() => this.get_loan_by_id(true)}
          />
        </Modal>
      </div>
    );
  }
}

export default LoanDetails;
