import React from "react";
import AppClass from "../../AppClass";
import { LoanDetail, PendingLoanDetail } from "../../components/Loan";
import { Empty } from "../../components/Empty";
import { Loading } from "../../components/Loader";
import { Modal, Header, Body, Footer } from "../../components/Modal";
import { File } from "../../components/File";

class LoanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      status: "",
      apps: props.apps,
      message: "Please wait...",
      response: {},
      approved_amount: 0,
      files: [],
      show: false,
    };
  }

  componentDidMount() {
    this.get_loan_by_id(this.props.somethingID);
  }

  get_loan_by_id(id) {
    this.setState({ isLoading: true });
    let response = {},
      ids = [];
    ids.push(id);
    const body = {
      tag: "by_id",
      ids,
      model: "loan",
    };
    AppClass.get_data(body)
      .then((data) => {
        //get pending / processing loans
        let loan = data.data;
        this.setState({
          isLoading: false,
          status: loan[0].status,
          approved_amount: loan[0].amount,
          data: loan,
        });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  onConfirm() {
    this.setState({ isSubmitting: true });

    const input_data = {
      id: this.props.somethingID,
      model: "loan",
      status: "Accepted",
      date: new Date().getTime(),
    };

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors).length) {
      let response = {};

      AppClass.update_data(input_data)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });
          response.success = "Application has been approved, success";
          this.setState({
            response,
          });

          window.location.href = "/home/member/loanhistory";
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          response.error = "Error, " + err;
          this.setState({
            response,
          });
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  view_purpose(purpose) {
    window.alert(purpose);
  }

  view_files(files) {
    this.setState({ show: true });
    this.setState({ files });
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    const {
      status,
      data,
      isLoading,
      message,
      sacco,
      isSubmitting,
      response,
      files,
      show,
    } = this.state;
    return (
      <div className="content-cont">
        {data.length === 0 && !isLoading && (
          <Empty
            message="Loan ID is missing please go back and try again"
            icon="far fa-sad-tear"
          />
        )}
        {isLoading ? (
          <Loading message={message} icon="fa fa-spinner fa-spin" />
        ) : status === "Pending" || status === "Processing" ? (
          <PendingLoanDetail
            data={data}
            isSubmitting={isSubmitting}
            view_purpose={this.view_purpose.bind(this)}
            onConfirm={this.onConfirm.bind(this)}
            response={response}
            view_files={this.view_files.bind(this)}
          />
        ) : (
          status === "Accepted" && <LoanDetail data={data} sacco={sacco} />
        )}

        <Modal show={show}>
          <Header>
            <h5 class="modal-title">Files</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleClose.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <File files={files} />
          </Body>
          <Footer>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleClose.bind(this)}
            >
              Close
            </button>
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default LoanDetails;
