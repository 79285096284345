import React from "react";

const SavingMenus = ({ role, page }) => (
  <ul>
    <li className="nav-item pcoded-menu-caption"></li>
    {role === "member" && (
      <div>
        <li className={page === "list" ? "active" : ""}>
          <a href={`/home/member_savings/list`}>
            <i className="fas fa-money-bill"></i> <span>Savings</span>
          </a>
        </li>
        <li className={page === "penalties" ? "active" : ""}>
          <a href={`/home/member_savings/penalties`}>
            <i className="fa fa-window-close" /> <span>Penalties</span>
          </a>
        </li>
      </div>
    )}

    {/* admin menus */}
    {role === "admin" && (
      <div>
        <li className={page === "list" ? "active" : ""}>
          <a href={`/home/admin_savings/list`}>
            <i className="fas fa-money-bill"></i> <span>Savings</span>
          </a>
        </li>
        <li className={page === "penalties" ? "active" : ""}>
          <a href={`/home/admin_savings/penalties`}>
            <i className="fa fa-window-close" /> <span>Penalties</span>
          </a>
        </li>
        <li className={page === "settings" ? "active" : ""}>
          <a href={`/home/admin_savings/settings`}>
            <i className="fas fa-cog" /> <span>Settings</span>
          </a>
        </li>
      </div>
    )}
  </ul>
);

export default SavingMenus;
