// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.app_container {
  box-sizing: border-box;
}

div.container-fluid {
  padding-left: 0;
  padding-right: 0;
  /* height: 100vh;
  width: 100%; */
  /* display: flex;
  align-items: center;
  overflow-y: hidden; */
}
`, "",{"version":3,"sources":["webpack://./src/App/components/Container/styles.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB;gBACc;EACd;;uBAEqB;AACvB","sourcesContent":["div.app_container {\n  box-sizing: border-box;\n}\n\ndiv.container-fluid {\n  padding-left: 0;\n  padding-right: 0;\n  /* height: 100vh;\n  width: 100%; */\n  /* display: flex;\n  align-items: center;\n  overflow-y: hidden; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
