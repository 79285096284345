export default function getFileTypeFromBase64(base64String) {
  // Remove metadata prefix from base64 string
  const base64Data = base64String.replace(/^data:[a-z]+\/[a-z]+;base64,/, "");

  // Decode base64 string to binary data
  const binaryData = atob(base64Data);

  // Get the file type from binary data
  const signature = binaryData.slice(0, 4);
  let fileType;

  switch (signature) {
    case "\x89PNG":
      fileType = "image/png";
      break;
    case "JVBER":
    case "%PDF":
      fileType = "application/pdf";
      break;
    case "GIF8":
      fileType = "image/gif";
      break;
    case "R0lG":
      fileType = "image/webp";
      break;
    // Add more cases for other file types as needed
    default:
      fileType = "unknown";
  }

  return fileType;
}
