import React from "react";
import { Card } from "../../Card";
import moment from "moment";
import { numberWithCommas } from "../../../configs/formatCurrency";
import getFileTypeFromBase64 from "../../../utils/isPDF";
import getTotalFees from "../../../utils/getTotalFees";

const LoanDetail = ({
  data,
  onConfirm,
  isSubmitting,
  response,
  view_purpose,
  view_files,
}) => (
  <Card className="col-md-12">
    {response.error ? (
      <div className="alert alert-danger">
        <span>{response.error}</span>
      </div>
    ) : (
      response.success && (
        <div className="alert alert-success">
          <span>{response.success}</span>
        </div>
      )
    )}
    <div className="panel-heading card-title">
      <span>
        Loan ID: <b>{data[0].loan_id}</b>
      </span>
    </div>
    <div className="panel-body pending">
      <div className="loan-pending table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Principal Amount</th>
              <th>Interest</th>
              <th>Fees</th>
              <th>Amount Payable</th>
              <th>Date Requested</th>
              <th>Purpose</th>
              <th>Date Approved</th>
              <th>Organization</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>KES {numberWithCommas(data[0].req_amount)}</b>
              </td>
              <td>
                <b>KES {numberWithCommas(data[0].expected_interest)}</b>
              </td>
              <td>
                <b>KES {getTotalFees(data[0].fees)}</b>
              </td>
              <td>
                <b>
                  {" "}
                  {data[0].amount === 0
                    ? "-"
                    : "KES " + numberWithCommas(data[0].amount)}
                </b>
              </td>
              <td>{moment(data[0].date).format("ll")}</td>
              <td>
                {data[0].purpose !== undefined ? (
                  <span>{data[0].purpose}</span>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {data[0].amount === 0 ? "-" : moment(data[0].date).format("ll")}
              </td>
              <td>
                <b>{data[0].organization}</b>
              </td>
              {
                <td className="text-info">
                  <b>{data[0].status}</b>
                </td>
              }
              {/* {data[0].files !== undefined && data[0].files.length > 0 ? (
                <td>
                  <a href="#" onClick={() => view_files(data[0].files)}>
                    {data[0].files.length} attachment(s)
                  </a>
                </td>
              ) : (
                <td>
                  <a href="#">-</a>
                </td>
              )} */}
            </tr>
          </tbody>
        </table>
        {data?.length > 0 && data[0]?.files?.length > 0 && (
          <div style={{ marginTop: 30 }}>
            <div class="panel panel-default">
              <div class="panel-heading">
                <h4
                  class="panel-title"
                  data-toggle="collapse"
                  data-target="#attachment"
                >
                  View Attachement
                </h4>
              </div>
              <div id="attachment" class="panel-collapse collapse">
                <div class="panel-body">
                  {data.length > 0 && data[0]?.files?.length > 0 && (
                    <table className="table table-bordered">
                      <tbody>
                        {data[0]?.files?.map((f) => {
                          if (getFileTypeFromBase64(f) === "application/pdf") {
                            return (
                              <tr>
                                <td align="center">
                                  <embed
                                    src={`data:application/pdf;base64,${f}`}
                                    width="500"
                                    height="600"
                                    type="application/pdf"
                                  />
                                </td>
                              </tr>
                            );
                          }
                          return (
                            <tr>
                              <td align="center">
                                <img
                                  style={{ width: 512 }}
                                  src={`data:image/png;base64, ${f}`}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    {data[0].status === "Processing" ? (
      <div className="loan-explanation">
        <h2>
          Application has been Confirmed by <b>{data[0].organization}</b>.
        </h2>
        <span>Please review your application carefully and confirm</span>
        <br />
        <br />
        <div>
          {isSubmitting ? (
            <button className="btn btn-primary" disabled={true}>
              Confirming...
            </button>
          ) : (
            <button className="btn btn-primary" onClick={onConfirm}>
              Confirm
            </button>
          )}
        </div>
      </div>
    ) : (
      <div className="loan-explanation">
        <h2>Application under Evaluation</h2>
        <span>
          Your application is being reviewed by <b>{data[0].organization}</b>.
          <br />
          We hope to hear from us soon...
        </span>
        <br />
        <br />
        {/* <a href="/home/member/view_application">View Application</a> */}
      </div>
    )}
  </Card>
);

export default LoanDetail;
