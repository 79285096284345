export default (fees) => {
  let totalFees = 0;
  let feeObj = JSON.parse(fees || {});
  let feeArr = Object.keys(feeObj);

  for (let fee of feeArr) {
    totalFees += Number(feeObj[fee]);
  }

  return totalFees > 0 ? totalFees : "0";
};
