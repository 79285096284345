import React from "react";
import { AddNew } from "../../components/Payments";
import { Empty } from "../../components/Empty";
import { Loading } from "../../components/Loader";
import AppClass from "../../AppClass";
// import { getLoanAmortization } from "../../utils/loan_amortization";
import moment from "moment";
import { Body, Footer, Header, Modal } from "../../components/Modal";
import toastMessage from "../../components/toastMessage";
import HOST_URL from "../../configs/api";
import axios from "axios";
import { getUnpaidSchedule } from "../../utils/loan_amortization";
import { numberWithCommas } from "../../configs/formatCurrency";

class AddPayment extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      status: "",
      apps: [],
      message: "Searching...",
      sacco: "",
      response: {},
      isSubmitting: false,
      payments: [],
      files: [],
      purpose: "-",
      id: "",
      page_count: 0,
      loan_amortization_results: [],
      unpaid_dates: [],
      unpaid_schedules: [],
      next_schedules: [],
      _id: "",
      loan_info: {},
      total_interest: 0,
      total_payment: 0,
      expected_interest: 0,
    };
  }

  componentDidMount = async () => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        window.location.href = "/home/admin/loans";
      } else {
        let response = {};

        try {
          let data = await this.get_loan_by_loan_id(
            this.props.somethingID,
            true
          );

          await this.updateStateLoan(data.data);

          await this.setAmortizationData(data.data);
        } catch (error) {
          response.error = "Error, " + error;
          this.setState({
            response,
            isLoading: false,
          });
        }
      }
    }
  };

  get_loan_by_id(id, showLoader) {
    this.setState({ isLoading: showLoader });

    let ids = [];
    ids.push(id);

    const body = {
      tag: "by_id",
      ids,
      model: "loan",
    };
    return AppClass.get_data(body);
  }

  get_loan_by_loan_id(loan_id, showLoader) {
    this.setState({ isLoading: showLoader });

    const body = {
      tag: "by_last",
      loan_id,
      model: "loan",
    };
    return AppClass.get_data(body);
  }

  getPayments = async (loan_id, showLoader) => {
    this.setState({ isLoading: showLoader });

    const body = {
      tag: "by_loan_id",
      loan_id,
      model: "loan",
    };

    let res = [];

    const data = await AppClass.get_data(body);

    for (let i = 0; i < data.data.length; i++) {
      if (data.data[i].total_payment > 0) {
        res.push(data.data[i]);
      }
    }
    return res;
  };

  getInterestByID = async () => {
    this.setState({ isLoading: true });

    const body = {
      tag: "by_loan_id",
      loan_id: this.props.somethingID,
      model: "loan",
    };
    const data = await AppClass.get_data(body);

    let requested_loan = {};

    for (let el of data.data) {
      if (el.total_payment === 0) {
        requested_loan = el;
      }
    }

    return {
      interestrate: requested_loan.interestrate,
    };
  };

  updateStateLoan = async (loan) => {
    try {
      if (loan.length === 0 && !loan[0].last) {
        this.setState({ isLoading: false });

        toastMessage("error", "Loan id is missing");

        return;
      }

      let loan_info = loan[0].last;

      delete loan_info._id;
      delete loan_info.__v;
      delete loan_info.file;

      let start_date_loan = moment(loan_info.date).format("YYYY-MM-DD");

      delete loan_info.date;

      let requested_loan = await this.getInterestByID();

      this.setState({
        isLoading: false,
        ...loan_info,
        interestrate: requested_loan.interestrate,
        start_date_loan,
      });
    } catch (error) {
      toastMessage("error", error);
    }
  };

  setAmortizationData = async () => {
    try {
      const { loan_id, loan_type, purpose, interestrate } =
        this.state;

      const payments = await this.getPayments(loan_id);

      if (loan_id) {
        let loan_settings = {};

        let term =
          loan_type.toLowerCase() === "short-term loans"
            ? "short_term"
            : "long_term";

        if (interestrate.length) {
          let products = interestrate[0][term] || [];

          for (let el of products) {
            if (el.type === purpose) {
              loan_settings = el;
            }
          }
        }

        let paid_dates = [];

        for (let i = 0; i < payments.length; i++) {
          if (payments[i].total_payment > 0 && payments[i].payment_date) {
            paid_dates.push(
              moment(payments[i].payment_date).format("YYYY-MM-DD")
            );
          }
        }

        this.calculateLoanAmortization(paid_dates);
      }
    } catch (error) {
      console.log(error);
      toastMessage("error", error);
    }
  };

  formatSettings(setting) {
    let purpose = {};

    let keys = Object.keys(setting);

    for (let k of keys) {
      let key_name = k.split("_");

      purpose[key_name[0]] = setting[k];
    }

    return purpose;
  }

  calculateLoanAmortization = async (paid_dates) => {
    this.setState({ isLoading: true });

    let loan_result = await this.getLoanAmortization({
      loan_id: this.state.loan_id,
      app_id: this.state.app_id,
    });

    const { unpaid_dates, next_schedules } = await getUnpaidSchedule({
      amortization_result: loan_result,
      paid_dates,
    });

    this.calculateUnpaidSchedules(loan_result, unpaid_dates);

    this.setState({
      loan_amortization_results: loan_result,
      unpaid_dates: unpaid_dates,
      next_schedules: next_schedules,
      isLoading: false,
    });
  };

  getLoanAmortization = async ({ loan_id, app_id }) => {
    try {
      const options = {
        method: "POST",
        url: HOST_URL.url + "/get_amortizationdata",
        data: {
          loan_id,
          app_id,
          model: "amortization",
          tag: "by_loan",
        },
      };

      const data = await axios(options);

      let loan_result = [];

      for (let el of data.data.amortization_schedule) {
        loan_result.push({
          ...el,
          payment_date: moment(el.payment_date).format("YYYY-MM-DD"),
        });
      }

      return loan_result;
    } catch (error) {
      toastMessage("error", "Failed to retrieve loan amortization");

      return {
        success: false,
      };
    }
  };

  calculateUnpaidSchedules(results, unpaid_dates) {
    let unpaid_schedules = [],
      total_amount_paid = 0;

    for (let el of results) {
      if (unpaid_dates.includes(el.payment_date)) {
        unpaid_schedules.push(el);

        total_amount_paid += parseFloat(el.term_payment);
      }
    }

    this.setState({
      unpaid_schedules,
      amount_paid: total_amount_paid,
    });
  }

  handleFile(e) {
    const files = Array.from(e.target.files);

    this.setState({
      files,
    });
  }

  handleSaveData() {
    this.setState({ isSubmitting: true });
    let response = {};
    let { unpaid_schedules, total_payment, total_interest } =
      this.state;

    for (let u = 0; u < unpaid_schedules.length; u++) {
      total_payment += Math.round(unpaid_schedules[u].term_payment);

      total_interest += Number(unpaid_schedules[u].term_interest);

      const input_data = {
        app_id: this.state.app_id,
        loan_id: this.state.loan_id,
        organization: this.state.organization,
        model: "loan",
        req_amount: this.state.req_amount,
        amount: unpaid_schedules[u].ending_balance,
        last_payment: Math.round(unpaid_schedules[u].term_payment),
        payment_info: JSON.stringify({
          exp_interest_rate: unpaid_schedules[u].term_interest,
          exp_principle_payment: unpaid_schedules[u].principal,
        }),
        interestrate:
          this.state.interestrate.length > 0
            ? this.state.interestrate[0]._id
            : "",
        payment_duration: this.state.payment_duration,
        total_interest,
        total_payment,
        user_id: this.state.user_id,
        user_name: this.state.user_name,
        phone_number: this.state.phone_number,
        status: this.state.status,
        available: true,
        date: new Date().getTime(),
        purpose: this.state.purpose,
        payment_date: unpaid_schedules[u].payment_date,
        loan_type: this.state.loan_type,
        guarantor_save_id: JSON.stringify(this.state.guarantor_save_id || {}),
        guaranted_amount: JSON.stringify(this.state.guaranted_amount || []),
        expected_interest: this.state.expected_interest,
      };

      for (var i = 0; i < this.state.files.length; i++) {
        input_data["file" + i] = this.state.files[i];
      }

      if (parseInt(this.state.amount_paid) === 0) {
        response["last_payment"] = "Amount is mandatory";
        this.setState({ response: response, isSubmitting: false });
        return;
      }

      console.log("input_data", input_data);

      const errors = AppClass.validate_form(input_data);

      if (!Object.keys(errors).length) {
        let response = {};

        AppClass.add_data(input_data)
          .then(async (data) => {
            // send an sms
            // var balance = input_data.req_amount - input_data.total_payment;
            // this.notifyMember(input_data.last_payment, balance);

            this.setState({
              isSubmitting: false,
              // amount_paid: "",
              // purpose: "",
            });
            response.success = "Payment saved, successfully";
            this.setState({
              response,
              id: data.data._id,
            });

            toastMessage("success", response.success);

            window.location.href = `/home/admin/view_loan_payments/${this.state.loan_id}`;

            let loan_queried = await this.get_loan_by_id(data.data._id, true);

            await this.updateStateLoan(loan_queried.data);
          })
          .catch((err) => {
            this.setState({ isSubmitting: false });
            // response.error = "Error, " + err;
            // this.setState({
            //   response,
            // });
            toastMessage("error", err);
          });
      } else {
        this.setState({ response: errors, isSubmitting: false });
      }
    }
  }

  notifyMember(payment, balance) {
    let option = {
      phonenumber: this.state.phone_number,
      text: `Dear ${this.state.user_name}, your payment of KSH ${parseInt(
        payment
      )} for loan ${
        this.state.loan_id
      } has been successful, the remaining outstanding balance is KSH ${balance}, ***** Loan purpose: ${
        this.state.purpose
      }. Thank you for loaning with us.`,
      appid: this.state.app_id,
    };

    let response = {};
    response.sms_sending_message = "Wait sending sms...";
    this.setState({ sending_sms: true, response });

    AppClass.sendSMS(option)
      .then((data) => {
        response.success =
          "SMS has been sent successfully to " + this.state.phone_number;
        this.setState({
          response,
          sending_sms: false,
        });
      })
      .catch((err) => {
        this.setState({});
        this.setState({
          response,
          sending_sms: false,
          isSubmitting: false,
        });
      });
  }

  handleAddModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onAddMoreSchedules(item, i) {
    if (this.state.unpaid_dates.includes(item.payment_date)) {
      this.state.unpaid_dates.splice(i, 1);
    } else {
      this.state.unpaid_dates.push(item.payment_date);
    }

    this.setState(
      {
        unpaid_schedules: this.state.unpaid_dates,
      },
      () => {
        this.calculateUnpaidSchedules(
          this.state.loan_amortization_results,
          this.state.unpaid_dates
        );
      }
    );
  }

  onChangePaidAmount(e, item, i) {
    let { unpaid_schedules, unpaid_dates, loan_amortization_results } =
      this.state;

    let v = e.target.value;

    if (!unpaid_schedules[i].original_payment) {
      unpaid_schedules[i]["original_payment"] =
        unpaid_schedules[i].term_payment;
    }

    unpaid_schedules[i].term_payment = v;
    unpaid_schedules[i].principal = v - unpaid_schedules[i].term_interest;
    unpaid_schedules[i].ending_balance =
      unpaid_schedules[i].beginning_balance - unpaid_schedules[i].principal;

    this.setState(
      {
        unpaid_schedules,
      },
      () => {
        this.calculateUnpaidSchedules(loan_amortization_results, unpaid_dates);
      }
    );
  }

  onChangeBeginningAmount(e, item, i) {
    let { unpaid_schedules, unpaid_dates, loan_amortization_results } =
      this.state;

    let v = e.target.value;

    if (!unpaid_schedules[i].scheduled_beginning_balance) {
      unpaid_schedules[i]["scheduled_beginning_balance"] =
        unpaid_schedules[i].beginning_balance;
    }

    unpaid_schedules[i].beginning_balance = v;
    unpaid_schedules[i].ending_balance =
      unpaid_schedules[i].beginning_balance - unpaid_schedules[i].principal;

    this.setState(
      {
        unpaid_schedules,
      },
      () => {
        this.calculateUnpaidSchedules(loan_amortization_results, unpaid_dates);
      }
    );
  }

  render() {
    const {
      response,
      loan_id,
      amount_paid,
      isSubmitting,
      isLoading,
      message,
      sending_sms,
    } = this.state;

    console.log({
      total_payment: this.state.total_payment,
    });
    return (
      <div className="content-cont">
        {isLoading ? (
          <Loading message={message} icon="fa fa-spinner fa-spin" />
        ) : loan_id === undefined ? (
          <Empty
            message="Loan ID is missing please go back and try again"
            icon="far fa-sad-tear"
          />
        ) : (
          <AddNew
            response={response}
            loan_id={loan_id}
            amount_paid={amount_paid}
            isLoading={this.state.isLoading}
            isSubmitting={isSubmitting}
            handleSaveData={this.handleSaveData.bind(this)}
            handleformonchange={(e) => AppClass.handleformonchange(this, e)}
            handleFile={this.handleFile.bind(this)}
            sending_sms={sending_sms}
            loan_amortization_results={this.state.loan_amortization_results}
            selected_schedules={this.state.selected_schedules}
            unpaid_schedules={this.state.unpaid_schedules}
            handleAddModal={() => this.handleAddModal("schedules_modal")}
            next_schedules={this.state.next_schedules}
            onAddMoreSchedules={this.onAddMoreSchedules.bind(this)}
            onChangePaidAmount={this.onChangePaidAmount.bind(this)}
            onChangeBeginningAmount={this.onChangeBeginningAmount.bind(this)}
          />
        )}
        <Modal
          show={this.state.schedules_modal}
          handleCloseModal={this.handleCloseModal.bind(this, "schedules_modal")}
        >
          <Header>
            <h5 class="modal-title">More Schedules</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleCloseModal.bind(this, "schedules_modal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <div className="panel-body table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      <input type="checkbox" /> Select All
                    </th>
                    <th>Month(s)</th>
                    <th>Payment Date</th>
                    <th>Beginning Balance</th>
                    <th>Payment</th>
                    <th>Principal</th>
                    <th>Interest</th>
                    <th>Ending Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.loan_amortization_results.length > 0 &&
                    this.state.loan_amortization_results.map((item, i) => {
                      if (
                        this.state.next_schedules.includes(item.payment_date)
                      ) {
                        return (
                          <tr key={i}>
                            <td>
                              <input
                                type="checkbox"
                                checked={this.state.unpaid_dates.includes(
                                  item.payment_date
                                )}
                                onChange={() =>
                                  this.onAddMoreSchedules(item, i)
                                }
                              />
                            </td>
                            <td>{i + 1}</td>
                            <td>{item.payment_date}</td>
                            <td>
                              KES {numberWithCommas(item.beginning_balance)}
                            </td>
                            <td>KES {numberWithCommas(item.term_payment)}</td>
                            <td>KES {numberWithCommas(item.principal)}</td>
                            <td>KES {numberWithCommas(item.term_interest)}</td>
                            <td>KES {numberWithCommas(item.ending_balance)}</td>
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>
          </Body>
          <Footer>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              disabled={this.state.isAddingMoreSchedules}
              onClick={() => this.handleCloseModal("schedules_modal")}
            >
              {this.state.isAddingMoreSchedules
                ? "Please wait..."
                : "Add Schedule(s)"}
            </button>
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default AddPayment;
