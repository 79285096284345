import React from "react";
import { ApplicationList } from "../../components/application";
import { Filter } from "../../components/Filter";
import AppClass from "../../AppClass";
import moment from "moment";
import { Loading } from "../../components/Loader";

let copyData = [];

class MyApplication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: props.apps,
      loan_ids: [],
      loans: [],
      isLoading: false,
      start_date: moment().subtract("30", "days"),
      end_date: moment(),
      app_ids: [],
    };
  }

  componentDidMount = async () => {
    let f_apps = [],
      { apps } = this.props;

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    await this.setState({ apps: f_apps });

    this.get_loan_by_apps(true, this.state.apps);

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    }
  };

  get_loan_by_apps = async (apps) => {
    let app_ids = await AppClass.get_app_ids(apps);

    this.get_loans(true, app_ids);
    this.setState({ app_ids });
  };

  onChangeSacco(e) {
    this.setState({ appid: e }, () => {
      this.get_loans(true);
    });
  }

  onChangeLoanID(e) {
    this.setState({ loan_id: e }, () => {
      this.get_loans(true);
    });
  }

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value }, () => {
      this.get_loans(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value }, () => {
      this.get_loans(true);
    });
  }

  get_loans(isLoading, app_ids) {
    this.setState({ isLoading, loans: [] });

    let response = {},
      { user_id } = this.props,
      { start_date, end_date, appid } = this.state;

    const _start_date = new Date(start_date).getTime();
    const _end_date = new Date(end_date).getTime();

    let _appid = "";

    if (!appid || !appid?.value) {
      _appid = AppClass.get_app_ids(app_ids);
    } else {
      _appid = [appid.value];
    }

    const body = {
      tag: "by_user",
      user_id,
      model: "loan",
    };
    if (start_date !== undefined || end_date !== undefined) {
      body["start_date"] = _start_date;
      body["end_date"] = _end_date;
      body["app_id"] = _appid;
      body["tag"] = "by_date";
    }
    AppClass.get_data(body)
      .then((data) => {
        //get pending / processing loans
        let loans = data.data,
          loan_array = [];
        for (let i = 0; i < loans.length; i++) {
          if (
            loans[i].status === "Pending" ||
            loans[i].status === "Processing"
          ) {
            loan_array.push(loans[i]);
          }
        }

        copyData = loan_array.slice(0);

        loan_array.sort(function (a, b) {
          return b.date - a.date;
        });

        this.setState({ isLoading: false, loans: loan_array });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  delete(e, id) {
    e.preventDefault();
    if (window.confirm("Are you sure to delete your application?")) {
      this.setState({ isLoading: true });
      let response = {};

      AppClass.delete_data({ model: "loan", id })
        .then(() => {
          this.setState({
            isLoading: false,
          });

          this.get_loans(
            this.props.user_id,
            this.state.appid,
            this.state.loan_id
          );
        })
        .catch((err) => {
          response.error = "Error, " + err;
          this.setState({
            response,
            isLoading: false,
          });
        });
    }
  }

  render() {
    const {
      apps,
      response,
      loans,
      isLoading,
      start_date,
      end_date,
      appid,
      loan_id,
    } = this.state;
    return (
      <div className="content-cont">
        <Filter
          apps={apps}
          loans={copyData}
          appid={appid}
          loan_id={loan_id}
          response={response}
          handleformonchange={(e) => AppClass.handleformonchange(this, e)}
          onChangeSacco={this.onChangeSacco.bind(this)}
          onChangeLoanID={this.onChangeLoanID.bind(this)}
          start_date={start_date}
          end_date={end_date}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
        />
        {isLoading ? (
          <Loading message="Please wait..." icon="fa fa-spinner fa-spin" />
        ) : (
          <div>
            <ApplicationList
              loans={loans}
              apps={apps}
              delete_data={this.delete.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default MyApplication;
