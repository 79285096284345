// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.modal-header {
  /* padding-top: 10px; */
  display: flex;
  align-items: center;
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0; */
  /* z-index: 999; */
  background-color: white;
}

button.close {
  position: absolute;
  right: 0;
  margin-right: 20px;
}

.modal {
  overflow-y: auto;
}

.modal-footer {
  /* bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: white;
  position: absolute; */
}

.modal-content {
  /* margin: 50px 0;
  padding: 50px 0; */
}

.modal-container {
  /* height: 55vh; */
  overflow: auto;
}

a {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/App/components/Modal/styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB;;;aAGW;EACX,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;;;;;uBAKqB;AACvB;;AAEA;EACE;oBACkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["div.modal-header {\r\n  /* padding-top: 10px; */\r\n  display: flex;\r\n  align-items: center;\r\n  /* position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0; */\r\n  /* z-index: 999; */\r\n  background-color: white;\r\n}\r\n\r\nbutton.close {\r\n  position: absolute;\r\n  right: 0;\r\n  margin-right: 20px;\r\n}\r\n\r\n.modal {\r\n  overflow-y: auto;\r\n}\r\n\r\n.modal-footer {\r\n  /* bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  z-index: 999;\r\n  background-color: white;\r\n  position: absolute; */\r\n}\r\n\r\n.modal-content {\r\n  /* margin: 50px 0;\r\n  padding: 50px 0; */\r\n}\r\n\r\n.modal-container {\r\n  /* height: 55vh; */\r\n  overflow: auto;\r\n}\r\n\r\na {\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
