import React from "react";

const Navbar = ({
  add_new_saving,
  role,
  apply_for_loan,
  signMeOut,
  showDropDown,
  openMenu,
  username,
  show_btn,
  route,
  onTaggleSidebar,
  current_app_type,
  onSwitch,
}) => (
  <nav className="navbar navbar-default navbar-static-top">
    <div className="navbar-header">
      <a href="#" className="navbar-brand">
        <a href="#" onClick={onTaggleSidebar}>
          <i className="fas fa-bars"></i>
        </a>
        <span>{route}</span>
      </a>
    </div>
    <div>
      <ul className="nav navbar-nav navbar-right">
        {role === "admin" && show_btn ? (
          <li>
            <button
              onClick={apply_for_loan}
              className="btn btn-primary request_loan"
            >
              APPLY LOAN
            </button>
          </li>
        ) : (
          role === "saving" && (
            <li>
              <button
                onClick={add_new_saving}
                className="btn btn-primary request_loan"
              >
                NEW SAVING
              </button>
            </li>
          )
        )}
        <li>
          <div className="profile" onClick={openMenu}>
            <div className="user-profile" />
            <span>{username}</span>
            <i className="fa fa-chevron-down" />
            {showDropDown && (
              <div className="dropdown">
                <ul>
                  <div className="app_mobile_menus">
                    <li>{username}</li>
                    <li onClick={onSwitch}>
                      {current_app_type === "loan"
                        ? "Switch to Epesi Savings"
                        : "Switch to Epesi loans"}
                    </li>
                  </div>
                  <li onClick={signMeOut}>Sign out</li>
                </ul>
              </div>
            )}
          </div>
        </li>
      </ul>
    </div>
  </nav>
);

export default Navbar;
