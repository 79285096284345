import React from "react";
import { Card } from "../../../Card";
import { Select } from "../../../Select";
import AppClass from "../../../../AppClass";
import toastMessage from "../../../toastMessage";
import { Loading } from "../../../Loader";
import axios from "axios";
import HOST_URL from "../../../../configs/api";

class LoanIDSettings extends React.Component {
  state = {
    isLoading: false,
    data: [],
    error: {},
    settingId: "",
    loanIDConfig: "",
    loanSequency: "",
  };

  componentDidMount() {
    const { apps } = this.props;
    if (apps.length === 1) {
      this.setState({ appid: apps[0] });
      this.onChangeInput("sacco", apps[0]);
    }
  }

  onChangeInput(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target ? e.target.value : e }, () => {
      if (field === "sacco") {
        this.get_setting_by_appid(e.value);
      }
    });
  }

  get_setting_by_appid(app_id) {
    const body = {
      app_ids: [app_id],
      model: "setting",
      tag: "by_appid",
    };
    this.setState({ isLoading: true });
    AppClass.get_setting_data(body)
      .then((_data) => {
        if (_data.data.length > 0) {
          const { loan_id_config, loan_seq } = _data.data[0].loan_id_setting;

          this.setState({
            settingId: _data.data[0]._id,
            loanIDConfig: loan_id_config,
            loanSequency: loan_seq,
          });
        } else {
          toastMessage("error", "Loan settings not found");
        }

        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        toastMessage("error", "Unable to find loan settings");
        this.setState({
          isLoading: false,
        });
      });
  }

  validateForm() {
    const { loanIDConfig, loanSequency, error, frequency } = this.state;

    if (loanIDConfig === "") {
      error.loanIDConfig = "Loan ID config is required";
    }

    if (loanSequency === "") {
      error.loanSequency = "Loan Sequency is required";
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const { loanIDConfig, loanSequency, error, settingId, sacco } = this.state;

    const user = await AppClass.get_user_sessions();
    if (Object.keys(error).length === 0) {
      this.setState({ isSubmitting: true });

      const options = {
        method: "POST",
        url: HOST_URL.url + "/update_settingdata",
        data: {
          id: settingId,
          model: "setting",
          loan_id_setting: {
            loan_seq: loanSequency,
            loan_id_config: loanIDConfig,
          },
        },
        headers: {
          Authorization: "Bearer " + user.ztoken,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("success", "Settings updated successfully");

          this.get_setting_by_appid(sacco.value);
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });

          toastMessage("error", "error updating settings, please try again");
        });
    }
  };

  render() {
    console.log(this.state.settingId);
    return (
      <Card className="col-md-8 col-md-offset-2">
        <div className="panel-heading  card-title">
          <span>Loan ID Settings</span>
        </div>
        <div className="panel-body">
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="col-md-12">
                <div className="form-group">
                  <Select
                    label="Sacco"
                    value={this.state.sacco}
                    options={this.props.apps}
                    name="fleet"
                    onChange={(e) => this.onChangeInput("sacco", e)}
                  />
                  {this.state.error.sacco && (
                    <span className="text-danger">
                      {this.state.error.sacco}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Loan Sequency</label>
                  <input
                    type="number"
                    label="Loan Sequency"
                    value={this.state.loanSequency}
                    onChange={(e) => this.onChangeInput("loanSequency", e)}
                    className="form-control"
                    disabled
                  />
                  {this.state.error.loanSequency && (
                    <span className="text-danger">
                      {this.state.error.loanSequency}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Loan ID Config</label>
                  <input
                    value={this.state.loanIDConfig}
                    onChange={(e) => this.onChangeInput("loanIDConfig", e)}
                    className="form-control"
                  />
                  {this.state.error.loanIDConfig && (
                    <span className="text-danger">
                      {this.state.error.loanIDConfig}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <button
                  className="btn btn-primary"
                  disabled={this.state.isSubmitting}
                  onClick={this.onSubmit.bind(this)}
                >
                  {this.state.isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </>
          )}
        </div>
      </Card>
    );
  }
}

export default LoanIDSettings;
