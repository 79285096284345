// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.withdraw_btn {
  height: 30px !important;
  color: white;
}

.required {
  color: red;
}

.badge-active {
  background-color: green;
}

.badge-disbursed {
  background-color: blue;
}
`, "",{"version":3,"sources":["webpack://./src/App/components/Saving/styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["button.withdraw_btn {\r\n  height: 30px !important;\r\n  color: white;\r\n}\r\n\r\n.required {\r\n  color: red;\r\n}\r\n\r\n.badge-active {\r\n  background-color: green;\r\n}\r\n\r\n.badge-disbursed {\r\n  background-color: blue;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
