export const data_loan_type = ["Short-term Loans", "Long-term Loans"];

export const purporses = {
  "Short-term Loans": [
    {
      type: "Tyre Loan",
      grace_period: 1,
      amount: 500,
    },
    {
      type: "Advanced tax loan",
      grace_period: 3,
      amount: 920,
    },
    {
      type: "Insurance loan",
      grace_period: 1,
      amount: 0,
    },
  ],
  "Long-term Loans": [
    {
      type: "Vehicle repair",
      grace_period: 1,
      amount: 0,
      interestrate: [],
    },
    {
      type: "Vehicle repair",
      grace_period: 1,
      amount: 0,
      interestrate: [],
    },
  ],
};

export const savingTypes = [
  "Normal Saving",
  "Share Capital",
  "Withdrawable Saving",
];

export const loan_methods = ["Reducing balance method", "Straight-line method"];
export const terms = ["Monthly", "Yearly"];
export const rules = ["2 times", "3 Times", "4 Times"];
