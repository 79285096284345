import React from "react";
import { Container } from "../../../components/Container";
import { Sidebar } from "../../../components/Sidebar";
import { Content } from "../../../components/Content";
import { Navbar } from "../../../components/Navbar";

import AppClass from "../../../AppClass";
import Savings from "../Savings";
import NewSaving from "./NewSaving";
import Settings from "./Settings";
import PenaltyScreen from "../Penalty";
import TransactionReversal from "./TransactionReversal";
import Withdraw from "./Withdraw";

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      onTaggle: true,
      page: "",
      showDropDown: false,
      user_id: "",
      username: "",
      ztoken: "",
      admin: false,
      route: "",
      epesi_app: "Epesi Saving",
      diladmin: false,
      epesirole: {},
    };
  }

  componentDidMount() {
    let data = AppClass.get_user_sessions();
    if (data?.ztoken) {
      this.setState({
        page: AppClass.getParams(this, "page"),
        user_id: data._id,
        username: data.firstName + " " + data.lastName,
        ztoken: data.ztoken,
        admin: true,
        apps: data.apps,
        diladmin: data.diladmin,
        epesirole: data.role,
        epesi_loan_saving_account_id: data.epesi_loan_saving_account_id,
        phone_number: data.phone,
        epesidiladmin: data.diladmin,
      });
    } else {
      window.location.href = "/adminAuth";
    }
    //setup page header from params
    let page = AppClass.getParams(this, "page");

    this.setState({
      route:
        page === "list"
          ? "Savings"
          : page === "settings"
          ? "Settings"
          : page === "new_saving"
          ? "New Saving"
          : "",
    });

    page === "list"
      ? this.save_monitoring("Savings", data.firstName + " " + data.lastName)
      : page === "settings"
      ? this.save_monitoring("Settings", data.firstName + " " + data.lastName)
      : page === "new_saving" &&
        this.save_monitoring(
          "New Saving",
          data.firstName + " " + data.lastName
        );
  }

  onTaggleSidebar() {
    this.setState({ onTaggle: this.state.onTaggle ? false : true });
  }

  signMeOut() {
    AppClass.signMeOut();

    window.location.href = "/adminAuth";
  }

  openMenu() {
    this.setState({ showDropDown: this.state.showDropDown ? false : true });
  }

  onChangeEpesiApp(e) {
    this.setState({ epesi_app: e.target.value });
    this.get_home_page(e.target.value);
  }

  get_home_page(target_value) {
    if (target_value === "Epesi Loan") {
      window.location.href = "/home/admin/loans";
    }

    if (target_value === "Epesi Saving") {
      window.location.href = "/home/admin_savings/list";
    }
  }

  add_new_saving() {
    window.location.href = "/home/admin_savings/new_saving";
  }

  save_monitoring(page, username) {
    let body = {
      app_name: "Loan app / Savings",
      username,
      section: page,
      category: "Out-House",
      date: new Date().getTime(),
    };

    AppClass.monitoring(body)
      .then((data) => {
        //saved
      })
      .catch((err) => {
        //no error
      });
  }

  render() {
    const { admin, username, page, ztoken, route, epesi_app, user_id } =
      this.state;
    console.log(ztoken);
    return (
      <Container>
        <Sidebar
          role="admin"
          page={this.state.page}
          onTaggle={this.state.onTaggle}
          onTaggleSidebar={this.onTaggleSidebar.bind(this)}
          epesi_app={epesi_app}
          onChangeEpesiApp={this.onChangeEpesiApp.bind(this)}
        />

        <Content>
          <Navbar
            role={"saving"}
            signMeOut={this.signMeOut.bind(this)}
            openMenu={this.openMenu.bind(this)}
            showDropDown={this.state.showDropDown}
            username={username}
            page={page}
            route={route}
            onTaggle={this.state.onTaggle}
            onTaggleSidebar={this.onTaggleSidebar.bind(this)}
            add_new_saving={this.add_new_saving.bind(this)}
            current_app_type="savings"
            onSwitch={this.get_home_page.bind(this, "Epesi Loan")}
          />
          <div className=" main-content">
            {this.state.page === "list" ? (
              <Savings
                ztoken={ztoken}
                role={admin && "saving"}
                user_id={user_id}
                saving_permission={admin && "admin"}
                apps={this.state.apps}
                admin={admin}
                diladmin={this.state.diladmin}
                epesirole={this.state.epesirole}
                epesi_loan_saving_account_id={
                  this.state.epesi_loan_saving_account_id
                }
                username={this.state.username}
                phone_number={this.state.phone_number}
                epesidiladmin={this.state.epesidiladmin}
              />
            ) : this.state.page === "new_saving" ? (
              <NewSaving
                ztoken={ztoken}
                role={admin && "saving"}
                user_id={user_id}
                username={username}
                apps={this.state.apps}
                admin={admin}
              />
            ) : this.state.page === "penalties" ? (
              <PenaltyScreen
                ztoken={ztoken}
                apps={this.state.apps}
                role={admin && "admin"}
                admin={admin}
              />
            ) : (
              this.state.page === "settings" && (
                <Settings
                  ztoken={ztoken}
                  role={admin && "saving"}
                  apps={this.state.apps}
                  admin={admin}
                />
              )
            )}
          </div>
        </Content>
      </Container>
    );
  }
}

export default Home;
