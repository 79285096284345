import React from "react";

const Empty = ({ message, icon }) => (
  <div className="empty">
    <center>
      <i className={icon}></i>
      <p>{message}</p>
    </center>
  </div>
);

export default Empty;
