import React from "react";
import { Card } from "../Card";
import Select from "react-select";
import { terms } from "../../data/settings";
import { numberWithCommas } from "../../configs/formatCurrency";

const ApplyNew = ({
  handleformonchange,
  response,
  payment_duration,
  calculateLoanAmortization,
  total_interest,
  term_payment,
  total_payments,
  disabled,
  onChangeSacco,
  sacco,
  application_id,
  handleApplyData,
  isSubmitting,
  apps,
  term,
  interestrate,
  available,
  results,
  saved_loan,
  purpose,
  method,
  contribution_rate,
  contribution_amount,
  number_tyres,
  loan_type,
  long_term_purpose,
  short_term_purpose,
  onChangeLoanType,
  loan_type_value,
  onChangePurpose,
  fixed_amount,
  fleet,
  fleets,
  handleChangeFleet,
  loan_products,
  loan_period,
  start_date_loan,
  total_cost_loan,
  number_payment,
  loan_period_term,
  loan_amount,
  onChangeAmount,
  maximum_loan_amount,
  members,
  member,
  onChangeMember,
  role,
  need_guarantor,
  guaranted_amount,
  onChangeGuarantorAmount,
  onChangeGuarantor,
  guarantor,
  isLoading,
  user_id,
  handleFile,
  guarantors,
  last_saving,
  onAddGuarantor,
  onRemoveGuarantor,
  products,
  product,
  onChangeSavingProduct,
  onChangeFees,
  fees,
  totalFees,
  purpose_value,
  onChangeTotalPayment,
  onChangeTotalInterest,
}) => {
  let term_name =
    loan_period_term === "Daily"
      ? "months(s)"
      : loan_period_term === "Weekly"
      ? "week(s)"
      : loan_period_term === "Monthly"
      ? "months"
      : "year(s)";

  return (
    <div>
      <Card className="col-md-8">
        <div className="panel-heading card-title">
          <span>Application Form</span>
        </div>
        {isLoading && (
          <div className="alert alert-info">
            <i className="fa fa-spinner fa-spin" /> Fetching data, please
            wait...
          </div>
        )}
        {/* SACCO */}
        <div className="panel-body">
          <div className="col-md-12">
            <div className="form-group">
              <label>
                Sacco <span className="required">(*)</span>
              </label>
              <Select
                value={sacco}
                options={apps}
                name="fleet"
                onChange={onChangeSacco}
              />
              <span className="text-danger">{response.sacco}</span>
            </div>
          </div>

          {/* MEMBER */}
          {role === "admin" && (
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Member <span className="required">(*)</span>
                </label>
                <Select
                  value={member}
                  options={members}
                  name="member"
                  onChange={onChangeMember}
                />
                <span className="text-danger">{response.user_id}</span>
              </div>
            </div>
          )}

          {/* LOAN ID */}
          <div className="col-md-5">
            <div className="form-group">
              <label>
                Loan Application ID <span className="required">(*)</span>
              </label>
              <input
                className="form-control"
                value={application_id}
                disabled
                onChange={handleformonchange}
              />
              <span className="text-danger">{response.application_id}</span>
            </div>
          </div>

          {/* LOAN TYPE */}
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Loan Type <span className="required">(*)</span>
              </label>
              <select
                className="form-control"
                name="loan_type_value"
                onChange={onChangeLoanType}
                data-live-search="true"
                id="loan_type"
                value={loan_type_value}
              >
                <option selected disabled>
                  Choose loan type
                </option>
                {loan_type.map((item, i) => {
                  return <option key={i}>{item}</option>;
                })}
              </select>
              <span className="text-danger">{response.loan_type}</span>
            </div>
          </div>

          {/* LOAN PRODUCT */}
          <div className="col-md-12">
            <div className="form-group">
              <label>
                Loan Product <span className="required">(*)</span>
              </label>
              <select
                className="form-control"
                onChange={onChangePurpose}
                name="purpose"
                disabled={!loan_type_value}
                data-live-search="true"
                value={purpose_value}
              >
                <option disabled selected>
                  Choose loan product
                </option>
                {loan_products.map((item, i) => {
                  return <option key={i}>{item.type}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <label>
                Payment Frequency <span className="required">(*)</span>
              </label>
              <select
                className="form-control"
                value={loan_period_term}
                name="loan_period_term"
                onChange={handleformonchange}
                data-live-search="true"
              >
                {terms.map((t) => {
                  return <option>{t}</option>;
                })}
              </select>
              <span className="text-danger">{response.loan_period_term}</span>
            </div>
          </div>

          {loan_period_term !== "" && (
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  {` Loan period in ${
                    loan_period_term === "Monthly"
                      ? "Month(s)"
                      : loan_period_term === "Yearly"
                      ? "Year(s)"
                      : ""
                  }`}
                  <span className="required">(*)</span>
                </label>
                <input
                  className="form-control"
                  value={loan_period}
                  name="loan_period"
                  type="number"
                  onChange={handleformonchange}
                  disabled
                />
                <span className="text-danger">{response.loan_period}</span>
              </div>
            </div>
          )}

          {/* NO IDEA why this is here but... NO OF TYRES */}
          {purpose?.type?.toLowerCase() === "tyre" && (
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Number of Tyres <span className="required">(*)</span>
                </label>
                <input
                  className="form-control"
                  value={number_tyres}
                  name="number_tyres"
                  onChange={handleformonchange}
                />
                <span className="text-danger">{response.number_tyres}</span>
              </div>
            </div>
          )}

          {purpose.type && (
            <>
              {/* INTEREST RATE */}
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Period Interest Rate(%){" "}
                    <span className="required">(*)</span>
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={purpose.interest_rate}
                    name="rate"
                    min={0}
                    max={100}
                    step={0.1}
                    onChange={handleformonchange}
                  />
                  <span className="text-danger">{response.interest_rate}</span>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                   {/* SAVING PRODUCT */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Saving Product <span className="required">(*)</span>
                      </label>
                      <Select
                        options={products}
                        onChange={onChangeSavingProduct}
                        value={product}
                      />
                      <span className="text-danger">
                        {/* Determine saving balance from different saving products */}
                      </span>
                    </div>
                  </div>

                   {/* LOAN AMOUNT */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Loan Amount <span className="required">(*)</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="loan_amount"
                        onChange={onChangeAmount}
                        value={loan_amount}
                      />
                      <span className="text-danger">
                        {loan_amount > maximum_loan_amount
                          ? `Your loan amount must be in between of KES ${last_saving} and ${maximum_loan_amount}`
                          : `Maximum loan amount : KES ${maximum_loan_amount}`}
                      </span>
                      <span className="text-danger">
                        {response.loan_amount}
                      </span>
                    </div>
                  </div>

                  {/* PAYMENTS */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Payments</label>
                      <input
                        className="form-control"
                        type="number"
                        name="total_payments"
                        onChange={onChangeTotalPayment}
                        value={total_payments}
                      />
                      <span className="text-danger">
                        {response.total_payments}
                      </span>
                    </div>
                  </div>
                  
                  {/* INTEREST */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Interest</label>
                      <input
                        className="form-control"
                        type="number"
                        name="total_interest"
                        onChange={onChangeTotalInterest}
                        value={total_interest}
                      />
                      <span className="text-danger">
                        {response.total_interest}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {loan_amount > last_saving &&
                loan_amount < maximum_loan_amount &&
                need_guarantor?.guarantor?.map((item, i) => {
                  return (
                    <div key={i} className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Select Guarantor{" "}
                              <span className="required">(*)</span>
                            </label>
                            <Select
                              value={need_guarantor.guarantor[i]}
                              options={guarantors}
                              name="guarantor"
                              onChange={(e) => onChangeGuarantor("label", i, e)}
                            />
                            {need_guarantor?.guarantor[i]?.isChecking && (
                              <span className="text-danger">
                                Checking eligibility...
                              </span>
                            )}
                            {need_guarantor?.guarantor[i]?.last_saving &&
                              need_guarantor?.guarantor[i]?.last_saving
                                ?.free_deposit <= 0 && (
                                <span className="text-danger">
                                  {` No enough funds to contribute`}
                                </span>
                              )}
                            {need_guarantor?.guarantor[i]?.last_saving
                              ?.free_deposit > 0 && (
                              <span className="text-danger">
                                {`Maxiumum contribution from ${need_guarantor?.guarantor[i].label} is: KES ${need_guarantor?.guarantor[i]?.last_saving?.free_deposit}`}
                              </span>
                            )}
                            <span className="text-danger">
                              {need_guarantor.guarantor[i].error?.label}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Guaranted Amount{" "}
                              <span className="required">(*)</span>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              name="guaranted_amount"
                              onChange={(e) =>
                                onChangeGuarantorAmount("amount", i, e)
                              }
                              value={need_guarantor.guarantor[i].amount}
                            />
                            <span className="text-danger">
                              {need_guarantor.guarantor[i].error?.amount}
                            </span>
                          </div>
                        </div>
                        {need_guarantor?.guarantor.length - 1 !== i && (
                          <div className="col-md-2" style={{ marginTop: 25 }}>
                            <button
                              className="btn btn-danger"
                              onClick={() => onRemoveGuarantor(i)}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                        {need_guarantor?.guarantor.length - 1 === i &&
                          !need_guarantor?.guarantor[i]?.isChecking &&
                          need_guarantor?.guarantor[i]?.last_saving
                            ?.free_deposit > 0 &&
                          need_guarantor?.guarantor[i]?.amount > 0 && (
                            <div className="col-md-2" style={{ marginTop: 25 }}>
                              <button
                                className="btn btn-danger"
                                onClick={onAddGuarantor}
                              >
                                Add
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  );
                })}
            </>
          )}

          {/* {purpose.type && loan_period_term === "Monthly" && ( */}
          <div className="col-md-12">
            <div className="form-group">
              <label>
                Contribution <span className="required">(*)</span>
              </label>
              <input
                className="form-control"
                value={purpose.contribution}
                name="contribution_rate"
                onChange={handleformonchange}
                placeholder="0"
              />
              <span className="text-danger">{response.contribution_rate}</span>
            </div>
          </div>
          {/* )} */}
          <div className="col-md-12">
            <div className="form-group">
              <label>
                Start date of loan <span className="required">(*)</span>
              </label>
              <input
                className="form-control"
                value={start_date_loan}
                name="start_date_loan"
                type="date"
                onChange={handleformonchange}
              />
              <span className="text-danger">{response.start_date_loan}</span>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <label>Fleet</label>
              <Select
                value={fleet}
                onChange={handleChangeFleet}
                options={fleets}
                name="fleet"
              />
              <span className="text-danger">{response.fleet}</span>
            </div>
          </div>
          
          <div className="col-md-12">
            <div className="form-group">
              <label>Attach File</label>
              <input
                className="form-control"
                name="file"
                type="file"
                onChange={handleFile}
                multiple
              />
              <span className="text-danger">{response.start_date_loan}</span>
            </div>
          </div>

          {/* FEES */}
          {Object.keys(fees).map((el, i) => {
            return (
              <div className="col-md-4" key={i}>
                <div className="form-group">
                  <label style={{ textTransform: "capitalize" }}>{el}</label>
                  <input
                    type="number"
                    className="form-control"
                    value={fees[el] + ""}
                    onChange={(e) => onChangeFees(el, e)}
                    placeholder="Enter amount"
                  />
                </div>
              </div>
            );
          })}

          {sacco !== "" && purpose.type && (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <button
                    className="btn btn-default  btn-block"
                    onClick={calculateLoanAmortization}
                  >
                    Calculate
                  </button>
                </div>
                {(total_cost_loan > 0 ||
                  (need_guarantor.guarantor &&
                    Object.keys(need_guarantor.guarantor).length > 0 &&
                    last_saving >= loan_amount &&
                    loan_amount < maximum_loan_amount)) && (
                  <div className="col-md-4 col-md-offset-4">
                    <button
                      className="btn btn-primary  btn-block"
                      disabled={isSubmitting}
                      onClick={handleApplyData}
                    >
                      {isSubmitting ? "Sending..." : "Apply"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

      </Card>

      {total_cost_loan > 0 && (
        <>
          {/* SUMMARY */}
          <Card className="col-md-4">
            <div className="panel-heading card-title">
              <span>SUMMARY</span>
            </div>

            <div className="panel-body">
              <p>
                <b>{term} Payment</b>
              </p>
              <p>KES {numberWithCommas(Math.round(term_payment))}</p>
              <br />
              <p>
                <b>Repayment Period</b>
              </p>
              <p>
                {number_payment} {term_name}
              </p>
              <br />
              <p>
                <b>Loan Amount(Principal)</b>
              </p>
              <p>KES {numberWithCommas(Math.round(loan_amount))}</p>
              <br />

              <p>
                <b>Total Interest</b>
              </p>
              <p>KES {numberWithCommas(total_interest)}</p>
              <br />

              <p>
                <b>Total Fees</b>
              </p>
              <p>KES {numberWithCommas(totalFees || 0)}</p>
              <br />

              <p>
                <b>Total Amount Payable</b>
              </p>
              <p>KES {numberWithCommas(total_cost_loan)}</p>
              <br />
            </div>
          </Card>
          
          {/* SCHEDULE */}
          <Card className="col-md-12">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th  style={{textAlign: "center"}}>Date</th>
                  <th  style={{textAlign: "right"}}>Beginning Balance</th>
                  <th  style={{textAlign: "right"}}>Payment</th>
                  <th  style={{textAlign: "right"}}>Principal</th>
                  <th  style={{textAlign: "right"}}>Interest</th>
                  <th  style={{textAlign: "right"}}>Ending Balance</th>
                </tr>
              </thead>
              <tbody>
                {results.length > 0 &&
                  results.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td  style={{textAlign: "center"}}>{item.payment_date}</td>
                        <td  style={{textAlign: "right"}}>{numberWithCommas(item.beginning_balance)}</td>
                        <td  style={{textAlign: "right"}}>{numberWithCommas(item.term_payment)}</td>
                        <td  style={{textAlign: "right"}}>{numberWithCommas(item.principal)}</td>
                        <td  style={{textAlign: "right"}}>{numberWithCommas(item.term_interest)}</td>
                        <td style={{textAlign: "right"}}>{numberWithCommas(item.ending_balance)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Card>
        </>
      )}
    </div>
  );
};

export default ApplyNew;
