import React from "react";
import { Filter } from "../Filter";
import { connect } from "react-redux";
import Daily from "./Daily";
import moment from "moment";
import { onAddFilter } from "../../actions/Filters";
import toastMessage from "../toastMessage";
import axios from "axios";
import HOST_URL from "../../configs/api";
import AppClass from "../../AppClass";
import CardCount from "../CardCount/CardCount";
import { numberWithCommas } from "../../configs/formatCurrency";
import { Loading } from "../Loader";

class Analytics extends React.Component {
  state = {
    start_date: moment().subtract("30", "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    loans: {},
    savings: {},
    payments: {},
  };

  componentDidMount = async () => {
    this.handleInitial();
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.handleInitial();
    }
  }

  handleInitial = async () => {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    await this.setState({ apps: f_apps });

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    } else {
      this.getData(true);
    }

    this.getFiltersFromLCStorage();
  };

  getFiltersFromLCStorage = () => {
    const { filters } = this.props;

    this.setState({ ...filters });

    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== "") {
        this.setState({
          [key]: filters[key],
        });
      }
    });
  };

  onChangeMember(e) {
    this.setState({ member: e }, () => {
      this.getData(true);
      this.props.dispatch(onAddFilter({ member: e.target.value }));
    });
  }

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value, page: 1 }, () => {
      this.getData(true);
      this.props.dispatch(onAddFilter({ start_date: e.target.value }));
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value, page: 1 }, () => {
      this.getData(true);
      this.props.dispatch(onAddFilter({ end_date: e.target.value }));
    });
  }

  handleResetFilters() {
    this.setState(
      {
        loan_id: undefined,
        app_id: undefined,
        member: undefined,
      },
      () => {
        window.location.reload();
      }
    );
  }

  onChangeSacco(e) {
    this.setState({ appid: e }, () => {
      this.getData(true);
      this.props.dispatch(onAddFilter({ appid: e }));
    });
  }

  getData(isLoading) {
    this.setState({ isLoading });

    let { start_date, end_date, apps, appid } = this.state;
    const { ztoken } = this.props;

    let params = {},
      app_id = [];

    if (!appid?.value) {
      app_id = AppClass.get_app_ids(apps);
    } else {
      app_id = [appid.value];
    }

    params.app_id = JSON.stringify(app_id);

    if (start_date !== undefined || end_date !== undefined) {
      params["fromDate"] = start_date;
      params["toDate"] = end_date;
    }

    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/general`,
      params,
      headers: {
        authorization: `Bearer ${ztoken}`,
      },
    };

    axios(options)
      .then((data) => {
        const { loans, savings, payments } = data?.data?.data || {};

        this.setState({
          isLoading: false,
          loans,
          savings,
          payments,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  render() {
    return (
      <>
        <Filter
          appid={this.state.appid}
          loan_id={this.state.loan_id}
          apps={this.state.apps}
          loans={this.state.copyData}
          members={this.state.members}
          member={this.state.member}
          start_date={this.state.start_date}
          end_date={this.state.end_date}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
          onChangeSacco={this.onChangeSacco.bind(this)}
          loan_ids={this.state.loan_ids}
          showReset={this.state?.appid?.value}
          handleResetFilters={this.handleResetFilters.bind(this)}
          onChangeMember={this.onChangeMember.bind(this)}
        />

        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div className="panel-body">
            <div className="row">
              <CardCount
                count={
                  "KES " +
                  numberWithCommas(this.state?.loans?.pending?.totalAmount || 0)
                }
                title="Pending Loans"
                className="col-md-4"
                number={this.state?.loans?.pending?.totalNo || 0}
              />
              <CardCount
                count={
                  "KES " +
                  numberWithCommas(
                    this.state?.loans?.disbursed?.totalAmount || 0
                  )
                }
                title="Disbursed Loans"
                className="col-md-4"
                number={this.state?.loans?.disbursed?.totalNo || 0}
              />
              <CardCount
                count={
                  "KES " +
                  numberWithCommas(this.state?.loans?.cleared?.totalAmount || 0)
                }
                title="Cleared Loans"
                className="col-md-4"
                number={this.state?.loans?.cleared?.totalNo || 0}
              />
            </div>
          </div>
        )}

        {/* <Daily
          start_date={this.state.start_date}
          end_date={this.state.end_date}
          appid={this.state.appid}
          apps={this.state.apps}
          token={this.props.ztoken}
        /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(Analytics);
