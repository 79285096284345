import React from "react";
import PropTypes from "prop-types";

const Body = ({ children }) => <div className="modal-body">{children}</div>;

Body.propTypes = {
  children: PropTypes.any,
};

export default Body;
