import Axios from "axios";
import toastMessage from "../components/toastMessage";
import HOST_URL from "../configs/api";

const updateClientLoadIds = async ({ client, loanInfo, token }) => {
  try {
    const queriedClient = await getClient(client, token);

    let clientLoanIds = queriedClient.loanIds || [];

    let checkExist = clientLoanIds.filter((el) => el.id === loanInfo.id);

    if (checkExist.length === 0) {
      clientLoanIds.push(loanInfo);

      await updateClient({ client, loanIds: clientLoanIds, token });
    }
  } catch (error) {
    toastMessage("error", error);
  }
};

const getClient = async (client, token) => {
  try {
    const options = {
      method: "GET",
      url: `${HOST_URL.mobitill_url}/clients/${client}`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const data = await Axios(options);

    return data.data.data;
  } catch (error) {
    return error;
  }
};

const updateClient = async ({ client, loanIds, token }) => {
  try {
    const options = {
      method: "PUT",
      url: ` ${HOST_URL.mobitill_url}/clients/${client}`,
      data: {
        data: {
          loanIds,
        },
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return await Axios(options);
  } catch (error) {
    toastMessage("error", "Error occurred when update client loan id");
  }
};

export default updateClientLoadIds;
