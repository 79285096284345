import React from "react";
import MemberStatement from "./MemberStatements";
import GeneralReports from "./GeneralReports";
import AggregateReport from "./AggregateReport";

class Report extends React.Component {
  render() {
    return (
      <div className="content-cont">
        <ul className="nav nav-tabs">
          <li className="active">
            <a href="#a" data-toggle="tab">
              General Report
            </a>
          </li>
          <li>
            <a href="#b" data-toggle="tab">
              Member Statements
            </a>
          </li>
          <li>
            <a href="#c" data-toggle="tab">
              Aggregate Statements
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div className="tab-pane active" id="a">
            <GeneralReports {...this.props} />
          </div>
          <div className="tab-pane" id="b">
            <MemberStatement {...this.props} />
          </div>
          <div className="tab-pane" id="c">
            <AggregateReport {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default Report;
