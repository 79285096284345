import React from "react";
import PropTypes from "prop-types";

const Card = ({ className, children }) => (
  <div className={`${className} card`}>
    <div className="panel">{children}</div>
  </div>
);

Card.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default Card;
