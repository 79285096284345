import React from "react";
import axios from "axios";
import toastMessage from "../toastMessage";
import moment from "moment";
import { Body, Footer, Header, Modal } from "../Modal";
import HOST_URL from "../../configs/api";
class Penalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      error: {},
      amount: "",
      show: props.show || false,
    };
  }

  onChangeText(field, e) {
    delete this.state[field];

    this.setState({
      [field]: e.target.value,
    });
  }

  validateForm() {
    let { error, amount } = this.state;

    if (amount === "" || amount <= 0) {
      error.amount = "Amount is required";
    }

    this.setState({ error });
  }

  handleSubmit = async () => {
    await this.validateForm();

    const { amount, error } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      const options = {
        method: "PUT",
        url: `${HOST_URL.url}/add_penalty/${this.props.loan_id}`,
        data: {
          penalty: Number(amount),
        },
      };

      console.log("****add penalty body", options);

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            amount: "",
          });

          console.log(data.data);

          toastMessage("success", "Penalty added successful");

          this.props.handleClose();
          this.props.getData();
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <>
        <Header>
          <h5 class="modal-title">New Penalty</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Header>
        <Body>
          <div className="form-group">
            <label>Amount</label>
            <input
              type="number"
              className="form-control"
              value={this.state.amount}
              onChange={(e) => this.onChangeText("amount", e)}
            />
            {this.state.error.amount && (
              <span className="text-danger">{this.state.error.amount}</span>
            )}
          </div>
        </Body>
        <Footer>
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            onClick={this.props.handleClose}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            onClick={this.handleSubmit.bind(this)}
            disabled={this.state.isSubmitting}
          >
            {this.state.isSubmitting ? "Please wait..." : "Submit"}
          </button>
        </Footer>
      </>
    );
  }
}

export default Penalty;
