import React from "react";
import { Card } from "../../Card";

const LoanDetail = () => (
  <Card className="col-md-12">
    <div className="panel-heading card-title">
      <span>
        Loan Account ID: <b style={{ opacity: 0.8 }}>DIL12333</b>
      </span>
    </div>
    <div className="panel-body">
      <div className="col-md-3 card-home">
        <h3 className="title"> Loan Principle amount</h3>
        <p>
          <span>KES</span> 100,000
        </p>
      </div>
      <div className="col-md-3 card-home">
        <h3 className="title"> Next Payment date On </h3>
        <p>-</p>
      </div>
      <div className="col-md-3 card-home">
        <h3 className="title pending">Outstanding Amount </h3>
        <p className="pending">
          <span>KES</span> 0.00
        </p>
      </div>
      <div className="col-md-3 card-home">
        <h3 className="title pending">End date of Loan Repayment </h3>
        <p className="pending">29th June, 2019</p>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Issued by</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Mwiki Sacco</b>
            </td>
            <td>on 12 May, 2019</td>
            <td className="text-primary">
              <b>Fully Paid</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Card>
);

export default LoanDetail;
