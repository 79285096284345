import React from "react";
import { Card } from "../Card";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import moment from "moment";

const AddNew = ({
  handleformonchange,
  response,
  saving_duration,
  onChangeSacco,
  organization_id,
  organization_name,
  handleSaveData,
  isSubmitting,
  apps,
  term,
  interestrate,
  available,
  total_interest,
  total_saving,
  calculateTotal,
  disabled,
  members,
  user,
  onChangeMember,
  amount,
  sending_sms,
  capital_share,
  handleChangeFleet,
  fleet,
  operations,
  operation,
  onChangeOperation,
  fleets,
  isMulti,
  saving_type,
  isFleet,
  isInterestRate,
  isCapitalShare,
  onAddSavingType,
  openSavingTypeModal,
  saving_types,
  isLoading,
  member,
  appid,
  product,
  products,
  onChangeProduct,
  client_record_date,
  onChangeClientRecordDate
}) => (
  <div>
    <div className="col-md-12">
      {!available && (
        <div className="alert alert-warning">
          <span>{`Please setup ${organization_name}'s saving requirements`}</span>
        </div>
      )}
      {response.last_deposit && response.last_deposit !== "" ? (
        <div className="alert alert-danger">
          <span>{JSON.stringify(response.last_deposit)}</span>
        </div>
      ) : response.success ? (
        <div className="alert alert-success">
          <span>{response.success}</span>
        </div>
      ) : (
        sending_sms && (
          <div className="alert alert-info">
            <span>
              <i className="fa fa-spin fa-spinner"></i>{" "}
              {response.sms_sending_message}
            </span>
          </div>
        )
      )}
    </div>
    <Card className="col-md-12 filter">
      <div className="panel-heading card-title">
        <span>{`${operation} Form`}</span>
      </div>
      <div className="panel-body">
        {/* <div className="col-md-6">
          <div className="form-group">
            <label>
              Operation type <span className="required">(*)</span>
            </label>
            <select
              className="form-control"
              value={operation}
              onChange={onChangeOperation}
              data-live-search="true"
            >
              <option value="" disabled selected>
                Choose type
              </option>
              {operations.map((item, i) => {
                return <option key={i}>{item}</option>;
              })}
            </select>
            <span className="text-danger">{response.operation}</span>
          </div>
        </div> */}
        <div className="col-md-6">
          <div className="form-group">
            <label>
              Date <span className="required">(*)</span>
            </label>
            <input
              className="form-control"
              type="date"
              value={moment(client_record_date).format("YYYY-MM-DD")}
              name="client_record_date"
              onChange={onChangeClientRecordDate}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              Sacco <span className="required">(*)</span>
            </label>
            <Select
              value={appid}
              options={apps}
              name="appid"
              onChange={onChangeSacco}
            />
            {console.log(response)}
            <span className="text-danger">{response.app_id}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>
              Product <span className="required">(*)</span>
            </label>
            <Select
              value={product}
              options={products}
              name="appid"
              onChange={onChangeProduct}
            />
            <span className="text-danger">{response.product_id}</span>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              Member <span className="required">(*)</span>
            </label>
            <Select
              value={member}
              options={members}
              name="member"
              onChange={onChangeMember}
            />
            {/* <select
              className="form-control"
              name="user"
              onChange={onChangeMember}
              data-live-search="true"
            >
              <option>Choose Member</option>
              {members.map((item, i) => {
                return (
                  <option
                    value={item.user_id}
                    key={i}
                    data-phone_number={item.phone_number}
                    data-username={item.user_name}
                    data-appid={organization_id}
                  >
                    {item.user_name}
                  </option>
                );
              })}
            </select> */}
            <span className="text-danger">{response.user_id}</span>
          </div>
        </div>

        {isInterestRate && (
          <>
            <div className="col-md-6">
              <div className="form-group">
                <label>Interest Rate</label>
                <input
                  className="form-control"
                  disabled
                  value={
                    interestrate.length > 0 ? interestrate[0].rate + "%" : ""
                  }
                  name="rate"
                  min={0}
                  max={100}
                  step={0.1}
                  onChange={handleformonchange}
                />
                <span className="text-danger">{response.interestrate}</span>
              </div>
            </div>

            {isCapitalShare && (
              <div className="col-md-6">
                <div className="form-group">
                  <label>Capital Share</label>
                  <input
                    className="form-control"
                    value={capital_share}
                    name="capital_share"
                    min={0}
                    max={100}
                    step={0.1}
                    onChange={handleformonchange}
                  />
                  <span className="text-danger">{response.capital_share}</span>
                </div>
              </div>
            )}
          </>
        )}
        {isFleet && (
          <div className="col-md-6">
            <div className="form-group">
              <label>Fleet</label>
              <CreatableSelect
                isMulti={isMulti}
                value={fleet}
                onChange={handleChangeFleet}
                options={fleets}
                name="fleet"
              />
              <span className="text-danger">{response.fleet}</span>
            </div>
          </div>
        )}
        {/* {operation === "Deposit" && (
          <div className="col-md-6">
            <div className="form-group">
              <label>Saving Type</label>
              <input
                className="form-control"
                disabled
                value={
                  interestrate.length > 0 ? interestrate[0].saving_type : ""
                }
                name="saving_type"
                onChange={handleformonchange}
              />
              <span className="text-danger">{response.loan_type}</span>
            </div>
          </div>
        )} */}
        {isFleet &&
          fleet.length > 0 ?
          fleet.map((item, i) => {
            return (
              <div className="col-md-6" key={i}>
                <div className="form-group">
                  <label>Amount for {item.label}</label>
                  <input
                    className="form-control"
                    type="number"
                    name={`amount_${i}`}
                    value={amount}
                    onChange={handleformonchange}
                  />
                  <span className="text-danger">{response.amount}</span>
                </div>
              </div>
            );
          }) : <div className="col-md-6">
            <div className="form-group">
              <label>
                Amount <span className="required">(*)</span>
              </label>
              <input
                className="form-control"
                type="number"
                name={`amount`}
                value={amount}
                onChange={handleformonchange}
                placeholder="0"
              />
              <span className="text-danger">{response.amount}</span>
            </div>
          </div>
          }


        {/* {operation === "Deposit" && isSavingDuration && (
          <>
            <div className="col-md-6">
              <div className="form-group">
                <label>Saving Duration</label>
                <input
                  className="form-control"
                  value={saving_duration}
                  name="saving_duration"
                  onChange={handleformonchange}
                />
                <span className="text-danger">{response.saving_duration}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Frequency</label>
                <select
                  className="form-control"
                  value={term}
                  name="term"
                  onChange={handleformonchange}
                >
                  <option>Daily</option>
                  <option>Weekly</option>
                  <option>Monthly</option>
                  <option>Yearly</option>
                </select>
                <span className="text-danger">{response.term}</span>
              </div>
            </div>
          </>
        )} */}
        <div className="col-md-6" style={{ marginTop: 15 }}>
          <div className="row">
            {/* <div className="col-md-4">
              {operation === "Deposit" && (
                <button
                  className="btn btn-default  btn-block"
                  onClick={calculateTotal}
                >
                  Calculate
                </button>
              )}
            </div> */}
            {isLoading ? (
              <span>
                <i className="fa fa-spinner fa-spin">
                  {" "}
                  Please wait, Retrieving sacco settings
                </i>
              </span>
            ) : (
              <div className="col-md-4 ">
                <button
                  className="btn btn-primary  btn-block"
                  disabled={isSubmitting ? true : false}
                  onClick={handleSaveData}
                >
                  {isSubmitting ? "Sending..." : "Save"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
    {/* {operation === "Deposit" && (
      <Card className="col-md-6">
        <div className="panel-heading card-title">
          <span>Result</span>
        </div>
        <div className="panel-body">
          <p>
            <b>Total Interest</b>
          </p>
          <span>KES {total_interest}</span>
          <hr />
          <p>
            <b>Saving Duration</b>
          </p>
          <span>{saving_duration} Months</span>
          <hr />
          <p>
            <b>Total Saving</b>
          </p>
          <span>KES {total_saving}</span>
        </div>
      </Card>
    )} */}
  </div>
);
export default AddNew;
