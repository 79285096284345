import React from "react";
import { ViewLoanUpdate } from "../../components/Loan";
import { Empty } from "../../components/Empty";
import { Loading } from "../../components/Loader";
import { Modal, Header, Body, Footer } from "../../components/Modal";
import AppClass from "../../AppClass";
import toastMessage from "../../components/toastMessage";
import axios from "axios";
import HOST_URL from "../../configs/api";
import Select from "react-select";
import updateClientLoanIds from "../../utils/updateClientLoanIds";
import moment from "moment";

class LoanDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      status: "",
      apps: props.apps,
      message: "Please wait...",
      sacco: "",
      response: {},
      isSubmitting: false,
      show: false,
      files: [],
      fleet: "",
      app_id: "",
      guarantor_data: [],
      members: [],
      selected_guarantor_index: "",
      products: [],
      date_approved: moment().format("YYYY-MM-DD"),
      fees: {},
    };
  }

  componentDidMount() {
    this.get_loan_by_id(this.props.somethingID);
  }

  getMembers = async (app_id) => {
    this.setState({ isloadingMembers: true });

    try {
      const members = await AppClass.getRegisteredMembers(
        app_id,
        !this.props.admin ? this.props.user_id : undefined
      );

      this.setState({ members, isloadingMembers: false });
    } catch (error) {
      toastMessage("error", error);
      console.log("#Error on get members", error);

      this.setState({ isloadingMembers: true });
    }
  };

  get_loan_by_id(id) {
    this.setState({ isLoading: true });
    let response = {};
    const body = {
      tag: "by_id",
      ids: [id],
      model: "loan",
    };

    AppClass.get_data(body)
      .then((data) => {
        console.log("loan by id", data.data);
        //get pending / processing loans
        let loan = data.data;
        let fees = {};

        if (
          (loan.length > 0 && loan[0].fees && typeof loan[0].fees) ||
          "" === "string"
        ) {
          fees = JSON.parse(loan[0].fees);
        }

        this.setState(
          {
            ...loan[0],
            isLoading: false,
            status: loan[0].status,
            data: loan,
            approved_amount:
              loan[0].amount > 0 ? loan[0].amount : loan[0].disbursed_amount,
            user_id: loan[0].user_id,
            app_id: loan[0].app_id,
            sacco: loan[0].organization,
            fleet: loan[0].fleet,
            date_approved:
              moment(loan[0].date).format("YYYY-MM-DD") ||
              moment().format("YYYY-MM-DD"),
            fees,
          },
          () => {
            // this.getMembers(loan[0].app_id);
            // // this.getProducts(true, loan[0].app_id);
            // if (
            //   loan.length > 0 &&
            //   loan[0].guaranted_amount &&
            //   Object.keys(loan[0].guaranted_amount).length !== 0
            // ) {
            //   this.get_guaranted(true, loan[0]);
            // }
          }
        );
      })
      .catch((err) => {
        response.error = "Error, " + err;
        console.log("#Error on get loan id", err);
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  getProducts(isloadingProducts, app_id) {
    this.setState({
      isloadingProducts,
    });

    const options = {
      method: "GET",
      url: `${HOST_URL.mobitill_url}/products?0[]=appid&1[]=${app_id}`,
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };

    axios(options)
      .then((data) => {
        let products = [];

        for (let el of data.data.data) {
          if (el.loaning_loans) {
            products.push({
              label: el.name,
              value: el._id,
            });
          }
        }
        this.setState({ isloadingProducts: false, products });
      })
      .catch((error) => {
        this.setState({ isloadingProducts: false });

        toastMessage("error", error);
        console.log("#Error on get product", error);
      });
  }

  get_guaranted = async (isLoadingGuarantorData, loan) => {
    try {
      const guarantor_data = [];

      if (loan.guarantor_save_id && loan.guarantor_save_id.length > 0) {
        for (let last_saving_data of loan.guarantor_save_id) {
          const total_guaranted_amount_data = await this.get_guaranted_amount(
            last_saving_data
          );

          let guaranted_amount =
            total_guaranted_amount_data?.data?.guaranted_amount || 0;

          let total_saving =
            loan?.guaranted_amount[last_saving_data]?.last_saving
              ?.total_saving || 0;

          let free_deposit = total_saving - guaranted_amount;

          guarantor_data.push({
            guaranted_amount,
            user_name: loan?.guaranted_amount[last_saving_data]?.name,
            user_id: last_saving_data,
            total_saving,
            free_deposit,
            requested_amount:
              loan?.guaranted_amount[last_saving_data]?.amount || 0,
          });
        }
      }

      this.setState({
        guarantor_data,
        isLoadingGuarantorData: false,
      });
    } catch (error) {
      toastMessage("error", error);
      this.setState({
        isLoadingGuarantorData: false,
      });
      console.log("#Error on guarantor", error);
    }
  };

  getSavings(app_id, user_id) {
    let response = {};

    const body = {
      app_id,
      model: "save",
      tag: "by_user",
      user_id,
    };

    return AppClass.get_savedata(body);
  }

  get_guaranted_amount(guarantor_save_id) {
    const body = {
      method: "POST",
      url: `${HOST_URL.url}/get_guaranted_amount`,
      data: {
        model: "loan",
        tag: "by_guarantor_saveid",
        guarantor_save_id: [guarantor_save_id],
      },
    };

    return axios(body);
  }

  get_member_savings(isLoadingMemberSavings, app_id, user_id) {
    this.setState({ isLoadingMemberSavings, savings: [] });

    let response = {};

    const body = {
      app_id,
      model: "save",
      tag: "by_last",
      status: "active",
      user_id,
    };

    AppClass.get_savedata(body)
      .then((data) => {
        this.setState({
          isLoadingMemberSavings: false,
        });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoadingMemberSavings: false,
        });
      });
  }

  get_guarantor_saving(app_id, user_id) {
    const { product } = this.state;

    const body = {
      app_id,
      model: "save",
      tag: "by_last",
      status: "active",
      user_id,
    };

    if (product && product.value && product.value !== "all") {
      body["product_id"] = product.value;
    }

    return AppClass.get_savedata(body);
  }

  handleFile(e) {
    const files = Array.from(e.target.files);

    this.setState({
      files,
    });
  }

  update_loan(approved_amount, interestrate, files) {
    this.setState({ isSubmitting: true });

    const input_data = {
      id: this.props.somethingID,
      model: "loan",
      amount: this.state.approved_amount,
      status: "Processing",
      date: new Date(this.state.date_approved).getTime(),
      interestrate: interestrate[0]._id,
    };

    for (var i = 0; i < files.length; i++) {
      input_data["file" + i] = files[i];
    }

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors).length) {
      let response = {};

      AppClass.update_data(input_data)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });
          response.success = "Application has been approved, successful";

          toastMessage("success", response.success);

          window.location.reload();

          this.setState({
            response,
          });
        })
        .catch((err) => {
          // console.log("====================================");
          // console.log(err.response.data);
          // console.log("====================================");
          this.setState({ isSubmitting: false });
          // response.error = "Error, " + err;
          // this.setState({
          //   response,
          // });

          toastMessage("error", err);
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  decline() {
    const input_data = {
      id: this.props.somethingID,
      model: "loan",
      reason: this.state.reason,
      available: false,
    };

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors).length) {
      let response = {};

      if (
        window.confirm("Are you sure you want to decline this application?")
      ) {
        AppClass.delete_data(input_data)
          .then((data) => {
            window.alert("Application has declined successful");
            window.location.href = "/home/admin/applications";
          })
          .catch((err) => {
            this.setState({ isSubmitting: false });
            response.error = "Error, " + err;
            this.setState({
              response,
            });
          });
      }
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  view_purpose(purpose) {
    window.alert(purpose);
  }

  onClientAdminConfirm() {
    this.setState({ isSubmitting: true });

    const input_data = {
      id: this.props.somethingID,
      model: "loan",
      status: "Accepted",
      date: new Date(this.state.date_approved).getTime(),
    };

    const errors = AppClass.validate_form(input_data);

    if (!Object.keys(errors).length) {
      let response = {};

      AppClass.update_data(input_data)
        .then(async (data) => {
          this.setState({
            isSubmitting: false,
          });
          response.success =
            "Application has been approved by client and sacco, successful";

          toastMessage("success", response.success);

          await updateClientLoanIds({
            client: this.state.user_id,
            loanInfo: {
              id: this.state.loan_id,
              type: this.state.purpose,
            },
            token: this.props.ztoken,
          });

          window.location.href = `/home/admin/view_loan_payments/${data.data.loan_id}`;

          this.setState({
            response,
          });
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          response.error = "Error, " + err;
          this.setState({
            response,
          });
        });
    }
  }

  onChangeText(name, e) {
    let { response } = this.state;

    delete response[name];
    this.setState({ [name]: e.target.value, response });
  }

  onDeleteGuarantor(item, index) {
    if (window.confirm("Are you sure, you want to remove this guarantor?")) {
      let { data, guarantor_data } = this.state;

      if (data.length > 0) {
        let { guaranted_amount, guarantor_save_id } = data[0];

        const user_id = item.user_id;

        delete guaranted_amount[user_id];

        let g_save_ids = [];

        for (let i = 0; i < guarantor_save_id.length; i++) {
          if (user_id === guarantor_save_id[i].user_id) {
            guarantor_save_id.splice(i, 1);
          }
        }

        guarantor_data.splice(index, 1);

        for (let g_save of guarantor_save_id) {
          g_save_ids.push(g_save.user_id);
        }

        const input_data = {
          id: data[0]._id,
          model: "loan",
          guaranted_amount: JSON.stringify(guaranted_amount),
          guarantor_save_id: JSON.stringify(g_save_ids),
        };

        AppClass.update_data(input_data)
          .then((data) => {
            this.setState({
              isSubmittingGuarantor: false,
            });

            toastMessage("success", "Guarantor has been deleted successfully");

            window.location.reload();
          })
          .catch((err) => {
            this.setState({ isSubmittingGuarantor: false });
            toastMessage("error", err);
          });
      }
    }
  }

  onEditGuarantor(item, index) {
    this.setState({
      show_guarantor_form: true,
      member: {
        label: item.user_name,
        value: item.user_id,
      },
      guarantor_new_amount: item.requested_amount,
      selected_guarantor_index: index,
      new_guarantor_free_deposit: item.requested_amount,
    });
  }

  onAddNewGuarantor() {
    this.setState({
      show_guarantor_form: true,
      guarantor_new_amount: "",
      selected_guarantor_index: "",
      member: undefined,
    });
  }

  handleCloseGuarantor() {
    this.setState({
      show_guarantor_form: false,
    });
  }

  onChangeMember(e) {
    this.setState(
      {
        member: e,
        isCheckingEligibility: true,
        new_guarantor_free_deposit: undefined,
      },
      async () => {
        const guarantor_saving_saving = await this.get_guarantor_saving(
          [this.state.app_id],
          e.value
        );

        let new_guarantor_free_deposit = 0;

        if (
          guarantor_saving_saving.data &&
          guarantor_saving_saving?.data[0]?.last
        ) {
          const guaranted_amount_data = await this.get_guaranted_amount(
            e.value
          );

          new_guarantor_free_deposit =
            (guarantor_saving_saving?.data[0]?.last.total_saving || 0) -
            (guaranted_amount_data?.data?.guaranted_amount || 0);
        }

        let { member } = this.state;

        this.setState({
          isCheckingEligibility: false,
          new_guarantor_free_deposit,
          new_guarantor_total_saving:
            guarantor_saving_saving?.data[0]?.last.total_saving || 0,
          member: {
            ...member,
            total_saving:
              guarantor_saving_saving?.data[0]?.last.total_saving || 0,
            free_deposit: new_guarantor_free_deposit,
          },
        });
      }
    );
  }

  onUpdateGuarantor = async () => {
    let { data, guarantor_new_amount, member, response } = this.state;

    if (
      guarantor_new_amount === 0 ||
      !guarantor_new_amount ||
      guarantor_new_amount === ""
    ) {
      response.guarantor_new_amount = "Amount is required";
      this.setState({ response });
      return;
    }

    this.setState({ isSubmittingGuarantor: true, data });
    if (data.length > 0) {
      let { guaranted_amount = {} } = data[0];

      let g_save_ids = [];

      if (Object.keys(guaranted_amount || {}).length > 0) {
        for (let member of Object.keys(guaranted_amount)) {
          if (!member) return;
          const guarantor_savings = await this.get_guarantor_saving(
            [this.state.app_id],
            member
          );

          if (guarantor_savings.data) {
            if (
              guarantor_savings.data.length > 0 &&
              guarantor_savings.data[0]
            ) {
              g_save_ids.push(guarantor_savings.data[0].last.user_id);
            } else {
              toastMessage(
                "error",
                `No saving avaialable for ${guaranted_amount[member].name}`
              );

              delete guaranted_amount[member];
            }
          }
        }
      } else {
        g_save_ids.push(member.value);
      }

      if (
        !guaranted_amount[member.value] ||
        Object.keys(guaranted_amount || {}).length === 0
      ) {
        guaranted_amount[member.value] = {
          amount: guarantor_new_amount,
          name: member.label,
          last_saving: {
            total_saving: member.total_saving,
            free_deposit: member.free_deposit,
          },
        };
      } else {
        guaranted_amount[member.value].amount = guarantor_new_amount;
      }

      await this.get_guaranted(true, data[0]);

      const input_data = {
        id: data[0]._id,
        model: "loan",
        guaranted_amount: JSON.stringify(guaranted_amount),
        guarantor_save_id: JSON.stringify(Object.keys(guaranted_amount || [])),
      };

      if (
        Object.keys(member).length > 0 &&
        Object.keys(guaranted_amount).length > 0
      ) {
        AppClass.update_data(input_data)
          .then((data) => {
            this.setState({
              isSubmittingGuarantor: false,

              show_guarantor_form: true,
              guarantor_new_amount: "",
              selected_guarantor_index: "",
            });

            toastMessage("success", "Loan application has been updated");
            this.get_loan_by_id(this.props.somethingID);

            window.location.reload();
          })
          .catch((err) => {
            this.setState({ isSubmittingGuarantor: false });
            toastMessage("error", err);

            console.log("#get guarantor #01", err);
          });
      }
    }
  };

  onChangeProduct(product) {
    this.setState(
      {
        product,
      },
      () => {
        this.onChangeMember(this.state.member);
      }
    );
  }

  render() {
    const {
      data,
      isLoading,
      message,
      sacco,
      response,
      status,
      approved_amount,
      isSubmitting,
      show,
      files,
      user_id,
      app_id,
      fleet,
    } = this.state;

    return (
      <div className="content-cont">
        {isLoading ? (
          <Loading message={message} icon="fa fa-spinner fa-spin" />
        ) : data.length === 0 ? (
          <Empty
            message="Loan ID is missing please go back and try again"
            icon="far fa-sad-tear"
          />
        ) : (
          <ViewLoanUpdate
            sacco={sacco}
            data={data}
            response={response}
            status={status}
            approved_amount={approved_amount}
            handleformonchange={(e) => AppClass.handleformonchange(this, e)}
            update_loan={this.update_loan.bind(this)}
            isSubmitting={isSubmitting}
            decline={this.handleShow.bind(this)}
            view_purpose={this.view_purpose.bind(this)}
            handleFile={this.handleFile.bind(this)}
            files={files}
            logged_user_id={this.props.user_id}
            user_id={user_id}
            fleet={fleet}
            app_id={app_id}
            onClientAdminConfirm={this.onClientAdminConfirm.bind(this)}
            free_savings={this.state.free_savings}
            guarantor_deposit_status={this.state.guarantor_deposit_status}
            total_deposit={this.state.total_deposit}
            total_guaranted={this.state.total_guaranted}
            guarantor_data={this.state.guarantor_data}
            isLoadingGuarantorData={this.state.isLoadingGuarantorData}
            onEditGuarantor={this.onEditGuarantor.bind(this)}
            onDeleteGuarantor={this.onDeleteGuarantor.bind(this)}
            onAddNewGuarantor={this.onAddNewGuarantor.bind(this)}
            isloadingMembers={this.state.isloadingMembers}
            date_approved={this.state.date_approved}
            ztoken={this.props.ztoken}
            fees={this.state.fees}
          />
        )}
        <Modal show={show}>
          <Header>
            <h5 class="modal-title">Why have you declined this loan?</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleClose.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <div className="form-group">
              <textarea
                className="form-control"
                onChange={(e) => AppClass.handleformonchange(this, e)}
                name="reason"
                rows={5}
                placeholder="write your reason here..."
                value={this.state.reason}
              ></textarea>
              <span className="text-danger">{response.reason}</span>
            </div>
          </Body>
          <Footer>
            {isSubmitting ? (
              <button type="button" class="btn btn-primary" disabled>
                Submitting...
              </button>
            ) : (
              <button
                type="button"
                class="btn btn-primary"
                onClick={this.decline.bind(this)}
              >
                Submit
              </button>
            )}
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleClose.bind(this)}
            >
              Close
            </button>
          </Footer>
        </Modal>
        <Modal show={this.state.show_guarantor_form}>
          <Header>
            <h5 class="modal-title">Guarantor</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleCloseGuarantor.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <div className="panel">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Member <span className="required">(*)</span>
                    </label>
                    <Select
                      value={this.state.member}
                      options={this.state.members}
                      name="member"
                      onChange={this.onChangeMember.bind(this)}
                      isDisabled={this.state.selected_guarantor_index !== ""}
                      isLoading={this.state.isloadingMembers}
                      new_guarantor_free_deposit={
                        this.state.new_guarantor_free_deposit
                      }
                    />
                    {this.state.isCheckingEligibility && (
                      <span className="text-danger">Checking eligibility</span>
                    )}
                    {this.state.new_guarantor_free_deposit === 0 && (
                      <span className="text-danger">
                        Insuficient fund to contribute
                      </span>
                    )}
                    <span className="text-danger">
                      {this.state.response.user_id}
                    </span>
                  </div>
                </div>
                {this.state.products.length > 0 && (
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Saving Product</label>
                      <Select
                        value={this.state.product}
                        options={[
                          { label: "All", value: "all" },
                          ...this.state.products,
                        ]}
                        onChange={this.onChangeProduct.bind(this)}
                        isLoading={this.state.isloadingProducts}
                        new_guarantor_free_deposit={
                          this.state.new_guarantor_free_deposit
                        }
                      />
                      {this.state.isFetchingUserSaingProduct && (
                        <span className="text-danger">Please wait...</span>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Amount <span className="required">(*)</span>
                    </label>
                    <input
                      value={this.state.guarantor_new_amount}
                      name="guarantor_new_amount"
                      className="form-control"
                      onChange={(e) =>
                        this.onChangeText("guarantor_new_amount", e)
                      }
                    />
                    <span className="text-danger">
                      {this.state.response.guarantor_new_amount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Body>
          <Footer>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.handleCloseGuarantor.bind(this)}
            >
              Close
            </button>
            {this.state.isSubmittingGuarantor ? (
              <button type="button" class="btn btn-primary" disabled>
                Submitting...
              </button>
            ) : (
              !this.state.isCheckingEligibility &&
              this.state.new_guarantor_free_deposit > 0 && (
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={this.onUpdateGuarantor.bind(this)}
                >
                  Submit
                </button>
              )
            )}
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default LoanDetails;
