import React, { useState } from "react";
import { Card } from "../../Card";
import moment from "moment";
import { numberWithCommas } from "../../../configs/formatCurrency";
import toastMessage from "../../../components/toastMessage";
import HOST_URL from "../../../configs/api";
import Axios from "axios";
import { saveAs } from "file-saver";

const Loan = ({
  loans,
  send_reminder,
  sending_sms,
  response,
  onPressFileImport,
  fromDate,
  toDate,
  app_id,
  ztoken,
  user_id,
  pageLimit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const downloadFile = (file, type) => {
    try {
      toastMessage("success", "Downloaded successfully");
      setIsSubmitting(false);
      const fileBlob = new Blob([file], { type: `application/${type}` });
      saveAs(
        fileBlob,
        `loans_${fromDate}_${toDate}.${type}`
      );
    } catch (error) {
      setIsSubmitting(false);
      toastMessage("Error downloading file:", JSON.stringify(error));
    }
  }

  const handleGenerateReport = (type='csv') => {
    setIsSubmitting(true);
    const options = {
      method: "GET",
      url: `${HOST_URL.url}/loans/${type}`,
      responseType: "blob",
      params: {
        fromDate,
        toDate,
        app_id: JSON.stringify([app_id]),
        status: JSON.stringify(["Accepted","Disbursed","Cleared"]),
        pageLimit,
      },
      headers: {
        authorization: `Bearer ${ztoken}`,
        "Content-Type": `application/${type}`,
      },
    };
    if (user_id) {
      options.params.user_id = user_id;
    }

    Axios(options)
      .then((data) => {
        downloadFile(data.data, type);
      })
      .catch((error) => {
        setIsSubmitting(false);
        toastMessage("error", "Unable to generate loans statement report");
      });
  }

  return (
    <Card className="col-md-12">
      {sending_sms ? (
        <div className="alert alert-info">
          <span>
            <i className="fa fa-spin fa-spinner"></i>{" "}
            {response.sms_sending_message}
          </span>
        </div>
      ) : (
        response.success && (
          <div className="alert alert-success">
            <span>{response.success}</span>
          </div>
        )
      )}
      <div className="panel-heading card-title">
        <span>Loans</span>
        <div className="card-menus">
        <div
            // onClick={onPressFileImport}
            className="icon-default"
            style={{ marginRight: 10 }}
          >
            Loans Template
          </div>
          <div
            onClick={onPressFileImport}
            className="icon-default"
            style={{ marginRight: 10 }}
          >
            Import Loans
          </div>

          <div className="icon-default">
            <div
              onClick={() => handleGenerateReport('csv')}
            >
              {isSubmitting ? "Downloading, Please wait..." : "Download CSV Report"}
            </div>
          </div>
        </div>
      </div>
      <div className="panel-body table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#Loan ID</th>
              <th>Member Name</th>
              <th>Loan Type</th>
              <th className="text-right">Principal</th>
              <th className="text-right">Amt. Payable</th>
              <th className="text-right">Monthly Payment</th>
              <th className="text-right">Amt. Paid</th>
              <th className="text-right">Balance</th>
              <th>Issued At</th>
              <th className="text-center">Loan Status</th>
              <th className="text-center">Payment Status</th>
              {/* <th></th>
              <th>Actions</th> */}
            </tr>
          </thead>

          <tbody>
            {loans.length > 0 ? (
              loans.map((item, i) => {
                let end_date = moment(item.date)
                  .add(item.payment_duration, "months")
                  .format("YYYY-MM-DD");
                let today = moment().format("YYYY-MM-DD");
                let outstanding_amount = item.amount;

                let isDefaulted = item.payment_status === "Behind Schedule";
                return (
                  <tr
                    key={i}
                    style={
                      isDefaulted
                        ? { background: "#fae3e3" }
                        : { background: "#fffff" }
                    }
                  >
                    <td>
                      <a href={`view_loan_payments/${item.loan_id}`}>
                        {item.loan_id}
                      </a>
                    </td>
                    <td>{item.user_name}</td>
                    <td>{item.purpose}</td>
                    <td className="text-right">KES {numberWithCommas(item.req_amount)}</td>
                    <td className="text-right">KES {numberWithCommas(item.disbursed_amount)}</td>
                    <td className="text-right">KES {numberWithCommas(item.monthly_payment)}</td>
                    <td className="text-right">KES {numberWithCommas(item.total_payment)}</td>
                    <td className="text-right">KES {numberWithCommas(outstanding_amount)}</td>
                    <td>{moment(item.date).format("ll")}</td>
                    <td className="text-center">
                      <span
                        className={`badge badge-${
                          item.status === "Accepted"
                            ? "info"
                            : item.status === "Cleared"
                            ? "cleared"
                            : "success"
                        }`}
                      >
                        {item.status?.toUpperCase()}
                      </span>
                    </td>
                    <td className="text-center">
                      <span
                        className={`badge badge-${
                          item.payment_status === "On Schedule"
                            ? "info"
                            : item.payment_status === "Behind Schedule"
                            ? "danger"
                            : "cleared"
                        }`}
                      >
                        {item.payment_status?.toUpperCase()}
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} align="center">
                  No data here
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};
export default Loan;
