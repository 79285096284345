import React from "react";
import { Filter } from "../../components/Filter";
import { Loading } from "../../components/Loader";
import toastMessage from "../../components/toastMessage";
import axios from "axios";
import HOST_URL from "../../configs/api";
import moment from "moment";
import AppClass from "../../AppClass";
import { numberWithCommas } from "../../configs/formatCurrency";
import CardCount from "../../components/CardCount/CardCount";

class GeneralReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: [],
      loans: [],
      isLoading: false,
      start_date: moment().subtract("30", "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      reports: [],
      loans: {},
      payments: {},
      savings: {},
    };
  }

  componentDidMount = async () => {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    await this.setState({ apps: f_apps });

    this.get_reports(true);

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    }
  };

  onChangeSacco = async (e) => {
    await this.setState({ appid: e });

    //reports
    this.get_reports(true);
  };

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value }, () => {
      this.get_reports(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value }, () => {
      this.get_reports(true);
    });
  }

  get_reports(isLoading) {
    this.setState({ isLoading });

    const { start_date, end_date, apps, appid } = this.state;
    let { ztoken } = this.props;

    let params = {},
      app_id = [];

    if (!appid?.value) {
      app_id = AppClass.get_app_ids(apps);
    } else {
      app_id = [appid.value];
    }

    params.app_id = JSON.stringify(app_id);

    if (start_date !== undefined || end_date !== undefined) {
      params["fromDate"] = start_date;
      params["toDate"] = end_date;
    }

    const options = {
      method: "GET",
      url: `${HOST_URL.url}/reports/general`,
      params,
      headers: {
        authorization: `Bearer ${ztoken}`,
      },
    };

    axios(options)
      .then((data) => {
        const { loans, savings, payments } = data?.data?.data || {};
        console.log(data.data);
        this.setState({
          isLoading: false,
          loans,
          savings,
          payments,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  render() {
    return (
      <div className="content-cont">
        <div className="panel-body" style={{ paddingLeft: 0 }}>
          <Filter
            appid={this.state.appid}
            apps={this.state.apps}
            start_date={this.state.start_date}
            end_date={this.state.end_date}
            response={this.state.response}
            onChangeStartingDate={this.onChangeStartingDate.bind(this)}
            onChangeEndDate={this.onChangeEndDate.bind(this)}
            onChangeSacco={this.onChangeSacco.bind(this)}
          />
          <div className="col-md-12">
            {this.state.isLoading ? (
              <Loading message="Please wait..." icon="fa fa-spinner fa-spin" />
            ) : (
              <>
                <div className="row">
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(
                        this.state?.loans?.pending?.totalAmount || 0
                      )
                    }
                    title="Pending Loans"
                    className="col-md-4"
                    number={this.state?.loans?.pending?.totalNo || 0}
                  />
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(
                        this.state?.loans?.disbursed?.totalAmount || 0
                      )
                    }
                    title="Disbursed Loans"
                    className="col-md-4"
                    number={this.state?.loans?.disbursed?.totalNo || 0}
                  />
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(
                        this.state?.loans?.cleared?.totalAmount || 0
                      )
                    }
                    title="Cleared Loans"
                    className="col-md-4"
                    number={this.state?.loans?.cleared?.totalNo || 0}
                  />
                </div>
                <div className="row">
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(this.state?.payments?.totalAmount || 0)
                    }
                    title="Total Payments"
                    className="col-md-4"
                    number={this.state?.payments?.totalNo || 0}
                  />
                  <CardCount
                    count={
                      "KES " +
                      numberWithCommas(this.state?.savings?.totalAmount || 0)
                    }
                    title="Total Savings"
                    className="col-md-4"
                    number={this.state?.savings?.totalNo || 0}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralReports;
