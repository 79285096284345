import React from "react";
import { Filter } from "../../components/Filter";
import { LoanHistory } from "../../components/Loan";
import AppClass from "../../AppClass";
import moment from "moment";
import { Loading } from "../../components/Loader";

let copyData = [];

class Loan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {},
      apps: props.apps,
      loan_ids: [],
      loans: [],
      isLoading: false,
      start_date: moment().subtract("30", "days"),
      end_date: moment(),
    };
  }

  componentDidMount() {
    const { apps } = this.props;

    let f_apps = [];

    for (let el of apps) {
      f_apps.push({
        label: el.name,
        value: el._id,
      });
    }

    this.setState({ apps: f_apps }, () => {
      this.get_loan_by_apps(apps);
    });

    if (apps.length === 1) {
      this.onChangeSacco({ label: apps[0].name, value: apps[0]._id });
    }
  }

  get_loan_by_apps = async (apps) => {
    let app_ids = await AppClass.get_app_ids(apps);

    this.get_loans(true, app_ids);
    this.setState({ app_ids });
  };

  onChangeSacco(e) {
    this.setState({ appid: e }, () => {
      this.get_loans(true);
    });
  }

  onChangeLoanID(e) {
    this.setState({ loan_id: e }, () => {
      this.get_loans(true);
    });
  }

  onChangeStartingDate(e) {
    this.setState({ start_date: e.target.value }, () => {
      this.get_loans(true);
    });
  }

  onChangeEndDate(e) {
    this.setState({ end_date: e.target.value }, () => {
      this.get_loans(true);
    });
  }

  get_loans(isLoading, app_ids) {
    this.setState({ isLoading });

    let response = {},
      status = ["Accepted", "Disbursed", "Cleared"],
      { start_date, end_date, appid } = this.state;

    const _start_date = new Date(start_date).getTime();
    const _end_date = new Date(end_date).getTime();

    let _appid = "";

    if (!appid || !appid?.value) {
      _appid = AppClass.get_app_ids(app_ids);
    } else {
      _appid = [appid.value];
    }

    const body = {
      user_id: this.props.user_id,
      model: "loan",
      tag: "by_last",
      status,
      app_id: _appid,
    };
    if (start_date !== undefined || end_date !== undefined) {
      body["start_date"] = _start_date;
      body["end_date"] = _end_date;
    }

    if (this.state.loan_id && this.state.loan_id.value) {
      body.tag = "by_loan_id";
      body.loan_id = this.state.loan_id.value;
    }

    AppClass.get_data(body)
      .then((data) => {
        //get accepted loans
        let arrayData = [];

        for (var i = 0; i < data.data.length; i++) {
          if (data.data[i].last) {
            arrayData.push(data.data[i].last);
          } else if (data.data[i].total_payment === 0) {
            arrayData.push(data.data[i]);
          }

          if (i + 1 === data.data.length) {
            this.setState({ isLoading: false });
          }
        }

        let all_loans = this.state.loans.concat(arrayData);

        copyData = all_loans.slice(0);

        arrayData.sort(function (a, b) {
          return b.date - a.date;
        });

        this.setState({ isLoading: false, loans: arrayData });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  render() {
    const {
      apps,
      response,
      loans,
      isLoading,
      start_date,
      end_date,
      loan_id,
      appid,
    } = this.state;

    const csvData = [
      [
        "Loan Principle amount",
        "Next Payment date On",
        "Outstanding Amount",
        "End date of Loan Repayment",
        "Status",
      ],
    ];

    for (var i = 0; i < loans.length; i++) {
      var end_date_ = moment(loans.date)
        .add(loans.payment_duration, "months")
        .format("YYYY-MM-DD");
      var today = moment().format("YYYY-MM-DD");
      var isCurrent =
        end_date_ >= today && loans.amount - loans.last_payment !== 0;
      csvData.push([
        loans[i].req_amount,
        moment(loans[i].date).add(30, "days").format("ll"),
        "KES " + (loans[i].amount - loans[i].last_payment),
        moment(loans[i].date)
          .add(loans[i].payment_duration, "months")
          .format("ll"),
        isCurrent ? "Current" : "Overdue",
      ]);
    }
    return (
      <div className="content-cont">
        <Filter
          apps={apps}
          loans={copyData}
          appid={appid}
          loan_id={loan_id}
          response={response}
          handleformonchange={(e) => AppClass.handleformonchange(this, e)}
          onChangeSacco={this.onChangeSacco.bind(this)}
          onChangeLoanID={this.onChangeLoanID.bind(this)}
          start_date={start_date}
          end_date={end_date}
          loan_ids={this.state.loan_ids}
          onChangeStartingDate={this.onChangeStartingDate.bind(this)}
          onChangeEndDate={this.onChangeEndDate.bind(this)}
        />
        {isLoading ? (
          <Loading message="Please wait..." icon="fa fa-spinner fa-spin" />
        ) : (
          <div>
            <LoanHistory
              className="col-md-12"
              loans={loans}
              csvData={csvData}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Loan;
