// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.profile {
  }
  
  div.user-profile {
    /* width: 30px;
    height: 30px;
    background: url("http://www.sclance.com/pngs/image-placeholder-png/image_placeholder_png_698953.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 5px; */
  }`, "",{"version":3,"sources":["webpack://./src/App/components/Profile/styles.css"],"names":[],"mappings":"AAAA;EACE;;EAEA;IACE;;;;;;;yBAOqB;EACvB","sourcesContent":["div.profile {\n  }\n  \n  div.user-profile {\n    /* width: 30px;\n    height: 30px;\n    background: url(\"http://www.sclance.com/pngs/image-placeholder-png/image_placeholder_png_698953.png\") no-repeat center center;\n    -webkit-background-size: cover;\n    -moz-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n    border-radius: 5px; */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
