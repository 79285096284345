import React, { useState } from "react";
import { Card } from "../Card";
import moment from "moment";
import { numberWithCommas } from "../../configs/formatCurrency";
import Axios from "axios";
import HOST_URL from "../../configs/api";
import toastMessage from "../toastMessage";
import { saveAs } from "file-saver";

const Payment = ({
  response,
  className,
  role,
  payments,
  loan_id,
  showAddPayment,
  fromDate,
  toDate,
  app_id,
  ztoken,
  user_id,
}) => {

  const [isSubmitting, setIsSubmitting] = useState(false);

  const downloadFile = (file, type) => {
    try {
      toastMessage("success", "Downloaded successful");
      setIsSubmitting(false);
      const fileBlob = new Blob([file], { type: `application/${type}` });
      saveAs(
        fileBlob,
        `payments_${fromDate}_${toDate}.${type}`
      );
    } catch (error) {
      setIsSubmitting(false);
      toastMessage("Error downloading file:", JSON.stringify(error));
    }
  }

  const handleGenerateReport = (type='csv') => {
    setIsSubmitting(true);
    const options = {
      method: "GET",
      url: `${HOST_URL.url}/repayments/${type}`,
      responseType: "blob",
      params: {
        fromDate,
        toDate,
        app_id: JSON.stringify([app_id]),
      },
      headers: {
        authorization: `Bearer ${ztoken}`,
        "Content-Type": `application/${type}`,
      },
    };
    if (user_id) {
      options.params.user_id = user_id;
    }

    Axios(options)
      .then((data) => {
        downloadFile(data.data, type);
      })
      .catch((error) => {
        setIsSubmitting(false);
        toastMessage("error", "Unable to generate repayments statement report");
      });
  }

  return (
    <div>
      <Card className={className}>
        {response.error ? (
          <div className="alert alert-danger">
            <span>{response.error}</span>
          </div>
        ) : (
          response.success && (
            <div className="alert alert-success">
              <span>{response.success}</span>
            </div>
          )
        )}
        <div className="panel-heading card-title">
          <span>Payments {loan_id}</span>
          <div className="card-menus">
            <div className="icon-default"
                onClick={() => handleGenerateReport('csv')}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Downloading, Please wait..." : "Download CSV Report"} 
            </div>
            {(role === "admin" || showAddPayment) && (
              <div className="icon-danger">
                <a href={`/home/admin/add_payment/${loan_id}`}>
                  <i className="" />
                  <i className="fa fa-plus" /> Add New
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="panel-body table-responsive">
          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Loan ID</th>
                <th>Payer</th>
                <th>Amount Paid</th>
                <th>Current Balance</th>
                <th>Total Payment</th>
                <th>Transaction Date</th>
                <th>System Date</th>
                <th>Cashier</th>
                <th>Ticket Number</th>
                <th>Device ID</th>
              </tr>
            </thead>
            <tbody>
              {payments.length > 0 ? (
                payments.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <a
                        href={`/home/admin/view_loan_payments/${item.loan_id}`}
                      >
                        {item.loan_id}
                      </a>
                    </td>
                    <td>{item.user_name.toUpperCase()}</td>
                    <td>KES {numberWithCommas(item.last_payment)}</td>
                    <td>
                      <b>KES {numberWithCommas(item.amount)}</b>
                    </td>
                    <td>
                      <b>KES {numberWithCommas(item.total_payment)}</b>
                    </td>
                    <td>{moment(item.payment_date).format("ll")}</td>
                    <td>{moment(item.date).format("ll")}</td>
                    <td>{item.cashier}</td>
                    <td>{item.tickno}</td>
                    <td>{item.deviceId}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} align="center">
                    No data here
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default Payment;
