import React from "react";
import { Trusts } from "../../components/Trusts";

class TrustScreen extends React.Component {
  render() {
    return (
      <div>
        <Trusts
          apps={this.props.apps}
          token={this.props.ztoken}
          role={this.props.role}
          user_id={this.props.user_id}
        />
      </div>
    );
  }
}

export default TrustScreen;
