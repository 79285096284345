import React from "react";
import { Card } from "../Card";
import { numberWithCommas } from "../../configs/formatCurrency";
import moment from "moment";

const TransactionReversalForm = ({
  onChangeText,
  isSubmitting,
  handleSaveData,
  isLoading,
  error,
  tickno,
  savingInfo,
}) => (
  <div>
    <div>
      {/* {error.errorMessage && (
        <div className="alert alert-danger">
          <span>{error.errorMessage}</span>
        </div>
      )} */}
      <ul className="list-group">
        <li className="list-group-item" style={{ display: "flex" }}>
          <span style={{ flex: 1 }}>Transaction ID:</span>{" "}
          <b>{savingInfo.tickno}</b>
        </li>
        <li className="list-group-item" style={{ display: "flex" }}>
          <span style={{ flex: 1 }}>Type:</span> <b>{savingInfo.type}</b>
        </li>
        <li className="list-group-item" style={{ display: "flex" }}>
          <span style={{ flex: 1 }}>Member Name:</span>{" "}
          <b>{savingInfo.user_name}</b>
        </li>
        <li className="list-group-item" style={{ display: "flex" }}>
          <span style={{ flex: 1 }}>Sacco:</span>{" "}
          <b>{savingInfo.organization}</b>
        </li>
        <li className="list-group-item" style={{ display: "flex" }}>
          <span style={{ flex: 1 }}>Source:</span> <b>{savingInfo.deviceId}</b>
        </li>
        <li className="list-group-item" style={{ display: "flex" }}>
          <span style={{ flex: 1 }}>Cashier:</span> <b>{savingInfo.cashier}</b>
        </li>
        <li className="list-group-item" style={{ display: "flex" }}>
          <span style={{ flex: 1 }}>Transaction Date:</span>{" "}
          <b>{moment(savingInfo.client_record_date).format("YYYY-MM-DD")}</b>
        </li>
        <li className="list-group-item" style={{ display: "flex" }}>
          <span style={{ flex: 1 }}>Date:</span>{" "}
          <b>{moment(savingInfo.date).format("YYYY-MM-DD")}</b>
        </li>
        <li className="list-group-item" style={{ display: "flex" }}>
          <span style={{ flex: 1 }}>Amount:</span>{" "}
          <b>KES {numberWithCommas(savingInfo.amount)}</b>
        </li>
      </ul>
    </div>
    <Card>
      <div className="panel-body">
        <div className="col-md-12">
          <div className="form-group">
            <label>Transaction ID</label>
            <input
              className="form-control"
              name="tickno"
              value={tickno}
              onChange={(e) => onChangeText("tickno", e)}
            />
            <span className="text-danger">{error.tickno}</span>
          </div>
        </div>
        <div className="col-md-3">
          <button
            className="btn btn-primary  btn-block"
            disabled={isSubmitting || isLoading ? true : false}
            onClick={handleSaveData}
          >
            {isSubmitting ? "Sending..." : "Submit"}
          </button>
        </div>
      </div>
    </Card>
  </div>
);
export default TransactionReversalForm;
