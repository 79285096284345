// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.empty {
  height: 400px;
  opacity: .4;
  display: flex;
  align-items: center;
  justify-content: center
}

div.empty i{
  font-size: 100px;
  margin-bottom: 20px
}

div.empty p {
  font-size: 20px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/App/components/Empty/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB;AACF;;AAEA;EACE,gBAAgB;EAChB;AACF;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["div.empty {\n  height: 400px;\n  opacity: .4;\n  display: flex;\n  align-items: center;\n  justify-content: center\n}\n\ndiv.empty i{\n  font-size: 100px;\n  margin-bottom: 20px\n}\n\ndiv.empty p {\n  font-size: 20px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
