// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
  display: flex;
  align-items: center;
}

.pagination-btn-content {
  display: flex;
  align-items: center;
}

.pagination-item {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ddd;
}

.pagination-counts {
  margin: 0 1rem;
}

.pagination-container .form-group {
  margin-top: 15px;
  margin-right: 15px;
}

.pagination-container .form-group .form-control {
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/App/components/Pagination/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".pagination-container {\n  display: flex;\n  align-items: center;\n}\n\n.pagination-btn-content {\n  display: flex;\n  align-items: center;\n}\n\n.pagination-item {\n  width: 35px;\n  height: 35px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 2px solid #ddd;\n}\n\n.pagination-counts {\n  margin: 0 1rem;\n}\n\n.pagination-container .form-group {\n  margin-top: 15px;\n  margin-right: 15px;\n}\n\n.pagination-container .form-group .form-control {\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
