import React from "react";
import { Card } from "../../Card";
import moment from "moment";
import { CSVLink } from "react-csv";
import { numberWithCommas } from "../../../configs/formatCurrency";

const LoanHistory = ({ className, loans, csvData, printcsv }) => (
  <Card className={`${className}`}>
    <div className="panel-heading card-title">
      <span>My Loans</span>
      <div className="card-menus">
        <div className="icon-default" onClick={printcsv}>
          <CSVLink
            data={csvData}
            filename={"loan-history" + new Date().getTime() + ".csv"}
          >
            CSV
          </CSVLink>
        </div>
      </div>
    </div>
    <div className="panel-body table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Principle amount</th>
            <th> Next Payment date On</th>
            <th>Outstanding Amount</th>
            <th>End date of Loan Repayment</th>
            <th>Product</th>
            <th>Status</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {loans.length > 0 ? (
            loans.map((item, i) => {
              var end_date = moment(item.date)
                .add(item.payment_duration, "months")
                .format("YYYY-MM-DD");
              var today = moment().format("YYYY-MM-DD");

              var status =
                end_date >= today && item.amount - item.last_payment !== 0
                  ? "Current"
                  : end_date >= today && item.amount - item.last_payment === 0
                  ? "full"
                  : "overdue";
              return (
                <tr
                  key={i}
                  style={
                    status === "full"
                      ? { background: "#c4ffc5" }
                      : status === "overdue"
                      ? { background: "#ffbdbd" }
                      : { background: "#fffff" }
                  }
                >
                  <td>
                    <b>KHS {numberWithCommas(item.req_amount)}</b>
                  </td>
                  <td>{moment(item.date).add(30, "days").format("ll")}</td>
                  <td>
                    {item.amount - item.last_payment > 0
                      ? "KES " +
                        numberWithCommas(item.amount - item.last_payment)
                      : 0}
                  </td>
                  <td>
                    {moment(item.date)
                      .add(item.payment_duration, "months")
                      .format("ll")}
                  </td>
                  <td>{item.product_id}</td>
                  {status === "Current" ? (
                    <td>Active</td>
                  ) : status === "full" ? (
                    <td>Full paid</td>
                  ) : (
                    <td className="text-danger">Overdue</td>
                  )}
                  <td width="5%" align="center">
                    <a
                      href={`view_loan_payments/${item.loan_id}`}
                      className="success"
                    >
                      <i className="fa fa-eye"></i> View
                    </a>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={10} align="center">
                No data here
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </Card>
);

export default LoanHistory;
