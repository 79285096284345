import React from "react";
import { Card } from "../../Card";
import moment from "moment";
import { CSVLink } from "react-csv";
import { numberWithCommas } from "../../../configs/formatCurrency";

const Loan = ({ loans, printcsv, csvData }) => (
  <Card className="col-md-12">
    <div className="panel-heading card-title">
      <span>Loan</span>
      <div className="card-menus">
        <div className="icon-default" onClick={printcsv}>
          <CSVLink
            data={csvData}
            filename={"loan" + new Date().getTime() + ".csv"}
          >
            CSV
          </CSVLink>
        </div>
      </div>
    </div>
    <div className="panel-body">
      <div className="col-md-3 card-home">
        <h3 className="title"> Loan Principle amount</h3>
        <p>
          <span>KES</span>{" "}
          {loans.length > 0 ? numberWithCommas(Math.round(loans[0].amount)) : 0}
        </p>
      </div>
      <div className="col-md-3 card-home">
        <h3 className="title"> Next Payment date On </h3>
        <p>
          {
            <span>
              {loans.length > 0 && loans[0].date
                ? moment(loans[0].date).add(30, "days").format("ll")
                : "-"}
            </span>
          }
        </p>
      </div>
      <div className="col-md-3 card-home">
        <h3 className="title pending">Outstanding Amount </h3>
        <p className="pending">
          <span>KES</span>{" "}
          {loans.length > 0 && loans[0].amount - loans[0].last_payment > 0
            ? numberWithCommas(
                Math.round(loans[0].amount - loans[0].last_payment)
              )
            : 0}
        </p>
      </div>
      <div className="col-md-3 card-home">
        <h3 className="title pending">End date of Loan Repayment </h3>
        <p className="pending">
          {loans.length > 0
            ? moment(loans[0].date)
                .add(loans[0].payment_duration, "months")
                .format("ll")
            : "-"}
        </p>
      </div>
    </div>
  </Card>
);

export default Loan;
