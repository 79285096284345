import React from "react";
import PropTypes from "prop-types";

const Modal = ({ children, show, size }) => (
  <div
    className={`modal fade in ${show && "show"}`}
    tabIndex="-1"
    role="dialog"
    data-backdrop="backdrop"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      className={size === "lg" ? "modal-dialog modal-lg" : "modal-dialog"}
      role="document"
    >
      <div className="modal-content">{children}</div>
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.any,
};

export default Modal;
