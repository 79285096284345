// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remove {
  color: rgb(243, 35, 35);
}

.status b {
  font-size: 12px;
  white-space: nowrap;
}

td {
  white-space: nowrap;
}

.text-disabled {
  opacity: 0.8;
}

td a.success {
  opacity: 0.8;
  font-size: 11px
}

td a.danger {
  opacity: 0.8;
  font-size: 11px;
  color: red
}
`, "",{"version":3,"sources":["webpack://./src/App/components/application/styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ;AACF;;AAEA;EACE,YAAY;EACZ,eAAe;EACf;AACF","sourcesContent":[".remove {\n  color: rgb(243, 35, 35);\n}\n\n.status b {\n  font-size: 12px;\n  white-space: nowrap;\n}\n\ntd {\n  white-space: nowrap;\n}\n\n.text-disabled {\n  opacity: 0.8;\n}\n\ntd a.success {\n  opacity: 0.8;\n  font-size: 11px\n}\n\ntd a.danger {\n  opacity: 0.8;\n  font-size: 11px;\n  color: red\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
