import React from "react";
import { Card } from "../Card";
import moment from "moment";
import getTotalFees from "../../utils/getTotalFees";
import { numberWithCommas } from "../../configs/formatCurrency";

const ApplicationList = ({
  role,
  loans,
  apps,
  delete_data,
  view_purpose,
  handleOtherColumn,
  other_column,
  user_id,
}) => (
  <Card className="col-md-12">
    <div className="panel-heading card-title">
      <span>Applications</span>
      <div className="col-md-4">
        <div className="form-group">
          <select
            className="form-control"
            style={{ height: 30, marginTop: 15, boxShadow: "none" }}
            onChange={handleOtherColumn}
          >
            <option>
              {other_column === "" ? "Show other column" : "Hide other column"}
            </option>
            <option>Number Tyre</option>
          </select>
        </div>
      </div>
    </div>
    {role === "admin" ? (
      <div className="panel-body table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#ID</th>
              <th>Borrower</th>
              <th>Principal Amount</th>
              <th>Interest</th>
              <th>Fees</th>
              <th>Amount Payable</th>
              <th>Purpose</th>
              <th>Payment Duration</th>
              {other_column === "Number Tyre" && <th>Number Tyre</th>}
              <th>Status</th>
              <th>Date</th>
              <th>-</th>
            </tr>
          </thead>
          <tbody>
            {loans.length > 0 ? (
              loans.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <a
                        href={
                          item.status === "Pending" &&
                          item.createdBy === user_id
                            ? `/home/admin/apply_loan/${item._id}`
                            : `view_loan_application/${item._id}`
                        }
                      >
                        {item.loan_id}
                      </a>
                    </td>
                    <td>{item.user_name}</td>
                    <td>KES {numberWithCommas(item.req_amount)}</td>
                    <td>KES {numberWithCommas(item.expected_interest)}</td>
                    <td>KES {getTotalFees(item.fees)}</td>
                    <td>KES {numberWithCommas(item.disbursed_amount)}</td>
                    <td>{item.purpose}</td>
                    <td>{item.payment_duration} Month(s)</td>
                    {other_column === "Number Tyre" && (
                      <td>{item.number_tyres}</td>
                    )}

                    <td className="text-success">{item.status}</td>
                    <td>{moment(item.date).format("lll")}</td>
                    <td width="5%" align="center">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1.5rem",
                        }}
                      >
                        {item.status === "Pending" && (
                          <a
                            href={`view_loan_application/${item._id}`}
                            className="remove"
                          >
                            <i className="fa fa-eye" /> View
                          </a>
                        )}
                        {item.status === "Processing" && (
                          <a
                            href={`view_loan_application/${item._id}`}
                            className="remove"
                          >
                            <i className="fa fa-eye" /> View
                          </a>
                        )}

                        {item.status === "Pending" &&
                          item.createdBy === user_id && (
                            <a
                              href={`view_loan_application/${item._id}`}
                              className="remove"
                            >
                              <i className="fa fa-edit" /> Edit
                            </a>
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} align="center">
                  No data here
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="panel-body table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colSpan={2}>-</th>
              <th>Sacco</th>
              <th>Requested Amount</th>
              <th>Purpose</th>
              <th>Amount Payable</th>
              {/* <th>Total Interest</th> */}
              <th>Payment Duration</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {loans.length > 0 ? (
              loans.map((item, i) => {
                return (
                  <tr key={i}>
                    <td width="5%" align="center">
                      <a href={`loandetails/${item._id}`} className="success">
                        <i className="fa fa-eye"></i> View
                      </a>
                    </td>
                    <td width="5%" align="center">
                      <a
                        href=""
                        className="danger"
                        onClick={(e) => delete_data(e, item._id)}
                      >
                        <i className="fa fa-trash"></i> Delete
                      </a>
                    </td>
                    <td
                      onClick={() =>
                        (window.location.href = `loandetails/${item._id}`)
                      }
                    >
                      {item.organization}
                    </td>
                    <td
                      onClick={() =>
                        (window.location.href = `loandetails/${item._id}`)
                      }
                    >
                      KES {numberWithCommas(parseFloat(item.req_amount))}
                    </td>
                    <td
                      onClick={() =>
                        (window.location.href = `loandetails/${item._id}`)
                      }
                    >
                      {item.purpose}
                    </td>
                    <td
                      onClick={() =>
                        (window.location.href = `loandetails/${item._id}`)
                      }
                    >
                      {numberWithCommas(item.disbursed_amount)}
                    </td>
                    {/* <td>
                      {item.interestrate.length > 0 &&
                        item.interestrate[0].long_term.setting.map(
                          (data, i) => {
                            if (data.type === item.purpose) {
                              return (
                                <span key={i}>
                                  {item.interestrate[0].long_term.interestrate}{" "}
                                  %
                                </span>
                              );
                            }
                          }
                        )}
                    </td> */}
                    {/* <td
                      onClick={() =>
                        (window.location.href = `loandetails/${item._id}`)
                      }
                    >
                      KES {item.total_interest}
                    </td> */}
                    <td
                      onClick={() =>
                        (window.location.href = `loandetails/${item._id}`)
                      }
                    >
                      {item.payment_duration} Month(s)
                    </td>
                    <td
                      onClick={() =>
                        (window.location.href = `loandetails/${item._id}`)
                      }
                      className="text-primary status"
                    >
                      {item.status}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} align="center">
                  No data here
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )}
  </Card>
);

export default ApplicationList;
